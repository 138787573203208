import { Emitter } from '../core'
import store from '../store'
import { bounds, qs, qsa } from '../utils'
import gsap from 'gsap'

export const animateScroll = (animation) => {
  const { M_UP } = store.breakpoints
  const isSmooth = store.flags.smooth

  switch (animation) {
    case 'hero-asset':
      const heroSubtitle = qsa('.hero-subtitle .-t4')
      const hero = gsap.timeline({
        scrollTrigger: {
          trigger: '.hero-asset',
          start: 'top top+=70%',
          end: 'bottom bottom+=20%',
          scrub: true,
          once: true,
        },
        onComplete: () => {
          // Emitter.emit('animation:ended', heroSubtitle)
        },
      })

      hero.to('.hero-asset', { scale: 1, borderRadius: '0' })
      hero.from('.hero-subtitle .char-2', {
        duration: 0.5,
        y: '100%',
        stagger: 0.05,
      })

      break

    case 'statement':
      const statementLines = qsa('.statement .line-0')
      const icons = qsa('.statement-icon .-cover')

      const statement = gsap.timeline({
        scrollTrigger: {
          trigger: '.statement-text',
          start: 'top top+=75%',
          end: 'bottom bottom-=70%',
        },
        onComplete: () => {
          // const split = qsa('.statement-text .-t4')
          // Emitter.emit('animation:ended', split)
        },
      })

      statementLines.forEach((line, i) => {
        const chars = qsa('.char-1', line)
        const highlight = qs('.highlight-line', line)

        statement.from(
          chars,
          {
            duration: 0.5,
            y: '100%',
            stagger: 0.05,
          },
          i * 0.15,
        )

        if (highlight)
          statement.from(
            highlight,
            {
              duration: 0.6,
              width: M_UP ? '100' : '50',
              //x: '-94%',
              //transformOrigin: 'left',
              ease: 'power2.inOut',
            },
            i * 0.15,
          )
      })

      statement.from(
        icons,
        { duration: 0.5, y: '-100%', stagger: 0.08 },
        '-=3.4',
      )

      break

    case 'trusted':
      const trusted = gsap.timeline({
        scrollTrigger: {
          trigger: '.trusted',
          start: 'top top+=30%',
          end: 'bottom bottom',
          // scrub: true,
        },
      })

      trusted.from('.trusted-media', {
        duration: 1.2,
        scale: 0.8,
        ease: 'power2.inOut',
      })

      break

    case 'feature':
      const featureSections = qsa('.feature-section')
      const separators = qsa('.feature-separator')

      separators.forEach((separator, i) => {
        const bgs = qsa('.separator-bg', separator)

        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: separator,
            start: 'top top+=20%',
            end: 'bottom bottom-=80%',
            scrub: true,
          },
        })

        tl.from(bgs, {
          duration: 1.2,
          scaleY: 0,
          transformOrigin: 'bottom',
          stagger: 0.3,
          ease: 'power3.inOut',
        })
      })

      featureSections.forEach((feature, i) => {
        const featureLines = qsa('.-t4', feature)
        const description = qsa('.feature-description .line-0', feature)
        const icons = qsa('.feature-icon', feature)
        const button = qs('.feature-button', feature)
        const balls = qsa('.intro-item', feature)

        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: feature,
            start: 'top top+=50%',
            end: 'bottom bottom',
          },
        })

        const tl2 = gsap.timeline({
          scrollTrigger: {
            trigger: feature,
            start: 'top top',
            end: 'bottom bottom-=80%',
            scrub: true,
          },
        })

        if (balls.length) {
          tl2.fromTo(
            balls,
            {
              scale: 0,
            },
            {
              duration: 1.2,
              scale: 4,
            },
          )
        }

        featureLines.forEach((line, i) => {
          const chars = qsa('.char-2', line)

          tl.from(
            chars,
            {
              duration: 0.45,
              y: '100%',
              stagger: 0.05,
            },
            i * 0.15,
          )
        })

        if (icons.length > 0) {
          tl.from(
            icons,
            {
              duration: 0.8,
              scale: 0.5,
              rotate: -60,
              stagger: 0.1,
            },
            '-=1',
          )
        }

        if (description.length > 0)
          tl.from(
            description,
            {
              duration: 0.5,
              y: 20,
              autoAlpha: 0,
              stagger: 0.1,
            },
            '-=0.25',
          )

        if (button) {
          tl.from(
            button,
            {
              duration: 0.5,
              y: 20,
              autoAlpha: 0,
            },
            '-=0.2',
          )
        }
      })

      break

    case 'feature-1':
      const feature1 = qs('.section-1')
      const feature1Line = qsa('.-t4', feature1)
      const feature1Desc = qsa('.feature-description .line-0', feature1)
      const feature1Icon = qsa('.feature-icon', feature1)
      const feature1Button = qs('.feature-button', feature1)
      const feature1Bgs = qsa('.separator-bg', feature1)

      const bg1TL = gsap.timeline({
        scrollTrigger: {
          trigger: feature1,
          start: 'bottom bottom+=60%',
          end: 'bottom bottom-=40%',
          scrub: true,
        },
      })

      bg1TL.from(feature1Bgs, {
        duration: 1.2,
        scaleY: 0,
        transformOrigin: 'bottom',
        stagger: 0.3,
        ease: 'power3.inOut',
      })

      const feature1TL = gsap.timeline({
        scrollTrigger: {
          trigger: feature1,
          start: 'top center-=100%',
          end: 'bottom bottom',
          scrub: false,
        },
      })

      feature1Line.forEach((line, i) => {
        const chars = qsa('.char-2', line)

        feature1TL.from(
          chars,
          {
            duration: 0.45,
            y: '100%',
            stagger: 0.05,
          },
          i * 0.15,
        )
      })

      if (feature1Icon.length > 0) {
        feature1TL.from(
          feature1Icon,
          {
            duration: 0.8,
            scale: 0,
            rotate: -60,
            stagger: 0.1,
          },
          '-=1',
        )
      }

      if (feature1Desc.length > 0)
        feature1TL.from(
          feature1Desc,
          {
            duration: 0.5,
            y: 20,
            autoAlpha: 0,
            stagger: 0.1,
          },
          '-=0.25',
        )

      if (feature1Button) {
        feature1TL.from(
          feature1Button,
          {
            duration: 0.5,
            y: 20,
            autoAlpha: 0,
          },
          '-=0.2',
        )
      }

      break

    case 'feature-2':
      const feature2 = qs('.section-2')
      const feature2Line = qsa('.-t4', feature2)
      const feature2Desc = qsa('.feature-description .line-0', feature2)
      const feature2Icon = qsa('.feature-icon', feature2)
      const feature2Button = qs('.feature-button', feature2)
      const feature2Bgs = qsa('.separator-bg', feature2)

      const bg2TL = gsap.timeline({
        scrollTrigger: {
          trigger: feature2,
          start: 'bottom bottom+=60%',
          end: 'bottom bottom-=40%',
          scrub: true,
        },
      })

      bg2TL.from(feature2Bgs, {
        duration: 1.2,
        scaleY: 0,
        transformOrigin: 'bottom',
        stagger: 0.3,
        ease: 'power3.inOut',
      })

      const feature2TL = gsap.timeline({
        scrollTrigger: {
          trigger: feature2,
          start: 'top top+=50%',
          end: 'bottom bottom',
          scrub: false,
        },
      })

      feature2Line.forEach((line, i) => {
        const chars = qsa('.char-2', line)

        feature2TL.from(
          chars,
          {
            duration: 0.45,
            y: '100%',
            stagger: 0.05,
          },
          i * 0.15,
        )
      })

      if (feature2Icon.length > 0) {
        feature2TL.from(
          feature2Icon,
          {
            duration: 0.8,
            scale: 0,
            rotate: -60,
            stagger: 0.1,
          },
          '-=1',
        )
      }

      if (feature2Desc.length > 0)
        feature2TL.from(
          feature2Desc,
          {
            duration: 0.5,
            y: 20,
            autoAlpha: 0,
            stagger: 0.1,
          },
          '-=0.25',
        )

      if (feature2Button) {
        feature2TL.from(
          feature2Button,
          {
            duration: 0.5,
            y: 20,
            autoAlpha: 0,
          },
          '-=0.2',
        )
      }
      break

    case 'feature-3':
      const feature3 = qs('.section-3')
      const feature3Line = qsa('.-t4', feature3)
      const feature3Desc = qsa('.feature-description .line-0', feature3)
      const feature3Icon = qsa('.feature-icon', feature3)
      const feature3Button = qs('.feature-button', feature3)
      const feature3Bgs = qsa('.separator-bg', feature3)

      const feature3TL = gsap.timeline({
        scrollTrigger: {
          trigger: feature3,
          start: 'top top+=50%',
          end: 'bottom bottom',
          scrub: false,
        },
      })

      feature3Line.forEach((line, i) => {
        const chars = qsa('.char-2', line)

        feature3TL.from(
          chars,
          {
            duration: 0.45,
            y: '100%',
            stagger: 0.05,
          },
          i * 0.15,
        )
      })

      if (feature3Icon.length > 0) {
        feature3TL.from(
          feature3Icon,
          {
            duration: 0.8,
            scale: 0,
            rotate: -60,
            stagger: 0.1,
          },
          '-=1',
        )
      }

      if (feature3Desc.length > 0)
        feature3TL.from(
          feature3Desc,
          {
            duration: 0.5,
            y: 20,
            autoAlpha: 0,
            stagger: 0.1,
          },
          '-=0.25',
        )

      if (feature3Button) {
        feature3TL.from(
          feature3Button,
          {
            duration: 0.5,
            y: 20,
            autoAlpha: 0,
          },
          '-=0.2',
        )
      }
      break

    case 'numbered':
      const headerItem = qsa('.numbered-header .header-item')
      const listItem = qsa('.numbered .list-item')
      const numbered = gsap.timeline({
        scrollTrigger: {
          trigger: '.numbered',
          start: 'top top+=50%',
          end: 'bottom bottom',
          // scrub: true,
        },
      })

      numbered.from('.numbered-header .line', {
        duration: 1.2,
        scaleX: 0,
        transformOrigin: 'left',
        ease: 'power2.inOut',
      })

      headerItem.forEach((item, i) => {
        const chars = qsa('.char-0', item)

        numbered.from(
          chars,
          {
            duration: 0.45,
            y: '100%',
            stagger: 0.05,
            autoAlpha: 0,
          },
          i * 0.12,
        )
      })

      numbered.from('.list-title .line-0', {
        duration: 0.5,
        y: 20,
        autoAlpha: 0,
        stagger: 0.1,
      })

      listItem.forEach((item, i) => {
        const nr = qs('.item-nr .word-1', item)
        const tldr = qsa('.item-tldr .word-0', item)
        const description = qsa('.item-description .-t7 .line-0', item)
        const line = qs('.line', item)

        const block = gsap.timeline({
          scrollTrigger: {
            trigger: item,
            start: 'top top+=50%',
            end: 'bottom bottom',
          },
        })

        block.from(nr, { duration: 0.5, y: '101%' })
        block.from(tldr, { duration: 0.5, y: 20, autoAlpha: 0 }, '-=0.5')
        block.from(
          description,
          {
            duration: 0.5,
            y: 20,
            autoAlpha: 0,
            stagger: 0.15,
          },
          '-=0.4',
        )
        block.from(
          line,
          {
            duration: 1,
            scaleX: 0,
            transformOrigin: 'left',
            ease: 'power3.inOut',
          },
          '-=0.8',
        )

        if (i === listItem.length - 1) {
          block.from('.numbered .-button', {
            duration: 0.5,
            y: 40,
            autoAlpha: 0,
          })
        }
      })

      break

    case 'features':
      const featuresBlock = qsa('.features-block')
      const features = qs('.features')
      const rect = bounds(features)
      const bg = qs('.features-bg')
      const fullHeight = bounds(features).height - store.sizes.vh

      const featuresBG = gsap.timeline({
        scrollTrigger: {
          trigger: features,
          start: 'top top',
          end: 'bottom bottom',
          onUpdate: (self) => {
            if (isSmooth) {
              const progress = self.progress
              gsap.set(bg, {
                y: progress * (rect.height - store.sizes.vh),
              })
            }
          },
          onEnter() {
            if (!isSmooth) {
              bg.classList.add('-fixed')
              gsap.set(bg, { top: 0 })
            }
          },
          onEnterBack() {
            if (!isSmooth) {
              bg.classList.add('-fixed')
              gsap.set(bg, { top: 0 })
            }
          },
          onLeave() {
            if (!isSmooth) {
              bg.classList.remove('-fixed')
              gsap.set(bg, { top: `${fullHeight}` })
            }
          },
          onLeaveBack() {
            if (!isSmooth) {
              bg.classList.remove('-fixed')
              gsap.set(bg, { top: 0 })
            }
          },
        },
      })

      const bgSize = gsap.timeline({
        scrollTrigger: {
          trigger: features,
          start: 'top top+=50%',
          end: 'top top',
          scrub: true,
        },
      })

      bgSize.from('.features-bg', { duration: 0.5, scale: 0 })

      featuresBlock.forEach((block, i) => {
        const titleLines = qsa('.title-text .line-0', block)
        const itemTitle = qsa('.item-title', block)
        const itemText = qsa('.item-text', block)
        const icon1 = qsa('.item-icon.icon-1', block)
        const icon2 = qsa('.item-icon.icon-2', block)

        icon1.push(qs('.item-icon.icon-4', block))
        icon2.push(qs('.item-icon.icon-3', block))

        const features = gsap.timeline({
          scrollTrigger: {
            trigger: block,
            start: 'top top+=50%',
            end: 'bottom bottom',
          },
        })

        titleLines.forEach((line, i) => {
          const chars = qsa('.char-2', line)

          features.from(
            chars,
            {
              duration: 0.45,
              y: '100%',
              delay: 0.1,
              stagger: 0.05,
            },
            i * 0.15,
          )
        })

        features.from(
          icon1,
          {
            duration: 1,
            scale: 0,
            rotate: -60,
            stagger: 0.1,
            ease: 'power2.inOut',
          },
          '-=1.4',
        )

        itemTitle.forEach((item, i) => {
          const words = qsa('.word-0', item)
          features.from(
            words,
            {
              duration: 0.5,
              y: 20,
              delay: 0.4,
              autoAlpha: 0,
              stagger: 0.1,
            },
            i * 0.15,
          )
        })

        itemText.forEach((item, i) => {
          const lines = qsa('.line-0', item)
          features.from(
            lines,
            {
              duration: 0.5,
              y: 10,
              delay: 0.3,
              autoAlpha: 0,
              stagger: 0.15,
            },
            i * 0.15,
          )
        })

        features.from(
          icon2,
          {
            duration: 1,
            scale: 0,
            rotate: -60,
            stagger: 0.1,
            ease: 'power2.inOut',
          },
          '-=1.2',
        )
      })

      break

    case 'threeup':
      const three = qs('.threeup')
      const icon1 = qsa('.threeup-card .icon-1', three)
      const icon2 = qsa('.threeup-card .icon-2', three)
      const threeTitle = qsa('.card-title', three)
      const threeDescription = qsa('.card-description', three)
      const threeButtons = qsa('.card-button ', three)

      icon1.push(qsa('.threeup-card .icon-4', three))
      icon2.push(qsa('.threeup-card .icon-3', three))

      const threeup = gsap.timeline({
        scrollTrigger: {
          trigger: three,
          start: 'top top+=50%',
          end: 'bottom bottom',
        },
      })

      threeup.from('.threeup-block', {
        duration: 0.5,
        autoAlpha: 0.1,
        y: 40,
        ease: 'power2.inOut',
      })

      threeup.from(icon1, {
        duration: 1,
        scale: 0,
        rotate: -60,
        stagger: 0.1,
        ease: 'power2.inOut',
      })

      threeup.from(
        '.card-icon',
        { duration: 0.5, y: 20, autoAlpha: 0, stagger: 0.1 },
        '-=0.5',
      )

      threeTitle.forEach((title, i) => {
        const words = qsa('.word-0', title)
        threeup.from(
          words,
          {
            duration: 0.5,
            y: 20,
            delay: 1.1,
            autoAlpha: 0,
            stagger: 0.1,
          },
          i * 0.15,
        )
      })

      threeDescription.forEach((description, i) => {
        const lines = qsa('.line-0', description)
        threeup.from(
          lines,
          {
            duration: 0.5,
            y: 10,
            delay: 1.2,
            autoAlpha: 0,
            stagger: 0.15,
          },
          i * 0.15,
        )
      })

      threeup.from(
        threeButtons,
        { duration: 0.5, y: 20, autoAlpha: 0 },
        '-=0.9',
      )

      threeup.from(
        icon2,
        {
          duration: 1,
          scale: 0,
          rotate: -60,
          stagger: 0.1,
          ease: 'power2.inOut',
        },
        '-=1',
      )

      break

    case 'team':
      const teamSection = qs('.team')
      const teamList = qsa('.list-block .team-item')

      const team = gsap.timeline({
        scrollTrigger: {
          trigger: teamSection,
          start: 'top top+=50%',
          end: 'bottom bottom',
        },
      })

      team.from('.team-description .line-0', {
        duration: 0.5,
        y: 20,
        autoAlpha: 0,
        stagger: 0.1,
      })

      teamList.forEach((item, i) => {
        const line = qs('.line', item)
        const title = qsa('.info-name .char-0', item)
        const position = qsa('.info-position .line-0', item)
        const lines = qsa('.info-description .line-0', item)
        const social = qs('.info-social', item)
        const img = qs('.team-asset', item)
        const crop = qs('.asset-crop', item)

        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: item,
            start: 'top top+=70%',
            end: 'bottom bottom',
          },
        })

        tl.from(line, {
          duration: 1,
          scaleX: 0,
          transformOrigin: 'left',
          ease: 'power3.inOut',
        })

        tl.from(img, { duration: 1, y: '100%', ease: 'power2.inOut' }, '-=1')
        tl.from(crop, { duration: 1, y: '-100%', ease: 'power2.inOut' }, '-=1')
        tl.from(title, { duration: 0.5, y: '100%', stagger: 0.025 }, '-=1')

        position.forEach((line, i) => {
          const chars = qsa('.char-1', line)

          tl.from(
            chars,
            {
              duration: 0.45,
              y: '100%',
              delay: 0.25,
              stagger: 0.025,
            },
            i * 0.15,
          )
        })

        tl.from(
          lines,
          { duration: 0.5, y: 20, autoAlpha: 0, stagger: 0.1 },
          '-=0.6',
        )
        tl.from(social, { duration: 0.5, y: 20, autoAlpha: 0 }, '-=0.4')
      })

      break

    case 'quotes':
      const quote = qs('.quotes')
      const rectQuote = bounds(quote)
      const bgQuote = qs('.quotes-bg')
      const quoteBlocks = qsa('.quote-block', quote)
      const quoteHeight = bounds(quote).height - store.sizes.vh

      const quotesBG = gsap.timeline({
        scrollTrigger: {
          trigger: quote,
          start: 'top top',
          end: 'bottom bottom',
          onUpdate: (self) => {
            if (isSmooth) {
              const progress = self.progress
              gsap.set(bgQuote, {
                y: progress * (rectQuote.height - store.sizes.vh),
              })
            }
          },
          onEnter() {
            if (!isSmooth) {
              bgQuote.classList.add('-fixed')
              gsap.set(bgQuote, { top: 0 })
            }
          },
          onEnterBack() {
            if (!isSmooth) {
              bgQuote.classList.add('-fixed')
              gsap.set(bgQuote, { top: 0 })
            }
          },
          onLeave() {
            if (!isSmooth) {
              bgQuote.classList.remove('-fixed')
              gsap.set(bgQuote, { top: `${quoteHeight}` })
            }
          },
          onLeaveBack() {
            if (!isSmooth) {
              bgQuote.classList.remove('-fixed')
              gsap.set(bgQuote, { top: 0 })
            }
          },
        },
      })

      quoteBlocks.forEach((block, i) => {
        const subtitle = qs('.block-subtitle', block)
        const title = qsa('.block-title .char-1', block)
        const position = qsa('.block-position .char-1', block)
        const text = qsa('.quote-text .line-0', block)
        const icon = qs('.quote-icon', block)
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: block,
            start: 'top top+=70%',
            end: 'bottom bottom',
          },
        })

        tl.from(subtitle, { duration: 0.5, y: 20, autoAlpha: 0 })
        tl.from(title, { duration: 0.5, y: '101%', stagger: 0.1 }, '-=0.5')
        tl.from(position, { duration: 0.5, y: '101%', stagger: 0.1 }, '-=1.2')
        tl.from(
          text,
          { duration: 0.5, y: 20, autoAlpha: 0, stagger: 0.1 },
          '-=0.5',
        )
        tl.from(icon, { duration: 0.5, y: 20, autoAlpha: 0 })
      })

      break

    case 'largep':
      const largep = gsap.timeline({
        scrollTrigger: {
          trigger: '.largep',
          start: 'top top+=50%',
          end: 'bottom bottom',
        },
      })

      largep.from('.largep .line-1', {
        duration: 0.8,
        y: 40,
        autoAlpha: 0,
      })

      largep.from(
        '.largep-text .line-0',
        {
          duration: 0.8,
          y: 40,
          autoAlpha: 0,
          stagger: 0.15,
        },
        '-=0.5',
      )

      largep.from(
        '.largep .line-2',
        {
          duration: 0.8,
          y: 40,
          autoAlpha: 0,
        },
        '-=0.5',
      )

      break

    case 'smallp':
      const smallp = gsap.timeline({
        scrollTrigger: {
          trigger: '.smallp',
          start: 'top top+=50%',
          end: 'bottom bottom',
        },
      })

      smallp.from('.smallp .line-1', {
        duration: 0.8,
        y: 40,
        autoAlpha: 0,
      })

      smallp.from(
        '.smallp-title',
        {
          duration: 0.6,
          y: 20,
          autoAlpha: 0,
        },
        '-=0.5',
      )

      smallp.from(
        '.smallp-text .line-0',
        {
          duration: 0.6,
          y: 20,
          autoAlpha: 0,
          stagger: 0.15,
        },
        '-=0.4',
      )

      smallp.from(
        '.smallp .-button',
        {
          duration: 0.6,
          y: 20,
          autoAlpha: 0,
          stagger: 0.15,
        },
        '-=0.4',
      )

      smallp.from(
        '.smallp .line-2',
        {
          duration: 0.8,
          y: 40,
          autoAlpha: 0,
        },
        '-=0.5',
      )

      break

    case 'list':
      const list = gsap.timeline({
        scrollTrigger: {
          trigger: '.list',
          start: 'top top+=50%',
          end: 'bottom bottom',
        },
      })

      list.from('.list-description .line-0', {
        duration: 0.5,
        y: 20,
        autoAlpha: 0,
        stagger: 0.1,
      })

      list.from(
        '.list .-button ',
        {
          duration: 0.5,
          y: 20,
          autoAlpha: 0,
        },
        '-=0.4',
      )

      list.from(
        '.list-item .item-name, .list-item .item-seed, .list-item .item-link',
        { duration: 1, autoAlpha: 0, y: 30, stagger: 0.1 },
        '-=0.3',
      )

      list.from(
        '.list-item .line',
        {
          duration: 1,
          scaleX: 0,
          transformOrigin: 'left',
          stagger: 0.12,
          ease: 'power2.inOut',
        },
        '-=1.4',
      )

      break

    case 'company-sticky':
      const companyLines = qsa('.company-title .line-0')
      const company = gsap.timeline({
        scrollTrigger: {
          trigger: '.company',
          start: 'top top',
          end: 'bottom bottom-=100%',
          scrub: true,
        },
      })

      companyLines.forEach((line, i) => {
        const chars = qsa('.char-2', line)

        company.to(
          chars,
          {
            duration: 0.1,
            y: '-115%',
            stagger: 0.01,
          },
          i * 0.05,
        )
      })

      company.fromTo(
        'html',
        { '--path': '0%' },
        { duration: 2, '--path': '100%' },
        '-=0.5',
      )

      break

    case 'resolve-scroll':
      const resolveLines = qsa('.resolve-title .word-0')
      const resolve = gsap.timeline({
        scrollTrigger: {
          trigger: '.resolve',
          start: 'top top',
          end: 'bottom bottom+=50%',
          scrub: true,
        },
      })

      resolveLines.forEach((line, i) => {
        const chars = qsa('.char-1', line)

        resolve.to(
          chars,
          {
            duration: 0.1,
            y: '-115%',
            stagger: 0.01,
          },
          i * 0.025,
        )
      })

      break
    case 'contact':
      const contact = gsap.timeline({
        scrollTrigger: {
          trigger: '.contact',
          start: 'top top+=70%',
          end: 'bottom bottom',
        },
      })

      contact.from('.contact-info .line-0', {
        duration: 0.6,
        y: 20,
        autoAlpha: 0,
        stagger: 0.2,
      })

      contact.from(
        '.contact-info .info-phone',
        {
          duration: 1,
          y: 20,
          autoAlpha: 0,
        },
        '-=0.5',
      )

      contact.from(
        '.contact-inquires .line-0',
        {
          duration: 0.6,
          y: 20,
          autoAlpha: 0,
          stagger: 0.2,
        },
        '-=0.4',
      )

      contact.from(
        '.contact-social .contact-link',
        {
          duration: 0.6,
          y: 20,
          autoAlpha: 0,
          stagger: 0.2,
        },
        '-=0.4',
      )

      break
  }
}
