import SplitText from '../vendor/SplitText'
import { qsa } from '../utils'

export default class Splits {
  constructor(obj = {}) {
    this.els = obj.els || qsa('[data-split]')
    this.splits = []

    this.splitContent()
  }

  splitContent() {
    if (this.els.length === 0) return

    this.els.forEach((el) => {
      const dataset = el.dataset.split
      const data = dataset.split(',')
      const split = {}

      split.el = el

      const classes = {}

      data.forEach((type, i) => {
        const filter = type.trim()
        if (filter == 'lines') classes.linesClass = `line-${i}`
        if (filter == 'words') classes.wordsClass = `word-${i}`
        if (filter == 'chars') classes.charsClass = `char-${i} chr-++`
      })

      const splitted = new SplitText(el, {
        type: dataset.toString(),
        ...classes,
      })

      this.splits.push(splitted)
    })
  }

  reverseContent(el) {
    this.splits.forEach((split) => {
      if (split.elements[0] === el) {
        split.revert()
      }
    })
  }

  init() {}
}
