import store from '../store'
import { Emitter } from '../core'
import { qs, qsa, bindAll, bounds, lerp } from '../utils'
import gsap from 'gsap'

export default class Slider {
  constructor(obj = {}) {
    bindAll(this, 'run', 'onMouseDown', 'onMouseUp', 'onMouseMove', 'onResize')

    const container = obj.container
    const nav = obj.nav
    const items = obj.items

    this.ui = {
      container,
      nav,
      items,
    }

    this.settings = {
      positionX: 0,
      oldX: 0,
      touchStart: 0,
      touchX: 0,
      isDragging: false,
      speed: 0,
      x: 0,
      itemWidth: 0,
      wrapWidth: 0,
      itemLen: 0,
      progress: 0,
    }

    this.init()
  }

  setup() {
    const { items, imgs } = this.ui
    const rect = bounds(items[0])
    const itemLen = items.length / 3

    this.settings.itemLen = itemLen

    let itemWidth = rect.width + rect.left
    let wrapWidth = itemLen * itemWidth

    this.settings.itemWidth = itemWidth
    this.settings.wrapWidth = wrapWidth

    this.settings.maxProgress = rect.width * (items.length - 2)
    this.settings.maxWidth = rect.width * (items.length - 1)
  }

  dispose(pos) {
    const { items } = this.ui
    const { itemLen } = this.settings

    gsap.set(items, {
      x: (i) => {
        let el = i * itemLen + pos
        return el
      },
    })
  }

  onMouseDown(e) {
    const { container } = this.ui

    this.settings.touchStart = e.clientX || e.touches[0].clientX
    this.settings.isDragging = true

    if (store.sniff.isDevice) gsap.set('body', { overflow: 'hidden' })
    container.classList.add('is-dragging')
  }

  onMouseMove(e) {
    const { x, isDragging } = this.settings

    if (!isDragging) return

    this.settings.touchX = e.clientX || e.touches[0].clientX
    this.settings.positionX +=
      (this.settings.touchX - this.settings.touchStart) * 2.5
    this.settings.touchStart = this.settings.touchX
    this.settings.progress += (this.settings.touchX - x) * 2.5

    this.settings.positionX = Math.min(this.settings.positionX, 0)
    this.settings.positionX = Math.max(
      this.settings.positionX,
      -this.settings.maxWidth,
    )
  }

  onMouseUp() {
    const { container } = this.ui
    this.settings.isDragging = false
    container.classList.remove('is-dragging')

    if (store.sniff.isDevice) gsap.set('body', { overflow: 'auto' })
  }

  run() {
    const { x, maxWidth } = this.settings
    const { nav } = this.ui

    // Different ease on the image and content
    this.settings.x = lerp(this.settings.x, this.settings.positionX, 0.1)

    this.dispose(x)

    this.speed = x - this.settings.oldX
    this.settings.oldX = x
    const mapped = gsap.utils.mapRange(0, maxWidth, 0.25, 1, Math.abs(x))

    gsap.set(nav, { width: `${mapped * 100}%` })
  }

  on() {
    const { container } = this.ui
    container.addEventListener('touchstart', this.onMouseDown)
    container.addEventListener('touchmove', this.onMouseMove)
    container.addEventListener('touchend', this.onMouseUp)
    container.addEventListener('mousedown', this.onMouseDown)
    container.addEventListener('mousemove', this.onMouseMove)
    container.addEventListener('mouseleave', this.onMouseUp)
    container.addEventListener('mouseup', this.onMouseUp)
    Emitter.on('tick', this.run)
    Emitter.on('resize', this.onResize)
  }

  off() {
    const { container } = this.ui
    container.removeEventListener('touchstart', this.onMouseDown)
    container.removeEventListener('touchmove', this.onMouseMove)
    container.removeEventListener('touchend', this.onMouseUp)
    container.removeEventListener('mousedown', this.onMouseDown)
    container.removeEventListener('mousemove', this.onMouseMove)
    container.removeEventListener('mouseleave', this.onMouseUp)
    container.removeEventListener('mouseup', this.onMouseUp)

    Emitter.off('tick', this.run)
    Emitter.off('resize', this.onResize)
  }

  onResize() {
    this.settings.positionX = 0
    this.settings.oldX = 0
    this.settings.touchStart = 0
    this.settings.touchX = 0
    this.settings.x = 0
    this.settings.x2 = 0
    this.setup()
  }

  destroy() {
    this.off()
  }

  init() {
    this.setup()
    this.on()
  }
}
