import FontFaceObserver from 'fontfaceobserver'
import data from '../data'

export default class AssetLoader {
  constructor() {
    this.promises = []
    this.loaded = false

    this.setup()
  }

  setup() {
    data.forEach((item) => {
      if (item.type === 'font') {
        let font = new FontFaceObserver(item.name)
        this.promises.push(font.load())
      }

      if (item.type === 'img') {
        let el = new Promise((resolve, reject) => {
          let image = new Image()
          image.onload = () => resolve(image)
          image.onerror = () => reject(new Error('No Image found'))
          image.src = item.path
        })
        this.promises.push(el)
      }
    })
  }

  on() {
    return Promise.all([this.promises])
  }
}
