export const app = {
  v: '5.12.1',
  fr: 30,
  ip: 0,
  op: 241,
  w: 1440,
  h: 880,
  nm: 'App',
  ddd: 0,
  assets: [
    {
      id: 'comp_0',
      nm: 'App Eyeball 2',
      fr: 30,
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Group 4',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [1327.683, 598.435, 0], ix: 2, l: 2 },
            a: { a: 0, k: [1256.683, 40.935, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -1.912],
                        [14.971, 0],
                        [0, 14.971],
                        [-14.971, 0],
                        [-1.804, -0.38],
                        [0, -7.912],
                        [-9.442, 0],
                        [-1.945, 7.291],
                      ],
                      o: [
                        [0, 14.971],
                        [-14.971, 0],
                        [0, -14.971],
                        [1.912, 0],
                        [-7.291, 1.945],
                        [0, 9.442],
                        [7.912, 0],
                        [0.38, 1.804],
                      ],
                      v: [
                        [27.103, 0],
                        [0, 27.103],
                        [-27.103, 0],
                        [0, -27.103],
                        [5.578, -26.524],
                        [-7.092, -10.005],
                        [10.005, 7.092],
                        [26.524, -5.578],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [1256.683, 40.935], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Group 6',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [1327.681, 598.434, 0], ix: 2, l: 2 },
            a: { a: 0, k: [1256.681, 40.934, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 21.226],
                        [21.227, 0],
                        [0, -21.226],
                        [-21.226, 0],
                      ],
                      o: [
                        [0, -21.226],
                        [-21.226, 0],
                        [0, 21.226],
                        [21.227, 0],
                      ],
                      v: [
                        [38.434, 0],
                        [0, -38.434],
                        [-38.434, 0],
                        [0, 38.434],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.929411824544, 0.098039223166, 0.309803921569, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [1256.681, 40.934], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 6',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_1',
      nm: 'App Eyeball 1',
      fr: 30,
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Group 1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [138.959, 625.458, 0], ix: 2, l: 2 },
            a: { a: 0, k: [67.959, 67.958, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -3.256],
                        [25.497, 0],
                        [0, 25.497],
                        [-25.497, 0],
                        [-3.073, -0.648],
                        [0, -13.474],
                        [-16.082, 0],
                        [-3.312, 12.417],
                      ],
                      o: [
                        [0, 25.497],
                        [-25.497, 0],
                        [0, -25.497],
                        [3.256, 0],
                        [-12.418, 3.313],
                        [0, 16.082],
                        [13.474, 0],
                        [0.649, 3.073],
                      ],
                      v: [
                        [46.159, 0],
                        [0, 46.159],
                        [-46.159, 0],
                        [0, -46.159],
                        [9.5, -45.173],
                        [-12.079, -17.04],
                        [17.04, 12.079],
                        [45.172, -9.5],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.364705882353, 0.207843152214, 0.898039275525, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [67.959, 67.958], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Group 3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [138.956, 625.457, 0], ix: 2, l: 2 },
            a: { a: 0, k: [67.956, 67.957, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 36.151],
                        [36.151, 0],
                        [0, -36.151],
                        [-36.15, 0],
                      ],
                      o: [
                        [0, -36.151],
                        [-36.15, 0],
                        [0, 36.151],
                        [36.151, 0],
                      ],
                      v: [
                        [65.456, 0],
                        [-0.001, -65.457],
                        [-65.456, 0],
                        [-0.001, 65.457],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [67.956, 67.957], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_2',
      nm: 'App Foreground',
      fr: 30,
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Group 2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 10,
                  s: [859.133, 1497.04, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 70, s: [859.133, 677.04, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [2211.633, 369.54, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1.288, -4.456],
                        [-158.791, -16.485],
                        [-6.839, -0.664],
                        [-0.078, -0.017],
                        [-0.055, -0.009],
                        [-4.629, -0.42],
                        [-4.119, -0.353],
                        [-0.6, -0.05],
                        [-1.644, -0.135],
                        [-3.463, -0.269],
                        [-2.031, -0.151],
                        [-1.077, -0.085],
                        [-1.987, -0.142],
                        [-6.106, -0.395],
                        [-1.3, -0.076],
                        [-0.633, -0.042],
                        [-1.232, -0.076],
                        [-0.666, -0.042],
                        [-1.532, -0.092],
                        [-8.205, -0.428],
                        [-0.955, -0.043],
                        [-2.42, -0.117],
                        [-2.709, -0.135],
                        [-2.265, -0.109],
                        [-1.077, -0.051],
                        [-14.877, -0.832],
                        [-1.21, -0.067],
                        [-11.19, -0.841],
                        [-2.087, -0.168],
                        [-7.471, -0.707],
                        [-4.041, -0.413],
                        [-0.289, -0.026],
                        [-1.421, -0.151],
                        [-6.129, -0.731],
                        [-2.609, -0.328],
                        [-5.251, -0.706],
                        [-1.876, -0.261],
                        [-0.378, -0.05],
                        [-1.721, -0.244],
                        [-9.281, -1.454],
                        [-37.214, -30.154],
                        [-0.389, -0.337],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [633.199, 21.84],
                        [124.13, 12.466],
                        [5.407, 1.505],
                        [0.023, 0.009],
                        [0.011, 0],
                        [0, 0],
                        [61.538, 18.376],
                        [57.108, 21.091],
                        [-0.622, -0.278],
                        [-0.155, -0.076],
                        [-2.309, -1.119],
                        [-1.321, -0.706],
                        [-0.844, -0.488],
                        [-0.799, -0.505],
                        [-0.322, -7.053],
                        [0, -0.194],
                        [0.034, -0.327],
                        [0.055, -0.328],
                        [0, 0],
                        [0.077, -0.328],
                        [0.1, -0.319],
                        [0, 0],
                        [0.111, -0.286],
                        [0.033, -0.058],
                        [0.134, -0.286],
                        [0.211, -0.345],
                        [0.2, -0.286],
                        [0.212, -0.286],
                        [0.266, -0.303],
                        [0, 0],
                        [0.277, -0.294],
                        [0.733, -0.664],
                        [0.399, -0.328],
                        [0.421, -0.328],
                        [0.456, -0.328],
                        [10.113, -4.203],
                        [1.643, -0.647],
                        [0, 0],
                        [1.721, -0.655],
                        [0, 0],
                        [5.917, -2.077],
                        [4.23, -1.454],
                        [2.265, -0.773],
                        [1.655, -0.571],
                        [3.808, -1.303],
                        [1.399, -0.487],
                        [1.421, -0.496],
                        [1.443, -0.513],
                        [1.454, -0.513],
                        [0, 0],
                        [4.53, -1.622],
                        [0.022, -0.008],
                        [11.269, -4.405],
                        [1.621, -0.648],
                        [13.522, -6.153],
                        [5.728, -2.664],
                        [0.522, -0.244],
                        [1.111, -0.522],
                        [0.577, -0.269],
                        [0.944, -0.454],
                        [1.51, -0.723],
                        [1.032, -0.496],
                        [0.533, -0.261],
                        [1.077, -0.53],
                        [1.11, -0.546],
                        [0.555, -0.277],
                        [0.056, -0.017],
                        [0.055, -0.025],
                        [1.732, -0.882],
                        [0.921, -0.471],
                        [0.977, -0.496],
                        [1.832, -0.95],
                        [3.975, -2.136],
                        [0.855, -0.463],
                        [5.185, -2.951],
                        [0.866, -0.496],
                        [0.056, -0.033],
                        [4.407, -2.656],
                        [1.454, -0.9],
                        [1.932, -1.244],
                        [1.91, -1.236],
                        [0.044, -0.026],
                        [1.821, -1.236],
                        [3.119, -2.262],
                        [0.833, -0.621],
                        [3.93, -3.245],
                        [0.4, -0.328],
                        [0.378, -0.328],
                        [3.775, -3.892],
                        [0.588, -0.631],
                        [2.697, -4.237],
                        [0.267, -0.437],
                        [1.021, -3.27],
                        [0.133, -0.521],
                      ],
                      o: [
                        [11.268, 39.064],
                        [7.249, 0.748],
                        [0, 0],
                        [0.033, 0.008],
                        [4.819, 0.462],
                        [4.275, 0.387],
                        [0.611, 0.051],
                        [1.676, 0.143],
                        [3.574, 0.294],
                        [2.077, 0.168],
                        [1.077, 0.084],
                        [2.031, 0.151],
                        [6.428, 0.463],
                        [1.31, 0.093],
                        [0.633, 0.042],
                        [1.254, 0.075],
                        [0.666, 0.042],
                        [1.555, 0.092],
                        [8.715, 0.521],
                        [0.966, 0.051],
                        [2.454, 0.126],
                        [2.754, 0.143],
                        [2.287, 0.109],
                        [1.088, 0.059],
                        [15.309, 0.739],
                        [1.199, 0.067],
                        [10.835, 0.622],
                        [2.065, 0.16],
                        [7.15, 0.563],
                        [3.931, 0.37],
                        [0.278, 0.025],
                        [1.399, 0.143],
                        [5.839, 0.622],
                        [2.553, 0.303],
                        [5.018, 0.622],
                        [1.843, 0.244],
                        [0.378, 0.05],
                        [1.687, 0.235],
                        [8.592, 1.219],
                        [55.255, 8.684],
                        [0.411, 0.328],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-168.838, -5.826],
                        [-335.978, -33.744],
                        [-0.033, -0.008],
                        [-0.01, -0.008],
                        [0, 0],
                        [-90.914, -21.293],
                        [-95.121, -28.438],
                        [0.633, 0.26],
                        [0.155, 0.058],
                        [2.432, 1.026],
                        [1.388, 0.672],
                        [0.877, 0.471],
                        [0.844, 0.488],
                        [7.961, 5.052],
                        [0.011, 0.202],
                        [0, 0.327],
                        [-0.022, 0.328],
                        [0, 0],
                        [-0.056, 0.32],
                        [-0.078, 0.319],
                        [0, 0],
                        [-0.1, 0.286],
                        [-0.022, 0.058],
                        [-0.111, 0.286],
                        [-0.177, 0.353],
                        [-0.155, 0.294],
                        [-0.189, 0.286],
                        [-0.222, 0.312],
                        [0, 0],
                        [-0.244, 0.303],
                        [-0.611, 0.673],
                        [-0.366, 0.328],
                        [-0.389, 0.328],
                        [-0.422, 0.328],
                        [-5.861, 4.237],
                        [-1.555, 0.656],
                        [0, 0],
                        [-1.643, 0.656],
                        [0, 0],
                        [-5.196, 1.993],
                        [-3.942, 1.387],
                        [-2.198, 0.757],
                        [-1.621, 0.547],
                        [-3.641, 1.253],
                        [-1.388, 0.462],
                        [-1.398, 0.479],
                        [-1.421, 0.479],
                        [-1.443, 0.495],
                        [0, 0],
                        [-4.374, 1.53],
                        [0, 0],
                        [-10.58, 3.792],
                        [-1.609, 0.631],
                        [-12.978, 5.144],
                        [-3.375, 1.538],
                        [-0.511, 0.236],
                        [-1.054, 0.496],
                        [-0.566, 0.26],
                        [-0.91, 0.429],
                        [-1.432, 0.672],
                        [-0.999, 0.479],
                        [-0.522, 0.244],
                        [-1.043, 0.512],
                        [-1.077, 0.529],
                        [-0.555, 0.27],
                        [-0.056, 0.017],
                        [-0.055, 0.025],
                        [-1.676, 0.824],
                        [-0.9, 0.446],
                        [-0.966, 0.488],
                        [-1.81, 0.916],
                        [-3.842, 1.992],
                        [-0.844, 0.453],
                        [-5.107, 2.765],
                        [-0.866, 0.487],
                        [-0.056, 0.025],
                        [-4.452, 2.564],
                        [-1.477, 0.891],
                        [-1.954, 1.193],
                        [-1.932, 1.228],
                        [-0.033, 0.025],
                        [-1.865, 1.227],
                        [-3.297, 2.211],
                        [-0.866, 0.621],
                        [-4.363, 3.195],
                        [-0.389, 0.328],
                        [-0.389, 0.311],
                        [-4.629, 3.909],
                        [-0.621, 0.647],
                        [-4.008, 4.32],
                        [-0.278, 0.429],
                        [-2.031, 3.354],
                        [-0.189, 0.538],
                        [-1.266, 4.775],
                      ],
                      v: [
                        [-1105.767, 52.573],
                        [-883.939, 119.866],
                        [-862.801, 121.984],
                        [-862.679, 122.001],
                        [-862.535, 122.018],
                        [-848.369, 123.337],
                        [-835.779, 124.447],
                        [-833.958, 124.598],
                        [-828.962, 125.019],
                        [-818.405, 125.868],
                        [-812.243, 126.347],
                        [-809.012, 126.591],
                        [-802.973, 127.036],
                        [-784.188, 128.322],
                        [-780.28, 128.575],
                        [-778.393, 128.701],
                        [-774.663, 128.928],
                        [-772.676, 129.054],
                        [-768.035, 129.331],
                        [-742.689, 130.743],
                        [-739.814, 130.887],
                        [-732.509, 131.256],
                        [-724.327, 131.668],
                        [-717.51, 131.996],
                        [-714.268, 132.156],
                        [-669.283, 134.442],
                        [-665.664, 134.652],
                        [-632.747, 136.821],
                        [-626.53, 137.309],
                        [-604.626, 139.209],
                        [-592.658, 140.386],
                        [-591.814, 140.47],
                        [-587.584, 140.915],
                        [-569.632, 142.941],
                        [-561.894, 143.883],
                        [-546.507, 145.875],
                        [-540.923, 146.64],
                        [-539.79, 146.791],
                        [-534.683, 147.506],
                        [-507.895, 151.515],
                        [-292.528, 209.654],
                        [-291.34, 210.646],
                        [220.826, 210.646],
                        [220.826, 209.654],
                        [1363.746, 209.654],
                        [660.328, 43.343],
                        [220.826, 14.417],
                        [-292.395, -67.999],
                        [-292.473, -68.016],
                        [-292.517, -68.024],
                        [-292.528, -68.033],
                        [-523.104, -131.047],
                        [-752.348, -207.006],
                        [-750.494, -206.199],
                        [-750.028, -205.981],
                        [-742.889, -202.769],
                        [-738.826, -200.702],
                        [-736.25, -199.264],
                        [-733.797, -197.776],
                        [-719.908, -179.753],
                        [-719.897, -179.164],
                        [-719.942, -178.173],
                        [-720.052, -177.189],
                        [-720.052, -177.181],
                        [-720.252, -176.214],
                        [-720.519, -175.256],
                        [-720.519, -175.247],
                        [-720.83, -174.381],
                        [-720.907, -174.205],
                        [-721.285, -173.356],
                        [-721.862, -172.305],
                        [-722.395, -171.439],
                        [-722.995, -170.582],
                        [-723.716, -169.665],
                        [-723.716, -169.657],
                        [-724.493, -168.766],
                        [-726.514, -166.757],
                        [-727.657, -165.765],
                        [-728.878, -164.781],
                        [-730.189, -163.798],
                        [-754.368, -151.264],
                        [-759.164, -149.314],
                        [-759.176, -149.314],
                        [-764.227, -147.347],
                        [-764.238, -147.347],
                        [-780.924, -141.26],
                        [-793.192, -136.999],
                        [-799.886, -134.704],
                        [-804.805, -133.031],
                        [-815.973, -129.214],
                        [-820.159, -127.785],
                        [-824.388, -126.322],
                        [-828.696, -124.834],
                        [-833.048, -123.321],
                        [-833.059, -123.321],
                        [-846.415, -118.597],
                        [-846.448, -118.589],
                        [-879.277, -106.324],
                        [-884.128, -104.415],
                        [-923.95, -87.519],
                        [-937.75, -81.181],
                        [-939.305, -80.457],
                        [-942.558, -78.927],
                        [-944.256, -78.129],
                        [-947.054, -76.8],
                        [-951.461, -74.699],
                        [-954.514, -73.228],
                        [-956.08, -72.471],
                        [-959.266, -70.916],
                        [-962.541, -69.311],
                        [-964.206, -68.487],
                        [-964.362, -68.436],
                        [-964.528, -68.352],
                        [-969.668, -65.78],
                        [-972.399, -64.41],
                        [-975.319, -62.93],
                        [-980.77, -60.131],
                        [-992.494, -53.96],
                        [-995.047, -52.59],
                        [-1010.535, -44.024],
                        [-1013.132, -42.545],
                        [-1013.288, -42.461],
                        [-1026.599, -34.626],
                        [-1030.995, -31.944],
                        [-1036.835, -28.288],
                        [-1042.597, -24.597],
                        [-1042.708, -24.521],
                        [-1048.248, -20.84],
                        [-1057.873, -14.131],
                        [-1060.438, -12.274],
                        [-1072.905, -2.606],
                        [-1074.082, -1.623],
                        [-1075.237, -0.656],
                        [-1087.871, 11.071],
                        [-1089.691, 12.988],
                        [-1099.816, 25.841],
                        [-1100.627, 27.152],
                        [-1105.234, 37.097],
                        [-1105.711, 38.694],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 4,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980392843, 0.0941176489, 0.082352943718, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.929411824544, 0.098039223166, 0.309803921569, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [2211.633, 363.67], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Group 4',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 10,
                  s: [202.078, 1504.254, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 70, s: [202.078, 684.254, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [1554.578, 376.754, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [814.649, -374.2],
                        [0, 0],
                        [3.264, 1.765],
                        [1.61, 0.849],
                        [5.207, 2.556],
                        [0.766, 0.378],
                        [0.988, 0.471],
                        [6.961, 3.043],
                        [2.809, 1.185],
                        [0.177, 0.067],
                        [0.999, 0.412],
                        [16.797, 5.59],
                        [0.722, 0.244],
                        [0, 0],
                        [2.787, 0.874],
                        [5.396, 1.563],
                        [1.41, 0.403],
                        [1.843, 0.513],
                        [0.777, 0.21],
                        [0.933, 0.252],
                        [0.789, 0.211],
                        [2.076, 0.546],
                        [0.844, 0.218],
                        [2.831, 0.706],
                        [0.8, 0.193],
                        [1.565, 0.379],
                        [1.533, 0.362],
                        [1.498, 0.344],
                        [1.465, 0.328],
                        [0.722, 0.159],
                        [2.742, 0.572],
                        [0.677, 0.134],
                        [3.819, 0.748],
                        [0.877, 0.169],
                        [1.855, 0.336],
                        [0.933, 0.169],
                        [0.144, 0.025],
                        [0.533, 0.092],
                        [4.841, 0.731],
                        [0.877, 0.126],
                        [1.533, 0.194],
                        [0.211, 0.025],
                        [0.289, 0.034],
                        [0.033, 0.009],
                        [0, 0],
                        [7.249, 0.748],
                        [11.268, 39.064],
                        [-1.265, 4.775],
                        [-0.189, 0.538],
                        [-2.032, 3.355],
                        [-0.277, 0.429],
                        [-4.007, 4.32],
                        [-0.622, 0.647],
                        [-4.629, 3.909],
                        [-0.389, 0.311],
                        [-0.389, 0.311],
                        [-4.363, 3.194],
                        [-0.866, 0.622],
                        [-3.297, 2.21],
                        [-1.865, 1.218],
                        [-0.034, 0.025],
                        [-1.932, 1.21],
                        [-1.954, 1.194],
                        [-1.477, 0.891],
                        [-4.452, 2.556],
                        [-0.055, 0.025],
                        [-0.866, 0.487],
                        [-5.106, 2.749],
                        [-0.844, 0.454],
                        [-3.841, 1.993],
                        [-1.81, 0.916],
                        [-0.966, 0.488],
                        [-0.899, 0.446],
                        [-1.677, 0.824],
                        [4.496, -1.95],
                        [2.298, -1.009],
                        [7.039, -3.194],
                        [7.161, -3.363],
                        [30.397, -16.249],
                        [5.106, -2.791],
                        [7.738, -4.371],
                        [5.185, -3.01],
                        [2.598, -1.522],
                        [7.849, -4.766],
                        [3.797, -2.337],
                        [3.741, -2.345],
                        [11.79, -7.802],
                        [0, 0],
                        [-4.64, 1.211],
                        [-0.022, 0],
                        [0, 0],
                        [-4.374, 1.53],
                        [0, 0],
                        [-1.443, 0.496],
                        [-1.421, 0.479],
                        [-1.399, 0.479],
                        [-1.387, 0.462],
                        [-3.641, 1.235],
                        [-1.62, 0.546],
                        [-2.198, 0.748],
                        [-3.941, 1.387],
                        [-5.196, 1.976],
                        [0, 0],
                        [-1.643, 0.648],
                        [0, 0],
                        [-1.554, 0.648],
                        [-5.861, 4.237],
                        [-0.422, 0.328],
                        [-0.388, 0.328],
                        [-0.367, 0.328],
                        [-0.611, 0.673],
                        [-0.256, 0.294],
                        [0, 0],
                        [-0.233, 0.302],
                        [-0.189, 0.286],
                        [-0.167, 0.294],
                        [-0.178, 0.354],
                        [-0.11, 0.286],
                        [-0.022, 0.059],
                        [-0.1, 0.286],
                        [0, 0],
                        [-0.078, 0.32],
                        [-0.055, 0.319],
                        [0, 0],
                        [-0.022, 0.328],
                        [0, 0.327],
                        [0.011, 0.202],
                        [7.96, 5.052],
                        [0.843, 0.488],
                        [0.877, 0.47],
                        [1.387, 0.672],
                        [2.431, 1.026],
                        [0, 0],
                        [0.633, 0.261],
                        [0.044, 0.008],
                        [0.544, 0.185],
                        [5.962, -0.538],
                        [0.211, -0.017],
                      ],
                      o: [
                        [0, 0],
                        [-3.231, -1.833],
                        [-1.599, -0.866],
                        [-5.162, -2.732],
                        [-0.766, -0.387],
                        [-1, -0.488],
                        [-6.95, -3.337],
                        [-2.809, -1.236],
                        [-0.166, -0.068],
                        [-0.988, -0.42],
                        [-17.52, -7.28],
                        [-0.733, -0.244],
                        [0, 0],
                        [-2.831, -0.908],
                        [-5.562, -1.732],
                        [-1.421, -0.412],
                        [-1.854, -0.53],
                        [-0.777, -0.21],
                        [-0.922, -0.252],
                        [-0.788, -0.211],
                        [-2.109, -0.563],
                        [-0.844, -0.218],
                        [-2.909, -0.749],
                        [-0.8, -0.202],
                        [-1.598, -0.395],
                        [-1.554, -0.37],
                        [-1.532, -0.361],
                        [-1.499, -0.345],
                        [-0.733, -0.168],
                        [-2.898, -0.631],
                        [-0.688, -0.152],
                        [-4.219, -0.883],
                        [-0.9, -0.169],
                        [-1.976, -0.378],
                        [-0.965, -0.186],
                        [-0.144, -0.025],
                        [-0.533, -0.101],
                        [-7.361, -1.303],
                        [-0.999, -0.143],
                        [-2.876, -0.412],
                        [-0.255, -0.033],
                        [-0.721, -0.093],
                        [-0.055, -0.008],
                        [-0.078, -0.017],
                        [-6.839, -0.664],
                        [-158.791, -16.485],
                        [-1.288, -4.455],
                        [0.134, -0.521],
                        [1.021, -3.27],
                        [0.266, -0.437],
                        [2.698, -4.237],
                        [0.589, -0.631],
                        [3.775, -3.892],
                        [0.377, -0.328],
                        [0.399, -0.344],
                        [3.93, -3.245],
                        [0.833, -0.622],
                        [3.119, -2.261],
                        [1.821, -1.236],
                        [0.044, -0.026],
                        [1.909, -1.253],
                        [1.931, -1.244],
                        [1.455, -0.9],
                        [4.407, -2.664],
                        [0.055, -0.033],
                        [0.866, -0.497],
                        [5.185, -2.959],
                        [0.855, -0.462],
                        [3.974, -2.136],
                        [1.832, -0.95],
                        [0.977, -0.496],
                        [0.922, -0.47],
                        [1.732, -0.882],
                        [-4.43, 1.891],
                        [-2.288, 0.992],
                        [-6.906, 3.026],
                        [-7.017, 3.194],
                        [-28.654, 13.442],
                        [-5.063, 2.707],
                        [-7.65, 4.178],
                        [-5.152, 2.918],
                        [-2.586, 1.505],
                        [-7.805, 4.573],
                        [-3.852, 2.337],
                        [-3.797, 2.337],
                        [-12.456, 7.802],
                        [0, 0],
                        [240.934, -117.815],
                        [0.012, 0],
                        [0.022, -0.008],
                        [4.53, -1.622],
                        [0, 0],
                        [1.454, -0.513],
                        [1.443, -0.513],
                        [1.422, -0.496],
                        [1.399, -0.487],
                        [3.809, -1.311],
                        [1.654, -0.572],
                        [2.265, -0.782],
                        [4.23, -1.455],
                        [5.918, -2.085],
                        [0, 0],
                        [1.732, -0.664],
                        [0.011, -0.008],
                        [1.643, -0.655],
                        [10.114, -4.203],
                        [0.456, -0.328],
                        [0.422, -0.328],
                        [0.4, -0.328],
                        [0.733, -0.664],
                        [0.266, -0.294],
                        [0, 0],
                        [0.255, -0.303],
                        [0.211, -0.286],
                        [0.189, -0.286],
                        [0.211, -0.344],
                        [0.133, -0.285],
                        [0.034, -0.058],
                        [0.111, -0.286],
                        [0, 0],
                        [0.1, -0.32],
                        [0.077, -0.328],
                        [0, 0],
                        [0.056, -0.328],
                        [0.034, -0.327],
                        [0, -0.194],
                        [-0.322, -7.053],
                        [-0.799, -0.504],
                        [-0.844, -0.488],
                        [-1.321, -0.706],
                        [-2.31, -1.118],
                        [0, 0],
                        [-0.622, -0.278],
                        [-0.045, -0.009],
                        [-0.533, -0.185],
                        [-5.407, -1.874],
                        [0, 0],
                        [-15.964, 1.127],
                      ],
                      v: [
                        [-1555.335, 212.248],
                        [66.203, 212.248],
                        [56.466, 206.851],
                        [51.648, 204.27],
                        [36.072, 196.326],
                        [33.763, 195.192],
                        [30.786, 193.753],
                        [9.916, 184.188],
                        [1.489, 180.565],
                        [0.979, 180.354],
                        [-1.996, 179.11],
                        [-53.688, 159.851],
                        [-55.875, 159.12],
                        [-60.271, 157.682],
                        [-68.686, 155.008],
                        [-85.128, 150.058],
                        [-89.37, 148.839],
                        [-94.909, 147.275],
                        [-97.24, 146.636],
                        [-100.016, 145.872],
                        [-102.392, 145.232],
                        [-108.675, 143.568],
                        [-111.195, 142.913],
                        [-119.81, 140.735],
                        [-122.209, 140.147],
                        [-126.949, 138.995],
                        [-131.59, 137.902],
                        [-136.13, 136.852],
                        [-140.582, 135.851],
                        [-142.758, 135.364],
                        [-151.219, 133.548],
                        [-153.26, 133.119],
                        [-165.328, 130.69],
                        [-167.982, 130.185],
                        [-173.733, 129.118],
                        [-176.597, 128.596],
                        [-177.03, 128.521],
                        [-178.628, 128.235],
                        [-197.047, 125.217],
                        [-199.866, 124.805],
                        [-206.517, 123.897],
                        [-207.216, 123.805],
                        [-208.737, 123.62],
                        [-208.881, 123.603],
                        [-209.003, 123.586],
                        [-230.141, 121.468],
                        [-451.969, 54.175],
                        [-451.914, 40.296],
                        [-451.436, 38.699],
                        [-446.829, 28.754],
                        [-446.019, 27.443],
                        [-435.894, 14.59],
                        [-434.073, 12.673],
                        [-421.439, 0.946],
                        [-420.284, -0.021],
                        [-419.107, -1.004],
                        [-406.641, -10.671],
                        [-404.075, -12.529],
                        [-394.45, -19.237],
                        [-388.91, -22.919],
                        [-388.799, -22.995],
                        [-383.037, -26.685],
                        [-377.198, -30.342],
                        [-372.801, -33.024],
                        [-359.49, -40.859],
                        [-359.335, -40.942],
                        [-356.737, -42.422],
                        [-341.25, -50.988],
                        [-338.696, -52.358],
                        [-326.973, -58.529],
                        [-321.521, -61.328],
                        [-318.602, -62.808],
                        [-315.871, -64.178],
                        [-310.73, -66.75],
                        [-324.13, -60.975],
                        [-330.991, -57.965],
                        [-351.885, -48.626],
                        [-373.168, -38.799],
                        [-461.905, 5.687],
                        [-477.159, 13.934],
                        [-500.24, 26.77],
                        [-515.75, 35.656],
                        [-523.532, 40.187],
                        [-547.013, 54.192],
                        [-558.492, 61.203],
                        [-569.794, 68.23],
                        [-606.164, 91.668],
                        [-606.164, 45.878],
                        [-192.706, -116.978],
                        [-192.65, -116.987],
                        [-192.617, -116.995],
                        [-179.261, -121.719],
                        [-179.25, -121.719],
                        [-174.898, -123.232],
                        [-170.591, -124.72],
                        [-166.361, -126.183],
                        [-162.176, -127.612],
                        [-151.007, -131.428],
                        [-146.089, -133.101],
                        [-139.394, -135.396],
                        [-127.127, -139.658],
                        [-110.44, -145.745],
                        [-110.429, -145.745],
                        [-105.378, -147.712],
                        [-105.367, -147.712],
                        [-100.571, -149.662],
                        [-76.391, -162.196],
                        [-75.081, -163.179],
                        [-73.86, -164.163],
                        [-72.716, -165.155],
                        [-70.695, -167.164],
                        [-69.918, -168.055],
                        [-69.918, -168.063],
                        [-69.198, -168.979],
                        [-68.597, -169.837],
                        [-68.064, -170.703],
                        [-67.487, -171.754],
                        [-67.11, -172.603],
                        [-67.032, -172.779],
                        [-66.721, -173.645],
                        [-66.721, -173.653],
                        [-66.454, -174.612],
                        [-66.255, -175.578],
                        [-66.255, -175.587],
                        [-66.144, -176.571],
                        [-66.099, -177.562],
                        [-66.11, -178.151],
                        [-79.999, -196.174],
                        [-82.452, -197.662],
                        [-85.028, -199.099],
                        [-89.091, -201.167],
                        [-96.23, -204.379],
                        [-96.696, -204.597],
                        [-98.55, -205.404],
                        [-98.661, -205.446],
                        [-100.26, -206.018],
                        [-117.657, -208.069],
                        [-117.979, -208.044],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 4,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980392843, 0.0941176489, 0.082352943718, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.960784373564, 0.788235353956, 0.850980451995, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [1557.835, 362.068], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Group 6',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 10,
                  s: [351.107, 1660.611, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 70, s: [351.107, 840.611, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [1703.607, 533.111, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [4.818, 0.463],
                        [-0.721, -0.093],
                        [-0.255, -0.033],
                        [-2.875, -0.412],
                        [-0.999, -0.143],
                        [-7.361, -1.303],
                        [-0.544, -0.101],
                        [-0.144, -0.025],
                        [-0.976, -0.185],
                        [-1.976, -0.378],
                        [-0.9, -0.168],
                        [-4.219, -0.883],
                        [-0.688, -0.152],
                        [-2.898, -0.631],
                        [-0.733, -0.168],
                        [-1.499, -0.345],
                        [-1.532, -0.362],
                        [-1.554, -0.37],
                        [-1.598, -0.395],
                        [-0.811, -0.201],
                        [-2.909, -0.749],
                        [-0.844, -0.218],
                        [-2.109, -0.564],
                        [-0.799, -0.219],
                        [-0.922, -0.252],
                        [-0.788, -0.219],
                        [-1.854, -0.529],
                        [-1.421, -0.412],
                        [-5.562, -1.732],
                        [-2.82, -0.907],
                        [0, 0],
                        [-0.733, -0.252],
                        [-17.519, -7.28],
                        [-0.989, -0.421],
                        [-0.166, -0.068],
                        [-2.809, -1.236],
                        [-6.95, -3.337],
                        [-1, -0.488],
                        [-0.766, -0.387],
                        [-5.162, -2.732],
                        [-1.599, -0.866],
                        [-3.231, -1.833],
                        [0, 0],
                        [0.344, 0.328],
                        [55.255, 8.684],
                        [8.593, 1.219],
                        [1.688, 0.235],
                        [0.377, 0.051],
                        [1.843, 0.244],
                        [5.019, 0.622],
                        [2.554, 0.303],
                        [5.839, 0.623],
                        [1.399, 0.143],
                        [0.278, 0.025],
                        [3.93, 0.37],
                        [7.15, 0.563],
                        [2.065, 0.161],
                        [10.835, 0.622],
                        [1.2, 0.068],
                        [15.31, 0.74],
                        [1.088, 0.059],
                        [2.287, 0.11],
                        [2.753, 0.143],
                        [2.453, 0.126],
                        [0.965, 0.05],
                        [8.715, 0.521],
                        [1.554, 0.093],
                        [0.666, 0.042],
                        [1.254, 0.076],
                        [0.633, 0.042],
                        [1.31, 0.092],
                        [6.428, 0.462],
                        [2.032, 0.151],
                        [1.077, 0.084],
                        [2.076, 0.168],
                        [3.575, 0.294],
                        [1.677, 0.143],
                        [0.61, 0.05],
                        [4.274, 0.386],
                      ],
                      o: [
                        [0.289, 0.034],
                        [0.211, 0.025],
                        [1.533, 0.194],
                        [0.878, 0.126],
                        [4.841, 0.732],
                        [0.521, 0.093],
                        [0.144, 0.025],
                        [0.933, 0.169],
                        [1.855, 0.336],
                        [0.866, 0.169],
                        [3.819, 0.748],
                        [0.677, 0.135],
                        [2.742, 0.572],
                        [0.722, 0.16],
                        [1.465, 0.328],
                        [1.498, 0.344],
                        [1.533, 0.361],
                        [1.565, 0.379],
                        [0.8, 0.193],
                        [2.831, 0.707],
                        [0.843, 0.218],
                        [2.076, 0.547],
                        [0.789, 0.21],
                        [0.933, 0.252],
                        [0.766, 0.211],
                        [1.843, 0.512],
                        [1.399, 0.395],
                        [5.396, 1.564],
                        [2.787, 0.874],
                        [0, 0],
                        [0.722, 0.244],
                        [16.797, 5.591],
                        [1, 0.412],
                        [0.177, 0.066],
                        [2.809, 1.185],
                        [6.961, 3.043],
                        [0.988, 0.471],
                        [0.766, 0.378],
                        [5.207, 2.555],
                        [1.61, 0.85],
                        [3.264, 1.765],
                        [0, 0],
                        [-0.344, -0.328],
                        [-37.214, -30.154],
                        [-9.281, -1.455],
                        [-1.721, -0.244],
                        [-0.377, -0.051],
                        [-1.876, -0.26],
                        [-5.251, -0.706],
                        [-2.608, -0.328],
                        [-6.128, -0.731],
                        [-1.421, -0.151],
                        [-0.289, -0.025],
                        [-4.052, -0.42],
                        [-7.471, -0.706],
                        [-2.087, -0.168],
                        [-11.191, -0.84],
                        [-1.211, -0.067],
                        [-14.876, -0.832],
                        [-1.076, -0.05],
                        [-2.265, -0.109],
                        [-2.709, -0.134],
                        [-2.42, -0.118],
                        [-0.955, -0.042],
                        [-8.205, -0.429],
                        [-1.544, -0.093],
                        [-0.666, -0.042],
                        [-1.232, -0.076],
                        [-0.633, -0.042],
                        [-1.287, -0.076],
                        [-6.106, -0.396],
                        [-1.987, -0.143],
                        [-1.076, -0.084],
                        [-2.032, -0.151],
                        [-3.463, -0.269],
                        [-1.643, -0.134],
                        [-0.599, -0.05],
                        [-4.119, -0.353],
                        [-4.629, -0.421],
                      ],
                      v: [
                        [-356.792, -42.494],
                        [-355.271, -42.309],
                        [-354.573, -42.217],
                        [-347.922, -41.309],
                        [-345.103, -40.897],
                        [-326.683, -37.879],
                        [-325.086, -37.593],
                        [-324.653, -37.518],
                        [-321.789, -36.996],
                        [-316.037, -35.929],
                        [-313.384, -35.424],
                        [-301.316, -32.995],
                        [-299.274, -32.566],
                        [-290.813, -30.75],
                        [-288.638, -30.263],
                        [-284.186, -29.262],
                        [-279.646, -28.211],
                        [-275.005, -27.119],
                        [-270.265, -25.967],
                        [-267.865, -25.379],
                        [-259.25, -23.201],
                        [-256.73, -22.546],
                        [-250.448, -20.881],
                        [-248.072, -20.242],
                        [-245.296, -19.478],
                        [-242.964, -18.838],
                        [-237.425, -17.275],
                        [-233.183, -16.056],
                        [-216.742, -11.105],
                        [-208.327, -8.432],
                        [-203.931, -6.994],
                        [-201.743, -6.263],
                        [-150.052, 12.996],
                        [-147.077, 14.241],
                        [-146.567, 14.451],
                        [-138.14, 18.074],
                        [-117.269, 27.64],
                        [-114.292, 29.078],
                        [-111.984, 30.213],
                        [-96.407, 38.156],
                        [-91.589, 40.737],
                        [-81.853, 46.134],
                        [214.257, 46.134],
                        [213.214, 45.142],
                        [-2.152, -12.996],
                        [-28.942, -17.006],
                        [-34.049, -17.72],
                        [-35.181, -17.872],
                        [-40.764, -18.637],
                        [-56.152, -20.629],
                        [-63.891, -21.571],
                        [-81.842, -23.597],
                        [-86.072, -24.042],
                        [-86.916, -24.126],
                        [-98.884, -25.303],
                        [-120.787, -27.203],
                        [-127.005, -27.691],
                        [-159.922, -29.859],
                        [-163.542, -30.07],
                        [-208.526, -32.356],
                        [-211.768, -32.516],
                        [-218.584, -32.844],
                        [-226.766, -33.255],
                        [-234.072, -33.625],
                        [-236.947, -33.768],
                        [-262.292, -35.18],
                        [-266.933, -35.458],
                        [-268.921, -35.584],
                        [-272.651, -35.811],
                        [-274.539, -35.937],
                        [-278.447, -36.189],
                        [-297.23, -37.475],
                        [-303.271, -37.921],
                        [-306.5, -38.165],
                        [-312.662, -38.644],
                        [-323.221, -39.493],
                        [-328.217, -39.913],
                        [-330.036, -40.064],
                        [-342.626, -41.174],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 4,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980392843, 0.0941176489, 0.082352943718, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.364705882353, 0.207843152214, 0.898039275525, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [1705.891, 528.181], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 6',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: 'Group 8',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 5,
                  s: [859.133, 1542.826, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 65, s: [859.133, 722.826, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [2211.633, 415.326, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [116.615, 73.925],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [272.086, 43.68],
                        [235.072, 0.042],
                        [181.895, -31.162],
                        [34.205, -3.531],
                        [173.024, -44.15],
                        [78.99, 25.63],
                        [201.245, -44.318],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-242.167, -38.871],
                        [0, 0],
                        [-21.093, 3.614],
                        [-329.117, 33.919],
                        [-173.034, 44.141],
                        [-78.991, -25.64],
                        [-201.245, 44.31],
                      ],
                      v: [
                        [-2209.133, 70.361],
                        [-2209.133, 158.989],
                        [1363.747, 157.997],
                        [1363.747, -49.833],
                        [919.881, -29.204],
                        [216.129, -155.349],
                        [298.516, -91.654],
                        [216.129, -80.919],
                        [-617.982, 103.247],
                        [-1127.682, 86.157],
                        [-1725.777, 114.679],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 4,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980392843, 0.0941176489, 0.082352943718, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.443137284821, 0.580392156863, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [2211.633, 415.326], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 8',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: 'Group 10',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 5,
                  s: [680.337, 1525.168, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 65, s: [680.337, 705.168, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [2032.837, 397.668, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [88.716, 67.175],
                        [0, 0],
                        [-201.245, 44.31],
                        [-78.991, -25.639],
                        [-173.034, 44.142],
                        [-329.117, 33.92],
                        [-21.093, 3.615],
                        [0, 0],
                        [29.364, -2.917],
                        [230.709, 15.98],
                        [253.28, -75.018],
                      ],
                      o: [
                        [0, 0],
                        [116.615, 73.925],
                        [201.245, -44.318],
                        [78.99, 25.631],
                        [173.024, -44.15],
                        [34.205, -3.53],
                        [181.895, -31.162],
                        [-29.054, -0.009],
                        [-266.913, 26.497],
                        [-230.709, -15.981],
                        [-253.279, 75.018],
                      ],
                      v: [
                        [-2089.338, 42.961],
                        [-2089.338, 70.366],
                        [-1605.982, 114.684],
                        [-1007.887, 86.161],
                        [-498.187, 103.251],
                        [335.924, -80.915],
                        [418.311, -91.65],
                        [335.924, -155.344],
                        [248.319, -151.108],
                        [-625.459, -5.619],
                        [-1387.807, -13.067],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 4,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980392843, 0.0941176489, 0.082352943718, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.960784373564, 0.788235353956, 0.850980451995, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [2091.838, 415.322], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 10',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: 'Group 12',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 0,
                  s: [1036.799, 1437.472, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 60, s: [1036.799, 617.472, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [2389.299, 309.972, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-84.514, -132.427],
                        [0, 0],
                        [151.155, 38.404],
                        [-1.129, 0.431],
                        [0, 0],
                        [-54.49, -25.807],
                        [-139.161, 90.221],
                        [-180.371, 31.004],
                      ],
                      o: [
                        [0, 0],
                        [-11.626, -22.31],
                        [-161.572, -41.053],
                        [0, 0],
                        [48.564, -1.474],
                        [150.449, 71.242],
                        [87.437, -56.684],
                        [-495.355, 152.833],
                      ],
                      v: [
                        [364.603, 274.777],
                        [-175.717, 274.777],
                        [-430.247, 46.399],
                        [-388.621, -71.815],
                        [-388.613, -71.815],
                        [-234.527, -38.569],
                        [277.597, -98.782],
                        [690.045, -272.129],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 4,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980392843, 0.0941176489, 0.082352943718, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.364705882353, 0.207843152214, 0.898039275525, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [2339.814, 298.546], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 12',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: 'Group 14',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 0,
                  s: [1433.11, 1443.363, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 60, s: [1433.11, 623.363, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [2785.61, 315.863, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [-495.356, 152.833],
                        [-21.965, 1.991],
                        [-289.585, -140.368],
                      ],
                      o: [
                        [0, 0],
                        [-84.514, -132.427],
                        [21.024, -3.621],
                        [-5.378, 1.599],
                        [291.31, 141.198],
                      ],
                      v: [
                        [682.493, 279.017],
                        [-28.65, 279.017],
                        [296.792, -267.889],
                        [361.3, -276.37],
                        [192.006, 1.347],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 4,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980392843, 0.0941176489, 0.082352943718, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [2733.068, 294.306], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 14',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: 'Group 16',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 0,
                  s: [1527.572, 1319.507, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 60, s: [1527.572, 499.507, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [2880.072, 192.007, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [291.31, 141.198],
                        [-2.712, 0.799],
                        [0, 0],
                        [-207.941, -150.221],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [-290.448, -140.783],
                        [0.008, -0.008],
                        [169.725, -15.428],
                        [465.7, 336.44],
                      ],
                      v: [
                        [921.947, 286.735],
                        [632.432, 286.735],
                        [141.945, 9.065],
                        [311.278, -268.66],
                        [311.286, -268.66],
                        [885.644, -97.03],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 4,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.364705882353, 0.207843152214, 0.898039275525, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [2783.13, 286.588], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 16',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.050980392843, 0.0941176489, 0.082352943718, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 4,
          nm: 'Group 18',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 0,
                  s: [474.666, 1526.203, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 60, s: [474.666, 706.203, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [1827.166, 398.703, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-11.625, -22.31],
                        [0, 0],
                        [0, 0],
                        [-118.637, 49.433],
                        [-85.525, 2.595],
                        [-161.573, -41.053],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [75.664, -31.521],
                        [-1.129, 0.431],
                        [151.154, 38.404],
                      ],
                      v: [
                        [336.232, 174.62],
                        [-537.527, 174.62],
                        [-537.527, -130.817],
                        [-115.734, -103.05],
                        [123.329, -171.972],
                        [81.703, -53.758],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 4,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980392843, 0.0941176489, 0.082352943718, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [1827.865, 398.703], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 18',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_3',
      nm: 'App Background',
      fr: 30,
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 0,
          nm: 'App Clouds',
          refId: 'comp_4',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [720, 720, 0], ix: 2, l: 2 },
            a: { a: 0, k: [720, 720, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          sy: [
            {
              c: {
                a: 0,
                k: [0.050980392843, 0.0941176489, 0.082352943718, 1],
                ix: 2,
              },
              s: { a: 0, k: 1, ix: 3 },
              ty: 0,
              nm: 'Stroke',
            },
          ],
          w: 1440,
          h: 1440,
          ip: 0,
          op: 900,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Group 47',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 0,
                  s: [256.813, 1040.864, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 60, s: [256.813, 520.864, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [379.313, 353.364, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 112.307],
                        [112.306, 0],
                        [0, -112.307],
                        [-112.307, 0],
                      ],
                      o: [
                        [0, -112.307],
                        [-112.307, 0],
                        [0, 112.307],
                        [112.306, 0],
                      ],
                      v: [
                        [203.349, 0],
                        [0, -203.349],
                        [-203.349, 0],
                        [0, 203.349],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.929411824544, 0.098039223166, 0.309803921569, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [379.313, 353.364], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 47',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 0,
          nm: 'App Clouds - 2',
          refId: 'comp_5',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [720, 720, 0], ix: 2, l: 2 },
            a: { a: 0, k: [720, 440, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          sy: [
            {
              c: {
                a: 0,
                k: [0.050980392843, 0.0941176489, 0.082352943718, 1],
                ix: 2,
              },
              s: { a: 0, k: 1, ix: 3 },
              ty: 0,
              nm: 'Stroke',
            },
          ],
          w: 1440,
          h: 880,
          ip: 0,
          op: 900,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 0,
          nm: 'Stars & Planets',
          refId: 'comp_6',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [720, 720, 0], ix: 2, l: 2 },
            a: { a: 0, k: [720, 720, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 1440,
          h: 1440,
          ip: 120,
          op: 241,
          st: 120,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 0,
          nm: 'Stars & Planets',
          refId: 'comp_6',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [720, 720, 0], ix: 2, l: 2 },
            a: { a: 0, k: [720, 720, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 1440,
          h: 1440,
          ip: 0,
          op: 121,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: 'Group 26',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [461.494, 406.376, 0], ix: 2, l: 2 },
            a: { a: 0, k: [583.994, 238.876, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.583, 0.583, 0.583], y: [1, 1, 1] },
                  o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                  t: 0,
                  s: [100, 100, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
                  o: { x: [0.417, 0.417, 0.417], y: [0, 0, 0] },
                  t: 30,
                  s: [0, 0, 100],
                },
                {
                  i: { x: [0.25, 0.25, 0.25], y: [1, 1, 1] },
                  o: { x: [0.33, 0.33, 0.33], y: [0, 0, 0] },
                  t: 90,
                  s: [0, 0, 100],
                },
                { t: 120, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [3.407, 2.072],
                        [17.763, -0.124],
                        [3.378, -2.119],
                        [9.105, -9.233],
                        [2.326, -3.617],
                        [-0.272, -38.948],
                        [-2.376, -3.584],
                        [-9.233, -9.105],
                        [-3.407, -2.072],
                        [-17.763, 0.124],
                        [-3.378, 2.119],
                        [-9.105, 9.233],
                        [-2.326, 3.617],
                        [0.272, 38.948],
                        [2.376, 3.584],
                        [9.233, 9.105],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [583.994, 238.876], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 26',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: 'Group 27',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [352.143, 781.705, 0], ix: 2, l: 2 },
            a: { a: 0, k: [474.643, 614.205, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.25, 0.25, 0.25], y: [1, 1, 1] },
                  o: { x: [0.33, 0.33, 0.33], y: [0, 0, 0] },
                  t: 30,
                  s: [0, 0, 100],
                },
                {
                  i: { x: [0.25, 0.25, 0.25], y: [1, 1, 1] },
                  o: { x: [0.33, 0.33, 0.33], y: [0, 0, 0] },
                  t: 60,
                  s: [100, 100, 100],
                },
                { t: 90, s: [0, 0, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [2.875, 1.744],
                        [15.154, 0.168],
                        [2.913, -1.681],
                        [7.906, -7.406],
                        [2.038, -2.921],
                        [0.355, -31.827],
                        [-1.973, -2.965],
                        [-7.74, -7.581],
                        [-2.875, -1.745],
                        [-15.154, -0.17],
                        [-2.914, 1.679],
                        [-7.907, 7.405],
                        [-2.039, 2.919],
                        [-0.355, 31.826],
                        [1.973, 2.964],
                        [7.739, 7.579],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [474.643, 614.205], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 27',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: 'Group 29',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [1235.547, 400.431, 0], ix: 2, l: 2 },
            a: { a: 0, k: [1358.047, 232.931, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.25, 0.25, 0.25], y: [1, 1, 1] },
                  o: { x: [0.33, 0.33, 0.33], y: [0, 0, 0] },
                  t: 14,
                  s: [0, 0, 100],
                },
                {
                  i: { x: [0.25, 0.25, 0.25], y: [1, 1, 1] },
                  o: { x: [0.33, 0.33, 0.33], y: [0, 0, 0] },
                  t: 44,
                  s: [100, 100, 100],
                },
                { t: 74, s: [0, 0, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [3.26, 1.976],
                        [16.982, -0.142],
                        [3.226, -2.031],
                        [8.693, -8.84],
                        [2.219, -3.462],
                        [-0.311, -37.236],
                        [-2.276, -3.424],
                        [-8.84, -8.694],
                        [-3.26, -1.977],
                        [-16.982, 0.141],
                        [-3.227, 2.03],
                        [-8.693, 8.839],
                        [-2.219, 3.461],
                        [0.311, 37.236],
                        [2.276, 3.423],
                        [8.84, 8.693],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [1358.047, 232.931], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 29',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 4,
          nm: 'Group 50',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [1367.411, 600.517, 0], ix: 2, l: 2 },
            a: { a: 0, k: [1489.911, 433.017, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.25, 0.25, 0.25], y: [1, 1, 1] },
                  o: { x: [0.33, 0.33, 0.33], y: [0, 0, 0] },
                  t: 0,
                  s: [0, 0, 100],
                },
                {
                  i: { x: [0.25, 0.25, 0.25], y: [1, 1, 1] },
                  o: { x: [0.33, 0.33, 0.33], y: [0, 0, 0] },
                  t: 30,
                  s: [100, 100, 100],
                },
                { t: 60, s: [0, 0, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [3.664, 2.4],
                        [19.171, -0.02],
                        [3.659, -2.409],
                        [9.887, -10.531],
                        [2.534, -4.134],
                        [-0.043, -44.686],
                        [-2.541, -4.13],
                        [-9.907, -10.512],
                        [-3.664, -2.402],
                        [-19.171, 0.018],
                        [-3.659, 2.408],
                        [-9.886, 10.53],
                        [-2.533, 4.133],
                        [0.044, 44.686],
                        [2.542, 4.129],
                        [9.907, 10.51],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [1489.911, 433.017], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 50',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 10,
          ty: 4,
          nm: 'Group 30',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 0,
                  s: [979.963, 868.269, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 60, s: [979.963, 348.269, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [1102.463, 140.769, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 23.392],
                        [0.103, 1.201],
                        [12.209, -5.774],
                        [1.658, -6.701],
                        [-0.865, 0],
                      ],
                      o: [
                        [0, -1.228],
                        [-7.649, 14.614],
                        [-10.314, 4.878],
                        [0.853, 0.051],
                        [23.392, 0],
                      ],
                      v: [
                        [22.465, -19.355],
                        [22.307, -22.999],
                        [-6.572, 2.564],
                        [-22.465, 22.92],
                        [-19.89, 22.999],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.58431372549, 0.486274539723, 0.917647118662, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [1102.463, 140.769], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 30',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 11,
          ty: 4,
          nm: 'Group 32',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 0,
                  s: [944.538, 863.617, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 60, s: [944.538, 343.617, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [1067.038, 136.117, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [16.678, -10.7],
                        [7.382, -14.154],
                        [-10.583, -4.505],
                        [-22.06, 15.081],
                        [-7.92, 9.685],
                        [-8.082, -0.847],
                        [9.254, 1.461],
                      ],
                      o: [
                        [-16.679, 10.701],
                        [4.09, 10.795],
                        [-8.28, -15.664],
                        [22.06, -15.081],
                        [1.867, -2.283],
                        [-6.237, -6.429],
                        [3.378, 6.28],
                      ],
                      v: [
                        [0.35, -31.403],
                        [-35.01, 0.297],
                        [-11.965, 24.285],
                        [-2.34, -7.685],
                        [19.182, -39.952],
                        [35.01, -44.19],
                        [11.263, -56.537],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 4,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.58431372549, 0.486274539723, 0.917647118662, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [1077.964, 136.117], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 32',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 12,
          ty: 4,
          nm: 'Group 34',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 0,
                  s: [960.073, 865.3, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 60, s: [960.073, 345.3, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [1082.573, 137.8, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -23.392],
                        [23.392, 0],
                        [0, 23.392],
                        [-23.392, 0],
                      ],
                      o: [
                        [0, 23.392],
                        [-23.392, 0],
                        [0, -23.392],
                        [23.392, 0],
                      ],
                      v: [
                        [42.355, -16.387],
                        [0, 25.968],
                        [-42.355, -16.387],
                        [0, -58.742],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 4,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.364705882353, 0.207843152214, 0.898039275525, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [1082.573, 137.8], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 34',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_4',
      nm: 'App Clouds',
      fr: 30,
      layers: [
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: 'Group 36',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [827.672, 639.566, 0], ix: 2, l: 2 },
            a: { a: 0, k: [950.172, 472.066, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.864, 2.687],
                        [-16.08, 5.878],
                        [-32.275, 11.604],
                        [-11.837, 6.152],
                        [-19.57, 3.038],
                        [-33.236, 9.762],
                        [-9.954, 5.162],
                        [-1.598, -6.899],
                        [10.593, -3.407],
                        [33.171, -9.211],
                        [28.857, -8.265],
                        [34.34, -4.032],
                        [26.232, -3.423],
                      ],
                      o: [
                        [16.678, -4.561],
                        [32.241, -11.784],
                        [12.595, -4.529],
                        [18.25, -9.484],
                        [33.889, -5.261],
                        [10.621, -3.12],
                        [6.102, -3.165],
                        [2.549, 11.004],
                        [-32.863, 10.568],
                        [-28.914, 8.028],
                        [-33.382, 9.561],
                        [-26.299, 3.088],
                        [-2.988, 0.39],
                      ],
                      v: [
                        [-193.798, 51.646],
                        [-144.356, 37.232],
                        [-46.055, 7.986],
                        [-9.714, -8.764],
                        [47.908, -23.203],
                        [149.544, -39.159],
                        [180.577, -51.167],
                        [191.248, -45.779],
                        [181.886, -29.297],
                        [81.959, -3.365],
                        [-4.546, 21.678],
                        [-106.183, 42.326],
                        [-184.77, 53.508],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.960784373564, 0.788235353956, 0.850980451995, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [950.89, 471.522], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 36',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: 'Group 37',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [841.615, 527.686, 0], ix: 2, l: 2 },
            a: { a: 0, k: [964.115, 360.186, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [7.383, 2.49],
                        [-9.556, 1.908],
                        [-23.242, 9.311],
                        [-15.137, 11.296],
                        [1.528, 11.483],
                        [-11.849, 0.635],
                        [-12.01, 0.263],
                        [-16.974, 2.702],
                        [-10.302, 1.421],
                        [-21.833, 0.86],
                        [-0.367, -3.098],
                        [2.511, -0.651],
                        [20.727, -13.251],
                        [13.048, -5.29],
                        [19.794, -8.316],
                        [16.886, -3.745],
                        [41.195, -7.986],
                      ],
                      o: [
                        [8.829, -4.72],
                        [24.308, -4.854],
                        [17.225, -6.9],
                        [8.865, -6.616],
                        [-1.637, -12.299],
                        [11.999, -0.644],
                        [17.164, -0.374],
                        [10.097, -1.608],
                        [21.588, -2.978],
                        [1.981, -0.078],
                        [0.335, 2.834],
                        [-23.812, 6.178],
                        [-12.011, 7.679],
                        [-19.891, 8.063],
                        [-16.269, 6.836],
                        [-40.985, 9.089],
                        [-7.1, 1.377],
                      ],
                      v: [
                        [-181.776, 60.814],
                        [-154.1, 51.335],
                        [-82.099, 32.84],
                        [-33.161, 6.3],
                        [-21.826, -20.322],
                        [-6.003, -40.27],
                        [30.013, -41.801],
                        [81.359, -45.03],
                        [111.037, -53.471],
                        [175.843, -61.992],
                        [181.442, -60.205],
                        [176.394, -55.579],
                        [110.833, -23.664],
                        [72.464, -5.928],
                        [12.77, 18.202],
                        [-37.578, 32.275],
                        [-159.961, 61.915],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.960784373564, 0.788235353956, 0.850980451995, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [964.269, 359.914], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 37',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: 'Group 38',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [345.834, 648.685, 0], ix: 2, l: 2 },
            a: { a: 0, k: [468.334, 481.185, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [51.44, -16.435],
                        [51.148, 24.324],
                        [4.3, 12.338],
                        [-110.417, 42.115],
                        [-14.857, 12.291],
                        [-9.439, 2.306],
                        [6.403, -1.172],
                        [16.591, -2.254],
                        [1.069, -3.303],
                        [1.117, -4.331],
                        [-5.73, 7.011],
                        [5.965, 13.309],
                        [-27.568, -25.279],
                        [-22.485, -10.764],
                        [99.262, -30.89],
                      ],
                      o: [
                        [-53.508, -2.109],
                        [-11.066, -4.727],
                        [118.707, -1.138],
                        [16.916, -8.354],
                        [4.714, -7.064],
                        [3.177, -1.536],
                        [-17.723, -1.929],
                        [-2.067, -0.434],
                        [-2.972, 1.767],
                        [-3.346, 0.456],
                        [8.457, -24.168],
                        [18.725, -15.333],
                        [8.18, 17.175],
                        [225.242, 47.25],
                        [-54.229, 16.384],
                      ],
                      v: [
                        [-124.659, 69.778],
                        [-285.345, 70.169],
                        [-322.737, 56.502],
                        [31.828, 0.908],
                        [82.681, -26.737],
                        [99.677, -43.878],
                        [99.064, -52.7],
                        [48.262, -47.203],
                        [42.83, -45.589],
                        [35.465, -44.065],
                        [27.09, -50.182],
                        [-12.62, -49.18],
                        [22.385, -69.214],
                        [97.496, -78.128],
                        [32.025, 39.856],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.960784373564, 0.788235353956, 0.850980451995, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [528.335, 481.037], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 38',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: 'Group 40',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [1271.004, 497.806, 0], ix: 2, l: 2 },
            a: { a: 0, k: [1393.504, 330.306, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [83.638, -66.534],
                        [14.575, -1.914],
                        [19.939, -2.527],
                        [20.868, -6.671],
                        [37.782, -15.279],
                        [26.927, -5.864],
                        [-12.105, 6.955],
                        [-28.367, 13.445],
                        [-11.85, 7.736],
                        [-19.307, 3.428],
                        [-39.302, 13.457],
                        [-16.273, 5.735],
                        [-4.88, 2.232],
                      ],
                      o: [
                        [-9.917, 5.72],
                        [-19.927, 2.617],
                        [-21.803, 2.763],
                        [-38.816, 12.407],
                        [-25.618, 10.36],
                        [-1.009, -1.426],
                        [12.104, -6.956],
                        [12.689, -6.013],
                        [17.175, -11.213],
                        [40.589, -7.205],
                        [16.318, -5.587],
                        [4.623, -1.63],
                        [30.413, -14.599],
                      ],
                      v: [
                        [189.991, -17.178],
                        [146.334, -10.853],
                        [86.5, -3.434],
                        [23.101, 13.369],
                        [-90.342, 58.931],
                        [-169.098, 83.712],
                        [-157.613, 73.678],
                        [-86.1, 41.332],
                        [-49.424, 20.085],
                        [6.313, 1.527],
                        [126.947, -25.483],
                        [175.681, -42.954],
                        [190.272, -49.147],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 4,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.960784373564, 0.788235353956, 0.850980451995, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [1393.177, 308.937], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 40',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 4,
          nm: 'Group 42',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [151.6, 491.367, 0], ix: 2, l: 2 },
            a: { a: 0, k: [274.1, 323.867, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-33.525, 13.367],
                        [-30.16, -5.13],
                        [-13.687, 0.908],
                        [-17.401, 5.347],
                        [-15.936, 16.423],
                        [-7.83, 11.017],
                        [-14.963, -2.024],
                        [-11.159, -5.286],
                        [13.55, -27.433],
                        [5.14, -8.115],
                        [-7.009, -6.238],
                        [-7.324, -11.779],
                        [-0.879, -7.505],
                        [6.54, -3.14],
                        [-2.78, -9.611],
                        [2.6, -2.074],
                        [7.357, 0.017],
                        [3.701, -2.495],
                        [18.559, -5.388],
                        [27.03, 0.865],
                        [18.101, 3.122],
                        [0.634, 2.259],
                        [-6.812, -0.039],
                        [-19.588, 1.386],
                        [-15.554, 4.446],
                        [-12.895, 4.121],
                        [-4.867, 5.46],
                        [-6.884, 2.461],
                        [-0.796, 2.319],
                        [6.203, 15.352],
                        [8.407, 4.57],
                        [2.433, 1.364],
                        [-5.908, 5.756],
                        [-0.314, 0.948],
                        [17.675, 7.159],
                        [11.615, -12.275],
                        [7.427, -0.16],
                        [26.743, -5.805],
                        [23.24, -6.209],
                        [4.712, -0.016],
                        [27.722, 1.804],
                      ],
                      o: [
                        [35.728, -10.576],
                        [13.618, 2.316],
                        [18.09, -1.201],
                        [21.253, -6.53],
                        [9.276, -9.559],
                        [12.379, -6.861],
                        [11.963, 1.618],
                        [30.401, 14.399],
                        [-4.319, 8.744],
                        [-5.153, 8.135],
                        [9.867, 8.783],
                        [3.752, 6.034],
                        [0.937, 7.996],
                        [-5.726, 2.749],
                        [0.95, 3.282],
                        [-4.639, 3.7],
                        [-5.339, 0.064],
                        [-16.32, 11.005],
                        [-26.447, 7.68],
                        [-18.534, -0.593],
                        [-0.929, -0.16],
                        [6.94, -0.736],
                        [19.655, 0.114],
                        [16.184, -1.144],
                        [12.995, -3.714],
                        [6.783, -2.168],
                        [4.659, -5.227],
                        [2.995, -1.071],
                        [7.651, -15.62],
                        [-3.373, -8.347],
                        [-0.57, -3.004],
                        [-5.572, -3.123],
                        [0.619, -0.603],
                        [3.147, -18.532],
                        [-15.558, -1.322],
                        [-5.316, 5.617],
                        [-27.182, 0.586],
                        [-23.618, 5.128],
                        [-4.571, 1.222],
                        [-27.761, 0.09],
                        [-22.085, -0.779],
                      ],
                      v: [
                        [-238.465, -39.246],
                        [-145.523, -40.293],
                        [-104.402, -41.733],
                        [-51.188, -52.199],
                        [5.387, -85.426],
                        [32.289, -115.092],
                        [71.892, -125.743],
                        [106.847, -117.823],
                        [126.692, -50.818],
                        [110.747, -26.855],
                        [113.161, -9.217],
                        [142.198, 17.806],
                        [150.269, 37.447],
                        [141.402, 53.301],
                        [131.984, 67.6],
                        [126.984, 76.577],
                        [113.227, 87.902],
                        [103.44, 96.371],
                        [50.897, 120.086],
                        [-29.396, 125.441],
                        [-83.996, 118.559],
                        [-86.593, 116.19],
                        [-66.021, 114.131],
                        [-7.149, 112.204],
                        [40.099, 101.421],
                        [78.664, 88.65],
                        [95.926, 77.357],
                        [111.681, 63.687],
                        [114.961, 57.236],
                        [114.967, 10.353],
                        [98.945, -11.043],
                        [93.989, -17.112],
                        [91.471, -29.745],
                        [92.449, -32.551],
                        [70.825, -70.789],
                        [28.627, -58.225],
                        [10.543, -50.413],
                        [-70.247, -40.71],
                        [-139.449, -20.087],
                        [-153.203, -17.957],
                        [-205.654, -29.935],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 4,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.960784373564, 0.788235353956, 0.850980451995, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [274.49, 323.865], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 42',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 10,
          ty: 4,
          nm: 'Group 44',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [340.798, 768.295, 0], ix: 2, l: 2 },
            a: { a: 0, k: [463.298, 600.795, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.134, 3.895],
                        [-23.587, 2.64],
                        [-20.755, 4.684],
                        [-9.882, 6.851],
                        [-0.305, 0.566],
                        [-12.645, -1.028],
                        [-10.782, 0.587],
                        [-14.967, -0.241],
                        [-1.076, 1.163],
                        [-3.179, 1.714],
                        [-10.782, 2.026],
                        [-15.426, -0.111],
                        [-18.345, 10.154],
                        [-9.101, 0.177],
                        [-12.504, 0.663],
                        [-70.46, -0.644],
                        [-16.241, -9.658],
                        [0.794, -4.507],
                        [3.483, -0.476],
                        [16.769, -2.062],
                        [1.51, -3.633],
                        [10.172, 2.118],
                        [4.775, 2.138],
                        [13.52, 0.21],
                        [50.672, -9.14],
                        [43.178, -14.322],
                        [24.316, -10.657],
                        [16.033, -4.027],
                        [1.052, 0.131],
                        [2.818, -0.991],
                        [22.869, -1.838],
                        [28.095, 3.734],
                        [20.984, 1.533],
                      ],
                      o: [
                        [23.699, -0.897],
                        [21.149, -2.367],
                        [11.119, -2.51],
                        [0.612, -0.424],
                        [8.413, -15.599],
                        [10.779, 0.877],
                        [14.92, -0.812],
                        [1.606, 0.026],
                        [3.767, 1.866],
                        [10.863, -1.548],
                        [15.208, -2.858],
                        [21.232, 0.153],
                        [8.349, -4.621],
                        [12.53, -0.244],
                        [70.419, -3.734],
                        [17.771, 0.163],
                        [2.635, 1.567],
                        [-0.714, 4.052],
                        [-16.743, 2.288],
                        [1.666, 3.603],
                        [-4.673, 0.061],
                        [-10.172, -2.118],
                        [-12.84, -5.748],
                        [-50.954, -0.793],
                        [-44.687, 8.059],
                        [-24.993, 8.29],
                        [-15.14, 6.636],
                        [-1.066, -0.129],
                        [-2.699, -2.88],
                        [-22.013, 7.739],
                        [-28.585, 2.298],
                        [-20.824, -2.768],
                        [0.134, -3.895],
                      ],
                      v: [
                        [-425.409, 23.823],
                        [-354.525, 18.182],
                        [-291.549, 8.209],
                        [-258.978, -3.135],
                        [-257.068, -4.245],
                        [-222.041, -14.641],
                        [-189.863, -14.529],
                        [-144.972, -14.219],
                        [-141.029, -15.742],
                        [-130.95, -17.578],
                        [-98.376, -22.314],
                        [-52.474, -27.669],
                        [6.286, -44.674],
                        [32.499, -50.901],
                        [70.093, -51.219],
                        [281.44, -53.94],
                        [333.537, -43.643],
                        [339.568, -37.042],
                        [331.856, -30.875],
                        [288.457, -24.989],
                        [274.03, -20.964],
                        [260.303, -21.861],
                        [247.556, -30.328],
                        [207.271, -37.974],
                        [54.68, -30.977],
                        [-77.508, 1.062],
                        [-152.732, 25.006],
                        [-199.118, 42.216],
                        [-202.302, 41.828],
                        [-211.02, 41.139],
                        [-278.822, 52.654],
                        [-363.369, 44.136],
                        [-425.811, 35.508],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 4,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.960784373564, 0.788235353956, 0.850980451995, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [463.646, 601.418], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 44',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 11,
          ty: 4,
          nm: 'Group 46',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [1206.733, 371.151, 0], ix: 2, l: 2 },
            a: { a: 0, k: [1329.233, 203.651, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [14.214, -15.075],
                        [3.165, -3.056],
                        [21.085, -29.243],
                        [9.714, -5.216],
                        [19.852, -11.214],
                        [32.627, -26.354],
                        [9.673, -8.334],
                        [27.889, -4.853],
                        [10.546, -0.877],
                        [1.391, 3.098],
                        [-0.998, 2.094],
                        [-15.733, 9.892],
                        [-19.105, 12.109],
                        [-5.666, 10.647],
                        [4.863, 7.568],
                        [-5.931, 6.171],
                        [-26.59, 19.887],
                        [-18.847, 10.544],
                        [-23.5, -2.661],
                        [-24.225, -0.253],
                      ],
                      o: [
                        [-4.749, 6.302],
                        [-25.482, 24.597],
                        [-6.971, 9.667],
                        [-20.084, 10.784],
                        [-36.088, 20.387],
                        [-9.904, 8],
                        [-21.949, 18.912],
                        [-10.374, 1.804],
                        [-2.256, 0.188],
                        [-1.088, -2.423],
                        [8.77, -18.409],
                        [19.148, -12.039],
                        [9.073, -5.751],
                        [4.367, -8.205],
                        [-6.612, -10.288],
                        [23.029, -23.96],
                        [17.383, -13.001],
                        [18.646, -10.432],
                        [22.081, 2.499],
                        [14.048, 0.146],
                      ],
                      v: [
                        [219.156, -183.311],
                        [207.699, -170.417],
                        [134.324, -93.337],
                        [107.143, -74.013],
                        [46.291, -42.906],
                        [-58.443, 24.504],
                        [-87.011, 50.08],
                        [-162.15, 84.756],
                        [-193.149, 91.027],
                        [-199.703, 89.184],
                        [-198.104, 82.792],
                        [-157.301, 44.951],
                        [-99.166, 10.056],
                        [-74.917, -11.78],
                        [-73.483, -35.821],
                        [-69.692, -60.832],
                        [0.564, -131.439],
                        [55.641, -165.693],
                        [121.637, -181.537],
                        [188.773, -201.362],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 4,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.960784373564, 0.788235353956, 0.850980451995, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [1362.493, 204.115], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 46',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_5',
      nm: 'App Clouds - 2',
      fr: 30,
      layers: [
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Group 49',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [1103.587, 301.34, 0], ix: 2, l: 2 },
            a: { a: 0, k: [1226.087, 413.84, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-0.731, 0.286],
                        [-1.973, 0.645],
                        [-20.701, 8.247],
                        [-2.06, 1.765],
                        [-2.873, 1.073],
                        [-10.336, 3.959],
                        [-6.502, 2.467],
                        [-7.593, 2.825],
                        [0, 0],
                        [0.771, 2.296],
                        [1.939, 0],
                        [0.398, -0.068],
                        [1.204, -0.509],
                        [7.429, -3.168],
                        [13.441, -5.592],
                        [17.652, -8.192],
                        [2.796, -1.25],
                        [9.007, -6.171],
                      ],
                      o: [
                        [0.732, -0.281],
                        [1.898, -0.745],
                        [19.868, -6.495],
                        [2.635, -1.05],
                        [2.175, -1.862],
                        [10.542, -3.942],
                        [6.496, -2.488],
                        [7.704, -2.925],
                        [0, 0],
                        [2.81, -1.049],
                        [-0.669, -1.992],
                        [-0.358, 0],
                        [-1.044, 0.179],
                        [-7.438, 3.148],
                        [-13.167, 5.614],
                        [-21.436, 8.916],
                        [-2.774, 1.288],
                        [-9.765, 4.364],
                        [0, 0],
                      ],
                      v: [
                        [584.569, -94.479],
                        [586.764, -95.333],
                        [592.597, -97.492],
                        [653.738, -119.71],
                        [660.564, -124.349],
                        [667.86, -129.212],
                        [699.426, -141.208],
                        [718.919, -148.651],
                        [742.066, -157.322],
                        [749.935, -160.25],
                        [752.843, -165.023],
                        [748.912, -168.025],
                        [747.774, -167.922],
                        [744.173, -166.963],
                        [721.875, -157.479],
                        [681.651, -140.468],
                        [621.629, -113.785],
                        [613.264, -109.999],
                        [584.398, -94.953],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [-10.764, 3.24],
                        [-6.785, 2.125],
                        [-10.734, 3.101],
                        [-6.884, 2.055],
                        [-9.473, 2.959],
                        [-7.72, 2.344],
                        [-5.441, 1.552],
                        [-5.541, 1.684],
                        [-8.21, 2.563],
                        [-8.827, 2.674],
                        [-15.067, 4.21],
                        [-26.597, 8.777],
                        [-7.291, 1.826],
                        [-7.308, 2.429],
                        [-6.782, 1.992],
                        [-6.116, 1.985],
                        [-3.959, 1.177],
                        [-4.76, 1.608],
                        [-5.644, 1.616],
                        [-5.472, 1.831],
                        [-5.77, 1.665],
                        [-2.212, 0.652],
                        [-1.94, 0.519],
                        [-5.618, 3.087],
                        [-3.815, 0.659],
                        [0, 0],
                        [-0.121, 0.32],
                        [0.823, 1.031],
                        [0, 0],
                        [0.179, 0.136],
                        [0.1, 0.085],
                        [-0.428, 2.403],
                        [-3.647, 1.351],
                        [-4.371, 1.772],
                        [-8.946, 2.502],
                        [0.36, 1.715],
                        [0.935, 0],
                        [0.52, -0.082],
                        [0.39, -0.019],
                        [15.95, -1.951],
                        [23.781, -3.645],
                        [29.409, -3.183],
                        [0.714, -0.082],
                        [3.132, 0],
                        [1.409, 0.194],
                        [5.585, 0],
                        [12.249, -2.647],
                        [9.317, -1.514],
                        [11.544, -2.402],
                        [9.944, -1.748],
                        [8.661, -1.554],
                        [26.337, -3.877],
                        [10.25, -1.716],
                        [14.581, -1.874],
                        [0.37, -0.054],
                        [0.938, -0.04],
                        [0.26, -0.044],
                        [0.36, 0],
                        [0.189, 1.094],
                        [-0.784, 0.221],
                        [-0.202, 0.101],
                        [-8.521, 2.039],
                        [-1.741, 0.434],
                        [-27.981, 6.482],
                        [0, 0],
                        [-34.988, 7.798],
                        [-10.312, 2.107],
                        [-10.764, 2.411],
                        [-9.919, 2.178],
                        [-20.574, 4.924],
                        [0, 0],
                        [-22.119, 5.747],
                        [0, 0],
                        [-4.939, 1.461],
                        [-2.56, 2.429],
                        [-6.025, 1.201],
                        [-1.044, 0.227],
                        [-36.752, 5.687],
                        [-1.953, 0.058],
                        [0, 0],
                        [-0.212, 0.021],
                        [-0.29, 0],
                        [-0.332, -1.758],
                        [1.109, -0.569],
                        [1.884, -0.873],
                        [3.786, -2.789],
                        [0.492, -0.301],
                        [-0.392, -2.466],
                        [-2.559, -0.612],
                        [0, 0],
                        [-6.794, 0],
                        [-5.81, 0.881],
                        [-1.175, 2.071],
                        [-8.918, 7.304],
                        [0, 0],
                        [3.22, 5.364],
                        [0, 0],
                        [-2.49, 2.654],
                        [-0.232, 0.252],
                        [-5.253, 3.718],
                        [-2.53, 2.001],
                        [-9.149, 5.084],
                        [-6.279, 4.204],
                        [-2.582, 1.776],
                        [0, 0],
                        [0.687, 1.999],
                        [1.436, 0],
                        [0.589, -0.074],
                        [0.257, -0.026],
                        [11.973, -5.325],
                        [6.956, -0.574],
                        [0.473, 0],
                        [0.881, 2.171],
                        [-1.749, 1.075],
                        [0, 0],
                        [-10.217, 3.873],
                        [0, 0],
                        [-23.599, 7.562],
                        [-18.738, 2.177],
                        [-4.276, 0],
                        [-2.733, -0.171],
                        [-0.968, -4.029],
                        [1.798, -1.288],
                        [2.757, -1.743],
                        [1.545, -1.016],
                        [0.258, -0.142],
                        [-0.385, -1.035],
                        [-0.657, 0],
                        [-0.278, 0.029],
                        [-0.286, 0],
                        [-0.364, 0],
                        [-4.345, 0.595],
                        [-0.865, 0.114],
                        [0, 0],
                        [-17.574, 0],
                        [-2.538, -0.106],
                        [-2.539, -0.723],
                        [-0.151, -5.369],
                        [-4.242, 0],
                        [-1.412, 0.461],
                        [-3.813, 1.261],
                        [-18.145, 4.446],
                        [-3.822, 0.447],
                        [-2.852, 0.537],
                        [-0.849, 0],
                        [-4.932, -3.868],
                        [-0.679, -5.5],
                        [17.445, -4.373],
                        [0, 0],
                        [8.687, -2.857],
                        [0.223, -0.067],
                        [0.35, -0.667],
                        [-0.169, -0.52],
                        [-0.665, 0],
                        [-0.494, 0.102],
                        [-0.41, 0.024],
                        [-6.812, 1.414],
                        [-4.597, 0.66],
                        [-0.443, 0],
                        [-0.098, -1.181],
                        [3.758, -0.632],
                        [6.03, -2.217],
                        [3.098, -0.937],
                        [0.02, -0.357],
                        [-1.842, -0.792],
                        [0, 0],
                        [0, 0],
                        [0.372, -0.146],
                        [0.895, -0.152],
                        [21.942, -10.601],
                        [15.554, -14.202],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-7.111, 4.774],
                        [-1.865, 1.336],
                        [-3.572, 2.045],
                        [-2.372, 1.508],
                        [-0.194, 0.116],
                        [0.689, 1.329],
                        [0.297, 0],
                        [0.633, -0.263],
                        [0.358, -0.106],
                        [1.667, -0.47],
                        [2.038, -0.617],
                        [3.091, -0.883],
                        [10.546, -5.529],
                        [2.015, -1.204],
                        [7.96, 0],
                        [0.515, 0.032],
                        [1.204, 0],
                        [11.32, -3.84],
                        [2.309, -0.75],
                        [2.792, -0.903],
                        [17.749, -6.689],
                        [16.24, -8.285],
                        [2.098, -1.214],
                        [5.165, -1.51],
                        [17.344, -4.871],
                        [13.054, -3.594],
                        [5.864, -1.621],
                        [0, 0],
                        [0, 0],
                        [2.119, 0],
                        [1.186, -0.055],
                        [1.205, 0],
                        [3.127, 0.35],
                        [3.173, 0],
                        [1.578, -0.235],
                        [8.628, -2.934],
                        [1.819, -0.602],
                        [4.824, -1.36],
                        [5.073, -1.708],
                        [14.182, -4.064],
                        [8.161, -2.477],
                        [3.829, -1.148],
                        [5.678, -1.67],
                        [13.227, -4.323],
                        [11.732, -3.349],
                        [7.273, -2.189],
                        [6.081, -1.962],
                        [10.587, -2.767],
                        [11.43, -3.519],
                        [7.709, -2.216],
                        [14.193, -4.463],
                        [9.754, -2.947],
                        [0, 0],
                        [16.205, -4.552],
                        [8.956, -2.816],
                        [11.189, -2.973],
                        [3.821, -1.233],
                        [6.13, -1.193],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [10.853, -3.612],
                        [6.696, -2.016],
                        [10.83, -3.395],
                        [6.791, -1.962],
                        [9.669, -2.888],
                        [7.573, -2.365],
                        [5.507, -1.671],
                        [5.478, -1.562],
                        [8.369, -2.542],
                        [8.655, -2.702],
                        [17.619, -5.338],
                        [19.29, -5.392],
                        [7.248, -2.392],
                        [7.377, -1.847],
                        [6.835, -2.272],
                        [6.067, -1.782],
                        [3.982, -1.293],
                        [4.785, -1.422],
                        [5.666, -1.914],
                        [5.458, -1.562],
                        [5.689, -1.904],
                        [2.227, -0.644],
                        [1.96, -0.579],
                        [5.979, -1.599],
                        [3.305, -1.816],
                        [0, 0],
                        [1.807, -0.314],
                        [0.228, -0.601],
                        [0, 0],
                        [-0.112, -0.141],
                        [-0.106, -0.08],
                        [-2.885, -2.468],
                        [0.433, -2.429],
                        [4.493, -1.664],
                        [8.421, -3.415],
                        [3.081, -0.861],
                        [-0.166, -0.789],
                        [-0.476, 0],
                        [-0.439, 0.069],
                        [-14.729, 0.726],
                        [-24.285, 2.971],
                        [-28.732, 4.404],
                        [-0.712, 0.077],
                        [-3.089, 0.352],
                        [-1.617, 0],
                        [-6.438, -0.887],
                        [-12.374, 0],
                        [-6.841, 1.478],
                        [-11.82, 1.921],
                        [-9.72, 2.023],
                        [-8.668, 1.523],
                        [-25.747, 4.616],
                        [-10.453, 1.539],
                        [-14.249, 2.385],
                        [-0.37, 0.047],
                        [-0.906, 0.132],
                        [-0.232, 0.01],
                        [-0.353, 0.059],
                        [-1.049, 0.001],
                        [-0.265, -1.518],
                        [0.28, -0.08],
                        [8.08, -4.019],
                        [1.752, -0.419],
                        [28.341, -7.067],
                        [0, 0],
                        [32.598, -7.562],
                        [10.448, -2.328],
                        [10.631, -2.171],
                        [9.909, -2.221],
                        [20.313, -4.458],
                        [0, 0],
                        [21.855, -5.225],
                        [0, 0],
                        [4.91, -1.267],
                        [2.647, -0.783],
                        [4.725, -4.482],
                        [1.061, -0.212],
                        [30.392, -6.625],
                        [1.965, -0.303],
                        [0, 0],
                        [0.197, -0.006],
                        [0.294, -0.029],
                        [0.454, 0],
                        [0.369, 1.958],
                        [-1.88, 0.964],
                        [-4.186, 1.94],
                        [-0.458, 0.337],
                        [-1.829, 1.117],
                        [0.483, 3.031],
                        [0, 0],
                        [6.111, 1.518],
                        [5.174, 0],
                        [2.783, -0.422],
                        [6.218, -10.974],
                        [0, 0],
                        [4.941, -4.046],
                        [0, 0],
                        [-2.67, -4.455],
                        [0.242, -0.258],
                        [4.471, -4.886],
                        [2.6, -1.841],
                        [8.435, -6.671],
                        [6.502, -3.614],
                        [2.604, -1.744],
                        [0, 0],
                        [2.056, -1.405],
                        [-0.429, -1.25],
                        [-0.559, 0],
                        [-0.265, 0.033],
                        [-9.559, 0.989],
                        [-7.24, 3.221],
                        [-0.464, 0.039],
                        [-1.454, 0],
                        [-1.204, -2.964],
                        [0, 0],
                        [9.518, -5.924],
                        [0, 0],
                        [31.11, -11.879],
                        [15.288, -4.898],
                        [3.997, -0.464],
                        [2.592, 0],
                        [1.513, 0.094],
                        [0.799, 3.326],
                        [-2.698, 1.933],
                        [-1.564, 0.988],
                        [-0.226, 0.148],
                        [-1.276, 0.703],
                        [0.189, 0.508],
                        [0.268, 0],
                        [0.307, -0.032],
                        [0.454, 0.01],
                        [4.457, 0],
                        [0.866, -0.119],
                        [0, 0],
                        [17.088, -2.278],
                        [2.65, 0],
                        [3.411, 0.143],
                        [5.383, 1.534],
                        [0.147, 5.216],
                        [1.198, 0],
                        [3.817, -1.248],
                        [17.4, -5.757],
                        [3.843, -0.943],
                        [2.84, -0.332],
                        [0.956, -0.053],
                        [8.071, 0],
                        [4.417, 3.465],
                        [1.394, 11.303],
                        [0, 0],
                        [-8.695, 2.831],
                        [-0.207, 0.068],
                        [-0.968, 0.289],
                        [-0.167, 0.322],
                        [0.193, 0.587],
                        [0.435, 0],
                        [0.432, -0.088],
                        [7.078, -0.424],
                        [4.466, -0.927],
                        [0.535, -0.038],
                        [5.853, 0],
                        [0.254, 3.042],
                        [-6.577, 0.748],
                        [-2.985, 1.097],
                        [-1.66, 0.503],
                        [-0.006, 0.099],
                        [0, 0],
                        [0, 0],
                        [-0.376, 0.129],
                        [-0.824, 0.325],
                        [-26.694, 4.552],
                        [-21.172, 10.228],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [4.89, -7.665],
                        [1.885, -1.265],
                        [3.399, -2.431],
                        [2.409, -1.379],
                        [0.168, -0.106],
                        [1.632, -0.973],
                        [-0.283, -0.548],
                        [-0.516, 0],
                        [-0.388, 0.162],
                        [-1.66, 0.49],
                        [-2.051, 0.577],
                        [-3.131, 0.947],
                        [-11.137, 3.181],
                        [-2.081, 1.091],
                        [-6.386, 3.818],
                        [-0.503, 0],
                        [-1.186, -0.074],
                        [-12.374, 0],
                        [-2.302, 0.781],
                        [-2.791, 0.907],
                        [-17.734, 5.738],
                        [-22.427, 8.453],
                        [-2.181, 1.114],
                        [-4.516, 2.614],
                        [-16.815, 4.915],
                        [-13.257, 3.723],
                        [-5.865, 1.615],
                        [0, 0],
                        [0, 0],
                        [-2.706, -1.198],
                        [-1.198, 0],
                        [-1.19, 0.056],
                        [-3.289, -0.007],
                        [-3.124, -0.348],
                        [-1.809, 0],
                        [-9.185, 1.367],
                        [-1.821, 0.619],
                        [-4.853, 1.608],
                        [-5.061, 1.427],
                        [-10.906, 3.672],
                        [-8.336, 2.388],
                        [-3.828, 1.162],
                        [-5.67, 1.7],
                        [-13.121, 3.857],
                        [-11.79, 3.854],
                        [-7.184, 2.051],
                        [-6.216, 1.87],
                        [-10.249, 3.308],
                        [-11.778, 3.078],
                        [-7.536, 2.32],
                        [-14.525, 4.177],
                        [-9.563, 3.008],
                        [0, 0],
                        [-15.838, 4.797],
                        [-9.19, 2.582],
                        [-10.843, 3.409],
                        [-3.932, 1.044],
                        [-5.809, 1.875],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-337.197, 205.049],
                        [-336.776, 204.909],
                        [-304.028, 194.705],
                        [-283.625, 188.479],
                        [-250.961, 178.776],
                        [-230.264, 172.73],
                        [-201.327, 163.824],
                        [-178.204, 156.67],
                        [-161.63, 151.842],
                        [-144.946, 146.98],
                        [-119.876, 139.224],
                        [-93.445, 131.055],
                        [-45.937, 117.089],
                        [20.413, 96.6],
                        [42.493, 90.495],
                        [64.805, 84.309],
                        [85.435, 77.985],
                        [103.891, 72.376],
                        [115.925, 68.688],
                        [130.417, 64.188],
                        [147.562, 58.988],
                        [164.137, 53.98],
                        [181.563, 48.688],
                        [188.227, 46.75],
                        [194.134, 45.116],
                        [211.912, 38.777],
                        [222.978, 35.621],
                        [223.692, 35.497],
                        [226.143, 34.417],
                        [224.695, 31.719],
                        [224.613, 31.617],
                        [224.152, 31.211],
                        [223.84, 30.965],
                        [220.085, 23.519],
                        [226.322, 17.741],
                        [239.705, 12.472],
                        [265.781, 3.023],
                        [269.663, -0.651],
                        [268.133, -1.749],
                        [266.601, -1.582],
                        [265.357, -1.427],
                        [219.121, 2.607],
                        [146.465, 12.993],
                        [58.615, 25.135],
                        [56.476, 25.375],
                        [47.061, 26.092],
                        [42.564, 25.803],
                        [24.695, 24.484],
                        [-9.603, 29.222],
                        [-33.514, 33.891],
                        [-68.817, 40.696],
                        [-98.533, 46.558],
                        [-124.524, 51.188],
                        [-203.231, 64.456],
                        [-234.535, 69.485],
                        [-278.096, 76.212],
                        [-279.205, 76.368],
                        [-281.984, 76.677],
                        [-282.73, 76.774],
                        [-283.809, 76.896],
                        [-285.676, 75.248],
                        [-283.843, 73.137],
                        [-283.095, 72.881],
                        [-257.681, 64.714],
                        [-252.439, 63.443],
                        [-167.183, 43.191],
                        [-155.126, 40.397],
                        [-52.785, 17.001],
                        [-21.362, 10.388],
                        [11.026, 3.561],
                        [40.772, -3.015],
                        [102.663, -17.007],
                        [109.054, -18.535],
                        [175.626, -34.911],
                        [178.829, -35.74],
                        [193.754, -39.779],
                        [201.387, -44.487],
                        [218.365, -51.413],
                        [221.528, -52.062],
                        [324.695, -71.911],
                        [330.632, -72.333],
                        [331.346, -72.356],
                        [331.963, -72.405],
                        [332.848, -72.463],
                        [335.015, -70.705],
                        [332.601, -67.318],
                        [326.89, -64.578],
                        [314.59, -57.841],
                        [313.13, -56.892],
                        [309.967, -52.254],
                        [315.654, -47.978],
                        [316.317, -47.817],
                        [335.496, -45.561],
                        [351.818, -46.87],
                        [357.247, -51.34],
                        [381.61, -77.667],
                        [381.923, -77.922],
                        [383.644, -87.333],
                        [383.328, -87.859],
                        [386.247, -98.272],
                        [386.96, -99.038],
                        [402.116, -111.486],
                        [409.935, -117.231],
                        [436.98, -134.244],
                        [456.485, -145.799],
                        [464.257, -151.088],
                        [464.51, -151.262],
                        [467.175, -155.736],
                        [464.55, -157.495],
                        [462.792, -157.345],
                        [462.008, -157.252],
                        [430.447, -147.997],
                        [408.816, -143.226],
                        [407.401, -143.158],
                        [403.032, -145.58],
                        [405.971, -151.09],
                        [406.322, -151.307],
                        [436.621, -165.109],
                        [440.121, -166.439],
                        [520.312, -194.937],
                        [572.494, -207.312],
                        [584.961, -208.012],
                        [592.988, -207.755],
                        [600.072, -203.346],
                        [596.063, -196.578],
                        [587.755, -191.101],
                        [583.083, -188.109],
                        [582.346, -187.679],
                        [580.935, -185.523],
                        [582.095, -184.827],
                        [582.922, -184.885],
                        [583.821, -184.95],
                        [585.003, -184.936],
                        [598.299, -186.146],
                        [600.895, -186.499],
                        [606.349, -187.223],
                        [658.681, -191.856],
                        [666.499, -191.697],
                        [675.342, -190.41],
                        [683.683, -180.007],
                        [690.588, -171.799],
                        [694.522, -172.494],
                        [705.964, -176.265],
                        [759.501, -192.421],
                        [771.12, -194.273],
                        [779.748, -195.486],
                        [782.422, -195.561],
                        [802.298, -189.648],
                        [810.2, -175.745],
                        [786.436, -146.34],
                        [778.191, -143.653],
                        [752.112, -135.134],
                        [751.463, -134.934],
                        [749.048, -133.65],
                        [749.05, -132.398],
                        [750.242, -131.583],
                        [751.683, -131.788],
                        [752.97, -131.992],
                        [773.902, -135.291],
                        [787.583, -137.835],
                        [789.033, -137.89],
                        [795.846, -133.96],
                        [789.029, -126.814],
                        [770.185, -121.557],
                        [761.015, -118.388],
                        [758.572, -116.944],
                        [760.474, -115.518],
                        [761.664, -115.005],
                        [760.44, -114.585],
                        [759.32, -114.163],
                        [756.748, -113.351],
                        [684.471, -90.833],
                        [629.892, -54.528],
                        [629.753, -54.401],
                        [626.216, -54.337],
                        [626.717, -55.122],
                        [646.073, -72.499],
                        [651.772, -76.408],
                        [662.437, -82.999],
                        [669.71, -87.312],
                        [670.257, -87.645],
                        [672.303, -90.737],
                        [671.38, -91.359],
                        [669.578, -90.822],
                        [668.449, -90.395],
                        [663.455, -88.966],
                        [657.316, -87.195],
                        [647.898, -84.451],
                        [614.7, -72.45],
                        [608.52, -68.909],
                        [587.571, -61.143],
                        [586.044, -61.19],
                        [582.442, -61.302],
                        [547.043, -53.492],
                        [540.13, -51.18],
                        [531.756, -48.466],
                        [477.936, -30.107],
                        [421.261, -5.573],
                        [414.813, -1.991],
                        [400.461, 4.836],
                        [348.984, 19.584],
                        [309.175, 30.618],
                        [291.581, 35.469],
                        [281.006, 37.006],
                        [280.874, 36.949],
                        [273.269, 35.597],
                        [269.658, 35.709],
                        [266.033, 35.823],
                        [256.382, 35.104],
                        [246.857, 34.395],
                        [241.822, 34.745],
                        [215.087, 42.078],
                        [209.63, 43.917],
                        [194.967, 48.292],
                        [179.599, 52.904],
                        [142.825, 64.241],
                        [117.889, 71.668],
                        [106.405, 75.141],
                        [89.379, 80.18],
                        [49.466, 92.347],
                        [13.814, 103.014],
                        [-8.073, 109.376],
                        [-26.658, 115.236],
                        [-58.09, 124.732],
                        [-93.119, 134.967],
                        [-116.155, 141.902],
                        [-159.55, 155.157],
                        [-188.755, 164.221],
                        [-197.381, 166.831],
                        [-245.801, 181.141],
                        [-273.207, 189.45],
                        [-306.451, 199.357],
                        [-318.155, 202.91],
                        [-336.103, 207.917],
                        [-336.589, 208.013],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 1,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 4,
                  nm: 'Merge Paths 2',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.929411824544, 0.098039223166, 0.309803921569, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [1226.087, 346.065], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 49',
              np: 5,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_6',
      nm: 'Stars & Planets',
      fr: 30,
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Group 1',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 0,
                  s: [0],
                },
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 30,
                  s: [100],
                },
                { t: 60, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [1210.811, 595.381, 0], ix: 2, l: 2 },
            a: { a: 0, k: [1333.311, 427.881, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [3.613, 1.912],
                        [11.198, 0],
                        [3.613, -1.911],
                        [6.139, -6.138],
                        [1.911, -3.613],
                        [0, -11.198],
                        [-1.912, -3.613],
                        [-6.139, -6.138],
                        [-3.614, -1.911],
                        [-11.198, 0],
                        [-3.614, 1.912],
                        [-6.139, 6.139],
                        [-1.912, 3.614],
                        [0, 11.198],
                        [1.911, 3.614],
                        [6.138, 6.139],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [1333.311, 427.881], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Group 2',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 3,
                  s: [0],
                },
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 33,
                  s: [100],
                },
                { t: 63, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [1155.62, 688.028, 0], ix: 2, l: 2 },
            a: { a: 0, k: [1278.12, 520.528, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [3.613, 1.912],
                        [11.198, 0],
                        [3.613, -1.911],
                        [6.139, -6.138],
                        [1.911, -3.613],
                        [0, -11.198],
                        [-1.912, -3.613],
                        [-6.139, -6.138],
                        [-3.613, -1.911],
                        [-11.198, 0],
                        [-3.613, 1.912],
                        [-6.139, 6.139],
                        [-1.912, 3.614],
                        [0, 11.198],
                        [1.911, 3.614],
                        [6.138, 6.139],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [1278.12, 520.528], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 3,
          op: 903,
          st: 3,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Group 3',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 6,
                  s: [0],
                },
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 36,
                  s: [100],
                },
                { t: 66, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [535.056, 325.452, 0], ix: 2, l: 2 },
            a: { a: 0, k: [657.556, 157.952, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [3.613, 1.912],
                        [11.198, 0],
                        [3.613, -1.911],
                        [6.139, -6.138],
                        [1.911, -3.613],
                        [0, -11.198],
                        [-1.912, -3.613],
                        [-6.139, -6.138],
                        [-3.613, -1.911],
                        [-11.198, 0],
                        [-3.613, 1.912],
                        [-6.139, 6.139],
                        [-1.912, 3.614],
                        [0, 11.198],
                        [1.911, 3.614],
                        [6.138, 6.139],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [657.556, 157.952], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 6,
          op: 906,
          st: 6,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: 'Group 4',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 9,
                  s: [0],
                },
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 39,
                  s: [100],
                },
                { t: 69, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [883.671, 411.421, 0], ix: 2, l: 2 },
            a: { a: 0, k: [1006.171, 243.921, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [3.613, 1.912],
                        [11.198, 0],
                        [3.613, -1.911],
                        [6.139, -6.138],
                        [1.911, -3.613],
                        [0, -11.198],
                        [-1.912, -3.613],
                        [-6.139, -6.138],
                        [-3.613, -1.911],
                        [-11.198, 0],
                        [-3.613, 1.912],
                        [-6.139, 6.139],
                        [-1.912, 3.614],
                        [0, 11.198],
                        [1.911, 3.614],
                        [6.138, 6.139],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [1006.171, 243.921], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 9,
          op: 909,
          st: 9,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: 'Group 5',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 12,
                  s: [0],
                },
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 42,
                  s: [100],
                },
                { t: 72, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [964.357, 546.679, 0], ix: 2, l: 2 },
            a: { a: 0, k: [1086.857, 379.179, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [3.613, 1.912],
                        [11.198, 0],
                        [3.613, -1.911],
                        [6.139, -6.138],
                        [1.911, -3.613],
                        [0, -11.198],
                        [-1.912, -3.613],
                        [-6.139, -6.138],
                        [-3.613, -1.911],
                        [-11.198, 0],
                        [-3.613, 1.912],
                        [-6.139, 6.139],
                        [-1.912, 3.614],
                        [0, 11.198],
                        [1.911, 3.614],
                        [6.138, 6.139],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [1086.857, 379.179], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 5',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 12,
          op: 912,
          st: 12,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: 'Group 6',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 15,
                  s: [0],
                },
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 45,
                  s: [100],
                },
                { t: 75, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [769.558, 534.572, 0], ix: 2, l: 2 },
            a: { a: 0, k: [892.058, 367.072, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [882.489, 367.022],
                        [901.627, 367.022],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 6',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 15,
          op: 915,
          st: 15,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: 'Group 7',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 18,
                  s: [0],
                },
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 48,
                  s: [100],
                },
                { t: 78, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [759.989, 534.522, 0], ix: 2, l: 2 },
            a: { a: 0, k: [882.489, 367.022, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-2.812, 0],
                        [0, 2.812],
                        [2.812, 0],
                        [0, -2.811],
                      ],
                      o: [
                        [2.812, 0],
                        [0, -2.811],
                        [-2.812, 0],
                        [0, 2.812],
                      ],
                      v: [
                        [0, 5.091],
                        [5.091, 0],
                        [0, -5.091],
                        [-5.091, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [882.489, 367.022], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 7',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 18,
          op: 918,
          st: 18,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: 'Group 8',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 21,
                  s: [0],
                },
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 51,
                  s: [100],
                },
                { t: 81, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [1051.206, 396.123, 0], ix: 2, l: 2 },
            a: { a: 0, k: [1173.706, 228.623, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [3.613, 1.912],
                        [11.198, 0],
                        [3.613, -1.911],
                        [6.139, -6.138],
                        [1.911, -3.613],
                        [0, -11.198],
                        [-1.912, -3.613],
                        [-6.139, -6.138],
                        [-3.613, -1.911],
                        [-11.198, 0],
                        [-3.613, 1.912],
                        [-6.139, 6.139],
                        [-1.912, 3.614],
                        [0, 11.198],
                        [1.911, 3.614],
                        [6.138, 6.139],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [1173.706, 228.623], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 8',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 21,
          op: 921,
          st: 21,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 4,
          nm: 'Group 9',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 24,
                  s: [0],
                },
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 54,
                  s: [100],
                },
                { t: 84, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [1024.933, 622.38, 0], ix: 2, l: 2 },
            a: { a: 0, k: [1147.433, 454.88, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [5.34, 2.825],
                        [16.551, 0],
                        [5.34, -2.826],
                        [9.073, -9.073],
                        [2.825, -5.341],
                        [-0.001, -16.551],
                        [-2.826, -5.341],
                        [-9.074, -9.073],
                        [-5.341, -2.826],
                        [-16.551, 0],
                        [-5.341, 2.825],
                        [-9.074, 9.073],
                        [-2.826, 5.34],
                        [-0.001, 16.551],
                        [2.825, 5.34],
                        [9.073, 9.073],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [1147.433, 454.88], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 9',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 24,
          op: 924,
          st: 24,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 10,
          ty: 4,
          nm: 'Group 10',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 0,
                  s: [100],
                },
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 30,
                  s: [0],
                },
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 90,
                  s: [0],
                },
                { t: 120, s: [100] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [892.743, 804.329, 0], ix: 2, l: 2 },
            a: { a: 0, k: [1015.243, 636.829, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [3.582, 1.895],
                        [11.099, 0],
                        [3.582, -1.895],
                        [6.086, -6.085],
                        [1.895, -3.582],
                        [0, -11.1],
                        [-1.894, -3.581],
                        [-6.084, -6.084],
                        [-3.581, -1.895],
                        [-11.099, 0],
                        [-3.581, 1.895],
                        [-6.084, 6.085],
                        [-1.894, 3.582],
                        [0, 11.1],
                        [1.895, 3.582],
                        [6.085, 6.085],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [1015.243, 636.829], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 10',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 11,
          ty: 4,
          nm: 'Group 11',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 30,
                  s: [0],
                },
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 60,
                  s: [100],
                },
                { t: 90, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [520.755, 690.111, 0], ix: 2, l: 2 },
            a: { a: 0, k: [643.255, 522.611, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [3.613, 1.912],
                        [11.198, 0],
                        [3.613, -1.911],
                        [6.139, -6.138],
                        [1.911, -3.613],
                        [0, -11.198],
                        [-1.912, -3.613],
                        [-6.139, -6.138],
                        [-3.613, -1.911],
                        [-11.198, 0],
                        [-3.613, 1.912],
                        [-6.139, 6.139],
                        [-1.912, 3.614],
                        [0, 11.198],
                        [1.911, 3.614],
                        [6.138, 6.139],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [643.255, 522.611], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 11',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 30,
          op: 930,
          st: 30,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 12,
          ty: 4,
          nm: 'Group 12',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 33,
                  s: [0],
                },
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 63,
                  s: [100],
                },
                { t: 93, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [23.439, 362.559, 0], ix: 2, l: 2 },
            a: { a: 0, k: [145.939, 195.059, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [3.613, 1.912],
                        [11.198, 0],
                        [3.613, -1.911],
                        [6.139, -6.138],
                        [1.911, -3.613],
                        [0, -11.198],
                        [-1.912, -3.613],
                        [-6.139, -6.138],
                        [-3.613, -1.911],
                        [-11.198, 0],
                        [-3.613, 1.912],
                        [-6.139, 6.139],
                        [-1.912, 3.614],
                        [0, 11.198],
                        [1.911, 3.614],
                        [6.138, 6.139],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [145.939, 195.059], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 12',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 33,
          op: 933,
          st: 33,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 13,
          ty: 4,
          nm: 'Group 13',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 36,
                  s: [0],
                },
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 66,
                  s: [100],
                },
                { t: 96, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [1062.404, 763.72, 0], ix: 2, l: 2 },
            a: { a: 0, k: [1184.904, 596.22, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [3.613, 1.912],
                        [11.198, 0],
                        [3.613, -1.911],
                        [6.139, -6.138],
                        [1.911, -3.613],
                        [0, -11.198],
                        [-1.912, -3.613],
                        [-6.139, -6.138],
                        [-3.613, -1.911],
                        [-11.198, 0],
                        [-3.613, 1.912],
                        [-6.139, 6.139],
                        [-1.912, 3.614],
                        [0, 11.198],
                        [1.911, 3.614],
                        [6.138, 6.139],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [1184.904, 596.22], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 13',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 36,
          op: 936,
          st: 36,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 14,
          ty: 4,
          nm: 'Group 14',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 39,
                  s: [0],
                },
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 69,
                  s: [100],
                },
                { t: 99, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [1309.543, 452.548, 0], ix: 2, l: 2 },
            a: { a: 0, k: [1432.043, 285.048, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -1.504],
                        [1.504, 0],
                        [0, 1.504],
                        [-1.504, 0],
                      ],
                      o: [
                        [0, 1.504],
                        [-1.504, 0],
                        [0, -1.504],
                        [1.504, 0],
                      ],
                      v: [
                        [2.723, 0],
                        [0, 2.723],
                        [-2.723, 0],
                        [0, -2.723],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [1432.043, 285.048], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 14',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 39,
          op: 939,
          st: 39,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 15,
          ty: 4,
          nm: 'Group 15',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 42,
                  s: [0],
                },
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 72,
                  s: [100],
                },
                { t: 102, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [1329.751, 644.646, 0], ix: 2, l: 2 },
            a: { a: 0, k: [1452.251, 477.146, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -1.504],
                        [1.504, 0],
                        [0, 1.504],
                        [-1.504, 0],
                      ],
                      o: [
                        [0, 1.504],
                        [-1.504, 0],
                        [0, -1.504],
                        [1.504, 0],
                      ],
                      v: [
                        [2.723, 0],
                        [0, 2.723],
                        [-2.723, 0],
                        [0, -2.723],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [1452.251, 477.146], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 15',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 42,
          op: 942,
          st: 42,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 16,
          ty: 4,
          nm: 'Group 16',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 45,
                  s: [0],
                },
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 75,
                  s: [100],
                },
                { t: 105, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [352.143, 311.531, 0], ix: 2, l: 2 },
            a: { a: 0, k: [474.643, 144.031, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -1.504],
                        [1.504, 0],
                        [0, 1.504],
                        [-1.504, 0],
                      ],
                      o: [
                        [0, 1.504],
                        [-1.504, 0],
                        [0, -1.504],
                        [1.504, 0],
                      ],
                      v: [
                        [2.723, 0],
                        [0, 2.723],
                        [-2.723, 0],
                        [0, -2.723],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [474.643, 144.031], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 16',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 45,
          op: 945,
          st: 45,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 17,
          ty: 4,
          nm: 'Group 17',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 48,
                  s: [0],
                },
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 78,
                  s: [100],
                },
                { t: 108, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [970.087, 688.028, 0], ix: 2, l: 2 },
            a: { a: 0, k: [1092.587, 520.528, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -1.504],
                        [1.504, 0],
                        [0, 1.504],
                        [-1.504, 0],
                      ],
                      o: [
                        [0, 1.504],
                        [-1.504, 0],
                        [0, -1.504],
                        [1.504, 0],
                      ],
                      v: [
                        [2.723, 0],
                        [0, 2.723],
                        [-2.723, 0],
                        [0, -2.723],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [1092.587, 520.528], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 17',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 48,
          op: 948,
          st: 48,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 18,
          ty: 4,
          nm: 'Group 18',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 51,
                  s: [0],
                },
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 81,
                  s: [100],
                },
                { t: 111, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [933.928, 731.736, 0], ix: 2, l: 2 },
            a: { a: 0, k: [1056.428, 564.236, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -1.504],
                        [1.504, 0],
                        [0, 1.504],
                        [-1.504, 0],
                      ],
                      o: [
                        [0, 1.504],
                        [-1.504, 0],
                        [0, -1.504],
                        [1.504, 0],
                      ],
                      v: [
                        [2.723, 0],
                        [0, 2.723],
                        [-2.723, 0],
                        [0, -2.723],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [1056.428, 564.236], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 18',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 51,
          op: 951,
          st: 51,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 19,
          ty: 4,
          nm: 'Group 19',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 54,
                  s: [0],
                },
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 84,
                  s: [100],
                },
                { t: 114, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [473.908, 774.918, 0], ix: 2, l: 2 },
            a: { a: 0, k: [596.408, 607.418, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -1.504],
                        [1.504, 0],
                        [0, 1.504],
                        [-1.504, 0],
                      ],
                      o: [
                        [0, 1.504],
                        [-1.504, 0],
                        [0, -1.504],
                        [1.504, 0],
                      ],
                      v: [
                        [2.723, 0],
                        [0, 2.723],
                        [-2.723, 0],
                        [0, -2.723],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [596.408, 607.418], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 19',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 54,
          op: 954,
          st: 54,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 20,
          ty: 4,
          nm: 'Group 20',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 57,
                  s: [0],
                },
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 87,
                  s: [100],
                },
                { t: 117, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [61.773, 733.347, 0], ix: 2, l: 2 },
            a: { a: 0, k: [184.273, 565.847, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -1.504],
                        [1.504, 0],
                        [0, 1.504],
                        [-1.504, 0],
                      ],
                      o: [
                        [0, 1.504],
                        [-1.504, 0],
                        [0, -1.504],
                        [1.504, 0],
                      ],
                      v: [
                        [2.723, 0],
                        [0, 2.723],
                        [-2.723, 0],
                        [0, -2.723],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [184.273, 565.847], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 20',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 57,
          op: 957,
          st: 57,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 21,
          ty: 4,
          nm: 'Group 21',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 60,
                  s: [0],
                },
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 90,
                  s: [100],
                },
                { t: 120, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [592.828, 392.724, 0], ix: 2, l: 2 },
            a: { a: 0, k: [715.328, 225.224, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -1.504],
                        [1.504, 0],
                        [0, 1.504],
                        [-1.504, 0],
                      ],
                      o: [
                        [0, 1.504],
                        [-1.504, 0],
                        [0, -1.504],
                        [1.504, 0],
                      ],
                      v: [
                        [2.723, 0],
                        [0, 2.723],
                        [-2.723, 0],
                        [0, -2.723],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [715.328, 225.224], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 21',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 60,
          op: 960,
          st: 60,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 22,
          ty: 4,
          nm: 'Group 22',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 0,
                  s: [100],
                },
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 30,
                  s: [0],
                },
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 90,
                  s: [0],
                },
                { t: 120, s: [100] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [905.931, 328.546, 0], ix: 2, l: 2 },
            a: { a: 0, k: [1028.431, 161.046, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -1.504],
                        [1.504, 0],
                        [0, 1.504],
                        [-1.504, 0],
                      ],
                      o: [
                        [0, 1.504],
                        [-1.504, 0],
                        [0, -1.504],
                        [1.504, 0],
                      ],
                      v: [
                        [2.723, 0],
                        [0, 2.723],
                        [-2.723, 0],
                        [0, -2.723],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [1028.431, 161.046], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 22',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 23,
          ty: 4,
          nm: 'Group 23',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 8,
                  s: [0],
                },
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 38,
                  s: [100],
                },
                { t: 68, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [1166.818, 559.505, 0], ix: 2, l: 2 },
            a: { a: 0, k: [1289.318, 392.005, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -1.504],
                        [1.504, 0],
                        [0, 1.504],
                        [-1.504, 0],
                      ],
                      o: [
                        [0, 1.504],
                        [-1.504, 0],
                        [0, -1.504],
                        [1.504, 0],
                      ],
                      v: [
                        [2.723, 0],
                        [0, 2.723],
                        [-2.723, 0],
                        [0, -2.723],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [1289.318, 392.005], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 23',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 8,
          op: 908,
          st: 8,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 24,
          ty: 4,
          nm: 'Group 24',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 25,
                  s: [0],
                },
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 55,
                  s: [100],
                },
                { t: 85, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [761.872, 323.84, 0], ix: 2, l: 2 },
            a: { a: 0, k: [884.372, 156.34, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -1.504],
                        [1.504, 0],
                        [0, 1.504],
                        [-1.504, 0],
                      ],
                      o: [
                        [0, 1.504],
                        [-1.504, 0],
                        [0, -1.504],
                        [1.504, 0],
                      ],
                      v: [
                        [2.723, 0],
                        [0, 2.723],
                        [-2.723, 0],
                        [0, -2.723],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [884.372, 156.34], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 24',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 25,
          op: 925,
          st: 25,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 25,
          ty: 4,
          nm: 'Group 25',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 46,
                  s: [0],
                },
                {
                  i: { x: [0.25], y: [1] },
                  o: { x: [0.33], y: [0] },
                  t: 76,
                  s: [100],
                },
                { t: 106, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [824.529, 390.001, 0], ix: 2, l: 2 },
            a: { a: 0, k: [947.029, 222.501, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -1.504],
                        [1.504, 0],
                        [0, 1.504],
                        [-1.504, 0],
                      ],
                      o: [
                        [0, 1.504],
                        [-1.504, 0],
                        [0, -1.504],
                        [1.504, 0],
                      ],
                      v: [
                        [2.723, 0],
                        [0, 2.723],
                        [-2.723, 0],
                        [0, -2.723],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [947.029, 222.501], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 25',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 46,
          op: 946,
          st: 46,
          ct: 1,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 2,
      ty: 0,
      nm: 'App Eyeball 2',
      parent: 11,
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 61,
              s: [720, 697, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 121,
              s: [720, 713, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 181,
              s: [720, 697, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 241, s: [720, 713, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [720, 440, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 1440,
      h: 880,
      ip: 121,
      op: 272,
      st: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 0,
      nm: 'App Eyeball 1',
      parent: 11,
      refId: 'comp_1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 61,
              s: [720, 735, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 121,
              s: [720, 715, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 181,
              s: [720, 735, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 241, s: [720, 715, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [720, 440, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 1440,
      h: 880,
      ip: 121,
      op: 272,
      st: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'Group 7',
      parent: 11,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1327.681, 951.55, 0], ix: 2, l: 2 },
        a: { a: 0, k: [1256.681, 114.05, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 2.982],
                    [13.998, 0],
                    [0, -2.982],
                    [-13.999, 0],
                  ],
                  o: [
                    [0, -2.982],
                    [-13.999, 0],
                    [0, 2.982],
                    [13.998, 0],
                  ],
                  v: [
                    [25.347, 0],
                    [0.001, -5.399],
                    [-25.346, 0],
                    [0.001, 5.399],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [1256.681, 114.05], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 5',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 121,
      op: 272,
      st: 1,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'Group 6',
      parent: 11,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [138.957, 1029.98, 0], ix: 2, l: 2 },
        a: { a: 0, k: [67.957, 192.48, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 5.078],
                    [23.84, 0],
                    [0, -5.078],
                    [-23.841, 0],
                  ],
                  o: [
                    [0, -5.078],
                    [-23.841, 0],
                    [0, 5.078],
                    [23.84, 0],
                  ],
                  v: [
                    [43.167, 0],
                    [0, -9.195],
                    [-43.167, 0],
                    [0, 9.195],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [67.957, 192.48], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 121,
      op: 272,
      st: 1,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 0,
      nm: 'App Eyeball 2',
      parent: 11,
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 60,
              s: [720, 697, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 120,
              s: [720, 713, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 180,
              s: [720, 697, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 240, s: [720, 713, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [720, 440, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 1440,
      h: 880,
      ip: 0,
      op: 242,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 0,
      nm: 'App Eyeball 1',
      parent: 11,
      refId: 'comp_1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 60,
              s: [720, 735, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 120,
              s: [720, 715, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 180,
              s: [720, 735, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 240, s: [720, 715, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [720, 440, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 1440,
      h: 880,
      ip: 0,
      op: 242,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      nm: 'Group 5',
      parent: 11,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1327.681, 951.55, 0], ix: 2, l: 2 },
        a: { a: 0, k: [1256.681, 114.05, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 2.982],
                    [13.998, 0],
                    [0, -2.982],
                    [-13.999, 0],
                  ],
                  o: [
                    [0, -2.982],
                    [-13.999, 0],
                    [0, 2.982],
                    [13.998, 0],
                  ],
                  v: [
                    [25.347, 0],
                    [0.001, -5.399],
                    [-25.346, 0],
                    [0.001, 5.399],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [1256.681, 114.05], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 5',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 242,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 4,
      nm: 'Group 2',
      parent: 11,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [138.957, 1029.98, 0], ix: 2, l: 2 },
        a: { a: 0, k: [67.957, 192.48, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 5.078],
                    [23.84, 0],
                    [0, -5.078],
                    [-23.841, 0],
                  ],
                  o: [
                    [0, -5.078],
                    [-23.841, 0],
                    [0, 5.078],
                    [23.84, 0],
                  ],
                  v: [
                    [43.167, 0],
                    [0, -9.195],
                    [-43.167, 0],
                    [0, 9.195],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [67.957, 192.48], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 242,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 10,
      ty: 0,
      nm: 'App Foreground',
      refId: 'comp_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [720, 440, 0], ix: 2, l: 2 },
        a: { a: 0, k: [720, 440, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 1440,
      h: 880,
      ip: 0,
      op: 242,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 11,
      ty: 0,
      nm: 'App Background',
      refId: 'comp_3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.05, y: 1 },
              o: { x: 0.5, y: 0 },
              t: 0,
              s: [720, 1440, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 60, s: [720, 440, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [720, 720, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 1440,
      h: 1440,
      ip: 0,
      op: 242,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
  props: {},
}
