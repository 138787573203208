import store from '../store'
import { bounds } from '../utils'
import { ScrollTrigger } from '../vendor/ScrollTrigger'
import gsap from 'gsap'

export default class Stick {
  constructor(obj = {}) {
    this.isSmooth = store.flags.smooth

    this.dom = {
      container: obj.container,
      sticky: obj.sticky,
    }

    this.settings = {
      fullWidth: 0,
      fullHeight: 0,
      width: 0,
      posX: 0,
      posY: 0,
      enter: 0,
      totalItems: 0,
    }

    this.state = {
      fixed: false,
    }

    this.init()
  }

  setup() {
    const { container } = this.dom
    const { sizes } = store
    const fullHeight = bounds(container).height
    this.settings.fullHeight = fullHeight - sizes.vh
  }

  stick() {
    const { container } = this.dom

    ScrollTrigger.create({
      trigger: container,
      start: 'top top',
      end: 'bottom bottom',
      scrub: true,
      onEnter: () => {
        this.onEnter()
      },
      onEnterBack: () => {
        this.onEnterBack()
      },
      onLeaveBack: () => {
        this.onLeaveBack()
      },
      onLeave: () => {
        this.onLeave()
      },
      onUpdate: (self) => {
        this.onUpdate(self)
      },
      onRefresh: () => {
        this.onRefresh()
      },
    })
  }

  onEnter() {
    const { sticky } = this.dom
    this.state.fixed = true
    if (!this.isSmooth) {
      sticky.classList.add('-fixed')
      gsap.set(sticky, { top: 0, position: '' })
    }
  }

  onEnterBack() {
    const { sticky } = this.dom
    this.state.fixed = true

    if (!this.isSmooth) {
      sticky.classList.add('-fixed')
      gsap.set(sticky, { top: 0, position: '' })
    }
  }

  onLeave() {
    const { sticky } = this.dom
    const { totalItems, fullHeight } = this.settings

    this.state.fixed = false
    if (!this.isSmooth) {
      sticky.classList.remove('-fixed')
      gsap.set(sticky, { top: `${fullHeight}`, position: 'absolute' })
    }
  }

  onLeaveBack() {
    const { sticky } = this.dom

    this.state.fixed = false
    if (!this.isSmooth) {
      sticky.classList.remove('-fixed')
      gsap.set(sticky, { top: 0, position: '' })
    }
  }

  onUpdate(self) {
    const { sticky } = this.dom
    const { fullWidth, fullHeight } = this.settings

    if (this.isSmooth) {
      this.settings.posY = self.progress * fullHeight
      this.settings.posX = self.progress * fullWidth
      gsap.set(sticky, { y: this.settings.posY })
    } else {
      this.settings.posX = self.progress * fullWidth
    }
  }

  onRefresh() {}

  resize = () => {
    this.setup()
  }

  init() {
    this.setup()
    this.stick()
  }
}
