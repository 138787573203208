import store from '../store'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { Text } from 'troika-three-text'
import { Emitter } from '../core'

const vertexShader = `
precision mediump float;
  varying vec2 vUv;
  void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
`

const fragmentShader = `
precision mediump float;
  varying vec2 vUv;
  uniform sampler2D uTexture;
  
  void main() {
    vec2 uv = vec2(vUv.y, vUv.x); // Map only based on the horizontal position
    vec3 text = texture2D(uTexture, uv).rgb;

   gl_FragColor = texture2D(uTexture, vUv );
  }
`

export default class Replaces {
  constructor(obj = {}) {
    const font = '/fonts/PPMonumentExtended-Medium.woff'

    const { sizes } = store
    const canvas = document.querySelector('.replaces-list')
    const scene = new THREE.Scene()
    const camera = new THREE.PerspectiveCamera(
      75,
      sizes.vw / sizes.vh,
      0.1,
      1000,
    )

    const loader = new THREE.TextureLoader()

    const clock = new THREE.Clock()
    const renderer = new THREE.WebGLRenderer({
      canvas: canvas,
      alpha: true,
    })

    renderer.outputColorSpace = THREE.LinearSRGBColorSpace
    renderer.shadowMap.enabled = true
    renderer.shadowMap.type = THREE.PCFSoftShadowMap
    renderer.setSize(sizes.vw, sizes.vh)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    camera.position.set(0, 0, 10)
    scene.add(camera)

    const controls = new OrbitControls(camera, canvas)
    controls.target.set(0, 0, 0)
    controls.enableDamping = true
    controls.enabled = false

    const marquee = new THREE.Group()
    const marqueeText = [
      canvas.dataset.title,
      'star',
      canvas.dataset.title,
      'star',
    ]

    const starGeometry = new THREE.PlaneGeometry(1, 1, 1)
    const starMaterial = new THREE.MeshBasicMaterial({
      map: loader.load('images/star.png'),
      transparent: true,
    })

    marqueeText.forEach((text, index) => {
      if (text === 'star') {
        const star = new THREE.Mesh(starGeometry, starMaterial)
        star.position.x = 16.5 * index

        marquee.add(star)
      } else {
        const marqueeTXT = new Text()
        marqueeTXT.text = text.toUpperCase()
        marqueeTXT.fontSize = 2
        marqueeTXT.anchorX = 'center'
        marqueeTXT.textAlign = 'center'
        marqueeTXT.color = '#d7d0f5'
        marqueeTXT.font = font
        marqueeTXT.maxWidth = 75
        marqueeTXT.whiteSpace = 'normal'
        marqueeTXT.name = 'Text'
        marqueeTXT.material.transparent = false
        marqueeTXT.position.y = 1.5
        marqueeTXT.position.x = 16.5 * index
        marqueeTXT.gpuAccelerateSDF = true
        // marqueeTXT.sync()
        marquee.add(marqueeTXT)

        marqueeTXT.addEventListener('synccomplete', () => {})
      }
    })

    scene.add(marquee)

    marquee.position.x = 1

    const geometry = new THREE.CylinderGeometry(3, 3, 12, 60)
    const textureAsset = canvas.dataset.texture
    const material = new THREE.ShaderMaterial({
      uniforms: {
        uTexture: { value: loader.load(textureAsset) },
      },
      vertexShader,
      fragmentShader,
    })

    const cylinder = new THREE.Mesh(geometry, material)

    material.transparent = true

    cylinder.rotateZ(Math.PI / 2)
    scene.add(cylinder)

    this.scene = {
      canvas,
      scene,
      camera,
      renderer,
      controls,
      cylinder,
      clock,
      marquee,
    }

    this.init()
  }

  on() {
    Emitter.on('resize', this.resize)
    Emitter.on('tick', this.tick)
  }

  off() {
    Emitter.off('resize', this.resize)
    Emitter.off('tick', this.tick)
  }

  tick = () => {
    const {
      scene,
      camera,
      renderer,
      controls,
      clock,
      prevTime,
      cylinder,
      marquee,
    } = this.scene

    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - prevTime
    this.scene.prevTime = elapsedTime

    cylinder.rotation.x += 0.005

    if (marquee.position.x > -32.1) {
      marquee.position.x -= 0.05
    } else {
      marquee.position.x = 1
    }

    controls.update()
    renderer.render(scene, camera)
  }

  resize = () => {
    const { sizes } = store
    const { camera, renderer, scene, cylinder } = this.scene

    camera.aspect = sizes.vw / sizes.vh
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.vw, sizes.vh)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
  }

  destroy() {
    this.off()
  }

  init() {
    this.on()

    this.resize()
  }
}
