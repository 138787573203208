import store from '../store'
import Default from './Default'
import {
  Balls,
  Form,
  LottieAnimation,
  Stick,
  Slider,
  WebGL,
  Dots,
  Replaces,
} from '../components'
import { bounds, qs, qsa } from '../utils'
import { ScrollTrigger } from '../vendor/ScrollTrigger'
import {
  mss,
  mssmb,
  app,
  appmb,
  city,
  citymb,
  features,
  featuresmb,
  feature_1,
  feature_2,
  feature_3
} from '../animations/lottie'
import gsap from 'gsap'

export default class Page extends Default {
  constructor(opt = {}) {
    super(opt)
  }

  initialLoad() {
    this.onEnter()
    this.onEnterCompleted()
  }

  setup() {
    super.setup()

    Object.assign(this.dom, {
      home: {
        features: qsa('.feature-section'),
      },
      sections: {
        bgs: qsa('[data-bg]'),
      },
      features: {
        el: qs('.features'),
        blocks: qsa('.features-block'),
      },
      hero: {
        el: qs('.hero'),
        asset: qs('.hero-asset'),
      },
      statement: {
        el: qs('.statement'),
        asset: qs('.statement-asset'),
      },
      company: {
        el: qs('.company'),
        asset: qs('.company-asset'),
      },
      getStarted: {
        el: qs('.getstarted'),
        cards: qsa('.getstarted-card'),
        buttons: qsa('.button-item'),
      },
      resolved: {
        el: qs('.resolve'),
        asset: qs('.resolve-asset'),
      },
      replaces: {
        el: qs('.replaces'),
      },
      contactForm: {
        el: qs('.form'),
        form: qs('.form-item'),
        cards: qsa('.form-group'),
        buttons: qsa('.form-button'),
        state: { index: 0, name: '' },
        names: qsa('.form-name'),
      },
      trusted: {
        el: qs('.trusted'),
        container: qs('.trusted-media'),
        items: qsa('.trusted-item'),
      },
      menu: {
        nav: qs('.menu-nav'),
        login: qs('.cta-1'),
        state: {
          animate: false,
          hidden: false,
        },
      },
    })
  }

  components() {
    super.components()
    // this.addSwitch()
    this.addForm()
    this.addBalls()
    this.addLottie()
    this.addSticky()
    this.addSlider()
    this.addRobot()
    this.addDots()
    this.addReplaces()
  }

  run() {
    this.updadeMenu()
  }

  addReplaces() {
    const { replaces } = this.dom

    if (!replaces.el) return
    this.replaces = new Replaces()
  }

  addDots() {
    const { features } = this.dom.home

    if (features.length === 0) return
    this.dots = new Dots()
  }

  addBalls() {
    const { el, container, items } = this.dom.trusted
    if (!el) return
    this.balls = new Balls({ container, items })
    this.balls.init()
  }

  addRobot() {
    const { resolved } = this.dom

    if (!resolved.el) return

    this.robot = new WebGL()
  }

  updadeMenu() {
    const { nav, login, state } = this.dom.menu
    const { el } = this.dom.desktopMenu

    if (this.scroll > 100 && this.direction == 'down' && !state.hidden) {
      if (this.dom.menu.state.animate) return
      this.dom.menu.state.animate = true
      this.dom.menu.state.hidden = true

      gsap.to(nav, { duration: 0.5, y: '-150px' })
      gsap.to(login, {
        duration: 0.5,
        y: '-150px',
        onComplete: () => {
          this.dom.menu.state.animate = false
        },
      })
    }

    if (this.direction == 'up' && this.dom.menu.state.hidden) {
      if (this.dom.menu.state.animate) return

      this.dom.menu.state.animate = true
      this.dom.menu.state.hidden = false

      gsap.to(nav, { duration: 0.5, y: '-0' })
      gsap.to(login, {
        duration: 0.5,
        y: '-0',
        onComplete: () => {
          this.dom.menu.state.animate = false
        },
      })
    }

    if (
      this.scroll > store.sizes.h - store.sizes.vh / 2 &&
      this.direction == 'down'
    ) {
      if (this.dom.menu.state.animate) return

      this.dom.menu.state.animate = true
      gsap.to(el, {
        duration: 0.5,
        opacity: 0,
        onComplete: () => {
          this.dom.menu.state.animate = false
        },
      })
    } else {
      this.dom.menu.state.animate = true
      gsap.to(el, {
        duration: 0.5,
        opacity: 1,
        onComplete: () => {
          this.dom.menu.state.animate = false
        },
      })
    }
  }

  addForm() {
    const { el } = this.dom.contactForm

    if (!el) return

    this.form = new Form(this.dom.contactForm)
  }

  addMenu() {
    super.addMenu()

    const { bgs } = this.dom.sections

    bgs.forEach((bg) => {
      ScrollTrigger.create({
        trigger: bg,
        start: 'top top+=5%',
        end: 'bottom bottom-=95%',
        scrub: true,
        onEnter: () => {
          this.updadeBg(bg.dataset.bg)
        },
        onEnterBack: () => {
          this.updadeBg(bg.dataset.bg)
        },
      })
    })
  }

  updadeBg(bg) {
    const background = bg
    if (background === 'black') {
      gsap.to('html', { duration: 0.5, '--primary': '#f1dfdb' })
      gsap.to('html', { duration: 0.5, '--secondary': '#0d1815' })
    }
    if (background === 'white') {
      gsap.to('html', { duration: 0.5, '--primary': '#0d1815' })
      gsap.to('html', { duration: 0.5, '--secondary': '#f1dfdb' })
    }
    if (background === 'magenta') {
      gsap.to('html', { duration: 0.5, '--primary': '#f5c9d9' })
      gsap.to('html', { duration: 0.5, '--secondary': '#ed194f' })
    }
    if (background === 'pink') {
      gsap.to('html', { duration: 0.5, '--primary': '#ed194f' })
      gsap.to('html', { duration: 0.5, '--secondary': '#f5c9d9' })
    }
    if (background === 'purple') {
      gsap.to('html', { duration: 0.5, '--primary': '#ccc3ef' })
      gsap.to('html', { duration: 0.5, '--secondary': '#5d35e5' })
    }
    if (background === 'lavender') {
      gsap.to('html', { duration: 0.5, '--primary': '#5d35e5' })
      gsap.to('html', { duration: 0.5, '--secondary': '#ccc3ef' })
    }
    if (background === 'blackish') {
      gsap.to('html', { duration: 0.5, '--primary': '#ccc3ef' })
      gsap.to('html', { duration: 0.5, '--secondary': '#0d1815' })
    }
  }

  addSwitch() {
    const { el, cards, buttons } = this.dom.getStarted

    if (!el) return

    buttons[0].classList.add('active')
    cards[0].classList.add('active')

    buttons.forEach((button, i) => {
      button.addEventListener('click', (el) => {
        const target = el.target
        const card = el.target.dataset.card
        const cardEl = qs(`.card-${card}`)

        buttons.forEach((btn) => {
          btn.classList.remove('active')
        })
        cards.forEach((crd) => {
          crd.classList.remove('active')
        })
        cardEl.classList.add('active')
        target.classList.add('active')
      })
    })
  }

  addSticky() {
    const { company, home, resolved } = this.dom

    if (company.el) {
      this.sticky = new Stick({
        container: company.el,
        sticky: qs('.sticky', company.el),
      })
    }

    if (resolved.el) {
      this.sticky = new Stick({
        container: resolved.el,
        sticky: qs('.sticky', resolved.el),
      })
    }
  }

  addSlider() {
    const { blocks } = this.dom.features

    if (blocks.length === 0) return

    if (!store.breakpoints.M_UP) {
      blocks.forEach((block, i) => {
        const container = qs('.features-list', block)
        const items = qsa('.list-item', container)
        const nav = qs('.controls-inner', block)
        const slider = new Slider({
          container,
          items,
          nav,
        })
      })
    }
  }

  addLottie() {
    const { M_UP } = store.breakpoints
    const { hero, statement, company, resolved } = this.dom

    if (hero.asset) {
      const lottie = new LottieAnimation({
        container: hero.asset,
        data: M_UP ? mss : mssmb,
        loop: false,
        autoplay: false,
      })

      lottie.load()

      const anim = lottie.getAnimation()
      const animationTimeline = gsap.timeline({
        scrollTrigger: {
          trigger: hero.asset,
          start: 'top top+=120%',
          end: 'center center+=10%',
          scrub: true,
          once: true,
          onUpdate: (self) => {
            const progress = self.progress
            anim.goToAndStop(progress * anim.totalFrames, true)
          },
        },
      })

      animationTimeline.to(anim, {
        frame: 0,
        ease: 'none',
      })
    }

    if (statement.asset) {
      const intro = new LottieAnimation({
        container: statement.asset,
        data: M_UP ? app : appmb,
        loop: false,
        autoplay: false,
      })

      intro.load()

      const tl = gsap.timeline({
        onComplete: () => {
          gsap.to('.statement-app img', {
            duration: 2,
            y: 20,
            yoyo: true,
            repeat: -1,
            ease: 'power0.inOut',
          })
        },
        scrollTrigger: {
          trigger: statement.asset,
          start: 'top top+=80%',
          end: 'bottom center',
          scrub: false,
          once: true,
          onEnter: () => {
            const anim = intro.getAnimation()
            intro.playSegments(0, 119, true)
            anim.addEventListener('complete', () => {
              intro.playSegments(120, 240, true)
            })
          },
        },
      })

      tl.from('.statement-app', { duration: 1.2, y: '150%', delay: 0.5 })
    }

    if (company.asset) {
      const lottie = new LottieAnimation({
        container: company.asset,
        data: M_UP ? city : citymb,
        loop: true,
        autoplay: true,
      })

      lottie.load()
      lottie.play()
    }

    if (resolved.asset) {
      const intro = new LottieAnimation({
        container: resolved.asset,
        data: M_UP ? features : featuresmb,
        loop: false,
        autoplay: false,
      })

      intro.load()

      const anim = intro.getAnimation()

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: resolved.asset,
          start: 'top top+=80%',
          end: 'bottom center',
          scrub: false,
          once: true,
          onEnter: () => {
            intro.playSegments(0, 119, true)
            anim.addEventListener('complete', () => {
              intro.playSegments(120, 240, true)
            })
          },
        },
      })
    }

    if (feature_1) {
      const lottie = new LottieAnimation({
        container: qs('section#feature #feature-1 .feature-block .screen'),
        renderer: 'svg', 
        data: feature_1,
        loop: true,
        autoplay: true,
        rendererSettings: {
          clearCanvas: true
        }
      })

      lottie.load()
      lottie.play()
    }

    if (feature_2) {
      const lottie = new LottieAnimation({
        container: qs('section#feature #feature-2 .feature-block .screen'),
        renderer: 'svg', 
        data: feature_2,
        loop: true,
        autoplay: true,
        rendererSettings: {
          clearCanvas: true
        }
      })

      lottie.load()
      lottie.play()
    }

    if (feature_3) {
      const lottie = new LottieAnimation({
        container: qs('section#feature #feature-3 .feature-block .screen'),
        renderer: 'svg', 
        data: feature_3,
        loop: true,
        autoplay: true,
        rendererSettings: {
          clearCanvas: true
        }
      })

      lottie.load()
      lottie.play()
    }
  }

  onLeave() {
    super.onLeave()

    const { nav, login } = this.dom.menu

    store.flags.firstload = false
    this.dom.menu.state.animate = false
    this.dom.menu.state.hidden = false
    this.balls && this.balls.destroy()
    this.robot && this.robot.destroy()
    this.replaces && this.replaces.destroy()

    gsap.to('.header', {
      y: '-100%',
      duration: 0.5,
      onComplete: () => {
        gsap.set(nav, { y: '0' })
        gsap.set(login, { y: '0' })
      },
    })
  }
}
