import store from '../store'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from '../vendor/GLTFLoader'
import { DRACOLoader } from '../vendor/DRACOLoader'
import { Emitter } from '../core'
import { ScrollTrigger } from '../vendor/ScrollTrigger'
import gsap from 'gsap'

export default class WebGL {
  constructor() {
    this.scene = {}

    const { sizes } = store
    const canvas = document.querySelector('.resolve-robot')
    const scene = new THREE.Scene()
    const camera = new THREE.PerspectiveCamera(
      75,
      sizes.vw / sizes.vh,
      0.1,
      1000,
    )

    let mixer = null

    const clock = new THREE.Clock()
    const renderer = new THREE.WebGLRenderer({
      canvas: canvas,
      alpha: true,
    })

    renderer.outputColorSpace = THREE.LinearSRGBColorSpace
    renderer.shadowMap.enabled = true
    renderer.shadowMap.type = THREE.PCFSoftShadowMap
    renderer.setSize(sizes.vw, sizes.vh)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    camera.position.set(0, 0, 6)

    scene.add(camera)

    const loader = new GLTFLoader()
    const dracoLoader = new DRACOLoader()
    const textureLoader = new THREE.TextureLoader()
    const matcapTexture = textureLoader.load('/images/matcap.png')
    const material = new THREE.MeshMatcapMaterial()
    material.matcap = matcapTexture

    dracoLoader.setDecoderPath('/draco/')
    loader.setDRACOLoader(dracoLoader)

    const controls = new OrbitControls(camera, canvas)
    controls.target.set(0, 0, 0)
    controls.enableDamping = true
    controls.enabled = false

    const animations = []

    loader.load(['/models/bot1.glb'], (gltf) => {
      const size = store.sizes.vw < 768 ? 0.35 : 0.55
      const modelCamera = gltf.cameras[0]

      gltf.scene.scale.set(size, size, size)
      gltf.scene.position.set(0, 0, 0)

      gsap.from(gltf.scene.position, {
        x: 10,
        z: 10,
        duration: 1,
        ease: 'power2.inOut',
      })

      scene.add(gltf.scene)

      this.scene.mixer = new THREE.AnimationMixer(gltf.scene)

      scene.traverse((object) => {
        if (object.material) {
          object.material.dispose()
          object.material = material
        }
      })

      gltf.animations.forEach((clip, i) => {
        const animation = this.scene.mixer.clipAction(clip)
        animation.setLoop(THREE.LoopOnce)
        animation.clampWhenFinished = true
        animation.enable = true
        animation.reset().play().paused = true
        animations.push(animation)
      })
    })

    this.scene = {
      canvas,
      scene,
      camera,
      renderer,
      controls,
      clock,
      prevTime: 0,
      mixer,
      animations,
    }

    this.init()
  }

  createAnimation(mixer, animation, clip) {
    const { camera } = this.scene

    let proxy = {
      get time() {
        return mixer.time
      },
      set time(value) {
        animation.paused = false
        mixer.setTime(value)
        animation.paused = true
      },
    }

    const tl = gsap.timeline({ paused: true })

    gsap.set(proxy, { time: clip.duration })

    return { proxy, tl, clip }
  }

  addSticky() {
    const { animations, camera } = this.scene

    ScrollTrigger.create({
      trigger: '.resolve',
      start: 'top top',
      end: 'bottom bottom',
      scrub: true,

      onUpdate: (self) => {
        const progress = self.progress
        animations.forEach((anim) => {
          anim.time = anim.getClip().duration * progress
        })

        gsap.set(camera.position, { x: 0 - 4 * progress })
      },
    })
  }

  on() {
    Emitter.on('resize', this.resize)
    Emitter.on('tick', this.tick)
  }

  off() {
    Emitter.off('resize', this.resize)
    Emitter.off('tick', this.tick)
  }

  tick = () => {
    const { scene, camera, renderer, controls, clock, prevTime, mixer } =
      this.scene

    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - prevTime
    this.scene.prevTime = elapsedTime

    if (mixer) {
      mixer.update(deltaTime)
    }

    controls.update()
    renderer.render(scene, camera)
  }

  destroy() {
    this.off()
  }

  resize = () => {
    const { sizes } = store
    const { camera, renderer } = this.scene

    camera.aspect = sizes.vw / sizes.vh
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.vw, sizes.vh)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
  }

  init() {
    this.on()
    this.addSticky()
    this.resize()
  }
}
