export const featuresmb = {
  v: '5.12.1',
  fr: 30,
  ip: 0,
  op: 240,
  w: 390,
  h: 844,
  nm: 'Features Mobile',
  ddd: 0,
  assets: [
    {
      id: 'comp_0',
      nm: 'Features Eyeball 1',
      fr: 30,
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Group 1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 20,
                  s: [105.613, 1134.122, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 80, s: [105.613, 714.122, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [59.613, 199.622, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -2.841],
                        [22.246, 0],
                        [0, 22.246],
                        [-22.246, 0],
                        [-2.681, -0.566],
                        [0, -11.757],
                        [-14.031, 0],
                        [-2.89, 10.833],
                      ],
                      o: [
                        [0, 22.246],
                        [-22.246, 0],
                        [0, -22.246],
                        [2.841, 0],
                        [-10.834, 2.89],
                        [0, 14.031],
                        [11.756, 0],
                        [0.566, 2.68],
                      ],
                      v: [
                        [40.273, 0],
                        [-0.001, 40.274],
                        [-40.273, 0],
                        [-0.001, -40.274],
                        [8.288, -39.413],
                        [-10.538, -14.867],
                        [14.867, 10.539],
                        [39.413, -8.288],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [59.613, 199.622], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Group 3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 20,
                  s: [105.611, 1134.121, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 80, s: [105.611, 714.121, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [59.611, 199.621, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 31.541],
                        [31.542, 0],
                        [0, -31.541],
                        [-31.541, 0],
                      ],
                      o: [
                        [0, -31.541],
                        [-31.541, 0],
                        [0, 31.541],
                        [31.542, 0],
                      ],
                      v: [
                        [57.111, 0],
                        [-0.001, -57.111],
                        [-57.111, 0],
                        [-0.001, 57.111],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.58431372549, 0.486274539723, 0.917647118662, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [59.611, 199.621], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_1',
      nm: 'Features Eyeball 2',
      fr: 30,
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Group 4',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 20,
                  s: [1330.767, 977.377, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 80, s: [1330.767, 557.377, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [1284.767, 42.877, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -2.008],
                        [15.727, 0],
                        [0, 15.728],
                        [-15.728, 0],
                        [-1.896, -0.4],
                        [0, -8.312],
                        [-9.92, 0],
                        [-2.043, 7.66],
                      ],
                      o: [
                        [0, 15.728],
                        [-15.728, 0],
                        [0, -15.728],
                        [2.008, 0],
                        [-7.66, 2.043],
                        [0, 9.92],
                        [8.311, 0],
                        [0.4, 1.895],
                      ],
                      v: [
                        [28.474, 0],
                        [0, 28.473],
                        [-28.474, 0],
                        [0, -28.473],
                        [5.86, -27.865],
                        [-7.45, -10.511],
                        [10.511, 7.451],
                        [27.865, -5.86],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [1284.767, 42.877], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Group 6',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 20,
                  s: [1330.766, 977.377, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 80, s: [1330.766, 557.377, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [1284.766, 42.877, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 22.3],
                        [22.3, 0],
                        [0, -22.3],
                        [-22.3, 0],
                      ],
                      o: [
                        [0, -22.3],
                        [-22.3, 0],
                        [0, 22.3],
                        [22.3, 0],
                      ],
                      v: [
                        [40.377, 0],
                        [0, -40.377],
                        [-40.377, 0],
                        [0, 40.377],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.929411824544, 0.098039223166, 0.309803921569, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [1284.766, 42.877], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 6',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_2',
      nm: 'Features-Foreground',
      fr: 30,
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Group 2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 15,
                  s: [606.45, 1431.761, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 75, s: [606.45, 711.761, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [1431.95, 177.262, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.062, -3.673],
                        [130.898, -13.589],
                        [5.637, -0.547],
                        [0.064, -0.014],
                        [0.046, -0.007],
                        [3.817, -0.347],
                        [3.395, -0.291],
                        [0.494, -0.041],
                        [1.355, -0.111],
                        [2.855, -0.222],
                        [1.675, -0.125],
                        [0.888, -0.07],
                        [1.638, -0.118],
                        [5.033, -0.326],
                        [1.07, -0.062],
                        [0.521, -0.035],
                        [1.016, -0.062],
                        [0.549, -0.035],
                        [1.263, -0.077],
                        [6.763, -0.353],
                        [0.787, -0.035],
                        [1.996, -0.097],
                        [2.233, -0.111],
                        [1.867, -0.09],
                        [0.888, -0.041],
                        [12.264, -0.686],
                        [0.997, -0.055],
                        [9.225, -0.693],
                        [1.72, -0.138],
                        [6.159, -0.582],
                        [3.331, -0.339],
                        [0.238, -0.021],
                        [1.172, -0.125],
                        [5.052, -0.603],
                        [2.151, -0.27],
                        [4.329, -0.582],
                        [1.546, -0.215],
                        [0.311, -0.042],
                        [1.419, -0.201],
                        [7.651, -1.199],
                        [30.677, -24.857],
                        [0.32, -0.278],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-521.97, 18.004],
                        [-102.326, 10.277],
                        [-4.457, 1.24],
                        [-0.018, 0.007],
                        [-0.009, 0],
                        [0, 0],
                        [-50.728, 15.148],
                        [-47.077, 17.386],
                        [0.512, -0.229],
                        [0.128, -0.063],
                        [1.904, -0.922],
                        [1.089, -0.582],
                        [0.695, -0.402],
                        [0.659, -0.416],
                        [0.266, -5.814],
                        [0, -0.16],
                        [-0.028, -0.27],
                        [-0.046, -0.27],
                        [0, 0],
                        [-0.064, -0.271],
                        [-0.083, -0.263],
                        [0, 0],
                        [-0.092, -0.236],
                        [-0.028, -0.048],
                        [-0.11, -0.236],
                        [-0.174, -0.284],
                        [-0.165, -0.236],
                        [-0.174, -0.236],
                        [-0.219, -0.249],
                        [0, 0],
                        [-0.229, -0.242],
                        [-0.605, -0.548],
                        [-0.329, -0.271],
                        [-0.348, -0.27],
                        [-0.375, -0.27],
                        [-8.337, -3.465],
                        [-1.355, -0.534],
                        [0, 0],
                        [-1.418, -0.54],
                        [0, 0],
                        [-4.878, -1.711],
                        [-3.487, -1.199],
                        [-1.867, -0.637],
                        [-1.364, -0.472],
                        [-3.14, -1.075],
                        [-1.153, -0.402],
                        [-1.171, -0.409],
                        [-1.189, -0.423],
                        [-1.199, -0.422],
                        [0, 0],
                        [-3.734, -1.338],
                        [-0.018, -0.007],
                        [-9.289, -3.631],
                        [-1.336, -0.533],
                        [-11.146, -5.073],
                        [-4.722, -2.197],
                        [-0.43, -0.201],
                        [-0.915, -0.43],
                        [-0.476, -0.222],
                        [-0.778, -0.374],
                        [-1.245, -0.596],
                        [-0.851, -0.409],
                        [-0.44, -0.215],
                        [-0.887, -0.437],
                        [-0.915, -0.45],
                        [-0.458, -0.229],
                        [-0.046, -0.014],
                        [-0.046, -0.021],
                        [-1.428, -0.727],
                        [-0.76, -0.389],
                        [-0.806, -0.409],
                        [-1.51, -0.783],
                        [-3.277, -1.76],
                        [-0.704, -0.381],
                        [-4.274, -2.432],
                        [-0.714, -0.409],
                        [-0.046, -0.028],
                        [-3.634, -2.189],
                        [-1.199, -0.742],
                        [-1.593, -1.025],
                        [-1.574, -1.018],
                        [-0.037, -0.021],
                        [-1.501, -1.019],
                        [-2.571, -1.864],
                        [-0.686, -0.512],
                        [-3.24, -2.675],
                        [-0.33, -0.27],
                        [-0.312, -0.27],
                        [-3.111, -3.208],
                        [-0.485, -0.519],
                        [-2.224, -3.492],
                        [-0.22, -0.36],
                        [-0.842, -2.695],
                        [-0.11, -0.429],
                      ],
                      o: [
                        [-9.289, 32.202],
                        [-5.975, 0.617],
                        [0, 0],
                        [-0.028, 0.007],
                        [-3.972, 0.381],
                        [-3.523, 0.319],
                        [-0.503, 0.041],
                        [-1.382, 0.118],
                        [-2.947, 0.242],
                        [-1.712, 0.138],
                        [-0.887, 0.069],
                        [-1.674, 0.125],
                        [-5.299, 0.381],
                        [-1.08, 0.076],
                        [-0.522, 0.035],
                        [-1.034, 0.062],
                        [-0.549, 0.035],
                        [-1.282, 0.076],
                        [-7.184, 0.429],
                        [-0.797, 0.041],
                        [-2.022, 0.104],
                        [-2.269, 0.118],
                        [-1.886, 0.09],
                        [-0.896, 0.048],
                        [-12.62, 0.61],
                        [-0.988, 0.055],
                        [-8.932, 0.513],
                        [-1.702, 0.132],
                        [-5.894, 0.464],
                        [-3.239, 0.305],
                        [-0.229, 0.021],
                        [-1.153, 0.117],
                        [-4.813, 0.513],
                        [-2.105, 0.249],
                        [-4.136, 0.513],
                        [-1.519, 0.201],
                        [-0.312, 0.042],
                        [-1.391, 0.194],
                        [-7.083, 1.005],
                        [-45.548, 7.158],
                        [-0.339, 0.27],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [139.18, -4.802],
                        [276.96, -27.816],
                        [0.028, -0.007],
                        [0.009, -0.007],
                        [0, 0],
                        [74.944, -17.553],
                        [78.412, -23.443],
                        [-0.522, 0.214],
                        [-0.129, 0.048],
                        [-2.004, 0.845],
                        [-1.144, 0.554],
                        [-0.723, 0.388],
                        [-0.696, 0.402],
                        [-6.562, 4.165],
                        [-0.009, 0.167],
                        [0, 0.27],
                        [0.018, 0.271],
                        [0, 0],
                        [0.046, 0.263],
                        [0.064, 0.263],
                        [0, 0],
                        [0.082, 0.236],
                        [0.018, 0.048],
                        [0.091, 0.236],
                        [0.147, 0.291],
                        [0.128, 0.243],
                        [0.156, 0.236],
                        [0.183, 0.256],
                        [0, 0],
                        [0.202, 0.25],
                        [0.503, 0.555],
                        [0.302, 0.27],
                        [0.32, 0.27],
                        [0.348, 0.271],
                        [4.832, 3.493],
                        [1.282, 0.541],
                        [0, 0],
                        [1.354, 0.541],
                        [0, 0],
                        [4.283, 1.643],
                        [3.249, 1.144],
                        [1.812, 0.623],
                        [1.337, 0.451],
                        [3.001, 1.032],
                        [1.144, 0.381],
                        [1.153, 0.395],
                        [1.172, 0.394],
                        [1.19, 0.409],
                        [0, 0],
                        [3.606, 1.262],
                        [0, 0],
                        [8.722, 3.125],
                        [1.327, 0.52],
                        [10.699, 4.241],
                        [2.783, 1.268],
                        [0.421, 0.194],
                        [0.87, 0.409],
                        [0.467, 0.215],
                        [0.751, 0.354],
                        [1.181, 0.555],
                        [0.823, 0.395],
                        [0.43, 0.201],
                        [0.86, 0.423],
                        [0.888, 0.437],
                        [0.458, 0.222],
                        [0.046, 0.013],
                        [0.046, 0.021],
                        [1.382, 0.679],
                        [0.741, 0.367],
                        [0.796, 0.402],
                        [1.491, 0.756],
                        [3.167, 1.642],
                        [0.695, 0.374],
                        [4.21, 2.28],
                        [0.714, 0.402],
                        [0.045, 0.02],
                        [3.67, 2.114],
                        [1.217, 0.735],
                        [1.611, 0.984],
                        [1.592, 1.012],
                        [0.028, 0.021],
                        [1.538, 1.012],
                        [2.718, 1.823],
                        [0.714, 0.513],
                        [3.597, 2.634],
                        [0.32, 0.27],
                        [0.32, 0.257],
                        [3.816, 3.223],
                        [0.513, 0.534],
                        [3.304, 3.562],
                        [0.228, 0.354],
                        [1.674, 2.766],
                        [0.155, 0.444],
                        [1.044, 3.936],
                      ],
                      v: [
                        [606.121, 41.86],
                        [423.259, 97.332],
                        [405.835, 99.078],
                        [405.734, 99.092],
                        [405.615, 99.106],
                        [393.937, 100.194],
                        [383.559, 101.109],
                        [382.058, 101.233],
                        [377.94, 101.58],
                        [369.237, 102.28],
                        [364.157, 102.675],
                        [361.494, 102.876],
                        [356.516, 103.243],
                        [341.031, 104.303],
                        [337.81, 104.511],
                        [336.254, 104.615],
                        [333.179, 104.802],
                        [331.541, 104.906],
                        [327.715, 105.135],
                        [306.822, 106.299],
                        [304.451, 106.417],
                        [298.429, 106.722],
                        [291.685, 107.061],
                        [286.065, 107.332],
                        [283.393, 107.463],
                        [246.31, 109.348],
                        [243.327, 109.521],
                        [216.192, 111.309],
                        [211.067, 111.711],
                        [193.01, 113.277],
                        [183.145, 114.247],
                        [182.449, 114.317],
                        [178.962, 114.684],
                        [164.164, 116.354],
                        [157.785, 117.13],
                        [145.101, 118.772],
                        [140.498, 119.403],
                        [139.564, 119.528],
                        [135.354, 120.117],
                        [113.271, 123.422],
                        [-64.264, 171.348],
                        [-65.243, 172.166],
                        [-487.442, 172.166],
                        [-487.442, 171.348],
                        [-1429.597, 171.348],
                        [-849.742, 34.251],
                        [-487.442, 10.406],
                        [-64.374, -57.532],
                        [-64.31, -57.546],
                        [-64.273, -57.553],
                        [-64.264, -57.56],
                        [125.809, -109.505],
                        [314.784, -172.121],
                        [313.256, -171.456],
                        [312.871, -171.276],
                        [306.986, -168.629],
                        [303.637, -166.924],
                        [301.514, -165.739],
                        [299.491, -164.513],
                        [288.042, -149.656],
                        [288.033, -149.17],
                        [288.07, -148.353],
                        [288.161, -147.542],
                        [288.161, -147.535],
                        [288.326, -146.738],
                        [288.546, -145.948],
                        [288.546, -145.941],
                        [288.802, -145.227],
                        [288.866, -145.082],
                        [289.177, -144.382],
                        [289.653, -143.516],
                        [290.092, -142.802],
                        [290.587, -142.095],
                        [291.181, -141.34],
                        [291.181, -141.333],
                        [291.822, -140.599],
                        [293.488, -138.942],
                        [294.43, -138.124],
                        [295.437, -137.314],
                        [296.517, -136.503],
                        [316.449, -126.171],
                        [320.403, -124.563],
                        [320.412, -124.563],
                        [324.576, -122.942],
                        [324.585, -122.942],
                        [338.34, -117.925],
                        [348.453, -114.411],
                        [353.971, -112.52],
                        [358.026, -111.14],
                        [367.233, -107.994],
                        [370.683, -106.816],
                        [374.169, -105.61],
                        [377.72, -104.384],
                        [381.308, -103.137],
                        [381.317, -103.137],
                        [392.327, -99.242],
                        [392.354, -99.235],
                        [419.416, -89.125],
                        [423.415, -87.552],
                        [456.242, -73.623],
                        [467.618, -68.398],
                        [468.899, -67.802],
                        [471.581, -66.541],
                        [472.981, -65.883],
                        [475.287, -64.788],
                        [478.921, -63.055],
                        [481.437, -61.843],
                        [482.728, -61.219],
                        [485.354, -59.937],
                        [488.054, -58.614],
                        [489.427, -57.934],
                        [489.555, -57.893],
                        [489.692, -57.823],
                        [493.93, -55.703],
                        [496.181, -54.573],
                        [498.588, -53.354],
                        [503.081, -51.046],
                        [512.746, -45.96],
                        [514.85, -44.83],
                        [527.617, -37.769],
                        [529.759, -36.549],
                        [529.887, -36.48],
                        [540.86, -30.022],
                        [544.484, -27.811],
                        [549.298, -24.797],
                        [554.047, -21.755],
                        [554.139, -21.692],
                        [558.706, -18.657],
                        [566.64, -13.127],
                        [568.754, -11.596],
                        [579.032, -3.626],
                        [580.002, -2.816],
                        [580.954, -2.019],
                        [591.368, 7.648],
                        [592.869, 9.228],
                        [601.216, 19.823],
                        [601.884, 20.904],
                        [605.682, 29.102],
                        [606.075, 30.419],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 4,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980392843, 0.0941176489, 0.082352943718, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.929411824544, 0.098039223166, 0.309803921569, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [1432.097, 177.262], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Group 4',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 15,
                  s: [1219.205, 1452.041, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 75, s: [1219.205, 732.041, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [2044.705, 197.541, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-70.615, -35.91],
                        [0, 0],
                        [0, 0],
                        [4.173, 2.231],
                        [23.621, 11.08],
                        [5.784, 2.633],
                        [5.693, 2.494],
                        [1.885, 0.817],
                        [3.698, 1.58],
                        [-0.045, -0.021],
                        [0.046, 0.02],
                        [0.046, 0.014],
                        [0.458, 0.222],
                        [0.887, 0.43],
                        [0.86, 0.416],
                        [0.43, 0.2],
                        [0.824, 0.395],
                        [1.18, 0.554],
                        [0.751, 0.353],
                        [0.467, 0.215],
                        [0.869, 0.409],
                        [0.421, 0.194],
                        [2.782, 1.268],
                        [10.699, 4.241],
                        [1.327, 0.519],
                        [8.713, 3.126],
                        [-38.126, -12.653],
                        [-65.902, -27.995],
                      ],
                      o: [
                        [0, 0],
                        [-84.397, -67.634],
                        [-4.21, -2.301],
                        [-25.057, -13.395],
                        [-5.903, -2.772],
                        [-5.802, -2.633],
                        [-1.894, -0.832],
                        [-3.762, -1.636],
                        [0.046, 0.021],
                        [-0.045, -0.021],
                        [-0.046, -0.021],
                        [-0.457, -0.228],
                        [-0.915, -0.451],
                        [-0.888, -0.436],
                        [-0.439, -0.214],
                        [-0.852, -0.416],
                        [-1.245, -0.596],
                        [-0.778, -0.375],
                        [-0.476, -0.222],
                        [-0.915, -0.436],
                        [-0.43, -0.201],
                        [-4.722, -2.197],
                        [-11.147, -5.072],
                        [-1.336, -0.534],
                        [-9.271, -3.632],
                        [1.492, 0.388],
                        [38.127, 12.654],
                        [81.679, 32.563],
                      ],
                      v: [
                        [396.987, 135.719],
                        [263.426, 135.719],
                        [14.279, -27.759],
                        [1.704, -34.557],
                        [-71.446, -71.229],
                        [-88.99, -79.33],
                        [-106.214, -87.028],
                        [-111.869, -89.509],
                        [-123.053, -94.339],
                        [-122.917, -94.27],
                        [-123.053, -94.332],
                        [-123.181, -94.381],
                        [-124.554, -95.06],
                        [-127.253, -96.384],
                        [-129.88, -97.666],
                        [-131.17, -98.289],
                        [-133.688, -99.502],
                        [-137.32, -101.234],
                        [-139.627, -102.329],
                        [-141.027, -102.988],
                        [-143.708, -104.249],
                        [-144.99, -104.845],
                        [-156.365, -110.07],
                        [-189.193, -123.998],
                        [-193.192, -125.571],
                        [-220.208, -135.675],
                        [-158.553, -116.882],
                        [-0.292, -56.677],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 4,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [2044.705, 213.708], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Group 13',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 15,
                  s: [1112.53, 1430.591, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 75, s: [1112.53, 710.591, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [1938.03, 176.091, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-671.547, -308.468],
                        [0, 0],
                        [-2.691, 1.455],
                        [-1.327, 0.7],
                        [-4.293, 2.107],
                        [-0.631, 0.311],
                        [-0.815, 0.388],
                        [-5.738, 2.508],
                        [-2.315, 0.977],
                        [-0.146, 0.055],
                        [-0.824, 0.34],
                        [-13.846, 4.608],
                        [-0.595, 0.201],
                        [0, 0],
                        [-2.297, 0.72],
                        [-4.448, 1.289],
                        [-1.162, 0.333],
                        [-1.519, 0.422],
                        [-0.641, 0.173],
                        [-0.769, 0.208],
                        [-0.649, 0.174],
                        [-1.711, 0.451],
                        [-0.696, 0.18],
                        [-2.333, 0.582],
                        [-0.659, 0.16],
                        [-1.29, 0.312],
                        [-1.263, 0.298],
                        [-1.235, 0.284],
                        [-1.208, 0.27],
                        [-0.595, 0.132],
                        [-2.261, 0.472],
                        [-0.559, 0.111],
                        [-3.149, 0.617],
                        [-0.723, 0.139],
                        [-1.529, 0.277],
                        [-0.769, 0.138],
                        [-0.119, 0.021],
                        [-0.439, 0.076],
                        [-3.99, 0.603],
                        [-0.723, 0.104],
                        [-1.263, 0.159],
                        [-0.174, 0.021],
                        [-0.238, 0.028],
                        [-0.028, 0.008],
                        [0, 0],
                        [-5.975, 0.617],
                        [-9.289, 32.202],
                        [1.044, 3.936],
                        [0.155, 0.443],
                        [1.675, 2.765],
                        [0.229, 0.353],
                        [3.304, 3.561],
                        [0.513, 0.534],
                        [3.816, 3.223],
                        [0.32, 0.256],
                        [0.32, 0.256],
                        [3.597, 2.633],
                        [0.714, 0.513],
                        [2.718, 1.823],
                        [1.538, 1.005],
                        [0.028, 0.021],
                        [1.592, 0.998],
                        [1.611, 0.984],
                        [1.217, 0.734],
                        [3.67, 2.107],
                        [0.045, 0.021],
                        [0.714, 0.402],
                        [4.21, 2.266],
                        [0.695, 0.374],
                        [3.167, 1.642],
                        [1.492, 0.755],
                        [0.796, 0.402],
                        [0.741, 0.368],
                        [1.382, 0.679],
                        [-3.706, -1.608],
                        [-1.894, -0.832],
                        [-5.802, -2.633],
                        [-5.903, -2.772],
                        [-25.058, -13.395],
                        [-4.209, -2.301],
                        [-6.379, -3.604],
                        [-4.274, -2.481],
                        [-2.141, -1.254],
                        [-6.47, -3.929],
                        [-3.13, -1.926],
                        [-3.085, -1.934],
                        [-9.719, -6.431],
                        [0, 0],
                        [3.825, 0.998],
                        [0.018, 0],
                        [0, 0],
                        [3.606, 1.261],
                        [0, 0],
                        [1.189, 0.409],
                        [1.172, 0.395],
                        [1.153, 0.395],
                        [1.144, 0.381],
                        [3.001, 1.018],
                        [1.337, 0.451],
                        [1.812, 0.617],
                        [3.249, 1.143],
                        [4.283, 1.628],
                        [0, 0],
                        [1.354, 0.534],
                        [0, 0],
                        [1.282, 0.533],
                        [4.832, 3.493],
                        [0.348, 0.27],
                        [0.321, 0.27],
                        [0.302, 0.27],
                        [0.503, 0.554],
                        [0.211, 0.243],
                        [0, 0],
                        [0.192, 0.25],
                        [0.156, 0.236],
                        [0.137, 0.243],
                        [0.147, 0.292],
                        [0.091, 0.236],
                        [0.018, 0.048],
                        [0.082, 0.236],
                        [0, 0],
                        [0.064, 0.263],
                        [0.046, 0.264],
                        [0, 0],
                        [0.018, 0.27],
                        [0, 0.27],
                        [-0.009, 0.166],
                        [-6.562, 4.164],
                        [-0.696, 0.402],
                        [-0.723, 0.388],
                        [-1.144, 0.555],
                        [-2.004, 0.846],
                        [0, 0],
                        [-0.522, 0.215],
                        [-0.036, 0.007],
                        [-0.448, 0.152],
                        [-4.915, -0.443],
                        [-0.173, -0.014],
                      ],
                      o: [
                        [0, 0],
                        [2.664, -1.511],
                        [1.318, -0.714],
                        [4.255, -2.253],
                        [0.631, -0.319],
                        [0.824, -0.402],
                        [5.729, -2.752],
                        [2.316, -1.019],
                        [0.138, -0.055],
                        [0.815, -0.347],
                        [14.441, -6.001],
                        [0.605, -0.201],
                        [0, 0],
                        [2.333, -0.748],
                        [4.586, -1.428],
                        [1.172, -0.339],
                        [1.529, -0.436],
                        [0.641, -0.174],
                        [0.759, -0.208],
                        [0.649, -0.173],
                        [1.739, -0.464],
                        [0.696, -0.18],
                        [2.398, -0.617],
                        [0.659, -0.166],
                        [1.318, -0.326],
                        [1.281, -0.305],
                        [1.263, -0.298],
                        [1.236, -0.284],
                        [0.604, -0.139],
                        [2.389, -0.52],
                        [0.567, -0.125],
                        [3.477, -0.728],
                        [0.741, -0.138],
                        [1.629, -0.312],
                        [0.796, -0.152],
                        [0.119, -0.021],
                        [0.439, -0.083],
                        [6.068, -1.074],
                        [0.824, -0.118],
                        [2.37, -0.339],
                        [0.21, -0.028],
                        [0.595, -0.077],
                        [0.046, -0.006],
                        [0.064, -0.013],
                        [5.637, -0.548],
                        [130.898, -13.589],
                        [1.062, -3.673],
                        [-0.11, -0.429],
                        [-0.842, -2.696],
                        [-0.22, -0.361],
                        [-2.224, -3.492],
                        [-0.485, -0.52],
                        [-3.111, -3.208],
                        [-0.311, -0.27],
                        [-0.33, -0.284],
                        [-3.24, -2.675],
                        [-0.686, -0.513],
                        [-2.571, -1.864],
                        [-1.501, -1.018],
                        [-0.037, -0.021],
                        [-1.574, -1.033],
                        [-1.593, -1.026],
                        [-1.199, -0.741],
                        [-3.634, -2.197],
                        [-0.046, -0.027],
                        [-0.714, -0.409],
                        [-4.274, -2.439],
                        [-0.704, -0.381],
                        [-3.277, -1.761],
                        [-1.51, -0.783],
                        [-0.806, -0.409],
                        [-0.76, -0.388],
                        [-1.428, -0.727],
                        [3.652, 1.559],
                        [1.886, 0.817],
                        [5.693, 2.494],
                        [5.784, 2.633],
                        [23.621, 11.08],
                        [4.173, 2.231],
                        [6.306, 3.444],
                        [4.246, 2.404],
                        [2.132, 1.241],
                        [6.434, 3.77],
                        [3.176, 1.927],
                        [3.13, 1.927],
                        [10.268, 6.431],
                        [0, 0],
                        [-198.612, -97.12],
                        [-0.009, 0],
                        [-0.018, -0.007],
                        [-3.734, -1.337],
                        [0, 0],
                        [-1.199, -0.423],
                        [-1.19, -0.422],
                        [-1.171, -0.409],
                        [-1.153, -0.402],
                        [-3.14, -1.081],
                        [-1.364, -0.471],
                        [-1.866, -0.644],
                        [-3.487, -1.199],
                        [-4.878, -1.719],
                        [0, 0],
                        [-1.428, -0.548],
                        [-0.009, -0.007],
                        [-1.355, -0.54],
                        [-8.337, -3.465],
                        [-0.375, -0.27],
                        [-0.348, -0.271],
                        [-0.329, -0.27],
                        [-0.605, -0.547],
                        [-0.22, -0.242],
                        [0, 0],
                        [-0.21, -0.249],
                        [-0.174, -0.236],
                        [-0.155, -0.235],
                        [-0.174, -0.283],
                        [-0.11, -0.235],
                        [-0.028, -0.048],
                        [-0.091, -0.236],
                        [0, 0],
                        [-0.083, -0.263],
                        [-0.064, -0.27],
                        [0, 0],
                        [-0.045, -0.27],
                        [-0.028, -0.27],
                        [0, -0.159],
                        [0.266, -5.814],
                        [0.659, -0.416],
                        [0.695, -0.402],
                        [1.089, -0.582],
                        [1.903, -0.921],
                        [0, 0],
                        [0.512, -0.229],
                        [0.036, -0.007],
                        [0.44, -0.152],
                        [4.457, -1.545],
                        [0, 0],
                        [13.161, 0.929],
                      ],
                      v: [
                        [1009.775, 173.486],
                        [-326.923, 173.486],
                        [-318.896, 169.037],
                        [-314.924, 166.91],
                        [-302.084, 160.361],
                        [-300.181, 159.426],
                        [-297.728, 158.241],
                        [-280.523, 150.355],
                        [-273.577, 147.368],
                        [-273.156, 147.195],
                        [-270.703, 146.169],
                        [-228.093, 130.293],
                        [-226.289, 129.69],
                        [-222.665, 128.505],
                        [-215.729, 126.302],
                        [-202.175, 122.22],
                        [-198.679, 121.215],
                        [-194.112, 119.927],
                        [-192.19, 119.4],
                        [-189.902, 118.769],
                        [-187.944, 118.242],
                        [-182.764, 116.87],
                        [-180.686, 116.33],
                        [-173.585, 114.535],
                        [-171.608, 114.05],
                        [-167.7, 113.101],
                        [-163.875, 112.2],
                        [-160.132, 111.334],
                        [-156.462, 110.509],
                        [-154.668, 110.107],
                        [-147.694, 108.61],
                        [-146.01, 108.257],
                        [-136.062, 106.254],
                        [-133.875, 105.838],
                        [-129.134, 104.958],
                        [-126.773, 104.529],
                        [-126.416, 104.466],
                        [-125.099, 104.231],
                        [-109.916, 101.743],
                        [-107.591, 101.403],
                        [-102.109, 100.655],
                        [-101.533, 100.579],
                        [-100.279, 100.426],
                        [-100.16, 100.412],
                        [-100.059, 100.399],
                        [-82.635, 98.652],
                        [100.227, 43.18],
                        [100.181, 31.739],
                        [99.788, 30.423],
                        [95.99, 22.225],
                        [95.322, 21.144],
                        [86.975, 10.549],
                        [85.474, 8.968],
                        [75.06, -0.699],
                        [74.108, -1.495],
                        [73.138, -2.306],
                        [62.86, -10.275],
                        [60.746, -11.807],
                        [52.812, -17.337],
                        [48.245, -20.372],
                        [48.153, -20.434],
                        [43.404, -23.476],
                        [38.59, -26.491],
                        [34.966, -28.701],
                        [23.993, -35.16],
                        [23.865, -35.229],
                        [21.723, -36.449],
                        [8.956, -43.51],
                        [6.852, -44.639],
                        [-2.813, -49.726],
                        [-7.306, -52.033],
                        [-9.713, -53.253],
                        [-11.964, -54.383],
                        [-16.202, -56.503],
                        [-5.156, -51.742],
                        [0.5, -49.261],
                        [17.724, -41.563],
                        [35.268, -33.462],
                        [108.418, 3.21],
                        [120.992, 10.008],
                        [140.019, 20.59],
                        [152.804, 27.914],
                        [159.219, 31.649],
                        [178.575, 43.194],
                        [188.038, 48.973],
                        [197.355, 54.767],
                        [227.336, 74.087],
                        [227.336, 36.341],
                        [-113.494, -97.908],
                        [-113.54, -97.915],
                        [-113.567, -97.922],
                        [-124.577, -101.816],
                        [-124.586, -101.816],
                        [-128.173, -103.064],
                        [-131.725, -104.29],
                        [-135.211, -105.496],
                        [-138.661, -106.674],
                        [-147.868, -109.82],
                        [-151.923, -111.199],
                        [-157.441, -113.091],
                        [-167.554, -116.604],
                        [-181.309, -121.621],
                        [-181.318, -121.621],
                        [-185.482, -123.243],
                        [-185.491, -123.243],
                        [-189.445, -124.85],
                        [-209.377, -135.183],
                        [-210.457, -135.993],
                        [-211.464, -136.804],
                        [-212.406, -137.622],
                        [-214.072, -139.278],
                        [-214.713, -140.013],
                        [-214.713, -140.02],
                        [-215.307, -140.775],
                        [-215.802, -141.482],
                        [-216.241, -142.196],
                        [-216.717, -143.062],
                        [-217.028, -143.762],
                        [-217.092, -143.907],
                        [-217.348, -144.621],
                        [-217.348, -144.628],
                        [-217.568, -145.418],
                        [-217.733, -146.215],
                        [-217.733, -146.222],
                        [-217.824, -147.032],
                        [-217.861, -147.85],
                        [-217.852, -148.335],
                        [-206.403, -163.192],
                        [-204.38, -164.419],
                        [-202.257, -165.604],
                        [-198.907, -167.309],
                        [-193.023, -169.956],
                        [-192.638, -170.136],
                        [-191.11, -170.801],
                        [-191.019, -170.836],
                        [-189.701, -171.307],
                        [-175.36, -172.998],
                        [-175.095, -172.977],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 4,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980392843, 0.0941176489, 0.082352943718, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.800000059838, 0.764705942191, 0.937254961799, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [1937.991, 175.941], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 13',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: 'Group 15',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 15,
                  s: [776.866, 1567.375, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 75, s: [776.866, 847.375, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [1602.366, 312.875, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-3.972, 0.381],
                        [0.595, -0.077],
                        [0.21, -0.028],
                        [2.37, -0.34],
                        [0.824, -0.118],
                        [6.068, -1.075],
                        [0.448, -0.083],
                        [0.12, -0.02],
                        [0.806, -0.153],
                        [1.629, -0.312],
                        [0.741, -0.139],
                        [3.478, -0.728],
                        [0.567, -0.125],
                        [2.389, -0.52],
                        [0.604, -0.139],
                        [1.236, -0.284],
                        [1.263, -0.298],
                        [1.281, -0.304],
                        [1.318, -0.326],
                        [0.668, -0.166],
                        [2.397, -0.617],
                        [0.696, -0.18],
                        [1.739, -0.464],
                        [0.659, -0.18],
                        [0.759, -0.207],
                        [0.65, -0.18],
                        [1.529, -0.436],
                        [1.172, -0.34],
                        [4.585, -1.427],
                        [2.324, -0.748],
                        [0, 0],
                        [0.605, -0.208],
                        [14.441, -6.001],
                        [0.815, -0.347],
                        [0.138, -0.056],
                        [2.316, -1.019],
                        [5.729, -2.751],
                        [0.824, -0.402],
                        [0.632, -0.319],
                        [4.255, -2.252],
                        [1.318, -0.714],
                        [2.664, -1.511],
                        [0, 0],
                        [-0.284, 0.27],
                        [-45.548, 7.159],
                        [-7.083, 1.005],
                        [-1.391, 0.194],
                        [-0.312, 0.042],
                        [-1.519, 0.201],
                        [-4.136, 0.513],
                        [-2.105, 0.249],
                        [-4.813, 0.513],
                        [-1.153, 0.118],
                        [-0.229, 0.021],
                        [-3.239, 0.305],
                        [-5.894, 0.464],
                        [-1.702, 0.132],
                        [-8.932, 0.513],
                        [-0.988, 0.055],
                        [-12.62, 0.61],
                        [-0.896, 0.048],
                        [-1.886, 0.09],
                        [-2.269, 0.118],
                        [-2.022, 0.104],
                        [-0.797, 0.042],
                        [-7.184, 0.429],
                        [-1.282, 0.076],
                        [-0.549, 0.035],
                        [-1.034, 0.062],
                        [-0.522, 0.035],
                        [-1.08, 0.076],
                        [-5.299, 0.381],
                        [-1.674, 0.125],
                        [-0.887, 0.069],
                        [-1.712, 0.138],
                        [-2.947, 0.242],
                        [-1.382, 0.118],
                        [-0.503, 0.041],
                        [-3.523, 0.319],
                      ],
                      o: [
                        [-0.238, 0.028],
                        [-0.174, 0.02],
                        [-1.263, 0.159],
                        [-0.723, 0.104],
                        [-3.99, 0.603],
                        [-0.43, 0.076],
                        [-0.119, 0.021],
                        [-0.768, 0.139],
                        [-1.528, 0.277],
                        [-0.714, 0.139],
                        [-3.149, 0.617],
                        [-0.558, 0.11],
                        [-2.261, 0.471],
                        [-0.595, 0.132],
                        [-1.208, 0.27],
                        [-1.235, 0.284],
                        [-1.263, 0.297],
                        [-1.29, 0.312],
                        [-0.659, 0.159],
                        [-2.333, 0.582],
                        [-0.696, 0.18],
                        [-1.711, 0.45],
                        [-0.65, 0.173],
                        [-0.769, 0.208],
                        [-0.632, 0.174],
                        [-1.519, 0.423],
                        [-1.153, 0.326],
                        [-4.448, 1.289],
                        [-2.297, 0.721],
                        [0, 0],
                        [-0.595, 0.201],
                        [-13.846, 4.608],
                        [-0.824, 0.339],
                        [-0.146, 0.055],
                        [-2.315, 0.977],
                        [-5.738, 2.508],
                        [-0.815, 0.389],
                        [-0.632, 0.312],
                        [-4.292, 2.106],
                        [-1.327, 0.7],
                        [-2.691, 1.455],
                        [0, 0],
                        [0.283, -0.271],
                        [30.677, -24.857],
                        [7.651, -1.199],
                        [1.419, -0.201],
                        [0.311, -0.042],
                        [1.546, -0.215],
                        [4.329, -0.582],
                        [2.151, -0.27],
                        [5.052, -0.603],
                        [1.172, -0.125],
                        [0.238, -0.021],
                        [3.34, -0.346],
                        [6.159, -0.582],
                        [1.72, -0.138],
                        [9.225, -0.693],
                        [0.997, -0.055],
                        [12.264, -0.686],
                        [0.888, -0.041],
                        [1.867, -0.09],
                        [2.233, -0.111],
                        [1.996, -0.097],
                        [0.787, -0.035],
                        [6.763, -0.353],
                        [1.272, -0.077],
                        [0.549, -0.035],
                        [1.016, -0.062],
                        [0.521, -0.035],
                        [1.061, -0.062],
                        [5.033, -0.326],
                        [1.638, -0.118],
                        [0.888, -0.07],
                        [1.675, -0.125],
                        [2.855, -0.222],
                        [1.355, -0.111],
                        [0.494, -0.041],
                        [3.395, -0.291],
                        [3.817, -0.347],
                      ],
                      v: [
                        [235.37, -36.508],
                        [234.115, -36.355],
                        [233.539, -36.279],
                        [228.057, -35.531],
                        [225.732, -35.191],
                        [210.549, -32.703],
                        [209.232, -32.468],
                        [208.874, -32.406],
                        [206.513, -31.976],
                        [201.773, -31.096],
                        [199.586, -30.68],
                        [189.637, -28.677],
                        [187.954, -28.324],
                        [180.98, -26.827],
                        [179.186, -26.425],
                        [175.516, -25.601],
                        [171.773, -24.734],
                        [167.948, -23.834],
                        [164.04, -22.884],
                        [162.063, -22.399],
                        [154.963, -20.604],
                        [152.884, -20.064],
                        [147.704, -18.692],
                        [145.746, -18.165],
                        [143.458, -17.535],
                        [141.536, -17.008],
                        [136.969, -15.719],
                        [133.474, -14.714],
                        [119.919, -10.633],
                        [112.984, -8.429],
                        [109.359, -7.244],
                        [107.555, -6.641],
                        [64.945, 9.235],
                        [62.492, 10.261],
                        [62.071, 10.434],
                        [55.125, 13.421],
                        [37.92, 21.306],
                        [35.467, 22.491],
                        [33.563, 23.427],
                        [20.724, 29.975],
                        [16.752, 32.103],
                        [8.725, 36.552],
                        [-235.37, 36.552],
                        [-234.51, 35.734],
                        [-56.974, -12.192],
                        [-34.891, -15.497],
                        [-30.682, -16.086],
                        [-29.748, -16.211],
                        [-25.145, -16.842],
                        [-12.461, -18.484],
                        [-6.082, -19.26],
                        [8.716, -20.93],
                        [12.203, -21.297],
                        [12.899, -21.367],
                        [22.765, -22.337],
                        [40.821, -23.903],
                        [45.946, -24.305],
                        [73.081, -26.093],
                        [76.064, -26.266],
                        [113.147, -28.151],
                        [115.819, -28.282],
                        [121.439, -28.553],
                        [128.183, -28.892],
                        [134.205, -29.197],
                        [136.576, -29.315],
                        [157.469, -30.479],
                        [161.296, -30.708],
                        [162.933, -30.812],
                        [166.008, -30.999],
                        [167.564, -31.103],
                        [170.786, -31.311],
                        [186.27, -32.371],
                        [191.248, -32.738],
                        [193.911, -32.939],
                        [198.991, -33.334],
                        [207.694, -34.034],
                        [211.812, -34.381],
                        [213.313, -34.505],
                        [223.692, -35.42],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 4,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [1602.343, 312.875], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 15',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: 'Group 18',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 10,
                  s: [719.414, 1474.271, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 70, s: [719.414, 754.271, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [1544.914, 219.771, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-96.13, 60.94],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-224.291, 36.007],
                        [-193.78, 0.035],
                        [-149.942, -25.688],
                        [-28.197, -2.911],
                        [-142.63, -36.395],
                        [-65.115, 21.128],
                        [-165.895, -36.533],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [199.628, -32.043],
                        [0, 0],
                        [17.389, 2.98],
                        [271.304, 27.961],
                        [142.639, 36.388],
                        [65.114, -21.136],
                        [165.894, 36.527],
                      ],
                      v: [
                        [1402.852, 56.523],
                        [1402.852, 129.583],
                        [-1542.414, 128.765],
                        [-1542.414, -42.557],
                        [-1176.518, -25.552],
                        [-596.387, -129.539],
                        [-664.303, -77.033],
                        [-596.387, -68.183],
                        [91.203, 83.632],
                        [511.37, 69.544],
                        [1004.403, 93.056],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 4,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [1544.914, 219.844], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 18',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: 'Group 20',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 10,
                  s: [720.928, 1460.004, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 70, s: [720.928, 740.004, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [1546.428, 205.504, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-73.132, 55.375],
                        [0, 0],
                        [165.894, 36.526],
                        [65.114, -21.136],
                        [142.639, 36.387],
                        [271.304, 27.962],
                        [17.389, 2.98],
                        [0, 0],
                        [-24.206, -2.405],
                        [-190.183, 13.174],
                        [-208.788, -61.84],
                      ],
                      o: [
                        [0, 0],
                        [-96.13, 60.939],
                        [-165.895, -36.533],
                        [-65.115, 21.128],
                        [-142.63, -36.395],
                        [-28.197, -2.91],
                        [-149.942, -25.688],
                        [23.95, -0.007],
                        [220.027, 21.842],
                        [190.183, -13.173],
                        [208.788, 61.84],
                      ],
                      v: [
                        [1402.852, 33.936],
                        [1402.852, 56.527],
                        [1004.403, 93.06],
                        [511.37, 69.548],
                        [91.203, 83.636],
                        [-596.387, -68.18],
                        [-664.303, -77.029],
                        [-596.387, -129.535],
                        [-524.171, -126.042],
                        [196.119, -6.111],
                        [824.552, -12.25],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 4,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980392843, 0.0941176489, 0.082352943718, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [1544.914, 219.841], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 20',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_3',
      nm: 'Features-Background',
      fr: 30,
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Group 1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 10,
                  s: [78.174, 1389.417, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 60, s: [78.174, 449.417, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [259.674, 505.917, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-82.97, 0.724],
                        [-18.267, 8.607],
                        [101.719, -8.363],
                        [-0.941, 1.084],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [79.412, -0.693],
                        [0, 0],
                        [-98.58, 8.104],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-215.436, -3.347],
                        [-90.848, -25.523],
                        [77.74, -43.464],
                        [-55.878, -8.446],
                        [-197.326, 42.38],
                        [-239.627, -8.363],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 4,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980392843, 0.0941176489, 0.082352943718, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.800000059838, 0.764705942191, 0.937254961799, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [259.674, 530.633], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Group 3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 10,
                  s: [720.184, 1443.318, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 60, s: [720.184, 503.318, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [901.684, 559.818, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [-0.376, -4.325],
                        [-26.881, -9.492],
                        [-0.71, -3.937],
                        [7.518, -6.283],
                        [0.274, -20.776],
                        [-72.509, -73.473],
                        [-168.744, -69.877],
                        [-229.954, -0.055],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-2.037, 1.784],
                        [0.811, 9.337],
                        [11.447, 4.042],
                        [0.829, 4.602],
                        [-11.596, 9.69],
                        [-0.241, 18.247],
                        [60.25, 61.049],
                        [179.038, 74.139],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-900.906, -219.371],
                        [-346.676, -375.767],
                        [-350.937, -366.493],
                        [-309.204, -338.117],
                        [-291.385, -326.422],
                        [-304.013, -310.574],
                        [-331.763, -266.838],
                        [-232.855, -118.789],
                        [103.38, 99.579],
                        [719.75, 211.392],
                        [719.75, 376.267],
                        [-858.363, 377.066],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 4,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980392843, 0.0941176489, 0.082352943718, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.364705882353, 0.207843152214, 0.898039275525, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [901.684, 559.818], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Group 5',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 10,
                  s: [869.108, 1442.201, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 60, s: [869.108, 502.201, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [1050.608, 558.701, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-490.147, -376.884],
                        [-571.326, -353.275],
                        [-303.057, 376.884],
                        [572.754, 377.884],
                        [572.754, 285.561],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 4,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980392843, 0.0941176489, 0.082352943718, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [1050.608, 558.701], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 5',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: 'Group 6',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 0,
                  s: [1336.238, 1272.815, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 50, s: [1336.238, 332.815, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [1517.738, 389.315, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [260.241, 1.204],
                        [41.512, 16.166],
                        [-188.944, -22.529],
                        [0, 0],
                      ],
                      o: [
                        [-4.791, -4.029],
                        [-131.61, -0.608],
                        [0, 0],
                        [197.674, 23.569],
                        [0, 0],
                      ],
                      v: [
                        [908.342, 102.796],
                        [216.045, -121.292],
                        [-104.196, -180.741],
                        [196.793, -91.848],
                        [872.767, 180.741],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 4,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.364705882353, 0.207843152214, 0.898039275525, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [1517.738, 533.312], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 6',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: 'Group 7',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 0,
                  s: [850.233, 1379.085, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                { t: 60, s: [850.233, 439.085, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [1031.733, 495.585, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-11.571, 4.083],
                        [-9.789, 1.484],
                        [-11.204, 5.619],
                        [-28.294, 20.654],
                        [-47.393, 28.696],
                        [-90.382, 1.528],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-209.09, 3.458],
                        [-141.09, 27.905],
                      ],
                      o: [
                        [17.322, -6.113],
                        [13.076, -1.983],
                        [43.924, -22.033],
                        [34.926, -25.495],
                        [69.24, -41.924],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [214.29, -3.544],
                        [23.829, -4.713],
                      ],
                      v: [
                        [-91.417, -237.048],
                        [-49.901, -246.344],
                        [-13.568, -258.126],
                        [89.518, -344.977],
                        [202.262, -384.635],
                        [407.136, -475.467],
                        [787.88, -495.335],
                        [786.835, 445.018],
                        [-1742.202, 441.597],
                        [-589.142, -163.688],
                        [-148.473, -222.784],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 4,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [1031.732, 495.585], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 7',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 1,
          nm: 'Pale Blue Solid 2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [720, 440, 0], ix: 2, l: 2 },
            a: { a: 0, k: [720, 440, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          sw: 1440,
          sh: 880,
          sc: '#ccc3ef',
          ip: 0,
          op: 900,
          st: 0,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Shape Layer 1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.05, y: 1 },
              o: { x: 0.5, y: 0 },
              t: 45,
              s: [195, 840, 0],
              to: [0, -13.333, 0],
              ti: [0, 13.333, 0],
            },
            { t: 75, s: [195, 760, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          d: 1,
          ty: 'el',
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.05, 0.05], y: [1, 1] },
                o: { x: [0.5, 0.5], y: [0, 0] },
                t: 45,
                s: [0, 0],
              },
              { t: 75, s: [200, 32] },
            ],
            ix: 2,
          },
          p: { a: 0, k: [0, 0], ix: 3 },
          nm: 'Ellipse Path 1',
          mn: 'ADBE Vector Shape - Ellipse',
          hd: false,
        },
        {
          ty: 'fl',
          c: {
            a: 0,
            k: [0.050980392843, 0.0941176489, 0.082352943718, 1],
            ix: 4,
          },
          o: { a: 0, k: 100, ix: 5 },
          r: 1,
          bm: 0,
          nm: 'Fill 1',
          mn: 'ADBE Vector Graphic - Fill',
          hd: false,
        },
      ],
      ip: 0,
      op: 241,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 0,
      nm: 'Features Eyeball 1',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 60,
              s: [347, 612, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 120,
              s: [347, 592, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 180,
              s: [347, 612, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 240, s: [347, 592, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [720, 440, 0], ix: 1, l: 2 },
        s: { a: 0, k: [50, 50, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 1440,
      h: 880,
      ip: 0,
      op: 900,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Group 2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.05, y: 1 },
              o: { x: 0.5, y: 0 },
              t: 20,
              s: [40.61, 1224.768, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 80, s: [40.61, 804.768, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [59.61, 308.268, 0], ix: 1, l: 2 },
        s: { a: 0, k: [50, 50, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 4.431],
                    [20.801, 0],
                    [0, -4.431],
                    [-20.801, 0],
                  ],
                  o: [
                    [0, -4.431],
                    [-20.801, 0],
                    [0, 4.431],
                    [20.801, 0],
                  ],
                  v: [
                    [37.663, 0],
                    [0.001, -8.023],
                    [-37.663, 0],
                    [0.001, 8.023],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [59.61, 308.268], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 900,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 0,
      nm: 'Features Eyeball 2',
      refId: 'comp_1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 60,
              s: [51, 514, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 120,
              s: [51, 534, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 180,
              s: [51, 514, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 240, s: [51, 534, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [720, 440, 0], ix: 1, l: 2 },
        s: { a: 0, k: [50, 50, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 1440,
      h: 880,
      ip: 0,
      op: 900,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'Group 5',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.05, y: 1 },
              o: { x: 0.5, y: 0 },
              t: 20,
              s: [357.766, 1052.189, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 80, s: [357.766, 632.189, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [1284.766, 119.689, 0], ix: 1, l: 2 },
        s: { a: 0, k: [50, 50, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 3.132],
                    [14.706, 0],
                    [0, -3.132],
                    [-14.706, 0],
                  ],
                  o: [
                    [0, -3.132],
                    [-14.706, 0],
                    [0, 3.132],
                    [14.706, 0],
                  ],
                  v: [
                    [26.627, 0],
                    [-0.001, -5.672],
                    [-26.628, 0],
                    [-0.001, 5.672],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [1284.766, 119.689], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 5',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 900,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 0,
      nm: 'Features-Foreground',
      refId: 'comp_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [195, 542, 0], ix: 2, l: 2 },
        a: { a: 0, k: [720, 440, 0], ix: 1, l: 2 },
        s: { a: 0, k: [70, 70, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 1440,
      h: 880,
      ip: 0,
      op: 900,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 0,
      nm: 'Features-Background',
      refId: 'comp_3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [195, 542, 0], ix: 2, l: 2 },
        a: { a: 0, k: [720, 440, 0], ix: 1, l: 2 },
        s: { a: 0, k: [70, 70, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 1440,
      h: 880,
      ip: 0,
      op: 900,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 10,
      ty: 1,
      nm: 'Pale Blue Solid 3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [195, 422, 0], ix: 2, l: 2 },
        a: { a: 0, k: [195, 422, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      sw: 390,
      sh: 844,
      sc: '#ccc3ef',
      ip: 0,
      op: 900,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
  props: {},
}
