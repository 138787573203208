import store from '../store'
import { Transition } from '@unseenco/taxi'
import { Core } from '../core'
import { gsap } from 'gsap'

export default class Fade extends Transition {
  onLeave({ from, trigger, done }) {
    const main = from.parentNode
    const { el, frames } = store.preloader

    const tl = gsap.timeline({
      paused: true,
      onComplete: () => {
        done()
      },
    })

    gsap.set(el, { autoAlpha: 1 })

    tl.from(frames, {
      duration: 0,
      scaleY: 0,
      transformOrigin: 'bottom',
      stagger: 0.3,
      ease: 'power3.inOut',
      onComplete: () => {},
    })
    tl.play()
  }

  onEnter({ to, trigger, done }) {
    this.core = new Core()
    const main = to.parentNode
    const { el, frames } = store.preloader

    store.body.scrollTo(0, 0)
    this.core.raf.setScroll(0)

    gsap.set('html', { '--primary': '#f1dfdb' })
    gsap.set('html', { '--secondary': '#0d1815' })

    const tl = gsap.timeline({
      paused: true,
      onComplete: () => {
        gsap.set(el, { clearProps: 'all' })
        gsap.set(frames, { clearProps: 'all' })
      },
    })

    done()

    tl.to(
      el,
      {
        duration: 0,
        scaleY: 0,
        transformOrigin: 'top',
        ease: 'power3.in',
      },
      //'-=0.5',
    )

    //tl.fromTo(main, { autoAlpha: 1 }, { duration: 0.5, autoAlpha: 1 })
    tl.play()
  }
}
