import { Emitter } from '../core'
import store from '../store'
import { qs } from '../utils'
import gsap from 'gsap'

export default class Dots {
  constructor(obj = {}) {
    const canvas = qs('.feature-dots')
    const ctx = canvas.getContext('2d')
    const ballDiameter = 32
    const gap = 48

    this.settings = {
      canvas,
      ctx,
      ballDiameter,
      gap,
    }

    this.balls = {
      scale: 0,
    }

    this.init()
  }

  drawBall(x, y) {
    const { ctx, ballDiameter } = this.settings

    const radius = ballDiameter / 2

    ctx.beginPath()
    ctx.arc(x, y, radius * this.balls.scale, 0, Math.PI * 2)
    ctx.fillStyle = '#f1dfdb'
    ctx.fill()
    ctx.closePath()
  }

  tick = () => {
    const {
      ctx,
      canvas,
      ballDiameter,
      gap,
      numRows,
      numCols,
      initialGapX,
      initialGapY,
    } = this.settings

    ctx.clearRect(0, 0, canvas.width, canvas.height)

    for (let row = 0; row < numRows; row++) {
      for (let col = 0; col < numCols; col++) {
        const x = col * (ballDiameter + gap) + ballDiameter / 2 + initialGapX
        const y = row * (ballDiameter + gap) + ballDiameter / 2 + initialGapY
        this.drawBall(x, y)
      }
    }
  }

  onScroll() {
    const { canvas } = this.settings

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: canvas,
        start: 'top top+=80%',
        end: 'bottom center-=60%',
        scrub: true,
        once: true,
      },
    })

    tl.fromTo(
      this.balls,
      {
        scale: 0,
      },
      {
        duration: 5,
        scale: 8,
        ease: 'power3.in',
      },
    )
  }

  resize = () => {
    const { canvas, gap, ballDiameter } = this.settings

    canvas.width = store.sizes.vw
    canvas.height = store.sizes.vh

    const numRows = Math.floor((canvas.height + gap) / (ballDiameter + gap))
    const numCols = Math.floor((canvas.width + gap) / (ballDiameter + gap))

    const initialGapX =
      (canvas.width - (numCols * (ballDiameter + gap) - gap)) / 2
    const initialGapY =
      (canvas.height - (numRows * (ballDiameter + gap) - gap)) / 2

    Object.assign(this.settings, {
      numRows,
      numCols,
      initialGapX,
      initialGapY,
    })
  }

  on() {
    Emitter.on('tick', this.tick)
    Emitter.on('resize', this.resize)
  }

  init() {
    this.on()
    this.onScroll()
    this.resize()
  }
}
