import lottie from 'lottie-web'

export default class LottieAnimation {
  constructor(obj = {}) {
    this.animationData = obj.data
    this.container = obj.container
    this.renderer = obj.renderer
    this.rendererSettings = obj.rendererSettings ?? {}
    this.animation = null
    this.autoplay = obj.autoplay || false
    this.loop = obj.loop || false
    this.canvas = null
  }

  load() {
    this.animation = lottie.loadAnimation({
      container: this.container,
      renderer: this.renderer ?? "canvas", // You can choose 'svg', 'canvas', or 'html' based on your preference
      loop: this.loop, // Set whether the animation should loop
      autoplay: this.autoplay, // Set whether the animation should start playing automatically
      animationData: this.animationData, // JSON animation data
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
        ...this.rendererSettings
      },
    })

    this.canvas = this.animation.container
  }

  play() {
    if (this.animation) {
      this.animation.play()
    }
  }

  playSegments(start, end, forceFlag) {
    if (this.animation) {
      this.animation.playSegments([start, end], forceFlag)
    }
  }

  pause() {
    if (this.animation) {
      this.animation.pause()
    }
  }

  stop() {
    if (this.animation) {
      this.animation.stop()
    }
  }

  getAnimation() {
    return this.animation
  }
}
