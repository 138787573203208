import gsap from 'gsap'

export const animateOut = (animation) => {
  switch (animation) {
    case 'hero':
      const hero = gsap.timeline({ paused: true })

      //hero.play()
      break
  }
}
