import store from '../store'
import Emitter from './Emitter'
import { Sniffer, lerp } from '../utils'
import { gsap } from 'gsap'
import { ScrollTrigger } from '../vendor/ScrollTrigger'

export default class GlobalVRaf {
  constructor() {
    gsap.registerPlugin(ScrollTrigger)

    this.scroll = {
      target: 0,
      current: 0,
      rounded: 0,
      ease: 0.115,
    }

    this.mouse = {
      x: 0,
      y: 0,
      target: null,
    }

    this.setScrollTrigger()
  }

  tick = () => {
    const { target, current, ease } = this.scroll
    this.scroll.current = lerp(current, target, ease)
    this.scroll.rounded = Math.round(current * 100) / 100
    this.diff = (target - current) * 0.005

    Emitter.emit('tick', {
      target: target,
      current: this.smooth(),
      mouse: this.mouse,
      diff: this.diff,
    })

    ScrollTrigger.update()
  }

  clamp() {
    this.scroll.target = Math.min(
      Math.max(this.scroll.target, 0),
      store.sizes.h,
    )
  }

  smooth() {
    const { main } = store

    if (!Sniffer.sniff.isDevice) {
      return this.scroll.rounded
    } else {
      return main.scrollTop
    }
  }

  onScroll = ({ y }) => {
    if (store.flags.locked) return
    this.scroll.target += y
    this.clamp()
  }

  setScroll(offset) {
    if (store.flags.smooth) {
      gsap.set(this.scroll, {
        target: offset,
        current: offset,
        rounded: offset,
      })
      gsap.to(this, {
        original: offset,
        duration: 1,
        ease: 'expo.inOut',
      })
    } else {
      window.scrollTo({
        top: offset,
        left: 0,
      })
    }
  }

  move = ({ x, y, target }) => {
    this.mouse.x = x
    this.mouse.y = y
    this.mouse.target = target
  }

  setScrollTrigger() {
    const { body, sizes, main } = store

    ScrollTrigger.defaults({
      scroller: main,
    })

    ScrollTrigger.scrollerProxy(main, {
      scrollTop: () => {
        return this.smooth()
      },
      getBoundingClientRect() {
        return { top: 0, left: 0, width: sizes.vw, height: sizes.vh }
      },
    })
  }

  on() {
    gsap.ticker.add(this.tick)
    Emitter.on('scroll', this.onScroll)
    Emitter.on('mousemove', this.move)
  }
}
