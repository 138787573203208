import store from '../store'
import { Renderer } from '@unseenco/taxi'
import { Core, Emitter, AnimationManager } from '../core'
import { Splits, Menu, Smooth } from '../components'
import { qs, qsa, formFocus, growTextarea } from '../utils'
import { ScrollTrigger } from '../vendor/ScrollTrigger'

export default class Default extends Renderer {
  constructor(opt = {}) {
    super(opt)

    // Initialize Core
    this.core = new Core()
    // Initialize WebGL
    this.webgl = this.core.webgl
    // Initialize Smooth Scroll
    this.smooth = this.core.smooth
    // The state of the page
    this.state = {
      resize: false,
    }
  }

  onEnter() {
    const { sniff, body } = store

    // Add classes if device or desktop
    if (sniff.isDevice) {
      body.classList.add('is-device')
    } else {
      body.classList.add('is-desktop')
    }
    // Store the page that I'm on
    this.page = this.wrapper.lastElementChild
    store.page = this.page
  }

  onEnterCompleted() {
    this.addSplit()
    // Add Event Listeners
    this.on()
    // Add DOM Elements
    this.setup()
    // If Loaded Elements then ready
    if (this.core.ready) this.ready()
  }

  setup() {
    // Initial DOM Elements
    this.dom = {
      desktopMenu: {
        el: qs('.header'),
        logo: qs('.header-logo'),
      },
      mobileMenu: {
        el: qs('.mobile'),
        logo: qs('.mobile-logo'),
        open: qs('.mobile-open'),
        close: qs('.mobile-close'),
      },
      footer: {
        el: qs('.footer'),
      },
      smooth: {
        els: qsa('[data-s-i]', this.page),
      },
    }
  }

  ready = () => {
    // Make sure animations on scroll are updated
    ScrollTrigger.refresh()
    // Add Animations
    this.animations = new AnimationManager()
    // Initialize animations
    //this.animations.onScroll()
    this.animations.in()

    // Add Components
    this.components()
    // WebGL Enter
    this.webgl && this.webgl.onEnter()

    this.animations.onScroll()
  }

  readyCompleted = () => {
    // Unlock the scroll
    store.flags.locked = false
  }

  animationEnded = (el) => {
    el.forEach((item) => {
      this.spliText.reverseContent(item)
    })
  }

  components() {
    // Add all components here
    this.addSmooth()
    this.addForms()
    this.addMenu()
  }

  addSplit() {
    // Spliting content witd [data-split="lines,words,chars"]
    this.spliText = new Splits()
  }

  addSmooth() {
    const { smooth } = this.dom
    // Smooth Scroll based on VS remove if using Lenis
    this.core.scroll.setScrollBounds()

    this.smooth.init(smooth.els)
    setTimeout(() => {
      this.smooth.update()
    }, 500)
  }

  addForms() {
    formFocus()
    growTextarea()
  }

  addMenu() {
    const { currentLocation } = this.core.taxi
    const { desktopMenu, mobileMenu, footer } = this.dom
    // Add Menu
    this.menu = new Menu({
      deskMenu: desktopMenu.el,
      mobMenu: mobileMenu.el,
      footer: footer.el,
      url: currentLocation.href,
      open: mobileMenu.open,
      close: mobileMenu.close,
    })
  }

  tick = ({ current, mouse, diff }) => {
    if (this.state.resize) return
    // Saving the scroll & mouse values for the app
    this.scroll = current
    this.mouse = { x: mouse.x, y: mouse.y }
    this.direction = diff > 0 ? 'down' : 'up'

    this.run()
  }

  run() {}

  resize = () => {
    this.onResize()
  }

  onResize() {
    this.state.resize = true
    this.core.scroll && this.core.scroll.setScrollBounds()
    ScrollTrigger.refresh()
    this.state.resize = false
  }

  smoothResize = () => {}

  on() {
    Emitter.on('tick', this.tick)
    Emitter.on('loaded', this.ready)
    Emitter.on('resize', this.resize)
    Emitter.on('smooth:resize', this.smoothResize)
    Emitter.on('animateIn:ended', this.readyCompleted)
    Emitter.on('animation:ended', this.animationEnded)
  }

  off() {
    Emitter.off('tick', this.tick)
    Emitter.off('loaded', this.ready)
    Emitter.off('resize', this.resize)
    Emitter.off('smooth:resize', this.smoothResize)
    Emitter.off('animateIn:ended', this.readyCompleted)
  }

  onLeave() {
    this.off()
    this.menu && this.menu.closeMenu()
    this.menu && this.menu.destroy()
    this.webgl && this.webgl.onLeave()
  }

  onLeaveCompleted() {
    ScrollTrigger.getAll().forEach((inst) => inst.kill(true))
  }
}
