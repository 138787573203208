import { qs } from '../utils'
import gsap from 'gsap'

export default class Form {
  constructor(opt = {}) {
    this.dom = {
      el: opt.el,
      form: opt.form,
      cards: opt.cards,
      buttons: opt.buttons,
      state: opt.state,
      names: opt.names,
      reset: qs('.reset-button'),
    }

    this.init()
  }

  setup() {
    const { el, cards } = this.dom

    if (!el) return

    cards[0].classList.add('active')
  }

  update = () => {
    const { el, cards, state } = this.dom
    const active = qs('.active', el)
    const input = qs('input', active) || qs('textarea', active)
    const required = input.required
    const value = input.value
    const error = qs('.form-error', active)

    if (required && !value) {
      gsap.to(error, { duration: 0.5, autoAlpha: 1 })

      return
    }

    if (state.index == 1) {
      const validate = this.validateEmail(value)

      if (!validate) {
        gsap.to(error, { duration: 0.5, autoAlpha: 1 })

        return
      }
    }

    if (state.index == 0) this.addName(value)

    this.dom.state.index += 1

    if (cards[state.index]) {
      cards.forEach((crd) => {
        crd.classList.remove('active')
      })

      if (state.index === 0)
        gsap.to(el, { duration: 0.5, backgroundColor: '#0d1815' })
      if (state.index === 1)
        gsap.to(el, { duration: 0.5, backgroundColor: '#ed194f' })
      if (state.index === 2)
        gsap.to(el, { duration: 0.5, backgroundColor: '#f5c9d9' })
      if (state.index === 3)
        gsap.to(el, { duration: 0.5, backgroundColor: '#5d35e5' })
      if (state.index === 4)
        gsap.to(el, { duration: 0.5, backgroundColor: '#ccc3ef' })
      cards[state.index].classList.add('active')
    }
  }

  addName(name) {
    const { names } = this.dom

    if (!names) return
    names.forEach((n) => {
      n.innerHTML = name
    })
  }

  validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
  }

  handleSubmit = (e, form) => {
    e.preventDefault()

    const formData = new FormData(form)

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        this.submitForm(form)
      })
      .catch((error) => console.log(error))
  }

  submitForm = (form) => {
    this.dom.state.index = 3
    this.update()
  }

  resetForm = (e, form) => {
    const { cards, state } = this.dom
    form.reset()
    this.dom.state.index = 0

    cards.forEach((crd) => {
      crd.classList.remove('active')
    })
    cards[state.index].classList.add('active')
  }

  on() {
    const { buttons, form, reset } = this.dom

    buttons.forEach((button, i) => {
      button.addEventListener('click', this.update)
    })

    reset.addEventListener('click', (e) => this.resetForm(e, form))

    form.addEventListener('submit', (e) => this.handleSubmit(e, form))
  }

  init() {
    this.setup()
    this.on()
  }
}
