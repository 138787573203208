import * as Matter from 'matter-js'
import { Sniffer, bounds, is } from '../utils'
import { ScrollTrigger } from '../vendor/ScrollTrigger'

export default class Balls {
  constructor(obj = {}) {
    const Engine = Matter.Engine
    const Render = Matter.Render
    const Runner = Matter.Runner
    const Composite = Matter.Composite
    const Composites = Matter.Composites
    const Common = Matter.Common
    const MouseConstraint = Matter.MouseConstraint
    const Mouse = Matter.Mouse
    const World = Matter.World
    const Bodies = Matter.Bodies

    this.dom = {
      container: obj.container || document.body,
      items: obj.items,
    }

    this.scene = {
      Engine,
      Render,
      Runner,
      Composite,
      Composites,
      Common,
      MouseConstraint,
      Mouse,
      World,
      Bodies,
    }
  }

  setup() {
    const { Engine, Render, Runner } = this.scene
    const { container } = this.dom

    this.rect = bounds(container)
    this.engine = Engine.create()
    this.world = this.engine.world

    this.engine.gravity.x = 0.0
    this.engine.gravity.y = 0.0

    this.render = Render.create({
      element: container,
      engine: this.engine,
      options: {
        width: this.rect.width,
        height: this.rect.height,
        background: '#5020f3',
        wireframes: false,
        showAngleIndicator: false,
        pixelRatio: 1,
      },
    })

    Render.run(this.render)
    this.runner = Runner.create()
    Runner.run(this.runner, this.engine)
  }

  addBalls() {
    const { Bodies, Composite, Composites } = this.scene
    const { width, height } = this.rect
    const { items } = this.dom
    const isDevice = Sniffer.sniff.isDevice
    const half = width / 2
    const getRandomNumber = this.getRandomNumber(0 + 40, width - 40)
    let offset = 19
    let options = {
      isStatic: true,
      size: isDevice ? 40 : 80,
      render: {
        fillStyle: 'transparent',
      },
      y: isDevice ? 10 : 200,
    }

    this.world.bodies = []

    Composite.add(this.world, [
      Bodies.rectangle(0 - offset, height / 2, 40, height, options), // left
      Bodies.rectangle(width / 2, height + offset, width, 40, options), // bottom
      Bodies.rectangle(width + offset, height / 2, 40, height, options), // right
    ])

    items.forEach((item, index) => {
      const logo = item.dataset.logo
      const ball = Bodies.circle(getRandomNumber, -options.y, options.size, {
        density: 0.0005,
        frictionAir: 0.01,
        restitution: 0.8,
        friction: 0.1,
        render: {
          sprite: {
            texture: logo,
            xScale: isDevice ? 0.25 : 0.5,
            yScale: isDevice ? 0.25 : 0.5,
          },
        },
      })

      Composite.add(this.world, ball)
    })
  }

  getRandomNumber(min, max) {
    return Math.random() * (max - min) + min
  }

  addMouse() {
    const { Mouse, MouseConstraint, Composite, Render } = this.scene

    const mouse = Mouse.create(this.render.canvas)
    const mouseConstraint = MouseConstraint.create(this.engine, {
      mouse: mouse,
      constraint: {
        stiffness: 0.2,
        render: {
          visible: false,
        },
      },
    })

    Composite.add(this.world, mouseConstraint)
    this.render.mouse = mouse

    Render.lookAt(this.render, {
      min: { x: 0, y: 0 },
      max: { x: this.rect.width, y: this.rect.height },
    })
  }

  addScroll() {
    const { container } = this.dom

    ScrollTrigger.create({
      trigger: container,
      start: 'top top+=80%',
      scrub: true,
      onEnter: () => {
        this.engine.gravity.y = 1
      },
    })
  }

  resize = () => {}

  on() {}

  destroy() {
    const { Engine, Render, Runner } = this.scene
    Render.stop(this.render)
    Runner.stop(this.runner)
    Engine.clear(this.engine)
  }

  init() {
    this.setup()
    this.addBalls()
    this.addScroll()
  }
}
