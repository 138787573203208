export const mssmb = {
  v: '5.12.1',
  fr: 30,
  ip: 0,
  op: 91,
  w: 390,
  h: 844,
  nm: 'MSS Mobile',
  ddd: 0,
  assets: [
    {
      id: 'comp_0',
      nm: 'MS-Elements',
      fr: 30,
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Cube 1/Most-Software-Elements Outlines',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 0,
                  s: [720, 440, 0],
                  to: [68, -65.667, 0],
                  ti: [-68, 65.667, 0],
                },
                { t: 60, s: [1128, 46, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [60, 63, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.05, 0.05, 0.05], y: [1, 1, 1] },
                  o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] },
                  t: 0,
                  s: [0, 0, 100],
                },
                { t: 60, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [18.768, -49.413],
                        [28.528, 10.837],
                        [-18.767, 49.413],
                        [-28.528, -10.837],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.443137284821, 0.580392156863, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [31.028, 51.912], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-33.409, -40.96],
                        [23.648, -19.29],
                        [33.409, 40.96],
                        [-23.647, 19.286],
                        [-23.649, 19.287],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.364705882353, 0.207843152214, 0.898039275525, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [83.205, 43.46], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-4.879, -30.125],
                        [52.177, -8.451],
                        [4.878, 30.125],
                        [-52.177, 8.454],
                        [-4.882, -30.121],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.58431372549, 0.486274539723, 0.917647118662, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [64.438, 92.871], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Airplane 1/Most-Software-Elements Outlines',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 1,
                  s: [720, 440, 0],
                  to: [23.667, 78.333, 0],
                  ti: [-23.667, -78.333, 0],
                },
                { t: 61, s: [862, 910, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [65, 144, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.05, 0.05, 0.05], y: [1, 1, 1] },
                  o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] },
                  t: 1,
                  s: [0, 0, 100],
                },
                { t: 61, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-47.372, -73.492],
                        [-15.647, 46.09],
                        [47.372, 73.492],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [79.223, 183.382], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [60.672, 123.624],
                        [-34.072, -23.359],
                        [-60.672, -123.624],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.443137284821, 0.580392156863, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [65.923, 133.25], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-11.151, -87.309],
                        [20.573, 32.273],
                        [-20.573, 87.309],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.443137284821, 0.580392156863, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [43.003, 197.2], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [3.878, 137.441],
                        [13.3, -37.177],
                        [-13.3, -137.441],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.364705882353, 0.207843152214, 0.898039275525, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [18.551, 147.068], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-41.87, 132.31],
                        [-2.259, 79.328],
                        [58.407, 105.706],
                        [-58.407, -132.31],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [60.907, 134.81], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 5',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 1,
          op: 901,
          st: 1,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Airplane 2/Most-Software-Elements Outlines',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 2,
                  s: [720, 440, 0],
                  to: [92.667, -10.333, 0],
                  ti: [-92.667, 10.333, 0],
                },
                { t: 62, s: [1276, 378, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [200.5, 86.5, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.05, 0.05, 0.05], y: [1, 1, 1] },
                  o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] },
                  t: 2,
                  s: [0, 0, 100],
                },
                { t: 62, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-101.476, 65.205],
                        [63.551, 21.676],
                        [101.476, -65.205],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [259.077, 67.705], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [170.661, -83.454],
                        [-32.292, 46.956],
                        [-170.661, 83.454],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.443137284821, 0.580392156863, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [189.893, 85.954], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-120.435, 15.093],
                        [44.592, -28.436],
                        [120.435, 28.436],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.443137284821, 0.580392156863, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [278.036, 117.817], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [189.62, -4.906],
                        [-51.25, -18.249],
                        [-189.62, 18.249],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.364705882353, 0.207843152214, 0.898039275525, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [208.852, 151.159], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-182.539, 80.338],
                        [182.539, 58.048],
                        [109.529, 3.299],
                        [146.039, -80.338],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [185.04, 89.72], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 5',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 2,
          op: 902,
          st: 2,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: 'Bolt 1/Most-Software-Elements Outlines',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 3,
                  s: [720, 440, 0],
                  to: [69, 35.333, 0],
                  ti: [-69, -35.333, 0],
                },
                { t: 63, s: [1134, 652, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [65.5, 59.5, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.05, 0.05, 0.05], y: [1, 1, 1] },
                  o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] },
                  t: 3,
                  s: [0, 0, 100],
                },
                { t: 63, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-2.31, 11.038],
                        [2.31, -11.038],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.960784373564, 0.788235353956, 0.850980451995, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [73.602, 61.34], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1.275, 6.096],
                        [-6.097, -1.276],
                        [1.276, -6.096],
                        [6.097, 1.275],
                      ],
                      o: [
                        [1.275, -6.096],
                        [6.096, 1.275],
                        [-1.275, 6.097],
                        [-6.096, -1.275],
                      ],
                      v: [
                        [-11.039, -2.309],
                        [2.309, -11.038],
                        [11.038, 2.309],
                        [-2.31, 11.039],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [73.603, 61.34], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-2.083, 9.96],
                        [-9.959, -2.084],
                        [2.083, -9.96],
                        [9.96, 2.084],
                      ],
                      o: [
                        [2.083, -9.96],
                        [9.96, 2.083],
                        [-2.084, 9.96],
                        [-9.96, -2.083],
                      ],
                      v: [
                        [-18.034, -3.773],
                        [3.772, -18.033],
                        [18.034, 3.773],
                        [-3.773, 18.033],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [73.602, 61.34], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [8.811, 13.473],
                        [0.007, 0.011],
                        [0, 0],
                        [-0.007, -0.01],
                        [13.462, -8.805],
                      ],
                      o: [
                        [-0.007, -0.01],
                        [0, 0],
                        [0.007, 0.011],
                        [8.811, 13.472],
                        [-13.473, 8.811],
                      ],
                      v: [
                        [-28.79, 7.337],
                        [-28.805, 7.316],
                        [19.98, -24.588],
                        [19.994, -24.567],
                        [11.554, 15.777],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.929411824544, 0.098039223166, 0.309803921569, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [77.994, 69.954], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-13.462, 8.804],
                        [-8.815, -13.455],
                      ],
                      o: [
                        [-8.794, -13.469],
                        [13.452, -8.798],
                        [0, 0],
                      ],
                      v: [
                        [-19.995, 24.561],
                        [-11.542, -15.762],
                        [28.789, -7.343],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [69.185, 52.71], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 5',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [22.243, -14.547],
                        [14.557, 22.237],
                        [0, 0],
                        [-13.452, 8.797],
                        [8.793, 13.469],
                      ],
                      o: [
                        [14.536, 22.25],
                        [-22.233, 14.54],
                        [0, 0],
                        [8.814, 13.455],
                        [13.463, -8.805],
                        [0, 0],
                      ],
                      v: [
                        [33.02, -40.582],
                        [19.065, 26.042],
                        [-47.556, 12.114],
                        [-31.665, 1.722],
                        [8.665, 10.141],
                        [17.119, -30.183],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.364705882353, 0.207843152214, 0.898039275525, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [80.882, 75.59], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 6',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [22.254, -14.554],
                        [-14.547, -22.244],
                        [-0.007, -0.011],
                        [0, 0],
                        [0.007, 0.011],
                      ],
                      o: [
                        [-22.243, 14.547],
                        [0.006, 0.01],
                        [0, 0],
                        [-0.007, -0.01],
                        [-14.547, -22.243],
                      ],
                      v: [
                        [-19.083, -26.048],
                        [-33.021, 40.58],
                        [-33.009, 40.601],
                        [47.568, -12.096],
                        [47.554, -12.117],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.800000059838, 0.764705942191, 0.937254961799, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [66.334, 47.104], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 7',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 7,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.146, -0.19],
                        [0.142, 0.196],
                      ],
                      o: [
                        [-0.146, -0.19],
                        [0.142, 0.196],
                      ],
                      v: [
                        [-22.221, 17.126],
                        [-22.66, 16.555],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.125, -0.148],
                        [0.122, 0.152],
                      ],
                      o: [
                        [-0.125, -0.148],
                        [0.122, 0.152],
                      ],
                      v: [
                        [-21.411, 18.132],
                        [-21.788, 17.689],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 2,
                  ty: 'sh',
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.084, -0.091],
                        [0.083, 0.093],
                      ],
                      o: [
                        [-0.084, -0.091],
                        [0.083, 0.093],
                      ],
                      v: [
                        [-20.614, 19.038],
                        [-20.868, 18.767],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 3',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 3,
                  ty: 'sh',
                  ix: 4,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.135, 0.207],
                        [-0.14, -0.202],
                      ],
                      o: [
                        [0.135, 0.206],
                        [-0.14, -0.202],
                      ],
                      v: [
                        [-23.474, 15.378],
                        [-23.056, 15.982],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 4',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 4,
                  ty: 'sh',
                  ix: 5,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.169, -0.363],
                        [0.186, 0.358],
                      ],
                      o: [
                        [-0.168, -0.363],
                        [0.186, 0.358],
                      ],
                      v: [
                        [25.453, -11.786],
                        [24.926, -12.87],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 5',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 5,
                  ty: 'sh',
                  ix: 6,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.157, -0.291],
                        [0.168, 0.288],
                      ],
                      o: [
                        [-0.157, -0.291],
                        [0.168, 0.288],
                      ],
                      v: [
                        [24.719, -13.247],
                        [24.239, -14.118],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 6',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 6,
                  ty: 'sh',
                  ix: 7,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [11.75, -7.684],
                        [5.647, 12.399],
                      ],
                      o: [
                        [11.744, -7.68],
                        [5.647, 12.397],
                      ],
                      v: [
                        [15.35, 23.482],
                        [25.544, -11.6],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 7',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 7,
                  ty: 'sh',
                  ix: 8,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.018, 0.018],
                        [-0.018, -0.018],
                      ],
                      o: [
                        [0.018, 0.018],
                        [-0.018, -0.018],
                      ],
                      v: [
                        [-19.902, 19.792],
                        [-19.848, 19.844],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 8',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 8,
                  ty: 'sh',
                  ix: 9,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-21.403, 13.997],
                        [13.993, 21.419],
                        [0, 0],
                        [-0.14, -0.227],
                        [0.147, 0.225],
                        [0, 0],
                        [0.006, 0.01],
                        [21.422, -14.01],
                        [-14.004, -21.413],
                        [-0.007, -0.01],
                      ],
                      o: [
                        [21.413, -14.004],
                        [0, 0],
                        [0.147, 0.225],
                        [-0.14, -0.227],
                        [0, 0],
                        [-0.007, -0.01],
                        [-14.004, -21.413],
                        [-21.413, 14.004],
                        [0.006, 0.01],
                        [14.013, 21.406],
                      ],
                      v: [
                        [25.361, 38.79],
                        [38.796, -25.346],
                        [23.488, -15.336],
                        [23.909, -14.654],
                        [23.487, -15.335],
                        [38.796, -25.346],
                        [38.783, -25.367],
                        [-25.367, -38.777],
                        [-38.785, 25.362],
                        [-38.772, 25.383],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 9',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 1,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [55.289, 55.288], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 8',
              np: 12,
              cix: 2,
              bm: 0,
              ix: 8,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 3,
          op: 903,
          st: 3,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: 'Bolt 2/Most-Software-Elements Outlines',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 4,
                  s: [720, 440, 0],
                  to: [-103, -61, 0],
                  ti: [103, 61, 0],
                },
                { t: 64, s: [102, 74, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [66.5, 60.5, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.05, 0.05, 0.05], y: [1, 1, 1] },
                  o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] },
                  t: 4,
                  s: [0, 0, 100],
                },
                { t: 64, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-10.714, 3.52],
                        [10.714, -3.52],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.960784373564, 0.788235353956, 0.850980451995, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [57.45, 57.453], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-5.917, 1.944],
                        [-1.944, -5.917],
                        [5.917, -1.944],
                        [1.944, 5.917],
                      ],
                      o: [
                        [5.917, -1.944],
                        [1.944, 5.917],
                        [-5.917, 1.943],
                        [-1.944, -5.918],
                      ],
                      v: [
                        [-3.52, -10.714],
                        [10.715, -3.519],
                        [3.52, 10.715],
                        [-10.715, 3.52],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [57.45, 57.453], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-9.667, 3.175],
                        [-3.176, -9.667],
                        [9.667, -3.176],
                        [3.175, 9.667],
                      ],
                      o: [
                        [9.667, -3.176],
                        [3.175, 9.667],
                        [-9.668, 3.175],
                        [-3.176, -9.667],
                      ],
                      v: [
                        [-5.749, -17.503],
                        [17.505, -5.749],
                        [5.751, 17.505],
                        [-17.503, 5.751],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [57.449, 57.453], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-7.262, 14.367],
                        [-0.006, 0.011],
                        [0, 0],
                        [0.005, -0.011],
                        [14.356, 7.256],
                      ],
                      o: [
                        [0.005, -0.011],
                        [0, 0],
                        [-0.006, 0.011],
                        [-7.262, 14.367],
                        [-14.367, -7.262],
                      ],
                      v: [
                        [-22.385, -23.197],
                        [-22.375, -23.218],
                        [29.647, 3.077],
                        [29.637, 3.1],
                        [-9.522, 15.962],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.929411824544, 0.098039223166, 0.309803921569, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [53.821, 67.495], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-14.356, -7.256],
                        [7.245, -14.361],
                      ],
                      o: [
                        [7.267, -14.35],
                        [14.344, 7.251],
                        [0, 0],
                      ],
                      v: [
                        [-29.633, -3.1],
                        [9.515, -15.941],
                        [22.389, 23.197],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [61.08, 47.376], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 5',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [23.72, 11.99],
                        [-11.978, 23.725],
                        [0, 0],
                        [-14.345, -7.251],
                        [-7.267, 14.35],
                      ],
                      o: [
                        [-12.001, 23.714],
                        [-23.708, -11.984],
                        [0, 0],
                        [-7.245, 14.361],
                        [14.355, 7.257],
                        [0, 0],
                      ],
                      v: [
                        [48.951, 5.108],
                        [-15.725, 26.335],
                        [-36.974, -38.324],
                        [-20.028, -29.759],
                        [-7.153, 9.377],
                        [31.994, -3.462],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.364705882353, 0.207843152214, 0.898039275525, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [51.452, 74.08], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 6',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [23.731, 11.995],
                        [11.99, -23.72],
                        [0.006, -0.011],
                        [0, 0],
                        [-0.005, 0.011],
                      ],
                      o: [
                        [-23.72, -11.99],
                        [-0.005, 0.011],
                        [0, 0],
                        [0.006, -0.011],
                        [11.99, -23.72],
                      ],
                      v: [
                        [15.719, -26.354],
                        [-48.952, -5.111],
                        [-48.963, -5.089],
                        [36.962, 38.344],
                        [36.973, 38.322],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.800000059838, 0.764705942191, 0.937254961799, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [63.441, 40.844], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 7',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 7,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.203, 0.317],
                        [0.214, -0.307],
                      ],
                      o: [
                        [-0.203, 0.317],
                        [0.214, -0.307],
                      ],
                      v: [
                        [23.607, 15.152],
                        [22.977, 16.085],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.152, 0.266],
                        [0.159, -0.26],
                      ],
                      o: [
                        [-0.152, 0.266],
                        [0.159, -0.26],
                      ],
                      v: [
                        [24.345, 13.933],
                        [23.872, 14.715],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 2,
                  ty: 'sh',
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [12.529, 6.333],
                        [-7.914, 11.088],
                      ],
                      o: [
                        [12.523, 6.33],
                        [-7.913, 11.088],
                      ],
                      v: [
                        [-12.662, 25.035],
                        [22.816, 16.322],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 3',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 3,
                  ty: 'sh',
                  ix: 4,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.072, -0.202],
                        [-0.076, 0.2],
                      ],
                      o: [
                        [0.072, -0.202],
                        [-0.076, 0.2],
                      ],
                      v: [
                        [-26.428, -9.423],
                        [-26.214, -10.027],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 4',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 4,
                  ty: 'sh',
                  ix: 5,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.04, -0.13],
                        [-0.043, 0.13],
                      ],
                      o: [
                        [0.04, -0.13],
                        [-0.043, 0.13],
                      ],
                      v: [
                        [-26.805, -8.298],
                        [-26.688, -8.691],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 5',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 5,
                  ty: 'sh',
                  ix: 6,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.009, -0.031],
                        [-0.008, 0.031],
                      ],
                      o: [
                        [0.009, -0.031],
                        [-0.008, 0.031],
                      ],
                      v: [
                        [-27.115, -7.248],
                        [-27.092, -7.34],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 6',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 6,
                  ty: 'sh',
                  ix: 7,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.097, -0.238],
                        [-0.104, 0.236],
                      ],
                      o: [
                        [0.097, -0.238],
                        [-0.104, 0.236],
                      ],
                      v: [
                        [-25.962, -10.636],
                        [-25.668, -11.348],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 7',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 7,
                  ty: 'sh',
                  ix: 8,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.118, -0.219],
                        [-0.113, 0.223],
                      ],
                      o: [
                        [-0.113, 0.223],
                        [0.118, -0.219],
                        [0, 0],
                      ],
                      v: [
                        [25.024, 12.673],
                        [24.67, 13.329],
                        [25.023, 12.673],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 8',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 8,
                  ty: 'sh',
                  ix: 9,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-11.552, 22.828],
                        [-0.005, 0.011],
                        [22.844, 11.548],
                        [11.542, -22.834],
                        [0.006, -0.011],
                        [0, 0],
                        [0.108, -0.229],
                        [-0.114, 0.228],
                        [0, 0],
                        [-22.824, -11.536],
                      ],
                      o: [
                        [0.006, -0.011],
                        [11.542, -22.834],
                        [-22.834, -11.542],
                        [-0.005, 0.01],
                        [0, 0],
                        [-0.115, 0.227],
                        [0.108, -0.229],
                        [0, 0],
                        [-11.531, 22.84],
                        [22.834, 11.543],
                      ],
                      v: [
                        [41.347, 20.924],
                        [41.358, 20.902],
                        [20.898, -41.358],
                        [-41.358, -20.908],
                        [-41.369, -20.887],
                        [-25.055, -12.641],
                        [-25.381, -11.954],
                        [-25.056, -12.642],
                        [-41.369, -20.887],
                        [-20.913, 41.358],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 9',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 1,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [77.123, 64.683], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 8',
              np: 12,
              cix: 2,
              bm: 0,
              ix: 8,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 4,
          op: 904,
          st: 4,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: 'Chart 1/Most-Software-Elements Outlines',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 5,
                  s: [720, 440, 0],
                  to: [107.667, -46.333, 0],
                  ti: [-107.667, 46.333, 0],
                },
                { t: 65, s: [1366, 162, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [69, 64.5, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.05, 0.05, 0.05], y: [1, 1, 1] },
                  o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] },
                  t: 5,
                  s: [0, 0, 100],
                },
                { t: 65, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [3.315, 0.694],
                        [0, 0],
                        [-0.693, 3.316],
                        [0, 0],
                        [-3.315, -0.694],
                        [0, 0],
                        [0.693, -3.316],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-3.315, -0.694],
                        [0, 0],
                        [0.693, -3.315],
                        [0, 0],
                        [3.315, 0.694],
                        [0, 0],
                        [-0.694, 3.315],
                      ],
                      v: [
                        [-2.979, 18.109],
                        [-4.53, 17.785],
                        [-9.276, 10.526],
                        [-4.278, -13.362],
                        [2.979, -18.109],
                        [4.53, -17.785],
                        [9.276, -10.526],
                        [4.28, 13.362],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.58431372549, 0.486274539723, 0.917647118662, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [97.703, 69.998], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [3.315, 0.694],
                        [0, 0],
                        [-0.693, 3.316],
                        [0, 0],
                        [-3.316, -0.693],
                        [0, 0],
                        [0.694, -3.315],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-3.315, -0.694],
                        [0, 0],
                        [0.694, -3.315],
                        [0, 0],
                        [3.315, 0.694],
                        [0, 0],
                        [-0.694, 3.315],
                      ],
                      v: [
                        [-4.133, 23.621],
                        [-5.683, 23.297],
                        [-10.43, 16.038],
                        [-3.126, -18.875],
                        [4.133, -23.622],
                        [5.682, -23.298],
                        [10.429, -16.039],
                        [3.126, 18.874],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.364705882353, 0.207843152214, 0.898039275525, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [77.831, 60.088], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [3.315, 0.693],
                        [0, 0],
                        [-0.693, 3.316],
                        [0, 0],
                        [-3.316, -0.694],
                        [0, 0],
                        [0.694, -3.315],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-3.315, -0.694],
                        [0, 0],
                        [0.694, -3.316],
                        [0, 0],
                        [3.315, 0.693],
                        [0, 0],
                        [-0.694, 3.315],
                      ],
                      v: [
                        [-2.128, 14.035],
                        [-3.678, 13.711],
                        [-8.424, 6.452],
                        [-5.132, -9.287],
                        [2.128, -14.034],
                        [3.676, -13.71],
                        [8.424, -6.452],
                        [5.132, 9.288],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.929411824544, 0.098039223166, 0.309803921569, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [54.801, 65.275], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.529, 2.529],
                        [0, 0],
                        [-2.528, -0.529],
                        [0, 0],
                        [0.529, -2.527],
                        [0, 0],
                        [2.529, 0.529],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0.529, -2.529],
                        [0, 0],
                        [2.528, 0.529],
                        [0, 0],
                        [-0.529, 2.529],
                        [0, 0],
                        [-2.529, -0.529],
                      ],
                      v: [
                        [-44.578, 23.275],
                        [-31.51, -39.192],
                        [-25.974, -42.813],
                        [40.959, -28.811],
                        [44.578, -23.277],
                        [31.51, 39.192],
                        [25.974, 42.813],
                        [-40.957, 28.811],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.960784373564, 0.788235353956, 0.850980451995, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [78.497, 56.906], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [10.859, 2.272],
                        [0, 0],
                        [2.272, -10.859],
                        [0, 0],
                        [-10.86, -2.271],
                        [0, 0],
                        [-2.272, 10.859],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-10.859, -2.271],
                        [0, 0],
                        [-2.272, 10.86],
                        [0, 0],
                        [10.86, 2.272],
                        [0, 0],
                        [2.272, -10.86],
                      ],
                      v: [
                        [38.35, -40.127],
                        [-19.05, -52.135],
                        [-42.826, -36.586],
                        [-53.899, 16.35],
                        [-38.35, 40.126],
                        [19.049, 52.134],
                        [42.825, 36.585],
                        [53.899, -16.351],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [78.497, 56.906], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 5',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [10.859, 2.272],
                        [0, 0],
                        [2.272, -10.859],
                        [0, 0],
                        [-10.86, -2.271],
                        [0, 0],
                        [-2.272, 10.859],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-10.859, -2.271],
                        [0, 0],
                        [-2.271, 10.86],
                        [0, 0],
                        [10.86, 2.272],
                        [0, 0],
                        [2.272, -10.86],
                      ],
                      v: [
                        [38.35, -40.127],
                        [-19.05, -52.135],
                        [-42.826, -36.586],
                        [-53.9, 16.35],
                        [-38.35, 40.126],
                        [19.049, 52.134],
                        [42.825, 36.585],
                        [53.899, -16.351],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.364705882353, 0.207843152214, 0.898039275525, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [76.679, 65.597], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 6',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1.496, 7.15],
                        [0, 0],
                        [0, 0],
                        [7.151, 1.496],
                        [0, 0],
                        [1.496, -7.151],
                        [0, 0],
                        [0, 0],
                        [-7.15, -1.496],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [1.496, -7.151],
                        [0, 0],
                        [-7.15, -1.495],
                        [0, 0],
                        [0, 0],
                        [-1.496, 7.151],
                        [0, 0],
                        [7.151, 1.495],
                      ],
                      v: [
                        [39.1, 45.383],
                        [40.85, 37.017],
                        [54.013, -25.905],
                        [43.774, -41.56],
                        [-23.445, -55.622],
                        [-39.1, -45.383],
                        [-40.905, -36.758],
                        [-54.013, 25.904],
                        [-43.775, 41.56],
                        [23.444, 55.622],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [58.009, 68.404], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 7',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 7,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 5,
          op: 905,
          st: 5,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: 'Chart 2/Most-Software-Elements Outlines',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 6,
                  s: [720, 440, 0],
                  to: [-102.667, -28, 0],
                  ti: [102.667, 28, 0],
                },
                { t: 66, s: [104, 272, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [74.5, 65, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.05, 0.05, 0.05], y: [1, 1, 1] },
                  o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] },
                  t: 6,
                  s: [0, 0, 100],
                },
                { t: 66, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [3.218, -1.057],
                        [0, 0],
                        [1.057, 3.218],
                        [0, 0],
                        [-3.218, 1.057],
                        [0, 0],
                        [-1.057, -3.218],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-3.218, 1.057],
                        [0, 0],
                        [-1.057, -3.218],
                        [0, 0],
                        [3.218, -1.057],
                        [0, 0],
                        [1.057, 3.218],
                      ],
                      v: [
                        [6.474, 17.173],
                        [4.97, 17.667],
                        [-2.77, 13.754],
                        [-10.387, -9.432],
                        [-6.474, -17.172],
                        [-4.97, -17.667],
                        [2.77, -13.754],
                        [10.387, 9.433],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.58431372549, 0.486274539723, 0.917647118662, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [84.522, 61.623], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [3.218, -1.057],
                        [0, 0],
                        [1.057, 3.218],
                        [0, 0],
                        [-3.218, 1.057],
                        [0, 0],
                        [-1.057, -3.218],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-3.218, 1.057],
                        [0, 0],
                        [-1.057, -3.218],
                        [0, 0],
                        [3.218, -1.057],
                        [0, 0],
                        [1.057, 3.218],
                      ],
                      v: [
                        [8.232, 22.523],
                        [6.728, 23.017],
                        [-1.013, 19.104],
                        [-12.145, -14.783],
                        [-8.232, -22.523],
                        [-6.728, -23.017],
                        [1.013, -19.105],
                        [12.145, 14.783],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.364705882353, 0.207843152214, 0.898039275525, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [62.357, 62.976], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [3.218, -1.057],
                        [0, 0],
                        [1.057, 3.217],
                        [0, 0],
                        [-3.218, 1.057],
                        [0, 0],
                        [-1.057, -3.218],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-3.218, 1.057],
                        [0, 0],
                        [-1.057, -3.218],
                        [0, 0],
                        [3.218, -1.057],
                        [0, 0],
                        [1.057, 3.218],
                      ],
                      v: [
                        [5.175, 13.218],
                        [3.671, 13.712],
                        [-4.069, 9.8],
                        [-9.088, -5.478],
                        [-5.175, -13.218],
                        [-3.671, -13.712],
                        [4.069, -9.799],
                        [9.088, 5.478],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.929411824544, 0.098039223166, 0.309803921569, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [45.006, 78.985], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.806, 2.454],
                        [0, 0],
                        [-2.453, 0.806],
                        [0, 0],
                        [-0.806, -2.454],
                        [0, 0],
                        [2.454, -0.806],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-0.806, -2.453],
                        [0, 0],
                        [2.454, -0.806],
                        [0, 0],
                        [0.806, 2.454],
                        [0, 0],
                        [-2.455, 0.806],
                      ],
                      v: [
                        [-26.968, 42.446],
                        [-46.885, -18.188],
                        [-43.902, -24.09],
                        [21.066, -45.43],
                        [26.968, -42.447],
                        [46.885, 18.187],
                        [43.901, 24.09],
                        [-21.064, 45.43],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.960784373564, 0.788235353956, 0.850980451995, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [61.343, 59.888], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [10.54, -3.462],
                        [0, 0],
                        [-3.463, -10.54],
                        [0, 0],
                        [-10.54, 3.462],
                        [0, 0],
                        [3.462, 10.54],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-10.541, 3.462],
                        [0, 0],
                        [3.463, 10.54],
                        [0, 0],
                        [10.54, -3.463],
                        [0, 0],
                        [-3.462, -10.54],
                      ],
                      v: [
                        [13.149, -53.926],
                        [-42.564, -35.625],
                        [-55.38, -10.271],
                        [-38.503, 41.11],
                        [-13.149, 53.926],
                        [42.565, 35.625],
                        [55.381, 10.271],
                        [38.503, -41.11],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [61.343, 59.888], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 5',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [10.54, -3.462],
                        [0, 0],
                        [-3.463, -10.541],
                        [0, 0],
                        [-10.54, 3.463],
                        [0, 0],
                        [3.462, 10.54],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-10.541, 3.462],
                        [0, 0],
                        [3.463, 10.54],
                        [0, 0],
                        [10.54, -3.463],
                        [0, 0],
                        [-3.462, -10.54],
                      ],
                      v: [
                        [13.149, -53.926],
                        [-42.564, -35.625],
                        [-55.38, -10.271],
                        [-38.503, 41.11],
                        [-13.149, 53.925],
                        [42.565, 35.625],
                        [55.381, 10.271],
                        [38.503, -41.11],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.364705882353, 0.207843152214, 0.898039275525, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [64.114, 68.323], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 6',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, -0.001],
                        [0, 0],
                        [-6.94, 2.28],
                        [0, 0],
                        [2.279, 6.94],
                        [0, 0],
                        [0, 0],
                        [6.94, -2.28],
                        [0, 0],
                        [-2.28, -6.94],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [2.28, 6.941],
                        [0, 0],
                        [6.94, -2.279],
                        [0, 0],
                        [0, 0],
                        [-2.28, -6.941],
                        [0, 0],
                        [-6.941, 2.28],
                        [0, 0],
                      ],
                      v: [
                        [-53.886, -11.634],
                        [-53.886, -11.633],
                        [-33.824, 49.44],
                        [-17.13, 57.879],
                        [48.115, 36.447],
                        [56.554, 19.754],
                        [36.492, -41.321],
                        [33.825, -49.441],
                        [17.13, -57.879],
                        [-48.114, -36.448],
                        [-56.553, -19.754],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [87.208, 67.007], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 7',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 7,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 6,
          op: 906,
          st: 6,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: 'Cube 2/Most-Software-Elements Outlines',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 7,
                  s: [720, 440, 0],
                  to: [-73.333, -40, 0],
                  ti: [73.333, 40, 0],
                },
                { t: 67, s: [280, 200, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [68, 62, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.05, 0.05, 0.05], y: [1, 1, 1] },
                  o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] },
                  t: 7,
                  s: [0, 0, 100],
                },
                { t: 67, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [50.128, -16.764],
                        [9.677, 28.941],
                        [-50.128, 16.764],
                        [-9.677, -28.941],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.443137284821, 0.580392156863, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [52.628, 31.441], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [10.544, -51.795],
                        [29.904, 6.088],
                        [-10.547, 51.795],
                        [-29.901, -6.089],
                        [-29.904, -6.091],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.364705882353, 0.207843152214, 0.898039275525, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [92.211, 66.472], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [20.228, -22.853],
                        [39.582, 35.03],
                        [-20.225, 22.85],
                        [-39.582, -35.03],
                        [20.223, -22.853],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.58431372549, 0.486274539723, 0.917647118662, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [42.082, 83.236], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-38.94, 43.998],
                        [18.634, 55.722],
                        [57.574, 11.722],
                        [38.938, -44],
                        [-0.001, -0.002],
                        [0.001, 0],
                        [-0.002, 0],
                        [38.938, -44],
                        [-18.634, -55.722],
                        [-57.574, -11.722],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [75.231, 65.003], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 7,
          op: 907,
          st: 7,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 4,
          nm: 'Cylinder 1/Most-Software-Elements Outlines',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 8,
                  s: [720, 440, 0],
                  to: [62.333, -21.333, 0],
                  ti: [-62.333, 21.333, 0],
                },
                { t: 68, s: [1094, 312, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [47, 30, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.05, 0.05, 0.05], y: [1, 1, 1] },
                  o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] },
                  t: 8,
                  s: [0, 0, 100],
                },
                { t: 68, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-3.165, -6.106],
                        [4.639, -2.405],
                        [3.166, 6.106],
                        [-4.639, 2.405],
                      ],
                      o: [
                        [3.165, 6.106],
                        [-4.639, 2.405],
                        [-3.165, -6.106],
                        [4.639, -2.405],
                      ],
                      v: [
                        [8.399, -4.354],
                        [5.732, 11.055],
                        [-8.399, 4.354],
                        [-5.732, -11.055],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [29.654, 41.831], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [4.639, -2.405],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [3.165, 6.106],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [4.639, -2.404],
                        [-3.166, -6.106],
                      ],
                      v: [
                        [16.303, -22.788],
                        [-33.599, 3.082],
                        [-22.136, 25.193],
                        [27.766, -0.677],
                        [30.434, -16.087],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.443137284821, 0.580392156863, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [57.521, 27.693], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [3.047, 5.878],
                        [4.466, -2.315],
                        [0, 0],
                        [0, 0],
                        [-3.047, -5.878],
                        [-4.465, 2.315],
                        [0, 0],
                      ],
                      o: [
                        [4.466, -2.315],
                        [-3.047, -5.878],
                        [0, 0],
                        [0, 0],
                        [-4.465, 2.315],
                        [3.047, 5.878],
                        [0.001, 0],
                        [0, 0],
                      ],
                      v: [
                        [29.537, -1.809],
                        [32.105, -16.644],
                        [18.502, -23.095],
                        [-29.536, 1.809],
                        [-29.537, 1.809],
                        [-32.105, 16.644],
                        [-18.502, 23.095],
                        [-18.501, 23.095],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [37.652, 32.077], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 8,
          op: 908,
          st: 8,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 10,
          ty: 4,
          nm: 'Cylinder 2/Most-Software-Elements Outlines',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 9,
                  s: [720, 440, 0],
                  to: [-80.333, -9.667, 0],
                  ti: [80.333, 9.667, 0],
                },
                { t: 69, s: [238, 382, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [36, 40, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.05, 0.05, 0.05], y: [1, 1, 1] },
                  o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] },
                  t: 9,
                  s: [0, 0, 100],
                },
                { t: 69, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-6.388, 2.549],
                        [-1.936, -4.853],
                        [6.389, -2.549],
                        [1.936, 4.853],
                      ],
                      o: [
                        [6.388, -2.548],
                        [1.936, 4.853],
                        [-6.388, 2.548],
                        [-1.936, -4.853],
                      ],
                      v: [
                        [-3.505, -8.788],
                        [11.566, -4.615],
                        [3.505, 8.788],
                        [-11.566, 4.615],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [36.83, 66.043], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1.936, -4.853],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [6.388, -2.548],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.936, -4.853],
                        [-6.388, 2.549],
                      ],
                      v: [
                        [-21.012, -18.129],
                        [-0.184, 34.079],
                        [22.949, 24.85],
                        [2.12, -27.358],
                        [-12.951, -31.531],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.443137284821, 0.580392156863, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [25.448, 36.579], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [6.15, -2.454],
                        [-1.864, -4.672],
                        [0, 0],
                        [0, 0],
                        [-6.15, 2.453],
                        [1.863, 4.672],
                        [0, 0],
                      ],
                      o: [
                        [-1.864, -4.672],
                        [-6.149, 2.453],
                        [0, 0],
                        [0, 0],
                        [1.864, 4.672],
                        [6.149, -2.453],
                        [-0.001, 0],
                        [0, 0],
                      ],
                      v: [
                        [1.109, -29.571],
                        [-13.401, -33.588],
                        [-21.16, -20.687],
                        [-1.109, 29.571],
                        [-1.109, 29.572],
                        [13.401, 33.589],
                        [21.16, 20.687],
                        [21.159, 20.687],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [46.088, 39.462], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 9,
          op: 909,
          st: 9,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 11,
          ty: 4,
          nm: 'Fuel/Most-Software-Elements Outlines',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 10,
                  s: [720, 440, 0],
                  to: [85.667, -29.333, 0],
                  ti: [-85.667, 29.333, 0],
                },
                { t: 70, s: [1234, 264, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [63, 54, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.05, 0.05, 0.05], y: [1, 1, 1] },
                  o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] },
                  t: 10,
                  s: [0, 0, 100],
                },
                { t: 70, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [15.788, -5.769],
                        [6.747, 2.735],
                        [0, 0],
                        [-5.277, 1.928],
                        [4.129, 11.301],
                      ],
                      o: [
                        [5.769, 15.788],
                        [-7.371, 2.693],
                        [0, 0],
                        [4.827, 1.958],
                        [11.301, -4.13],
                        [0, 0],
                      ],
                      v: [
                        [17.116, -20.862],
                        [-1.024, 18.168],
                        [-22.885, 17.793],
                        [-19.639, 9.774],
                        [-3.992, 10.047],
                        [8.994, -17.893],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.58431372549, 0.486274539723, 0.917647118662, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [85.483, 61.918], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.67, 1.833],
                        [-1.834, 0.67],
                        [-0.67, -1.834],
                        [1.833, -0.67],
                      ],
                      o: [
                        [-0.67, -1.833],
                        [1.833, -0.67],
                        [0.67, 1.833],
                        [-1.834, 0.67],
                      ],
                      v: [
                        [-3.32, 1.213],
                        [-1.213, -3.32],
                        [3.32, -1.213],
                        [1.213, 3.32],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.929411824544, 0.098039223166, 0.309803921569, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [74.016, 51.501], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.321, 0.385],
                        [0, 0],
                        [-0.005, -0.677],
                        [0, 0],
                        [0.298, -0.403],
                        [-1.411, -2.775],
                        [-3.435, 0.836],
                        [1.566, 4.287],
                        [2.443, 0.58],
                      ],
                      o: [
                        [0, 0],
                        [-0.434, -0.52],
                        [0, 0],
                        [0.003, 0.501],
                        [-1.669, 2.255],
                        [1.602, 3.151],
                        [4.565, -1.113],
                        [-0.927, -2.535],
                        [-0.488, -0.116],
                      ],
                      v: [
                        [0.259, -5.028],
                        [-4.79, -11.095],
                        [-6.066, -10.629],
                        [-6.015, -2.734],
                        [-6.481, -1.349],
                        [-7.149, 6.792],
                        [1.53, 10.779],
                        [6.994, 0.602],
                        [1.51, -4.268],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [74.286, 48.246], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-2.711, 0.991],
                        [2.711, -0.991],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [48.143, 60.955], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-2.617, -1.216],
                        [2.617, 1.216],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [49.035, 39.892], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 5',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-0.99, -2.711],
                        [0.99, 2.711],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [64.561, 25.629], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 6',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-5.768, -15.785],
                        [0, 0],
                        [11.301, -4.13],
                        [4.827, 1.958],
                        [0, 0],
                        [3.074, 8.413],
                        [-15.784, 5.768],
                      ],
                      o: [
                        [0, 0],
                        [4.129, 11.301],
                        [-5.277, 1.928],
                        [0, 0],
                        [-7.693, -3.119],
                        [-5.768, -15.785],
                        [15.788, -5.769],
                      ],
                      v: [
                        [31.467, -7.373],
                        [23.345, -4.405],
                        [10.359, 23.535],
                        [-5.288, 23.263],
                        [-8.534, 31.282],
                        [-25.699, 13.516],
                        [-7.563, -25.513],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.929411824544, 0.098039223166, 0.309803921569, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [71.132, 48.43], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 7',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 7,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [7.143, 19.55],
                        [-19.55, 7.143],
                        [-7.144, -19.549],
                        [19.55, -7.143],
                      ],
                      o: [
                        [-7.143, -19.55],
                        [19.549, -7.144],
                        [7.143, 19.55],
                        [-19.55, 7.144],
                      ],
                      v: [
                        [-35.398, 12.935],
                        [-12.934, -35.398],
                        [35.398, -12.935],
                        [12.934, 35.398],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.364705882353, 0.207843152214, 0.898039275525, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [74.016, 51.501], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 8',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 8,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [8.228, 22.518],
                        [-22.519, 8.229],
                        [-8.228, -22.518],
                        [22.518, -8.229],
                      ],
                      o: [
                        [-8.228, -22.518],
                        [22.518, -8.228],
                        [8.229, 22.518],
                        [-22.519, 8.228],
                      ],
                      v: [
                        [-40.773, 14.898],
                        [-14.898, -40.773],
                        [40.773, -14.898],
                        [14.898, 40.773],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.443137284821, 0.580392156863, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [74.016, 51.502], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 9',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 9,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [21.678, -7.921],
                        [-7.921, -21.677],
                        [-21.678, 7.921],
                        [7.921, 21.677],
                      ],
                      o: [
                        [-21.678, 7.921],
                        [7.922, 21.678],
                        [21.677, -7.921],
                        [-7.921, -21.678],
                      ],
                      v: [
                        [-14.342, -39.251],
                        [-39.251, 14.342],
                        [14.343, 39.251],
                        [39.251, -14.342],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [49.672, 58.328], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 10',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 10,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 10,
          op: 910,
          st: 10,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 12,
          ty: 4,
          nm: 'Gear 1/Most-Software-Elements Outlines',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 11,
                  s: [720, 440, 0],
                  to: [75.333, -12.333, 0],
                  ti: [-75.333, 12.333, 0],
                },
                { t: 71, s: [1172, 366, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [38.5, 34, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.05, 0.05, 0.05], y: [1, 1, 1] },
                  o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] },
                  t: 11,
                  s: [0, 0, 100],
                },
                { t: 71, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.387, -6.633],
                        [6.633, 1.388],
                        [-1.388, 6.633],
                        [-6.633, -1.388],
                      ],
                      o: [
                        [-1.388, 6.633],
                        [-6.633, -1.387],
                        [1.387, -6.633],
                        [6.632, 1.387],
                      ],
                      v: [
                        [12.01, 2.512],
                        [-2.512, 12.009],
                        [-12.009, -2.513],
                        [2.513, -12.009],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.58431372549, 0.486274539723, 0.917647118662, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [45.191, 31.126], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [2.054, -2.167],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [3.071, -0.082],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [2.167, 2.054],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.082, 3.071],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-2.053, 2.167],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-3.067, 0.083],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-2.167, -2.053],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.082, -3.07],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-1.306, 2.776],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-2.809, 1.013],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-2.78, -1.307],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.013, -2.808],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.306, -2.78],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [2.809, -1.013],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [2.78, 1.306],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.013, 2.809],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [20.956, 7.798],
                        [25.9, 12.223],
                        [20.813, 19.668],
                        [14.894, 16.679],
                        [9.306, 20.333],
                        [9.676, 26.952],
                        [0.807, 28.626],
                        [-1.261, 22.329],
                        [-7.798, 20.96],
                        [-12.218, 25.902],
                        [-19.668, 20.814],
                        [-16.679, 14.897],
                        [-20.334, 9.309],
                        [-26.951, 9.676],
                        [-28.626, 0.811],
                        [-22.328, -1.257],
                        [-20.96, -7.795],
                        [-25.901, -12.214],
                        [-20.813, -19.668],
                        [-14.898, -16.675],
                        [-9.31, -20.329],
                        [-9.676, -26.951],
                        [-0.815, -28.626],
                        [1.256, -22.324],
                        [7.794, -20.956],
                        [12.218, -25.9],
                        [19.668, -20.813],
                        [16.675, -14.894],
                        [20.33, -9.306],
                        [26.951, -9.676],
                        [28.626, -0.811],
                        [22.324, 1.26],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.364705882353, 0.207843152214, 0.898039275525, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [45.192, 31.125], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [-1.257, 2.673],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.975, 2.704],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [2.676, 1.257],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [2.703, -0.975],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.258, -2.676],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.975, -2.704],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-2.676, -1.258],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-2.703, 0.975],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [1.977, -2.086],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.079, -2.955],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-2.086, -1.976],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-2.952, 0.08],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.977, 2.087],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.08, 2.956],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [2.086, 1.977],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [2.956, -0.079],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [14.338, 16.057],
                        [20.036, 18.935],
                        [24.933, 11.766],
                        [20.174, 7.508],
                        [21.491, 1.214],
                        [27.557, -0.78],
                        [25.946, -9.314],
                        [19.571, -8.958],
                        [16.053, -14.337],
                        [18.934, -20.035],
                        [11.762, -24.932],
                        [7.504, -20.173],
                        [1.21, -21.49],
                        [-0.784, -27.557],
                        [-9.314, -25.944],
                        [-8.962, -19.57],
                        [-14.341, -16.052],
                        [-20.035, -18.933],
                        [-24.934, -11.757],
                        [-20.177, -7.503],
                        [-21.494, -1.209],
                        [-27.557, 0.782],
                        [-25.945, 9.316],
                        [-19.574, 8.963],
                        [-16.056, 14.342],
                        [-18.933, 20.037],
                        [-11.761, 24.935],
                        [-7.507, 20.178],
                        [-1.213, 21.495],
                        [0.778, 27.557],
                        [9.315, 25.946],
                        [8.958, 19.575],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [30.057, 37.267], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 11,
          op: 911,
          st: 11,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 13,
          ty: 4,
          nm: 'Gear 2/Most-Software-Elements Outlines',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 12,
                  s: [720, 440, 0],
                  to: [-9, 53, 0],
                  ti: [9, -53, 0],
                },
                { t: 72, s: [666, 758, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [31.5, 35, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.05, 0.05, 0.05], y: [1, 1, 1] },
                  o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] },
                  t: 12,
                  s: [0, 0, 100],
                },
                { t: 72, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -6.776],
                        [6.777, 0],
                        [0, 6.776],
                        [-6.776, 0],
                      ],
                      o: [
                        [0, 6.776],
                        [-6.776, 0],
                        [0, -6.776],
                        [6.777, 0],
                      ],
                      v: [
                        [12.27, 0],
                        [-0.001, 12.27],
                        [-12.27, 0],
                        [-0.001, -12.27],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.58431372549, 0.486274539723, 0.917647118662, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [30.354, 38.792], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [1.567, -2.542],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [2.989, -0.71],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [2.541, 1.566],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.709, 2.988],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.566, 2.541],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-2.985, 0.709],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-2.542, -1.566],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.71, -2.989],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-0.71, 2.985],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-2.542, 1.566],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-2.989, -0.71],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.566, -2.542],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.709, -2.989],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [2.541, -1.566],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [2.988, 0.709],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.567, 2.541],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [22.108, 3.342],
                        [27.853, 6.658],
                        [24.398, 14.99],
                        [17.993, 13.275],
                        [13.272, 17.997],
                        [14.989, 24.4],
                        [6.652, 27.854],
                        [3.337, 22.113],
                        [-3.342, 22.113],
                        [-6.655, 27.854],
                        [-14.99, 24.4],
                        [-13.275, 17.997],
                        [-17.996, 13.275],
                        [-24.4, 14.99],
                        [-27.853, 6.656],
                        [-22.113, 3.342],
                        [-22.113, -3.337],
                        [-27.853, -6.652],
                        [-24.4, -14.989],
                        [-17.996, -13.271],
                        [-13.275, -17.993],
                        [-14.99, -24.399],
                        [-6.66, -27.854],
                        [-3.342, -22.109],
                        [3.337, -22.109],
                        [6.655, -27.854],
                        [14.989, -24.399],
                        [13.272, -17.993],
                        [17.993, -13.271],
                        [24.398, -14.989],
                        [27.853, -6.656],
                        [22.108, -3.337],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.364705882353, 0.207843152214, 0.898039275525, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [30.354, 38.792], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [1.508, 2.447],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [2.877, 0.682],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [2.446, -1.508],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.683, -2.878],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.508, -2.447],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-2.877, -0.683],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-2.447, 1.508],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.683, 2.874],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [-0.683, -2.877],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-2.447, -1.508],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-2.873, 0.682],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.508, 2.447],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.683, 2.877],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [2.446, 1.508],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [2.877, -0.683],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.508, -2.447],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [26.814, -6.407],
                        [23.488, -14.43],
                        [17.322, -12.776],
                        [12.777, -17.321],
                        [14.431, -23.488],
                        [6.408, -26.813],
                        [3.214, -21.283],
                        [-3.217, -21.283],
                        [-6.41, -26.813],
                        [-14.429, -23.488],
                        [-12.78, -17.321],
                        [-17.324, -12.776],
                        [-23.488, -14.43],
                        [-26.814, -6.403],
                        [-21.286, -3.213],
                        [-21.286, 3.217],
                        [-26.814, 6.407],
                        [-23.488, 14.43],
                        [-17.324, 12.78],
                        [-12.78, 17.325],
                        [-14.429, 23.488],
                        [-6.407, 26.813],
                        [-3.217, 21.287],
                        [3.214, 21.287],
                        [6.404, 26.813],
                        [14.431, 23.488],
                        [12.777, 17.325],
                        [17.322, 12.78],
                        [23.488, 14.43],
                        [26.814, 6.41],
                        [21.283, 3.217],
                        [21.283, -3.213],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [33.468, 29.313], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 12,
          op: 912,
          st: 12,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 14,
          ty: 4,
          nm: 'Half Sphere 1/Most-Software-Elements Outlines',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 13,
                  s: [720, 440, 0],
                  to: [82.667, 18.333, 0],
                  ti: [-82.667, -18.333, 0],
                },
                { t: 73, s: [1216, 550, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [42, 36.5, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.05, 0.05, 0.05], y: [1, 1, 1] },
                  o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] },
                  t: 13,
                  s: [0, 0, 100],
                },
                { t: 73, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [4.177, -2.688],
                        [-9.714, -15.094],
                        [-4.176, 2.689],
                        [9.715, 15.095],
                      ],
                      o: [
                        [-4.177, 2.688],
                        [9.715, 15.094],
                        [4.177, -2.688],
                        [-9.714, -15.093],
                      ],
                      v: [
                        [-17.589, -27.33],
                        [-7.562, 4.867],
                        [17.589, 27.329],
                        [7.562, -4.868],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.960784373564, 0.788235353956, 0.850980451995, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [58.748, 32.518], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-4.177, 2.688],
                        [-9.714, -15.093],
                        [-15.093, 9.715],
                        [9.715, 15.094],
                      ],
                      o: [
                        [-15.094, 9.714],
                        [9.715, 15.095],
                        [-4.176, 2.689],
                        [-9.714, -15.094],
                      ],
                      v: [
                        [-7.862, -32.187],
                        [-17.602, 12.732],
                        [27.316, 22.472],
                        [2.164, 0.01],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.443137284821, 0.580392156863, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [49.021, 37.375], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [4.021, -2.588],
                        [-9.352, -14.53],
                        [-14.53, 9.351],
                        [9.352, 14.531],
                      ],
                      o: [
                        [-14.53, 9.352],
                        [9.352, 14.531],
                        [4.021, -2.588],
                        [-9.352, -14.53],
                      ],
                      v: [
                        [-9.579, -29.691],
                        [-18.955, 13.551],
                        [24.286, 22.928],
                        [14.634, -8.067],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [30.807, 35.171], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 13,
          op: 913,
          st: 13,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 15,
          ty: 4,
          nm: 'Half Sphere 2/Most-Software-Elements Outlines',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 14,
                  s: [720, 440, 0],
                  to: [-61.667, -63, 0],
                  ti: [61.667, 63, 0],
                },
                { t: 74, s: [350, 62, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [40, 41.5, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.05, 0.05, 0.05], y: [1, 1, 1] },
                  o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] },
                  t: 14,
                  s: [0, 0, 100],
                },
                { t: 74, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-3.934, -3.032],
                        [-10.957, 14.217],
                        [3.934, 3.032],
                        [10.957, -14.218],
                      ],
                      o: [
                        [3.934, 3.032],
                        [10.957, -14.218],
                        [-3.934, -3.032],
                        [-10.957, 14.217],
                      ],
                      v: [
                        [-19.84, 25.743],
                        [7.124, 5.49],
                        [19.84, -25.743],
                        [-7.124, -5.49],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.960784373564, 0.788235353956, 0.850980451995, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [26.274, 31.275], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [3.934, 3.032],
                        [-10.957, 14.218],
                        [14.217, 10.957],
                        [10.957, -14.218],
                      ],
                      o: [
                        [14.217, 10.957],
                        [10.957, -14.218],
                        [3.934, 3.032],
                        [-10.957, 14.217],
                      ],
                      v: [
                        [-28.27, 20.265],
                        [17.313, 14.361],
                        [11.409, -31.221],
                        [-1.307, 0.012],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.443137284821, 0.580392156863, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [34.704, 36.753], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-3.787, -2.919],
                        [-10.548, 13.686],
                        [13.687, 10.548],
                        [10.549, -13.687],
                      ],
                      o: [
                        [13.687, 10.548],
                        [10.548, -13.687],
                        [-3.787, -2.919],
                        [-10.548, 13.687],
                      ],
                      v: [
                        [-25.321, 20.967],
                        [18.56, 15.285],
                        [12.876, -28.597],
                        [-13.08, -9.1],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [47.978, 48.588], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 14,
          op: 914,
          st: 14,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 16,
          ty: 4,
          nm: 'Impossible 1/Most-Software-Elements Outlines',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 15,
                  s: [720, 440, 0],
                  to: [-31.667, -56, 0],
                  ti: [31.667, 56, 0],
                },
                { t: 75, s: [530, 104, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [87.5, 89.5, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.05, 0.05, 0.05], y: [1, 1, 1] },
                  o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] },
                  t: 15,
                  s: [0, 0, 100],
                },
                { t: 75, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [7.87, -22.148],
                        [21.638, -18.745],
                        [-17.702, 22.148],
                        [-21.638, 8.521],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.364705882353, 0.207843152214, 0.898039275525, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [63.494, 103.016], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [17.701, -22.15],
                        [21.638, -8.52],
                        [-7.87, 22.15],
                        [-21.638, 18.743],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.800000059838, 0.764705942191, 0.937254961799, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [67.431, 106.421], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-12.79, -27.258],
                        [0.981, -23.852],
                        [12.79, 17.032],
                        [2.954, 27.258],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.800000059838, 0.764705942191, 0.937254961799, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [33.002, 108.133], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-17.707, -11.925],
                        [23.607, -1.708],
                        [27.543, 11.925],
                        [-27.543, -1.7],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.364705882353, 0.207843152214, 0.898039275525, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [63.5, 137.09], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-53.124, -51.104],
                        [-29.502, 30.669],
                        [53.124, 51.104],
                        [33.444, -17.042],
                        [23.608, -6.818],
                        [35.419, 34.073],
                        [-19.669, 20.447],
                        [-35.414, -34.068],
                        [5.902, -23.85],
                        [15.739, -34.076],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.443137284821, 0.580392156863, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [55.626, 114.943], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 5',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-17.71, -11.921],
                        [-27.543, -1.701],
                        [27.543, 11.921],
                        [23.606, -1.706],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.364705882353, 0.207843152214, 0.898039275525, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [112.674, 85.972], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 6',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-27.542, -11.922],
                        [-23.607, 1.708],
                        [17.709, 11.922],
                        [27.542, 1.7],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.443137284821, 0.580392156863, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [112.674, 96.193], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 7',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 7,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-2.955, -27.258],
                        [-12.79, -17.034],
                        [-0.983, 23.852],
                        [12.79, 27.258],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.443137284821, 0.580392156863, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [141.2, 74.042], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 8',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 8,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [7.866, -22.152],
                        [-21.639, 8.519],
                        [-17.702, 22.152],
                        [21.639, -18.746],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.364705882353, 0.207843152214, 0.898039275525, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [132.351, 120.045], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 9',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 9,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [15.731, -71.559],
                        [39.346, 10.216],
                        [-19.661, 71.559],
                        [-39.346, 3.413],
                        [-25.572, 6.819],
                        [-13.763, 47.71],
                        [25.577, 6.811],
                        [9.832, -47.704],
                        [-19.675, -17.031],
                        [-33.448, -20.437],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.800000059838, 0.764705942191, 0.937254961799, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [128.413, 94.488], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 10',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 10,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-0.978, 23.852],
                        [12.79, 27.258],
                        [-2.956, -27.258],
                        [-12.79, -17.036],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.443137284821, 0.580392156863, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [72.34, 57.016], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 11',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 11,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [2.954, 27.258],
                        [12.79, 17.036],
                        [0.978, -23.854],
                        [-12.79, -27.258],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.800000059838, 0.764705942191, 0.937254961799, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [82.175, 57.016], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 12',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 12,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-21.638, 18.743],
                        [-7.866, 22.15],
                        [21.638, -8.519],
                        [17.701, -22.15],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.800000059838, 0.764705942191, 0.937254961799, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [47.747, 38.277], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 13',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 13,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-23.607, 1.707],
                        [17.707, 11.923],
                        [27.544, 1.697],
                        [-27.544, -11.923],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.443137284821, 0.580392156863, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [92.992, 28.051], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 14',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 14,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-70.82, 22.155],
                        [-11.808, -39.184],
                        [70.82, -18.754],
                        [21.646, 32.367],
                        [17.709, 18.736],
                        [47.217, -11.937],
                        [-7.872, -25.557],
                        [-47.211, 15.336],
                        [-5.894, 25.553],
                        [-1.957, 39.184],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.364705882353, 0.207843152214, 0.898039275525, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [73.32, 41.684], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 15',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 15,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [9.469, -9.842],
                        [-1.901, -49.203],
                        [-15.156, -52.481],
                        [-24.624, -42.64],
                        [-17.044, -16.401],
                        [-43.559, -22.958],
                        [-15.156, -52.481],
                        [24.615, -42.646],
                        [5.68, -22.964],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [17.05, 16.397],
                        [3.793, 13.119],
                        [43.564, 22.951],
                        [24.628, 42.637],
                        [28.418, 55.761],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 2,
                  ty: 'sh',
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-5.676, 22.961],
                        [1.904, 49.2],
                        [-37.867, 39.364],
                        [-49.237, 0.007],
                        [-62.495, -3.272],
                        [-22.721, 6.564],
                        [-41.658, 26.246],
                        [-37.867, 39.364],
                        [-24.613, 42.642],
                        [3.792, 13.12],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 3',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 3,
                  ty: 'sh',
                  ix: 4,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [0.003, -0.003],
                        [0.737, 2.54],
                        [0.003, -0.002],
                        [4.306, -4.476],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 4',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 4,
                  ty: 'sh',
                  ix: 5,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [49.242, -0.008],
                        [22.728, -6.563],
                        [41.664, -26.248],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 5',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 5,
                  ty: 'sh',
                  ix: 6,
                  ks: {
                    a: 0,
                    k: {
                      i: [[0, 0]],
                      o: [[0, 0]],
                      v: [[3.792, 13.118]],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 6',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 6,
                  ty: 'sh',
                  ix: 7,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-56.802, 59.048],
                        [22.738, 78.721],
                        [13.832, 47.879],
                        [22.74, 78.72],
                        [79.544, 19.667],
                        [56.811, -59.055],
                        [18.431, -19.158],
                        [56.807, -59.054],
                        [-22.736, -78.721],
                        [-79.544, -19.672],
                        [-79.543, -19.672],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 7',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 1,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [92.554, 97.272], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 16',
              np: 9,
              cix: 2,
              bm: 0,
              ix: 16,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [15.974, -0.567],
                        [4.603, -39.928],
                        [-8.652, -43.206],
                        [-18.119, -33.365],
                        [-10.539, -7.126],
                        [-37.054, -13.683],
                        [-8.652, -43.206],
                        [31.119, -33.371],
                        [12.184, -13.689],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [23.555, 25.672],
                        [10.298, 22.394],
                        [50.069, 32.226],
                        [31.133, 51.912],
                        [34.923, 65.036],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 2,
                  ty: 'sh',
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [0.829, 32.236],
                        [8.408, 58.475],
                        [-31.363, 48.639],
                        [-42.732, 9.282],
                        [-55.99, 6.003],
                        [-16.217, 15.839],
                        [-35.153, 35.521],
                        [-31.363, 48.639],
                        [-18.108, 51.917],
                        [10.296, 22.395],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 3',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 3,
                  ty: 'sh',
                  ix: 4,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [6.508, 9.272],
                        [7.242, 11.815],
                        [6.507, 9.273],
                        [10.811, 4.799],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 4',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 4,
                  ty: 'sh',
                  ix: 5,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [55.747, 9.267],
                        [29.233, 2.712],
                        [48.169, -16.973],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 5',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 5,
                  ty: 'sh',
                  ix: 6,
                  ks: { a: 0, k: { i: [], o: [], v: [], c: true }, ix: 2 },
                  nm: 'Path 6',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 6,
                  ty: 'sh',
                  ix: 7,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-50.298, 68.323],
                        [29.243, 87.996],
                        [20.337, 57.154],
                        [29.245, 87.995],
                        [86.049, 28.942],
                        [63.316, -49.78],
                        [24.936, -9.883],
                        [63.312, -49.779],
                        [-16.231, -69.446],
                        [-73.04, -10.397],
                        [-73.038, -10.397],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 7',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 1,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [86.049, 87.996], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 17',
              np: 9,
              cix: 2,
              bm: 0,
              ix: 17,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 15,
          op: 915,
          st: 15,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 17,
          ty: 4,
          nm: 'Impossible 2/Most-Software-Elements Outlines',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 16,
                  s: [720, 440, 0],
                  to: [96, 57.667, 0],
                  ti: [-96, -57.667, 0],
                },
                { t: 76, s: [1296, 786, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [96, 85.5, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.05, 0.05, 0.05], y: [1, 1, 1] },
                  o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] },
                  t: 16,
                  s: [0, 0, 100],
                },
                { t: 76, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [22.53, -4.099],
                        [28.191, 8.904],
                        [-28.191, 2.502],
                        [-19.758, -8.904],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.364705882353, 0.207843152214, 0.898039275525, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [75.833, 80.997], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [28.19, -2.503],
                        [19.758, 8.906],
                        [-22.53, 4.1],
                        [-28.19, -8.906],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.800000059838, 0.764705942191, 0.937254961799, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [75.833, 92.404], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [14.036, -23.614],
                        [19.698, -10.606],
                        [-5.602, 23.614],
                        [-19.698, 22.013],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.800000059838, 0.764705942191, 0.937254961799, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [53.244, 59.885], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-1.443, -24.416],
                        [15.541, 14.607],
                        [7.104, 26.017],
                        [-15.541, -26.017],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.364705882353, 0.207843152214, 0.898039275525, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [49.086, 107.915], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [11.145, -73.244],
                        [-39.456, -4.802],
                        [-5.49, 73.244],
                        [36.683, 16.21],
                        [22.585, 14.609],
                        [-2.718, 48.833],
                        [-25.363, -3.201],
                        [8.372, -48.827],
                        [25.357, -9.802],
                        [39.456, -8.201],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.443137284821, 0.580392156863, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [58.907, 85.099], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 5',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-1.449, -24.413],
                        [-15.539, -26.014],
                        [7.105, 26.014],
                        [15.539, 14.609],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.364705882353, 0.207843152214, 0.898039275525, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [119.564, 115.916], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 6',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-7.105, -26.014],
                        [-15.541, -14.608],
                        [1.447, 24.415],
                        [15.541, 26.014],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.443137284821, 0.580392156863, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [111.129, 115.916], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 7',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 7,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [19.699, -22.012],
                        [5.603, -23.613],
                        [-19.699, 10.605],
                        [-14.036, 23.613],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.443137284821, 0.580392156863, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [146.369, 131.326], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 8',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 8,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [22.531, -4.104],
                        [-19.756, -8.905],
                        [-28.193, 2.505],
                        [28.193, 8.905],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.364705882353, 0.207843152214, 0.898039275525, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [104.14, 146.035], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 9',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 9,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [67.59, -30.217],
                        [16.983, 38.221],
                        [-67.59, 28.621],
                        [-25.421, -28.416],
                        [-19.758, -15.407],
                        [-45.063, 18.816],
                        [11.323, 25.216],
                        [45.058, -20.41],
                        [2.768, -25.212],
                        [-2.894, -38.221],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.800000059838, 0.764705942191, 0.937254961799, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [121.01, 129.724], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 10',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 10,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-19.698, 10.61],
                        [-14.038, 23.614],
                        [19.698, -22.012],
                        [5.604, -23.614],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.443137284821, 0.580392156863, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [118.059, 66.288], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 11',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 11,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-19.698, 22.012],
                        [-5.603, 23.614],
                        [19.698, -10.609],
                        [14.037, -23.614],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.800000059838, 0.764705942191, 0.937254961799, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [123.719, 67.889], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 12',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 12,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-28.19, -8.906],
                        [-22.529, 4.102],
                        [19.756, 8.906],
                        [28.19, -2.502],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.800000059838, 0.764705942191, 0.937254961799, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [118.001, 35.369], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 13',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 13,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-15.542, -14.608],
                        [1.443, 24.415],
                        [15.542, 26.016],
                        [-7.107, -26.016],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.443137284821, 0.580392156863, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [153.299, 58.882], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 14',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 14,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-59.273, -43.825],
                        [25.3, -34.218],
                        [59.273, 43.825],
                        [-11.208, 35.824],
                        [-2.773, 24.416],
                        [39.518, 29.219],
                        [16.867, -22.813],
                        [-39.513, -29.215],
                        [-22.527, 9.809],
                        [-30.962, 21.218],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.364705882353, 0.207843152214, 0.898039275525, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [129.324, 55.679], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 15',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 15,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-8.121, 10.985],
                        [8.231, 48.548],
                        [-18.909, 45.467],
                        [-2.671, 23.506],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-21.692, 9.443],
                        [-46.05, 42.388],
                        [-37.929, 31.404],
                        [-54.278, -6.162],
                        [-48.829, 6.358],
                        [-8.123, 10.983],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 2,
                  ty: 'sh',
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-19.023, -14.058],
                        [-46.161, -17.141],
                        [-29.924, -39.103],
                        [-35.374, -51.625],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 3',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 3,
                  ty: 'sh',
                  ix: 4,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [37.921, -31.402],
                        [48.822, -6.356],
                        [21.685, -9.439],
                        [13.565, 1.543],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 4',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 4,
                  ty: 'sh',
                  ix: 5,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [19.015, 14.067],
                        [46.155, 17.149],
                        [21.799, 50.088],
                        [29.917, 39.109],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 5',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 5,
                  ty: 'sh',
                  ix: 6,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-0.002, 0.002],
                        [-0.002, 0.002],
                        [6.153, 0.701],
                        [-0.002, 0.002],
                        [-1.59, 2.15],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 6',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 6,
                  ty: 'sh',
                  ix: 7,
                  ks: {
                    a: 0,
                    k: {
                      i: [[0, 0]],
                      o: [[0, 0]],
                      v: [[-8.122, 10.984]],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 7',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 7,
                  ty: 'sh',
                  ix: 8,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [2.667, -23.5],
                        [-8.234, -48.545],
                        [18.904, -45.462],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 8',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 8,
                  ty: 'sh',
                  ix: 9,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [81.413, 9.246],
                        [48.709, -65.883],
                        [-32.706, -75.132],
                        [-32.705, -75.13],
                        [-81.416, -9.244],
                        [-48.719, 65.888],
                        [-29.715, 40.188],
                        [-48.717, 65.889],
                        [32.699, 75.132],
                        [81.416, 9.248],
                        [26.415, 3.003],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 9',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 1,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [83.916, 77.632], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 16',
              np: 11,
              cix: 2,
              bm: 0,
              ix: 16,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-6.871, 12.235],
                        [9.481, 49.798],
                        [-17.659, 46.717],
                        [-1.421, 24.756],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-20.442, 10.693],
                        [-44.8, 43.638],
                        [-36.679, 32.654],
                        [-53.028, -4.912],
                        [-47.579, 7.608],
                        [-6.873, 12.233],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 2,
                  ty: 'sh',
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-17.773, -12.808],
                        [-44.911, -15.891],
                        [-28.674, -37.853],
                        [-34.124, -50.375],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 3',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 3,
                  ty: 'sh',
                  ix: 4,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [39.171, -30.152],
                        [50.072, -5.106],
                        [22.935, -8.189],
                        [14.815, 2.793],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 4',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 4,
                  ty: 'sh',
                  ix: 5,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [20.265, 15.317],
                        [47.405, 18.399],
                        [23.049, 51.338],
                        [31.167, 40.359],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 5',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 5,
                  ty: 'sh',
                  ix: 6,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [1.248, 1.252],
                        [1.248, 1.252],
                        [7.403, 1.951],
                        [1.248, 1.252],
                        [-0.34, 3.4],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 6',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 6,
                  ty: 'sh',
                  ix: 7,
                  ks: { a: 0, k: { i: [], o: [], v: [], c: true }, ix: 2 },
                  nm: 'Path 7',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 7,
                  ty: 'sh',
                  ix: 8,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [3.917, -22.25],
                        [-6.984, -47.295],
                        [20.154, -44.212],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 8',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 8,
                  ty: 'sh',
                  ix: 9,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [82.663, 10.496],
                        [49.959, -64.633],
                        [-31.456, -73.882],
                        [-31.455, -73.88],
                        [-80.166, -7.994],
                        [-47.469, 67.138],
                        [-28.465, 41.438],
                        [-47.467, 67.139],
                        [33.949, 76.382],
                        [82.666, 10.498],
                        [27.665, 4.253],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 9',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 1,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [82.666, 76.382], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 17',
              np: 11,
              cix: 2,
              bm: 0,
              ix: 17,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 16,
          op: 916,
          st: 16,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 18,
          ty: 4,
          nm: 'Lightning 1/Most-Software-Elements Outlines',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 17,
                  s: [720, 440, 0],
                  to: [-2.333, -56.333, 0],
                  ti: [2.333, 56.333, 0],
                },
                { t: 77, s: [706, 102, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [77, 138, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.05, 0.05, 0.05], y: [1, 1, 1] },
                  o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] },
                  t: 17,
                  s: [0, 0, 100],
                },
                { t: 77, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0.636, 0.953],
                        [0, 0],
                        [-0.64, 1.404],
                        [1.121, 1.317],
                        [0, 0],
                        [-0.114, 1.2],
                        [0, 0],
                        [1.334, 0.917],
                        [0, 0],
                        [-0.301, 1.72],
                        [0, 0],
                        [-0.704, 0.66],
                        [0, 0],
                        [0.621, 1.681],
                        [0, 0],
                        [1.227, 0.45],
                        [0, 0],
                        [0, 0],
                        [-1.057, -0.547],
                        [0, 0],
                        [-0.353, -1.14],
                        [0, 0],
                        [-1.196, -0.516],
                        [0, 0],
                        [-0.358, -1.306],
                        [0, 0],
                        [-0.97, -0.592],
                        [0, 0],
                        [0.104, -1.651],
                        [0, 0],
                        [-0.953, -0.909],
                        [0, 0],
                        [-0.128, -1.019],
                        [0, 0],
                        [-0.608, -0.677],
                        [0, 0],
                        [-0.191, -0.61],
                      ],
                      o: [
                        [0, 0],
                        [0.197, -1.129],
                        [0, 0],
                        [-0.856, -1.284],
                        [0.717, -1.575],
                        [0, 0],
                        [-0.782, -0.917],
                        [0, 0],
                        [0.154, -1.612],
                        [0, 0],
                        [-1.439, -0.988],
                        [0, 0],
                        [0.166, -0.95],
                        [0, 0],
                        [1.306, -1.226],
                        [0, 0],
                        [-0.453, -1.226],
                        [0, 0],
                        [0, 0],
                        [0.355, 1.135],
                        [0, 0],
                        [1.06, 0.549],
                        [0, 0],
                        [0.385, 1.245],
                        [0, 0],
                        [1.244, 0.537],
                        [0, 0],
                        [0.3, 1.096],
                        [0, 0],
                        [1.412, 0.862],
                        [0, 0],
                        [-0.083, 1.314],
                        [0, 0],
                        [0.743, 0.709],
                        [0, 0],
                        [0.113, 0.902],
                        [0, 0],
                        [0.427, 0.475],
                        [0, 0],
                      ],
                      v: [
                        [35.578, 60.527],
                        [39.267, 39.476],
                        [38.583, 36.232],
                        [36.854, 33.638],
                        [36.505, 29.316],
                        [35.843, 24.577],
                        [33.07, 21.321],
                        [32.026, 18.009],
                        [33.026, 7.476],
                        [31.112, 3.379],
                        [27.385, 0.821],
                        [25.518, -3.624],
                        [26.749, -10.645],
                        [28.089, -13.126],
                        [28.458, -13.473],
                        [29.589, -18.268],
                        [23.076, -35.911],
                        [20.429, -38.553],
                        [-39.464, -60.527],
                        [-28.193, -24.462],
                        [-25.987, -21.833],
                        [-12.87, -15.041],
                        [-10.66, -12.401],
                        [-6.686, 0.428],
                        [-4.193, 3.203],
                        [-2.553, 3.91],
                        [-0.018, 6.826],
                        [0.221, 7.697],
                        [2.198, 10.324],
                        [11.319, 15.893],
                        [13.445, 19.978],
                        [13.408, 20.587],
                        [14.781, 24.094],
                        [24.525, 33.382],
                        [25.872, 36.053],
                        [26.172, 38.434],
                        [27.28, 40.859],
                        [29.901, 43.776],
                        [30.84, 45.422],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.960784373564, 0.788235353956, 0.850980451995, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [91.428, 118.542], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [2.631, 2.53],
                        [0, 0],
                        [-0.534, 4.221],
                        [0, 0],
                        [3.711, 2.923],
                        [0, 0],
                        [-6.209, -12.859],
                        [0, 0],
                        [-3.787, -1.011],
                        [0, 0],
                        [-0.538, -5.595],
                        [0, 0],
                        [-2.747, -2.271],
                        [0, 0],
                        [-0.746, -2.622],
                        [0, 0],
                        [-3.42, -1.681],
                        [0, 0],
                        [-1.114, -3.489],
                      ],
                      o: [
                        [0, 0],
                        [-0.009, -3.65],
                        [0, 0],
                        [-3.067, -2.949],
                        [0, 0],
                        [0.592, -4.686],
                        [0, 0],
                        [-11.215, -8.837],
                        [0, 0],
                        [1.705, 3.53],
                        [0, 0],
                        [5.431, 1.45],
                        [0, 0],
                        [0.342, 3.548],
                        [0, 0],
                        [2.101, 1.736],
                        [0, 0],
                        [1.043, 3.666],
                        [0, 0],
                        [3.287, 1.615],
                        [0, 0],
                      ],
                      v: [
                        [74.499, 130.49],
                        [74.384, 82.334],
                        [70.253, 72.663],
                        [63.306, 65.984],
                        [59.282, 54.59],
                        [68.892, -21.381],
                        [63.866, -33.644],
                        [-47.835, -121.653],
                        [-68.289, -105.225],
                        [-4.436, 27.017],
                        [4.213, 34.17],
                        [9.368, 35.547],
                        [19.296, 47.263],
                        [20.09, 55.529],
                        [24.915, 64.616],
                        [40.978, 77.894],
                        [45.35, 84.586],
                        [47.253, 91.272],
                        [54.264, 99.67],
                        [61.452, 103.202],
                        [68.34, 111.191],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.443137284821, 0.580392156863, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [76.999, 132.99], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.518, -5.386],
                        [0, 0],
                        [-2.645, -2.186],
                        [0, 0],
                        [-0.718, -2.524],
                        [0, 0],
                        [-3.292, -1.618],
                        [0, 0],
                        [-1.072, -3.359],
                        [0, 0],
                        [0, 0],
                        [2.533, 2.436],
                        [0, 0],
                        [-0.514, 4.063],
                        [0, 0],
                        [3.572, 2.814],
                        [0, 0],
                        [-5.977, -12.379],
                        [0, 0],
                        [-3.646, -0.973],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0.328, 3.416],
                        [0, 0],
                        [2.023, 1.672],
                        [0, 0],
                        [1.004, 3.529],
                        [0, 0],
                        [3.164, 1.555],
                        [0, 0],
                        [0, 0],
                        [-0.008, -3.514],
                        [0, 0],
                        [-2.952, -2.838],
                        [0, 0],
                        [0.57, -4.511],
                        [0, 0],
                        [-10.796, -8.507],
                        [0, 0],
                        [1.641, 3.398],
                        [0, 0],
                        [5.228, 1.396],
                      ],
                      v: [
                        [18.575, 45.499],
                        [19.341, 53.456],
                        [23.984, 62.204],
                        [39.448, 74.986],
                        [43.657, 81.428],
                        [45.488, 87.864],
                        [52.237, 95.949],
                        [59.157, 99.349],
                        [65.788, 107.04],
                        [71.717, 125.618],
                        [71.606, 79.26],
                        [67.629, 69.95],
                        [60.942, 63.52],
                        [57.068, 52.552],
                        [66.319, -20.582],
                        [61.482, -32.388],
                        [-46.05, -117.111],
                        [-65.74, -101.296],
                        [-4.271, 26.008],
                        [4.056, 32.894],
                        [9.019, 34.22],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [75.218, 147.483], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 17,
          op: 917,
          st: 17,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 19,
          ty: 4,
          nm: 'Lightning Planets 2/Most-Software-Elements Outlines',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 18,
                  s: [720, 440, 0],
                  to: [-109.333, 29.333, 0],
                  ti: [109.333, -29.333, 0],
                },
                { t: 78, s: [64, 616, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [183.5, 162, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.05, 0.05, 0.05], y: [1, 1, 1] },
                  o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] },
                  t: 18,
                  s: [0, 0, 100],
                },
                { t: 78, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-9.04, 32.544],
                        [13.137, -26.092],
                      ],
                      o: [
                        [-30.581, 8.841],
                        [33.776, -5.78],
                      ],
                      v: [
                        [35.018, -31.649],
                        [-35.019, 31.649],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.960784373564, 0.788235353956, 0.850980451995, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [274.818, 287.647], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [4.376, -4.01],
                        [2.835, -8.48],
                        [-9.172, -6.063],
                        [1.378, 22.517],
                      ],
                      o: [
                        [-5.975, 5.475],
                        [-3.582, 11.217],
                        [9.173, 6.062],
                        [-1.378, -22.517],
                      ],
                      v: [
                        [-0.338, -23.777],
                        [-13.164, -3.573],
                        [-4.944, 23.069],
                        [15.368, -6.613],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.960784373564, 0.788235353956, 0.850980451995, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [258.416, 235.873], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-10.277, -11.613],
                        [-29.272, 11.407],
                        [-15.283, -0.224],
                        [24.935, 8.601],
                        [42.03, -18.173],
                        [7.443, -14.778],
                      ],
                      o: [
                        [57.094, -28.699],
                        [18.309, -7.136],
                        [-9.156, -24.671],
                        [-2.772, 14.954],
                        [-38.694, 16.73],
                        [3.026, 15.846],
                      ],
                      v: [
                        [-64.142, 70.941],
                        [30.478, -16.432],
                        [84.707, -18.208],
                        [30.742, -70.941],
                        [-26.284, -16.591],
                        [-84.707, 29.142],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.960784373564, 0.788235353956, 0.850980451995, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [222.853, 219.837], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-4.83, -1.726],
                        [-7.363, 14.841],
                        [6.113, 1.991],
                        [13.444, -16.745],
                      ],
                      o: [
                        [11.367, 4.061],
                        [3.716, -7.491],
                        [-22.168, 3.483],
                        [-1.084, 4.352],
                      ],
                      v: [
                        [-26.949, 18.725],
                        [29.424, -9.023],
                        [22.981, -22.786],
                        [-32.055, 9.179],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.960784373564, 0.788235353956, 0.850980451995, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [188.026, 167.953], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -48.734],
                        [48.733, 0],
                        [0, 48.734],
                        [-48.734, 0],
                      ],
                      o: [
                        [0, 48.734],
                        [-48.734, 0],
                        [0, -48.734],
                        [48.733, 0],
                      ],
                      v: [
                        [88.24, 0],
                        [0, 88.24],
                        [-88.24, 0],
                        [0, -88.24],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.443137284821, 0.580392156863, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [224.812, 232.329], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 5',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [2.438, 2.465],
                        [3.997, -1.204],
                        [0, 0],
                        [2.891, 2.29],
                        [0, 0],
                        [3.017, -0.392],
                        [0, 0],
                        [2.39, 3.52],
                        [0, 0],
                        [5.103, -2.846],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-2.387, -2.453],
                        [0, 0],
                        [-3.606, 1.412],
                        [0, 0],
                        [-2.01, -0.56],
                        [0, 0],
                        [-2.094, 0.994],
                        [0, 0],
                        [-2.279, -4.801],
                        [0, 0],
                        [-4.581, 0.265],
                        [0, 0],
                        [-1.708, -3.469],
                        [0, 0],
                        [-4.312, 0.921],
                        [-1.981, -3.88],
                        [0, 0],
                        [-1.881, -1.088],
                      ],
                      o: [
                        [0, 0],
                        [-3.443, 0.401],
                        [-2.935, -2.969],
                        [0, 0],
                        [-3.531, 1.065],
                        [0, 0],
                        [-2.386, -1.889],
                        [0, 0],
                        [-4.22, 0.548],
                        [0, 0],
                        [-3.282, -4.833],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [3.322, -0.826],
                        [0, 0],
                        [2.7, 2.776],
                        [0, 0],
                        [1.944, -0.761],
                        [0, 0],
                        [2.233, 0.623],
                        [0, 0],
                        [4.801, -2.279],
                        [0, 0],
                        [1.968, 4.145],
                        [0, 0],
                        [3.861, -0.223],
                        [0, 0],
                        [1.947, 3.955],
                        [4.26, -0.911],
                        [0, 0],
                        [0.988, 1.935],
                        [0, 0],
                      ],
                      v: [
                        [171.738, 69.587],
                        [101.108, 77.809],
                        [91.791, 74.539],
                        [80.524, 71.672],
                        [74.141, 73.596],
                        [63.891, 71.64],
                        [49.629, 60.344],
                        [41.178, 58.003],
                        [5.059, 62.701],
                        [-5.72, 57.855],
                        [-17.334, 40.747],
                        [-32.149, 37.235],
                        [-92.607, 70.951],
                        [-171.738, -51.185],
                        [-66.392, -77.384],
                        [-57.172, -74.755],
                        [-46.701, -63.993],
                        [-36.294, -61.743],
                        [-13.332, -70.736],
                        [-7.239, -71.046],
                        [6.169, -67.309],
                        [12.879, -67.884],
                        [27.101, -74.634],
                        [39.921, -70.066],
                        [59.222, -29.396],
                        [70.054, -22.977],
                        [72.961, -23.145],
                        [82.149, -17.788],
                        [93.894, 6.071],
                        [104.539, 11.232],
                        [115.121, 16.267],
                        [128.508, 42.493],
                        [132.903, 47.125],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [174.238, 81.166], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 6',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.023, 0.006],
                        [-1.896, 0.616],
                        [-0.035, 0.011],
                        [-1.83, 0.743],
                        [-0.031, 0.012],
                        [-1.769, 0.871],
                        [-0.015, 0.008],
                        [-2.506, 1.58],
                        [-0.007, 0.004],
                        [-1.577, 1.155],
                        [-0.031, 0.023],
                        [-1.477, 1.247],
                        [-0.04, 0.033],
                        [-1.383, 1.343],
                        [-0.036, 0.036],
                        [-1.29, 1.442],
                        [-0.023, 0.025],
                        [-1.717, 2.375],
                        [-0.016, 0.023],
                        [-1.028, 1.651],
                        [-0.027, 0.044],
                        [-0.906, 1.715],
                        [-0.029, 0.054],
                        [-0.786, 1.782],
                        [-0.023, 0.052],
                        [-0.663, 1.855],
                        [-0.013, 0.036],
                        [0, 10],
                        [3.417, 9.205],
                        [0, 0],
                        [0.283, 0.702],
                        [0.036, 0.089],
                        [0.267, 0.613],
                        [0.048, 0.11],
                        [0.278, 0.595],
                        [0.047, 0.1],
                        [0.315, 0.626],
                        [0.024, 0.048],
                        [1.677, 2.577],
                        [0.048, 0.073],
                        [0.379, 0.551],
                        [0.065, 0.094],
                        [0.388, 0.531],
                        [0.061, 0.082],
                        [0.432, 0.558],
                        [0.026, 0.034],
                        [2.133, 2.207],
                        [0.054, 0.054],
                        [0.485, 0.476],
                        [0.073, 0.071],
                        [0.491, 0.456],
                        [0.065, 0.06],
                        [0.544, 0.477],
                        [0.019, 0.017],
                        [2.529, 1.771],
                        [0.049, 0.034],
                        [0.402, 0.27],
                        [-0.442, 0.052],
                        [0, 0],
                        [0, 0],
                        [0.951, 1.863],
                        [0, 0],
                        [4.101, -0.877],
                        [1.875, 3.808],
                        [0, 0],
                        [3.716, -0.214],
                        [0, 0],
                        [1.894, 3.99],
                        [0, 0],
                        [4.622, -2.194],
                        [0, 0],
                        [2.149, 0.599],
                        [0, 0],
                        [1.871, -0.733],
                        [0, 0],
                        [2.599, 2.672],
                        [0, 0],
                        [3.198, -0.795],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-3.159, -4.653],
                        [0, 0],
                        [-4.062, 0.528],
                        [0, 0],
                        [-2.296, -1.819],
                        [0, 0],
                        [0.757, 0],
                        [4.33, -0.68],
                        [0, 0],
                        [0.003, 0],
                        [4.921, -1.762],
                        [0.047, -0.017],
                        [0.546, -0.21],
                        [0.078, -0.03],
                        [0.523, -0.214],
                        [0.093, -0.038],
                        [0.508, -0.222],
                        [0.099, -0.044],
                        [0.497, -0.232],
                        [0.101, -0.047],
                        [0.49, -0.243],
                        [0.098, -0.049],
                        [0.488, -0.256],
                        [0.09, -0.047],
                        [0.488, -0.271],
                        [0.078, -0.044],
                        [0.494, -0.289],
                        [0.059, -0.035],
                        [0.505, -0.311],
                        [0.033, -0.02],
                        [0, -30.526],
                        [-46.914, 0],
                        [-6.538, 1.613],
                      ],
                      o: [
                        [1.952, -0.483],
                        [0.034, -0.011],
                        [1.891, -0.616],
                        [0.031, -0.013],
                        [1.837, -0.749],
                        [0.015, -0.007],
                        [2.673, -1.316],
                        [0.006, -0.004],
                        [1.657, -1.046],
                        [0.031, -0.023],
                        [1.561, -1.146],
                        [0.04, -0.034],
                        [1.471, -1.247],
                        [0.036, -0.035],
                        [1.385, -1.35],
                        [0.023, -0.025],
                        [1.949, -2.182],
                        [0.017, -0.022],
                        [1.136, -1.573],
                        [0.027, -0.044],
                        [1.018, -1.643],
                        [0.029, -0.054],
                        [0.902, -1.715],
                        [0.023, -0.052],
                        [0.786, -1.792],
                        [0.013, -0.036],
                        [3.174, -8.91],
                        [0, -10.393],
                        [0, -0.001],
                        [-0.264, -0.711],
                        [-0.035, -0.089],
                        [-0.252, -0.621],
                        [-0.047, -0.11],
                        [-0.265, -0.603],
                        [-0.047, -0.1],
                        [-0.3, -0.635],
                        [-0.024, -0.049],
                        [-1.395, -2.76],
                        [-0.047, -0.073],
                        [-0.367, -0.559],
                        [-0.065, -0.094],
                        [-0.375, -0.54],
                        [-0.06, -0.082],
                        [-0.418, -0.569],
                        [-0.026, -0.033],
                        [-1.885, -2.426],
                        [-0.053, -0.054],
                        [-0.473, -0.487],
                        [-0.072, -0.071],
                        [-0.48, -0.468],
                        [-0.065, -0.061],
                        [-0.531, -0.491],
                        [-0.019, -0.017],
                        [-2.316, -2.029],
                        [-0.049, -0.034],
                        [-0.397, -0.277],
                        [0.439, 0.002],
                        [0, 0],
                        [0, 0],
                        [-1.81, -1.048],
                        [0, 0],
                        [-1.907, -3.735],
                        [-4.151, 0.887],
                        [0, 0],
                        [-1.644, -3.34],
                        [0, 0],
                        [-4.41, 0.254],
                        [0, 0],
                        [-2.194, -4.622],
                        [0, 0],
                        [-2.016, 0.956],
                        [0, 0],
                        [-1.936, -0.539],
                        [0, 0],
                        [-3.471, 1.36],
                        [0, 0],
                        [-2.298, -2.362],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [4.911, -2.739],
                        [0, 0],
                        [2.301, 3.389],
                        [0, 0],
                        [2.905, -0.378],
                        [0, 0],
                        [-0.752, -0.02],
                        [-4.521, 0],
                        [0, 0],
                        [-0.002, 0],
                        [-5.292, 0.832],
                        [-0.047, 0.017],
                        [-0.552, 0.199],
                        [-0.078, 0.029],
                        [-0.528, 0.205],
                        [-0.092, 0.039],
                        [-0.513, 0.213],
                        [-0.098, 0.044],
                        [-0.502, 0.222],
                        [-0.102, 0.047],
                        [-0.496, 0.234],
                        [-0.098, 0.048],
                        [-0.493, 0.247],
                        [-0.09, 0.047],
                        [-0.494, 0.262],
                        [-0.079, 0.043],
                        [-0.5, 0.279],
                        [-0.059, 0.034],
                        [-0.512, 0.301],
                        [-0.033, 0.021],
                        [-24.175, 14.975],
                        [0, 46.914],
                        [7.036, 0],
                        [0.023, -0.005],
                      ],
                      v: [
                        [69.163, 150.386],
                        [74.936, 148.736],
                        [75.039, 148.703],
                        [80.622, 146.663],
                        [80.714, 146.625],
                        [86.124, 144.196],
                        [86.169, 144.174],
                        [93.941, 139.825],
                        [93.96, 139.813],
                        [98.812, 136.51],
                        [98.905, 136.442],
                        [103.463, 132.85],
                        [103.583, 132.749],
                        [107.864, 128.863],
                        [107.972, 128.757],
                        [111.987, 124.569],
                        [112.055, 124.493],
                        [117.559, 117.653],
                        [117.608, 117.586],
                        [120.854, 112.747],
                        [120.936, 112.615],
                        [123.823, 107.578],
                        [123.909, 107.416],
                        [126.442, 102.17],
                        [126.51, 102.014],
                        [128.686, 96.543],
                        [128.724, 96.435],
                        [133.631, 67.931],
                        [128.344, 38.377],
                        [128.344, 38.376],
                        [127.523, 36.257],
                        [127.415, 35.992],
                        [126.637, 34.14],
                        [126.493, 33.81],
                        [125.679, 32.013],
                        [125.539, 31.713],
                        [124.617, 29.822],
                        [124.545, 29.676],
                        [119.932, 21.666],
                        [119.789, 21.448],
                        [118.67, 19.783],
                        [118.475, 19.502],
                        [117.33, 17.896],
                        [117.15, 17.649],
                        [115.874, 15.957],
                        [115.796, 15.856],
                        [109.764, 8.903],
                        [109.604, 8.74],
                        [108.167, 7.296],
                        [107.95, 7.084],
                        [106.493, 5.698],
                        [106.299, 5.517],
                        [104.687, 4.065],
                        [104.63, 4.014],
                        [97.356, -1.691],
                        [97.209, -1.793],
                        [96.013, -2.616],
                        [97.334, -2.685],
                        [165.326, -10.6],
                        [127.941, -32.223],
                        [123.71, -36.682],
                        [110.823, -61.929],
                        [100.636, -66.776],
                        [90.388, -71.744],
                        [79.082, -94.712],
                        [70.237, -99.869],
                        [67.439, -99.707],
                        [57.011, -105.887],
                        [38.43, -145.038],
                        [26.089, -149.435],
                        [12.398, -142.937],
                        [5.939, -142.383],
                        [-6.968, -145.981],
                        [-12.834, -145.683],
                        [-34.939, -137.026],
                        [-44.957, -139.192],
                        [-55.037, -149.552],
                        [-63.913, -152.082],
                        [-165.326, -126.862],
                        [-89.149, -9.286],
                        [-30.948, -41.744],
                        [-16.687, -38.362],
                        [-5.506, -21.894],
                        [4.87, -17.229],
                        [39.64, -21.751],
                        [47.776, -19.497],
                        [50.949, -16.984],
                        [48.685, -17.014],
                        [35.396, -15.977],
                        [35.395, -15.977],
                        [35.388, -15.976],
                        [20.046, -12.06],
                        [19.904, -12.01],
                        [18.257, -11.395],
                        [18.023, -11.307],
                        [16.447, -10.677],
                        [16.169, -10.563],
                        [14.637, -9.909],
                        [14.341, -9.779],
                        [12.842, -9.097],
                        [12.537, -8.955],
                        [11.058, -8.24],
                        [10.763, -8.094],
                        [9.291, -7.34],
                        [9.021, -7.198],
                        [7.547, -6.399],
                        [7.311, -6.267],
                        [5.82, -5.415],
                        [5.644, -5.311],
                        [4.119, -4.393],
                        [4.02, -4.331],
                        [-36.26, 67.931],
                        [48.685, 152.877],
                        [69.093, 150.403],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [198.718, 155.377], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 7',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 7,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 18,
          op: 918,
          st: 18,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 20,
          ty: 4,
          nm: 'Lightning Planets/Most-Software-Elements Outlines',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 19,
                  s: [720, 440, 0],
                  to: [65.333, -56.333, 0],
                  ti: [-65.333, 56.333, 0],
                },
                { t: 79, s: [1112, 102, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [260, 166, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.05, 0.05, 0.05], y: [1, 1, 1] },
                  o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] },
                  t: 19,
                  s: [0, 0, 100],
                },
                { t: 79, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-70.698, 36.384],
                        [39.667, -36.384],
                        [70.698, -33.16],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.58431372549, 0.486274539723, 0.917647118662, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [77.485, 286.918], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-153.419, 102.733],
                        [-123.237, 105.869],
                        [153.419, -85.251],
                        [95.205, -105.869],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.58431372549, 0.486274539723, 0.917647118662, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [363.956, 157.503], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-226.188, 140.078],
                        [-150.994, -0.607],
                        [-82.47, 19.404],
                        [47.906, -140.078],
                        [226.188, -131.589],
                        [-22.437, 77.013],
                        [-115.823, 67.31],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [232.974, 183.223], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 22.602],
                        [0.099, 1.161],
                        [11.797, -5.58],
                        [1.601, -6.474],
                        [-0.836, 0],
                      ],
                      o: [
                        [0, -1.186],
                        [-7.392, 14.121],
                        [-9.965, 4.713],
                        [0.824, 0.049],
                        [22.601, 0],
                      ],
                      v: [
                        [21.706, -18.702],
                        [21.555, -22.223],
                        [-6.351, 2.478],
                        [-21.706, 22.145],
                        [-19.217, 22.223],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.58431372549, 0.486274539723, 0.917647118662, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [100.162, 116.068], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [16.114, -10.338],
                        [7.133, -13.676],
                        [-10.226, -4.353],
                        [-21.315, 14.572],
                        [-7.652, 9.358],
                        [-7.808, -0.819],
                        [8.942, 1.411],
                      ],
                      o: [
                        [-16.116, 10.339],
                        [3.952, 10.43],
                        [-8, -15.135],
                        [21.314, -14.572],
                        [1.805, -2.206],
                        [-6.026, -6.212],
                        [3.264, 6.068],
                      ],
                      v: [
                        [0.339, -14.761],
                        [-33.828, 15.868],
                        [-11.561, 39.046],
                        [-2.26, 8.156],
                        [18.533, -23.021],
                        [33.828, -27.116],
                        [10.882, -39.046],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.58431372549, 0.486274539723, 0.917647118662, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [76.491, 95.992], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 5',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.267, -2.942],
                        [2.444, 1.884],
                        [-2.267, 2.942],
                        [-2.444, -1.884],
                      ],
                      o: [
                        [-2.267, 2.942],
                        [-2.445, -1.884],
                        [2.267, -2.942],
                        [2.445, 1.884],
                      ],
                      v: [
                        [4.427, 3.411],
                        [-4.104, 5.326],
                        [-4.427, -3.411],
                        [4.104, -5.326],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.58431372549, 0.486274539723, 0.917647118662, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [102.002, 79.43], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 6',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -22.602],
                        [22.601, 0],
                        [0, 22.602],
                        [-22.602, 0],
                      ],
                      o: [
                        [0, 22.602],
                        [-22.602, 0],
                        [0, -22.602],
                        [22.601, 0],
                      ],
                      v: [
                        [40.924, 0],
                        [0.001, 40.924],
                        [-40.924, 0],
                        [0.001, -40.924],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.364705882353, 0.207843152214, 0.898039275525, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [80.944, 97.366], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 7',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 7,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-9.04, 32.544],
                        [13.137, -26.092],
                      ],
                      o: [
                        [-30.581, 8.841],
                        [33.776, -5.78],
                      ],
                      v: [
                        [35.018, -31.649],
                        [-35.018, 31.649],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.960784373564, 0.788235353956, 0.850980451995, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [236.744, 146.058], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 8',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 8,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [4.376, -4.01],
                        [2.835, -8.481],
                        [-9.172, -6.063],
                        [1.378, 22.517],
                      ],
                      o: [
                        [-5.975, 5.475],
                        [-3.582, 11.216],
                        [9.173, 6.062],
                        [-1.378, -22.517],
                      ],
                      v: [
                        [-0.339, -23.777],
                        [-13.165, -3.572],
                        [-4.944, 23.069],
                        [15.369, -6.613],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.960784373564, 0.788235353956, 0.850980451995, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [220.343, 94.284], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 9',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 9,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-10.277, -11.614],
                        [-29.271, 11.407],
                        [-15.283, -0.224],
                        [24.935, 8.601],
                        [42.03, -18.173],
                        [7.443, -14.778],
                      ],
                      o: [
                        [57.094, -28.699],
                        [18.309, -7.136],
                        [-9.156, -24.671],
                        [-2.772, 14.954],
                        [-38.694, 16.73],
                        [3.026, 15.846],
                      ],
                      v: [
                        [-64.142, 70.941],
                        [30.478, -16.432],
                        [84.706, -18.208],
                        [30.742, -70.941],
                        [-26.284, -16.591],
                        [-84.706, 29.142],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.960784373564, 0.788235353956, 0.850980451995, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [184.779, 78.248], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 10',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 10,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-4.83, -1.726],
                        [-7.363, 14.841],
                        [6.114, 1.991],
                        [13.444, -16.745],
                      ],
                      o: [
                        [11.367, 4.061],
                        [3.716, -7.491],
                        [-22.167, 3.483],
                        [-1.084, 4.352],
                      ],
                      v: [
                        [-26.95, 18.725],
                        [29.424, -9.023],
                        [22.979, -22.786],
                        [-32.055, 9.179],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.960784373564, 0.788235353956, 0.850980451995, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [149.952, 26.364], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 11',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 11,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -48.734],
                        [48.734, 0],
                        [0, 48.734],
                        [-48.734, 0],
                      ],
                      o: [
                        [0, 48.734],
                        [-48.734, 0],
                        [0, -48.734],
                        [48.734, 0],
                      ],
                      v: [
                        [88.24, 0],
                        [0, 88.24],
                        [-88.24, 0],
                        [0, -88.24],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.443137284821, 0.580392156863, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [186.738, 90.74], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 12',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 12,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.001, -0.002],
                        [-0.054, -0.137],
                        [-0.185, -0.425],
                        [-0.085, -0.188],
                        [-0.198, -0.402],
                        [-0.099, -0.192],
                        [-0.212, -0.38],
                        [-0.113, -0.194],
                        [-0.225, -0.36],
                        [-0.126, -0.195],
                        [-0.237, -0.342],
                        [-0.139, -0.192],
                        [-0.25, -0.325],
                        [-0.151, -0.189],
                        [-0.263, -0.308],
                        [-0.163, -0.184],
                        [-0.275, -0.292],
                        [-0.174, -0.178],
                        [-0.287, -0.276],
                        [-0.184, -0.17],
                        [-0.299, -0.261],
                        [-0.192, -0.162],
                        [-0.313, -0.247],
                        [-0.199, -0.151],
                        [-0.328, -0.234],
                        [-0.202, -0.138],
                        [-0.346, -0.222],
                        [-0.203, -0.125],
                        [-0.371, -0.212],
                        [-0.196, -0.107],
                        [-0.413, -0.208],
                        [-0.172, -0.083],
                        [-0.497, -0.216],
                        [-0.097, -0.041],
                        [0.001, 0.001],
                        [-4.598, -0.276],
                        [0.001, -0.002],
                        [-0.052, -0.003],
                        [-0.171, -0.008],
                        [-0.579, 0],
                        [-4.713, 1.993],
                        [-1.117, 0.606],
                        [-0.542, 0.329],
                        [-1.5, 1.249],
                        [-5.194, -5.87],
                        [-19.159, -3.454],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.205, 0.438],
                        [0.048, 0.1],
                        [0.315, 0.626],
                        [0.025, 0.049],
                        [1.677, 2.576],
                        [0.047, 0.073],
                        [0.379, 0.551],
                        [0.065, 0.094],
                        [0.388, 0.532],
                        [0.06, 0.081],
                        [0.434, 0.559],
                        [0.025, 0.033],
                        [2.133, 2.207],
                        [0.052, 0.054],
                        [0.485, 0.476],
                        [0.072, 0.071],
                        [0.492, 0.457],
                        [0.064, 0.059],
                        [0.545, 0.479],
                        [0.018, 0.015],
                        [2.529, 1.772],
                        [0.049, 0.034],
                        [0.585, 0.386],
                        [0.072, 0.047],
                        [0.589, 0.366],
                        [0.061, 0.037],
                        [0.65, 0.379],
                        [0.001, 0.001],
                        [2.863, 1.269],
                        [0.045, 0.02],
                        [0.665, 0.273],
                        [0.074, 0.03],
                        [0.663, 0.251],
                        [0.063, 0.023],
                        [10.513, 0],
                        [4.331, -0.68],
                        [0.002, 0],
                        [4.92, -1.762],
                        [0.048, -0.017],
                        [0.549, -0.211],
                        [0.075, -0.03],
                        [0.523, -0.215],
                        [0.092, -0.038],
                        [0.508, -0.222],
                        [0.098, -0.044],
                        [0.499, -0.232],
                        [0.099, -0.047],
                        [0.492, -0.243],
                        [0.097, -0.048],
                        [0.49, -0.256],
                        [0.088, -0.047],
                        [0.49, -0.272],
                        [0.076, -0.043],
                        [0.495, -0.29],
                        [0.057, -0.034],
                        [0.507, -0.313],
                        [0.031, -0.02],
                        [6.327, -19.035],
                        [0.159, 0.098],
                        [0.241, 0.142],
                        [0.269, 0.151],
                        [0.244, 0.13],
                        [0.293, 0.147],
                        [0.231, 0.11],
                        [0.51, 0.217],
                        [0.21, 0.085],
                        [0.34, 0.126],
                        [0.252, 0.088],
                        [0.313, 0.101],
                        [0.263, 0.078],
                        [0.319, 0.087],
                        [0.261, 0.065],
                        [0.358, 0.079],
                        [0.23, 0.046],
                        [0.549, 0.087],
                        [0, -0.001],
                        [2.107, 0],
                        [0, -21.758],
                        [-1.642, -4.337],
                      ],
                      o: [
                        [0.052, 0.138],
                        [0.17, 0.434],
                        [0.082, 0.19],
                        [0.185, 0.409],
                        [0.096, 0.194],
                        [0.2, 0.388],
                        [0.109, 0.197],
                        [0.214, 0.368],
                        [0.123, 0.197],
                        [0.227, 0.349],
                        [0.136, 0.195],
                        [0.241, 0.333],
                        [0.148, 0.192],
                        [0.253, 0.316],
                        [0.159, 0.187],
                        [0.266, 0.301],
                        [0.17, 0.181],
                        [0.278, 0.285],
                        [0.18, 0.174],
                        [0.291, 0.271],
                        [0.189, 0.165],
                        [0.305, 0.257],
                        [0.195, 0.155],
                        [0.32, 0.244],
                        [0.2, 0.143],
                        [0.339, 0.233],
                        [0.2, 0.129],
                        [0.363, 0.223],
                        [0.193, 0.11],
                        [0.404, 0.222],
                        [0.17, 0.086],
                        [0.486, 0.236],
                        [0.097, 0.042],
                        [0, 0],
                        [4.039, 1.72],
                        [-0.001, 0.002],
                        [0.053, 0.003],
                        [0.17, 0.01],
                        [0.572, 0.025],
                        [5.44, 0],
                        [1.178, -0.499],
                        [0.558, -0.304],
                        [1.676, -1.019],
                        [3.424, 7.146],
                        [12.383, 13.993],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.197, -0.442],
                        [-0.046, -0.1],
                        [-0.299, -0.635],
                        [-0.025, -0.049],
                        [-1.394, -2.76],
                        [-0.047, -0.073],
                        [-0.367, -0.559],
                        [-0.065, -0.093],
                        [-0.376, -0.54],
                        [-0.059, -0.081],
                        [-0.42, -0.57],
                        [-0.025, -0.033],
                        [-1.886, -2.427],
                        [-0.052, -0.053],
                        [-0.474, -0.488],
                        [-0.072, -0.071],
                        [-0.481, -0.468],
                        [-0.064, -0.059],
                        [-0.533, -0.492],
                        [-0.018, -0.015],
                        [-2.317, -2.03],
                        [-0.049, -0.034],
                        [-0.574, -0.4],
                        [-0.07, -0.047],
                        [-0.578, -0.38],
                        [-0.06, -0.038],
                        [-0.639, -0.396],
                        [-0.001, -0.001],
                        [-2.686, -1.565],
                        [-0.045, -0.02],
                        [-0.656, -0.289],
                        [-0.074, -0.031],
                        [-0.655, -0.267],
                        [-0.063, -0.024],
                        [-9.293, -3.492],
                        [-4.522, 0],
                        [-0.002, 0],
                        [-5.293, 0.833],
                        [-0.048, 0.017],
                        [-0.553, 0.2],
                        [-0.075, 0.028],
                        [-0.529, 0.204],
                        [-0.093, 0.038],
                        [-0.514, 0.213],
                        [-0.099, 0.044],
                        [-0.504, 0.223],
                        [-0.1, 0.047],
                        [-0.497, 0.234],
                        [-0.097, 0.048],
                        [-0.495, 0.248],
                        [-0.088, 0.047],
                        [-0.496, 0.262],
                        [-0.077, 0.043],
                        [-0.502, 0.28],
                        [-0.057, 0.033],
                        [-0.513, 0.302],
                        [-0.031, 0.019],
                        [-16.757, 10.378],
                        [-0.158, -0.101],
                        [-0.238, -0.148],
                        [-0.265, -0.157],
                        [-0.241, -0.135],
                        [-0.289, -0.154],
                        [-0.229, -0.115],
                        [-0.499, -0.238],
                        [-0.209, -0.089],
                        [-0.335, -0.136],
                        [-0.249, -0.094],
                        [-0.309, -0.108],
                        [-0.26, -0.084],
                        [-0.316, -0.095],
                        [-0.259, -0.07],
                        [-0.354, -0.088],
                        [-0.228, -0.05],
                        [-0.542, -0.109],
                        [0, 0.001],
                        [-2.017, -0.319],
                        [-21.758, 0],
                        [0, 4.912],
                        [-0.001, 0.002],
                      ],
                      v: [
                        [-211.225, -49.135],
                        [-211.058, -48.727],
                        [-210.531, -47.437],
                        [-210.276, -46.873],
                        [-209.705, -45.655],
                        [-209.409, -45.078],
                        [-208.794, -43.925],
                        [-208.458, -43.34],
                        [-207.802, -42.247],
                        [-207.427, -41.661],
                        [-206.731, -40.623],
                        [-206.318, -40.043],
                        [-205.583, -39.057],
                        [-205.134, -38.486],
                        [-204.36, -37.55],
                        [-203.878, -36.994],
                        [-203.066, -36.106],
                        [-202.552, -35.567],
                        [-201.703, -34.727],
                        [-201.159, -34.209],
                        [-200.272, -33.414],
                        [-199.703, -32.921],
                        [-198.773, -32.169],
                        [-198.185, -31.707],
                        [-197.209, -30.995],
                        [-196.609, -30.568],
                        [-195.577, -29.892],
                        [-194.976, -29.506],
                        [-193.87, -28.859],
                        [-193.29, -28.526],
                        [-192.062, -27.888],
                        [-191.554, -27.628],
                        [-190.077, -26.954],
                        [-189.79, -26.823],
                        [-189.791, -26.825],
                        [-176.768, -23.771],
                        [-176.77, -23.766],
                        [-176.612, -23.759],
                        [-176.1, -23.735],
                        [-174.374, -23.691],
                        [-159.039, -26.787],
                        [-155.595, -28.446],
                        [-153.945, -29.395],
                        [-149.177, -32.806],
                        [-136.162, -13.2],
                        [-87.697, 14.128],
                        [-107.412, 38.244],
                        [-173.376, 18.98],
                        [-245.763, 154.412],
                        [-109.645, 87.465],
                        [-49.619, 93.701],
                        [-20.563, 96.72],
                        [245.763, -87.264],
                        [189.722, -107.112],
                        [18.097, -115.285],
                        [8.011, -102.948],
                        [5.069, -104.065],
                        [4.463, -105.385],
                        [4.323, -105.685],
                        [3.402, -107.576],
                        [3.329, -107.722],
                        [-1.283, -115.731],
                        [-1.426, -115.95],
                        [-2.545, -117.615],
                        [-2.74, -117.896],
                        [-3.886, -119.504],
                        [-4.064, -119.748],
                        [-5.344, -121.443],
                        [-5.418, -121.541],
                        [-11.452, -128.496],
                        [-11.609, -128.656],
                        [-13.048, -130.102],
                        [-13.265, -130.314],
                        [-14.724, -131.702],
                        [-14.915, -131.88],
                        [-16.531, -133.336],
                        [-16.584, -133.382],
                        [-23.859, -139.089],
                        [-24.006, -139.191],
                        [-25.744, -140.37],
                        [-25.957, -140.511],
                        [-27.708, -141.63],
                        [-27.889, -141.743],
                        [-29.822, -142.906],
                        [-29.825, -142.908],
                        [-38.154, -147.163],
                        [-38.29, -147.223],
                        [-40.272, -148.066],
                        [-40.494, -148.157],
                        [-42.471, -148.934],
                        [-42.659, -149.005],
                        [-72.53, -154.412],
                        [-85.82, -153.375],
                        [-85.827, -153.374],
                        [-101.169, -149.458],
                        [-101.312, -149.408],
                        [-102.964, -148.791],
                        [-103.19, -148.705],
                        [-104.768, -148.074],
                        [-105.046, -147.961],
                        [-106.578, -147.307],
                        [-106.874, -147.177],
                        [-108.377, -146.494],
                        [-108.676, -146.354],
                        [-110.16, -145.637],
                        [-110.45, -145.493],
                        [-111.927, -144.737],
                        [-112.191, -144.597],
                        [-113.671, -143.796],
                        [-113.901, -143.667],
                        [-115.397, -142.812],
                        [-115.568, -142.711],
                        [-117.098, -141.789],
                        [-117.191, -141.731],
                        [-153.153, -96.278],
                        [-153.628, -96.577],
                        [-154.347, -97.011],
                        [-155.148, -97.472],
                        [-155.874, -97.872],
                        [-156.75, -98.321],
                        [-157.437, -98.664],
                        [-158.95, -99.348],
                        [-159.582, -99.602],
                        [-160.592, -100],
                        [-161.346, -100.269],
                        [-162.277, -100.584],
                        [-163.061, -100.827],
                        [-164.013, -101.098],
                        [-164.792, -101.305],
                        [-165.862, -101.552],
                        [-166.547, -101.704],
                        [-168.184, -101.999],
                        [-168.183, -101.996],
                        [-174.374, -102.484],
                        [-213.77, -63.088],
                        [-211.222, -49.141],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [248.263, 174.12], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 13',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 13,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 19,
          op: 919,
          st: 19,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 21,
          ty: 4,
          nm: 'Lightning Speed/Most-Software-Elements Outlines',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 20,
                  s: [720, 440, 0],
                  to: [-58.333, 56.333, 0],
                  ti: [58.333, -56.333, 0],
                },
                { t: 80, s: [370, 778, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [207, 143, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.05, 0.05, 0.05], y: [1, 1, 1] },
                  o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] },
                  t: 20,
                  s: [0, 0, 100],
                },
                { t: 80, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-12.248, 31.433],
                        [-43.374, 38.867],
                        [43.374, -38.867],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.364705882353, 0.207843152214, 0.898039275525, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [362.383, 46.572], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [147.938, -94.247],
                        [100.531, -82.926],
                        [-147.938, 67.982],
                        [-70.686, 94.247],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.364705882353, 0.207843152214, 0.898039275525, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [150.438, 184.614], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [163.002, -137.895],
                        [146.779, -23.471],
                        [95.793, -11.201],
                        [17.768, 137.895],
                        [-163.002, 133.26],
                        [55.621, -55.235],
                        [107.38, -67.595],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.960784373564, 0.788235353956, 0.850980451995, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [242.755, 145.601], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [14.536, 8.44],
                        [2.215, 6.935],
                        [0, 0],
                        [-4.858, -2.82],
                        [-6.042, 10.405],
                      ],
                      o: [
                        [-8.441, 14.536],
                        [-6.786, -3.941],
                        [0, 0],
                        [1.584, 4.962],
                        [10.405, 6.042],
                        [0, 0],
                      ],
                      v: [
                        [27.65, -5.419],
                        [-13.95, 5.618],
                        [-27.65, -11.421],
                        [-19.412, -14.059],
                        [-9.607, -1.861],
                        [20.173, -9.76],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.58431372549, 0.486274539723, 0.917647118662, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [103.428, 195.472], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.98, 1.688],
                        [-1.688, -0.98],
                        [0.98, -1.688],
                        [1.688, 0.981],
                      ],
                      o: [
                        [0.98, -1.688],
                        [1.689, 0.98],
                        [-0.98, 1.688],
                        [-1.688, -0.98],
                      ],
                      v: [
                        [-3.056, -1.775],
                        [1.775, -3.057],
                        [3.057, 1.775],
                        [-1.774, 3.056],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.929411824544, 0.098039223166, 0.309803921569, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [104.761, 174.772], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 5',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.091, 0.493],
                        [0, 0],
                        [0.518, -0.436],
                        [0, 0],
                        [0.5, -0.029],
                        [1.23, -2.86],
                        [-2.841, -2.104],
                        [-2.292, 3.947],
                        [1.117, 2.249],
                      ],
                      o: [
                        [0, 0],
                        [0.122, -0.666],
                        [0, 0],
                        [-0.383, 0.323],
                        [-2.801, 0.16],
                        [-1.396, 3.248],
                        [3.776, 2.796],
                        [1.356, -2.334],
                        [-0.224, -0.449],
                      ],
                      v: [
                        [6.833, -2.503],
                        [8.264, -10.266],
                        [7.09, -10.948],
                        [1.056, -5.857],
                        [-0.307, -5.328],
                        [-6.991, -0.632],
                        [-4.499, 8.588],
                        [6.818, 6.274],
                        [7.051, -1.056],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [104.631, 172.381], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 6',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-2.496, -1.449],
                        [2.496, 1.449],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [80.94, 160.94], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 7',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 7,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-0.74, -2.79],
                        [0.74, 2.79],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [97.697, 148.147], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 8',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 8,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [1.449, -2.496],
                        [-1.449, 2.496],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [118.593, 150.951], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 9',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 9,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [8.439, -14.533],
                        [0, 0],
                        [10.405, 6.042],
                        [1.584, 4.962],
                        [0, 0],
                        [-4.498, 7.747],
                        [-14.533, -8.439],
                      ],
                      o: [
                        [0, 0],
                        [-6.042, 10.405],
                        [-4.858, -2.82],
                        [0, 0],
                        [-2.526, -7.908],
                        [8.439, -14.533],
                        [14.537, 8.44],
                      ],
                      v: [
                        [24.694, 20.219],
                        [17.216, 15.878],
                        [-12.564, 23.777],
                        [-22.368, 11.579],
                        [-30.607, 14.217],
                        [-27.94, -10.343],
                        [13.657, -21.381],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.929411824544, 0.098039223166, 0.309803921569, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [106.385, 169.834], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 10',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 10,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-10.451, 18],
                        [-17.999, -10.451],
                        [10.452, -17.999],
                        [18, 10.452],
                      ],
                      o: [
                        [10.451, -18],
                        [18, 10.451],
                        [-10.451, 18],
                        [-18, -10.451],
                      ],
                      v: [
                        [-32.592, -18.924],
                        [18.924, -32.591],
                        [32.59, 18.923],
                        [-18.925, 32.59],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.364705882353, 0.207843152214, 0.898039275525, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [104.761, 174.772], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 11',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 11,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-12.039, 20.733],
                        [-20.733, -12.039],
                        [12.038, -20.733],
                        [20.733, 12.039],
                      ],
                      o: [
                        [12.039, -20.733],
                        [20.733, 12.038],
                        [-12.039, 20.733],
                        [-20.732, -12.038],
                      ],
                      v: [
                        [-37.54, -21.798],
                        [21.798, -37.54],
                        [37.541, 21.798],
                        [-21.798, 37.54],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.443137284821, 0.580392156863, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [104.761, 174.772], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 12',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 12,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [10.345, 6.007],
                        [11.589, -19.959],
                        [-14.988, -12.863],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-2.542, -10.793],
                        [-19.959, -11.589],
                        [-10.275, 17.695],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [110.591, -57.917],
                        [140.555, -65.073],
                        [90.729, -53.173],
                        [45.092, -42.275],
                        [-54.991, 18.51],
                        [-74.673, -8.057],
                        [-131.796, 7.098],
                        [-122.905, 59.758],
                        [-194.1, 102.999],
                        [-119.732, 128.284],
                        [54.288, 132.746],
                        [129.4, -10.784],
                        [178.483, -22.595],
                        [194.1, -132.746],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [216.905, 135.247], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 13',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 13,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 20,
          op: 920,
          st: 20,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 22,
          ty: 4,
          nm: 'Planet/Most-Software-Elements Outlines',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 21,
                  s: [720, 440, 0],
                  to: [-68.333, 37.667, 0],
                  ti: [68.333, -37.667, 0],
                },
                { t: 81, s: [310, 666, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [50, 46, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.05, 0.05, 0.05], y: [1, 1, 1] },
                  o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] },
                  t: 21,
                  s: [0, 0, 100],
                },
                { t: 81, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 22.602],
                        [0.099, 1.161],
                        [11.797, -5.58],
                        [1.602, -6.474],
                        [-0.836, 0],
                      ],
                      o: [
                        [0, -1.186],
                        [-7.392, 14.121],
                        [-9.965, 4.713],
                        [0.824, 0.049],
                        [22.602, 0],
                      ],
                      v: [
                        [21.706, -18.702],
                        [21.555, -22.223],
                        [-6.351, 2.478],
                        [-21.706, 22.145],
                        [-19.218, 22.223],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.58431372549, 0.486274539723, 0.917647118662, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [62.642, 66.648], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [16.114, -10.338],
                        [7.133, -13.676],
                        [-10.226, -4.353],
                        [-21.315, 14.572],
                        [-7.652, 9.358],
                        [-7.808, -0.819],
                        [8.942, 1.411],
                      ],
                      o: [
                        [-16.116, 10.339],
                        [3.952, 10.43],
                        [-8, -15.135],
                        [21.314, -14.572],
                        [1.805, -2.206],
                        [-6.026, -6.212],
                        [3.265, 6.068],
                      ],
                      v: [
                        [0.339, -14.761],
                        [-33.827, 15.868],
                        [-11.56, 39.046],
                        [-2.26, 8.156],
                        [18.534, -23.021],
                        [33.827, -27.116],
                        [10.883, -39.046],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.58431372549, 0.486274539723, 0.917647118662, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [38.971, 46.572], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.267, -2.942],
                        [2.445, 1.884],
                        [-2.267, 2.942],
                        [-2.444, -1.884],
                      ],
                      o: [
                        [-2.267, 2.942],
                        [-2.444, -1.884],
                        [2.267, -2.942],
                        [2.445, 1.884],
                      ],
                      v: [
                        [4.426, 3.411],
                        [-4.106, 5.327],
                        [-4.427, -3.411],
                        [4.104, -5.327],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.58431372549, 0.486274539723, 0.917647118662, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [64.483, 30.011], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -22.602],
                        [22.602, 0],
                        [0, 22.602],
                        [-22.602, 0],
                      ],
                      o: [
                        [0, 22.602],
                        [-22.602, 0],
                        [0, -22.602],
                        [22.602, 0],
                      ],
                      v: [
                        [40.924, 0],
                        [0, 40.924],
                        [-40.924, 0],
                        [0, -40.924],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.364705882353, 0.207843152214, 0.898039275525, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [43.424, 47.946], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.052, -0.003],
                        [-0.171, -0.008],
                        [-0.579, 0],
                        [-4.713, 1.994],
                        [-1.117, 0.607],
                        [-0.542, 0.329],
                        [-2.228, 2.228],
                        [-0.424, 0.467],
                        [-1.517, 2.791],
                        [-0.767, 2.467],
                        [-0.262, 1.282],
                        [0, 2.72],
                        [0.029, 0.611],
                        [0.019, 0.276],
                        [0.02, 0.238],
                        [0.002, -0.005],
                        [6.15, 6.337],
                        [-0.004, 0],
                        [0.059, 0.06],
                        [0.206, 0.2],
                        [0.214, 0.2],
                        [0.214, 0.192],
                        [0.228, 0.195],
                        [0.215, 0.176],
                        [0.295, 0.227],
                        [0.321, 0.232],
                        [0.29, 0.198],
                        [0.229, 0.149],
                        [0.263, 0.164],
                        [0.241, 0.142],
                        [0.269, 0.15],
                        [0.244, 0.13],
                        [0.294, 0.147],
                        [0.231, 0.11],
                        [0.51, 0.217],
                        [0.21, 0.086],
                        [0.34, 0.127],
                        [0.252, 0.088],
                        [0.313, 0.101],
                        [0.263, 0.078],
                        [0.319, 0.087],
                        [0.261, 0.065],
                        [0.359, 0.079],
                        [0.229, 0.046],
                        [0.55, 0.087],
                        [0, -0.001],
                        [2.107, 0],
                        [0, -21.758],
                        [-1.642, -4.337],
                        [0.001, -0.002],
                        [-0.054, -0.137],
                        [-0.185, -0.425],
                        [-0.085, -0.188],
                        [-0.198, -0.401],
                        [-0.099, -0.193],
                        [-0.212, -0.38],
                        [-0.113, -0.195],
                        [-0.225, -0.36],
                        [-0.126, -0.194],
                        [-0.237, -0.342],
                        [-0.139, -0.192],
                        [-0.25, -0.325],
                        [-0.151, -0.188],
                        [-0.263, -0.309],
                        [-0.163, -0.184],
                        [-0.275, -0.292],
                        [-0.174, -0.177],
                        [-0.287, -0.276],
                        [-0.184, -0.17],
                        [-0.299, -0.261],
                        [-0.192, -0.162],
                        [-0.313, -0.247],
                        [-0.199, -0.151],
                        [-0.328, -0.234],
                        [-0.203, -0.139],
                        [-0.346, -0.222],
                        [-0.203, -0.124],
                        [-0.371, -0.212],
                        [-0.196, -0.107],
                        [-0.413, -0.208],
                        [-0.172, -0.083],
                        [-0.497, -0.216],
                        [-0.097, -0.042],
                        [0.001, 0.001],
                        [-4.598, -0.276],
                        [0, -0.002],
                      ],
                      o: [
                        [0.17, 0.009],
                        [0.573, 0.025],
                        [5.44, 0],
                        [1.178, -0.498],
                        [0.558, -0.303],
                        [2.707, -1.645],
                        [0.445, -0.445],
                        [2.12, -2.332],
                        [1.213, -2.233],
                        [0.384, -1.233],
                        [0.525, -2.565],
                        [0, -0.618],
                        [-0.013, -0.278],
                        [-0.016, -0.239],
                        [-0.002, 0.004],
                        [-0.792, -9.322],
                        [0.004, 0.001],
                        [-0.059, -0.06],
                        [-0.202, -0.205],
                        [-0.21, -0.205],
                        [-0.211, -0.196],
                        [-0.224, -0.2],
                        [-0.212, -0.18],
                        [-0.288, -0.235],
                        [-0.313, -0.241],
                        [-0.285, -0.206],
                        [-0.226, -0.155],
                        [-0.258, -0.169],
                        [-0.238, -0.147],
                        [-0.266, -0.157],
                        [-0.241, -0.135],
                        [-0.289, -0.154],
                        [-0.229, -0.115],
                        [-0.499, -0.238],
                        [-0.209, -0.089],
                        [-0.335, -0.135],
                        [-0.249, -0.093],
                        [-0.309, -0.108],
                        [-0.26, -0.084],
                        [-0.316, -0.095],
                        [-0.259, -0.07],
                        [-0.355, -0.089],
                        [-0.228, -0.05],
                        [-0.542, -0.109],
                        [0, 0.001],
                        [-2.017, -0.319],
                        [-21.758, 0],
                        [0, 4.912],
                        [-0.001, 0.002],
                        [0.052, 0.138],
                        [0.17, 0.433],
                        [0.082, 0.19],
                        [0.185, 0.409],
                        [0.096, 0.194],
                        [0.2, 0.387],
                        [0.109, 0.196],
                        [0.214, 0.368],
                        [0.123, 0.197],
                        [0.228, 0.35],
                        [0.136, 0.195],
                        [0.241, 0.333],
                        [0.148, 0.191],
                        [0.253, 0.317],
                        [0.159, 0.186],
                        [0.266, 0.3],
                        [0.17, 0.181],
                        [0.278, 0.286],
                        [0.18, 0.174],
                        [0.291, 0.27],
                        [0.189, 0.165],
                        [0.305, 0.256],
                        [0.195, 0.155],
                        [0.32, 0.244],
                        [0.2, 0.143],
                        [0.338, 0.232],
                        [0.2, 0.128],
                        [0.364, 0.224],
                        [0.194, 0.11],
                        [0.404, 0.222],
                        [0.17, 0.085],
                        [0.486, 0.236],
                        [0.097, 0.042],
                        [0, -0.001],
                        [4.039, 1.72],
                        [-0.001, 0.002],
                        [0.052, 0.003],
                      ],
                      v: [
                        [-2.238, 39.329],
                        [-1.727, 39.353],
                        [0, 39.396],
                        [15.335, 36.3],
                        [18.779, 34.641],
                        [20.429, 33.693],
                        [27.857, 27.857],
                        [29.161, 26.489],
                        [34.641, 18.779],
                        [37.625, 11.715],
                        [38.596, 7.94],
                        [39.396, 0],
                        [39.349, -1.842],
                        [39.296, -2.671],
                        [39.25, -3.389],
                        [39.242, -3.376],
                        [28.266, -27.428],
                        [28.278, -27.427],
                        [28.099, -27.605],
                        [27.487, -28.211],
                        [26.851, -28.818],
                        [26.214, -29.4],
                        [25.534, -29.989],
                        [24.898, -30.527],
                        [24.021, -31.217],
                        [23.07, -31.927],
                        [22.211, -32.537],
                        [21.526, -32.989],
                        [20.745, -33.49],
                        [20.028, -33.923],
                        [19.226, -34.384],
                        [18.5, -34.784],
                        [17.623, -35.233],
                        [16.937, -35.576],
                        [15.424, -36.26],
                        [14.792, -36.515],
                        [13.781, -36.913],
                        [13.028, -37.181],
                        [12.096, -37.496],
                        [11.312, -37.739],
                        [10.361, -38.01],
                        [9.582, -38.217],
                        [8.51, -38.464],
                        [7.828, -38.616],
                        [6.19, -38.911],
                        [6.19, -38.908],
                        [0, -39.396],
                        [-39.396, 0],
                        [-36.849, 13.947],
                        [-36.852, 13.953],
                        [-36.685, 14.361],
                        [-36.158, 15.651],
                        [-35.902, 16.215],
                        [-35.331, 17.432],
                        [-35.035, 18.01],
                        [-34.421, 19.163],
                        [-34.085, 19.748],
                        [-33.428, 20.841],
                        [-33.053, 21.426],
                        [-32.357, 22.465],
                        [-31.944, 23.045],
                        [-31.21, 24.031],
                        [-30.76, 24.601],
                        [-29.986, 25.538],
                        [-29.504, 26.094],
                        [-28.693, 26.982],
                        [-28.178, 27.52],
                        [-27.329, 28.361],
                        [-26.785, 28.879],
                        [-25.898, 29.674],
                        [-25.329, 30.167],
                        [-24.4, 30.919],
                        [-23.811, 31.381],
                        [-22.836, 32.093],
                        [-22.234, 32.52],
                        [-21.203, 33.196],
                        [-20.603, 33.581],
                        [-19.496, 34.229],
                        [-18.917, 34.562],
                        [-17.689, 35.2],
                        [-17.18, 35.46],
                        [-15.703, 36.134],
                        [-15.417, 36.265],
                        [-15.417, 36.263],
                        [-2.395, 39.317],
                        [-2.396, 39.322],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [57.876, 41.896], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 5',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 21,
          op: 921,
          st: 21,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 23,
          ty: 4,
          nm: 'Suitcase/Most-Software-Elements Outlines',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 22,
                  s: [720, 440, 0],
                  to: [103, 24.667, 0],
                  ti: [-103, -24.667, 0],
                },
                { t: 82, s: [1338, 588, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [60.5, 79, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.05, 0.05, 0.05], y: [1, 1, 1] },
                  o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] },
                  t: 22,
                  s: [0, 0, 100],
                },
                { t: 82, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [3.219, -1.523],
                        [-3.219, 1.523],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [101.086, 67.732], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [3.219, -1.523],
                        [-3.219, 1.523],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [91.994, 48.517], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [3.219, -1.523],
                        [-3.219, 1.523],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [82.901, 29.302], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.668, 1.412],
                        [1.411, -0.667],
                        [-0.668, -1.412],
                        [-1.411, 0.668],
                      ],
                      o: [
                        [-0.668, -1.412],
                        [-1.412, 0.668],
                        [0.668, 1.411],
                        [1.412, -0.668],
                      ],
                      v: [
                        [2.556, -1.21],
                        [-1.209, -2.556],
                        [-2.556, 1.21],
                        [1.209, 2.556],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [71.787, 81.596], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.668, 1.412],
                        [1.411, -0.667],
                        [-0.668, -1.412],
                        [-1.412, 0.668],
                      ],
                      o: [
                        [-0.668, -1.412],
                        [-1.412, 0.668],
                        [0.668, 1.411],
                        [1.411, -0.668],
                      ],
                      v: [
                        [2.556, -1.21],
                        [-1.209, -2.556],
                        [-2.556, 1.21],
                        [1.21, 2.556],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [79.327, 54.51], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 5',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.668, 1.412],
                        [1.411, -0.667],
                        [-0.668, -1.412],
                        [-1.412, 0.668],
                      ],
                      o: [
                        [-0.668, -1.412],
                        [-1.412, 0.668],
                        [0.668, 1.411],
                        [1.411, -0.668],
                      ],
                      v: [
                        [2.556, -1.21],
                        [-1.209, -2.556],
                        [-2.556, 1.21],
                        [1.21, 2.556],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [47.219, 46.186], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 6',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.494, -0.707],
                        [0, 0],
                        [0.707, 1.493],
                        [-1.494, 0.707],
                        [0, 0],
                        [-0.707, -1.494],
                      ],
                      o: [
                        [0, 0],
                        [-1.493, 0.706],
                        [-0.707, -1.494],
                        [0, 0],
                        [1.493, -0.707],
                        [0.707, 1.493],
                      ],
                      v: [
                        [25.393, -8.705],
                        [-22.833, 14.115],
                        [-26.817, 12.69],
                        [-25.393, 8.705],
                        [22.834, -14.114],
                        [26.818, -12.689],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.960784373564, 0.788235353956, 0.850980451995, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [77.496, 68.447], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 7',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 7,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.494, -0.707],
                        [0, 0],
                        [0.707, 1.493],
                        [-1.494, 0.707],
                        [0, 0],
                        [-0.707, -1.494],
                      ],
                      o: [
                        [0, 0],
                        [-1.493, 0.706],
                        [-0.707, -1.494],
                        [0, 0],
                        [1.493, -0.707],
                        [0.707, 1.493],
                      ],
                      v: [
                        [25.393, -8.705],
                        [-22.833, 14.115],
                        [-26.817, 12.69],
                        [-25.393, 8.705],
                        [22.834, -14.114],
                        [26.818, -12.689],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.443137284821, 0.580392156863, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [68.404, 49.232], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 8',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 8,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.494, -0.707],
                        [0, 0],
                        [0.707, 1.493],
                        [-1.494, 0.707],
                        [0, 0],
                        [-0.707, -1.494],
                      ],
                      o: [
                        [0, 0],
                        [-1.493, 0.706],
                        [-0.707, -1.494],
                        [0, 0],
                        [1.493, -0.707],
                        [0.707, 1.493],
                      ],
                      v: [
                        [25.393, -8.705],
                        [-22.833, 14.115],
                        [-26.817, 12.69],
                        [-25.393, 8.705],
                        [22.834, -14.114],
                        [26.818, -12.689],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.929411824544, 0.098039223166, 0.309803921569, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [59.312, 30.017], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 9',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 9,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [3.224, 6.813],
                        [0, 0],
                        [-6.813, 3.224],
                        [0, 0],
                        [-3.224, -6.813],
                        [0, 0],
                        [6.813, -3.224],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-3.224, -6.814],
                        [0, 0],
                        [6.813, -3.224],
                        [0, 0],
                        [3.224, 6.813],
                        [0, 0],
                        [-6.813, 3.224],
                      ],
                      v: [
                        [-23.284, 34.814],
                        [-41.684, -4.072],
                        [-35.185, -22.246],
                        [5.109, -41.313],
                        [23.284, -34.814],
                        [41.684, 4.073],
                        [35.185, 22.246],
                        [-5.111, 41.313],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.364705882353, 0.207843152214, 0.898039275525, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [70.389, 53.426], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 10',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 10,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-2.356, 0.702],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [2.037, -1.377],
                      ],
                      v: [
                        [2.628, -3.016],
                        [3.999, -0.119],
                        [-2.628, 3.016],
                        [-3.999, 0.119],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [74.032, 107.527], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 11',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 11,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-3.117, -1.115],
                        [3.117, 1.115],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [96.675, 132.265], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 12',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 12,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [2.992, -1.416],
                        [-2.992, 1.416],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [70.87, 133.537], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 13',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 13,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [1.416, 2.992],
                        [-1.416, -2.992],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [77.528, 114.918], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 14',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 14,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.985, -5.55],
                        [5.55, 1.985],
                        [-1.985, 5.55],
                        [-5.55, -1.985],
                      ],
                      o: [
                        [-1.985, 5.55],
                        [-5.55, -1.984],
                        [1.984, -5.55],
                        [5.549, 1.985],
                      ],
                      v: [
                        [10.049, 3.594],
                        [-3.593, 10.048],
                        [-10.049, -3.594],
                        [3.594, -10.048],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.443137284821, 0.580392156863, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [83.509, 127.556], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 15',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 15,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [8.633, -4.085],
                        [4.085, 8.633],
                        [-8.634, 4.085],
                        [-4.085, -8.633],
                      ],
                      o: [
                        [-8.633, 4.085],
                        [-4.085, -8.633],
                        [8.633, -4.085],
                        [4.085, 8.633],
                      ],
                      v: [
                        [7.397, 15.632],
                        [-15.631, 7.397],
                        [-7.396, -15.632],
                        [15.632, -7.396],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.929411824544, 0.098039223166, 0.309803921569, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [83.509, 127.557], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 16',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 16,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [4.473, -12.502],
                        [12.501, 4.474],
                        [-4.473, 12.502],
                        [-4.092, 2.763],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-5.006, -1.791],
                      ],
                      o: [
                        [-4.468, 12.503],
                        [-12.503, -4.468],
                        [1.791, -5.007],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [4.73, -1.413],
                        [12.504, 4.468],
                      ],
                      v: [
                        [22.636, 6.77],
                        [-8.094, 21.31],
                        [-22.636, -9.421],
                        [-13.474, -21.237],
                        [-12.104, -18.34],
                        [-5.478, -21.474],
                        [-6.848, -24.371],
                        [8.094, -23.961],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [83.508, 128.882], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 17',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 17,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [3.224, 6.813],
                        [0, 0],
                        [-6.813, 3.224],
                        [0, 0],
                        [-3.223, -6.813],
                        [0, 0],
                        [6.813, -3.224],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-3.223, -6.813],
                        [0, 0],
                        [6.813, -3.224],
                        [0, 0],
                        [3.224, 6.813],
                        [0, 0],
                        [-6.813, 3.224],
                      ],
                      v: [
                        [-23.284, 34.814],
                        [-41.685, -4.073],
                        [-35.185, -22.246],
                        [5.109, -41.313],
                        [23.283, -34.814],
                        [41.684, 4.073],
                        [35.185, 22.246],
                        [-5.111, 41.313],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.929411824544, 0.098039223166, 0.309803921569, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [73.152, 59.267], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 18',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 18,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-2.415, 1.143],
                        [0, 0],
                        [-1.143, -2.415],
                        [0, 0],
                        [2.415, -1.143],
                        [0, 0],
                        [1.142, 2.414],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [2.414, -1.143],
                        [0, 0],
                        [1.142, 2.414],
                        [0, 0],
                        [-2.415, 1.143],
                        [0, 0],
                        [-1.143, -2.415],
                      ],
                      v: [
                        [-3.026, -4.28],
                        [-1.39, -5.054],
                        [5.052, -2.751],
                        [5.331, -2.161],
                        [3.026, 4.28],
                        [1.391, 5.054],
                        [-5.051, 2.751],
                        [-5.329, 2.161],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [107.044, 82.124], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 19',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 19,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0.625, -1.747],
                        [0, 0],
                        [1.677, -0.794],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0.793, 1.677],
                        [0, 0],
                        [-0.624, 1.746],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [0.624, -14.092],
                        [5.248, -4.322],
                        [5.511, 1.026],
                        [3.234, 7.393],
                        [-0.36, 11.359],
                        [-6.136, 14.092],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [106.421, 96.216], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 20',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 20,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [12.036, 4.302],
                        [4.554, -1.359],
                        [1.96, -1.325],
                        [1.724, -4.82],
                        [-12.037, -4.301],
                        [-4.302, 12.036],
                      ],
                      o: [
                        [-4.82, -1.724],
                        [-2.268, 0.676],
                        [-3.939, 2.659],
                        [-4.306, 12.034],
                        [12.034, 4.307],
                        [4.306, -12.035],
                      ],
                      v: [
                        [7.792, -23.068],
                        [-6.593, -23.462],
                        [-12.972, -20.444],
                        [-21.792, -9.069],
                        [-7.792, 20.514],
                        [21.792, 6.516],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [59.153, 120.568], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 21',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 21,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [4.479, -2.119],
                        [0, 0],
                        [1.59, -0.752],
                        [0, 0],
                        [-0.753, -1.59],
                        [0, 0],
                        [0, 0],
                        [-2.12, -4.48],
                        [0, 0],
                        [-0.001, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-4.479, 2.12],
                        [0, 0],
                        [0, 0],
                        [-1.179, -0.014],
                        [0, 0],
                        [0, 0],
                        [-0.601, 1.681],
                        [0, 0],
                        [0.764, 1.614],
                        [0, 0],
                        [0, 0],
                        [0.752, 1.59],
                        [0, 0],
                        [2.119, 4.479],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-2.119, -4.479],
                        [0, 0],
                        [-0.752, -1.591],
                        [0, 0],
                        [-1.59, 0.752],
                        [0, 0],
                        [0, 0],
                        [-4.479, 2.119],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [2.12, 4.479],
                        [0, 0],
                        [0, 0],
                        [0.538, 1.137],
                        [0, 0],
                        [0, 0],
                        [1.615, -0.764],
                        [0, 0],
                        [0.602, -1.682],
                        [0, 0],
                        [0, 0],
                        [1.59, -0.752],
                        [0, 0],
                        [4.479, -2.119],
                        [0, 0],
                      ],
                      v: [
                        [40.573, 3.008],
                        [40.573, 3.008],
                        [23.267, -33.566],
                        [21.969, -36.309],
                        [19.308, -41.932],
                        [7.342, -46.212],
                        [6.578, -47.825],
                        [2.337, -49.342],
                        [-1.899, -47.338],
                        [-3.415, -43.097],
                        [-2.652, -41.483],
                        [-38.954, -24.305],
                        [-43.233, -12.338],
                        [-40.573, -6.716],
                        [-40.572, -6.716],
                        [-23.266, 29.859],
                        [-21.968, 32.601],
                        [-19.308, 38.224],
                        [-7.341, 42.503],
                        [28.961, 25.326],
                        [29.725, 26.94],
                        [32.569, 28.751],
                        [27.45, 50.094],
                        [33.01, 47.463],
                        [36.47, 43.644],
                        [38.662, 37.515],
                        [38.408, 32.368],
                        [36.084, 27.454],
                        [38.201, 26.452],
                        [39.718, 22.211],
                        [38.954, 20.597],
                        [43.234, 8.631],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [47.853, 52.594], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 22',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 22,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-2.415, 1.143],
                        [0, 0],
                        [-1.143, -2.415],
                        [0, 0],
                        [2.415, -1.143],
                        [0, 0],
                        [1.142, 2.414],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [2.414, -1.143],
                        [0, 0],
                        [1.142, 2.414],
                        [0, 0],
                        [-2.415, 1.143],
                        [0, 0],
                        [-1.143, -2.415],
                      ],
                      v: [
                        [-3.026, -4.28],
                        [-1.39, -5.054],
                        [5.052, -2.751],
                        [5.331, -2.161],
                        [3.026, 4.28],
                        [1.391, 5.054],
                        [-5.051, 2.751],
                        [-5.329, 2.161],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [74.206, 12.724], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 23',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 23,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 22,
          op: 922,
          st: 22,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 24,
          ty: 4,
          nm: 'White Bolt/Most-Software-Elements Outlines',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.05, y: 1 },
                  o: { x: 0.5, y: 0 },
                  t: 23,
                  s: [720, 440, 0],
                  to: [60.667, 77.333, 0],
                  ti: [-60.667, -77.333, 0],
                },
                { t: 83, s: [1084, 904, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [104.5, 172.5, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.05, 0.05, 0.05], y: [1, 1, 1] },
                  o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] },
                  t: 23,
                  s: [0, 0, 100],
                },
                { t: 83, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0.124, -3.464],
                        [-3.617, -2.085],
                        [0, 0],
                        [-0.314, -3.675],
                        [0, 0],
                        [-2.354, -1.929],
                        [0, 0],
                        [0.925, -4.153],
                        [0, 0],
                        [-5.569, -1.765],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.15, 3.42],
                        [0, 0],
                        [3.499, 1.657],
                        [0, 0],
                        [0.97, 1.848],
                        [0, 0],
                        [2.159, 0.843],
                        [0, 0],
                        [-1.934, 4.95],
                        [0, 0],
                        [3.333, 3.154],
                        [0, 0],
                        [-1.355, 3.622],
                        [0, 0],
                        [3.626, 2.508],
                        [-1.468, 4.102],
                        [0, 0],
                        [0.497, 2.116],
                      ],
                      o: [
                        [0, 0],
                        [2.652, 2.232],
                        [-0.149, 4.172],
                        [0, 0],
                        [3.196, 1.841],
                        [0, 0],
                        [0.26, 3.032],
                        [0, 0],
                        [3.292, 2.696],
                        [0, 0],
                        [-1.27, 5.703],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-2.879, -1.853],
                        [0, 0],
                        [0.17, -3.868],
                        [0, 0],
                        [-1.886, -0.893],
                        [0, 0],
                        [-1.078, -2.053],
                        [0, 0],
                        [-4.95, -1.934],
                        [0, 0],
                        [1.669, -4.274],
                        [0, 0],
                        [-2.809, -2.659],
                        [0, 0],
                        [1.546, -4.129],
                        [-3.583, -2.478],
                        [0, 0],
                        [0.732, -2.046],
                        [0, 0],
                      ],
                      v: [
                        [-84.461, -166.438],
                        [-30.062, -120.646],
                        [-26.057, -111.621],
                        [-20.421, -101.452],
                        [-14.645, -98.123],
                        [-9.043, -89.319],
                        [-7.492, -71.192],
                        [-3.404, -63.433],
                        [24.774, -40.354],
                        [28.634, -29.185],
                        [24.138, -9.002],
                        [31.735, 4.193],
                        [97.726, 25.103],
                        [63.035, 166.438],
                        [-28.249, 107.69],
                        [-32.655, 99.175],
                        [-31.996, 84.174],
                        [-37.491, 75.055],
                        [-59.779, 64.499],
                        [-64.18, 60.275],
                        [-70.649, 47.951],
                        [-75.668, 43.46],
                        [-90.331, 37.731],
                        [-95.792, 25.266],
                        [-79.41, -16.665],
                        [-82.161, -28.952],
                        [-84.275, -30.953],
                        [-86.673, -41.316],
                        [-77.351, -66.221],
                        [-80.89, -77.509],
                        [-84.477, -88.666],
                        [-74.558, -116.389],
                        [-74.198, -122.765],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [108.118, 175.656], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1.223, 5.489],
                        [0, 0],
                        [3.169, 2.595],
                        [0, 0],
                        [0.249, 2.919],
                        [0, 0],
                        [3.076, 1.772],
                        [0, 0],
                        [-0.144, 4.016],
                        [2.553, 2.149],
                        [0, 0],
                        [0, 0],
                        [0.705, -1.969],
                        [0, 0],
                        [-3.45, -2.385],
                        [1.488, -3.975],
                        [0, 0],
                        [-2.704, -2.559],
                        [0, 0],
                        [1.608, -4.115],
                        [0, 0],
                        [-4.766, -1.862],
                        [0, 0],
                        [-0.074, -0.347],
                        [0, 0],
                        [1.178, -1.44],
                        [0, 0],
                        [-0.316, -1.762],
                        [0, 0],
                        [0.946, -1.339],
                        [0, 0],
                        [-0.284, -1.609],
                        [0, 0],
                        [0, 0],
                        [-1.643, -1.058],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0.891, -3.998],
                        [0, 0],
                        [-2.266, -1.856],
                        [0, 0],
                        [-0.303, -3.537],
                        [0, 0],
                        [-3.481, -2.007],
                        [0.119, -3.335],
                        [0, 0],
                        [0, 0],
                        [0.479, 2.037],
                        [0, 0],
                        [-1.413, 3.949],
                        [3.491, 2.415],
                        [0, 0],
                        [-1.305, 3.487],
                        [0, 0],
                        [3.208, 3.037],
                        [0, 0],
                        [-1.862, 4.765],
                        [0, 0],
                        [0.014, 0.35],
                        [0, 0],
                        [0.388, 1.82],
                        [0, 0],
                        [-1.133, 1.385],
                        [0, 0],
                        [0.289, 1.614],
                        [0, 0],
                        [-0.943, 1.335],
                        [0, 0],
                        [0, 0],
                        [0.704, 1.754],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-5.361, -1.699],
                      ],
                      v: [
                        [23.237, -8.666],
                        [27.565, -28.096],
                        [23.85, -38.848],
                        [-3.277, -61.066],
                        [-7.211, -68.535],
                        [-8.705, -85.985],
                        [-14.097, -94.46],
                        [-19.659, -97.665],
                        [-25.084, -107.454],
                        [-28.94, -116.142],
                        [-81.308, -160.224],
                        [-71.428, -118.182],
                        [-71.773, -112.045],
                        [-81.323, -85.355],
                        [-77.87, -74.616],
                        [-74.462, -63.749],
                        [-83.436, -39.773],
                        [-81.128, -29.798],
                        [-79.092, -27.872],
                        [-76.445, -16.043],
                        [-92.216, 24.322],
                        [-86.957, 36.322],
                        [-72.985, 41.781],
                        [-72.857, 42.828],
                        [-72.598, 44.041],
                        [-73.847, 49.198],
                        [-75.402, 51.096],
                        [-76.69, 56.055],
                        [-73.433, 74.21],
                        [-74.46, 78.825],
                        [-86.167, 95.392],
                        [-87.195, 99.992],
                        [-78.16, 151.092],
                        [-30.773, 99.327],
                        [-27.195, 103.669],
                        [60.681, 160.224],
                        [94.077, 24.167],
                        [30.55, 4.036],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [96.577, 162.724], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 23,
          op: 923,
          st: 23,
          ct: 1,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_1',
      nm: 'MS-Stars',
      fr: 30,
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Group 1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [1335.75, 503.927, 0], ix: 2, l: 2 },
            a: { a: 0, k: [1335.75, 503.927, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.05, 0.05, 0.05], y: [1, 1, 1] },
                  o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] },
                  t: 0,
                  s: [0, 0, 100],
                },
                { t: 60, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [2.672, 2.672],
                        [9.603, 0],
                        [2.672, -2.672],
                        [0, -9.602],
                        [-2.672, -2.672],
                        [-9.603, 0],
                        [-2.672, 2.672],
                        [0, 9.602],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [1335.75, 503.927], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Group 2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [863.672, 688.26, 0], ix: 2, l: 2 },
            a: { a: 0, k: [863.672, 688.26, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.05, 0.05, 0.05], y: [1, 1, 1] },
                  o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] },
                  t: 2,
                  s: [0, 0, 100],
                },
                { t: 62, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [2.672, 2.672],
                        [9.603, 0],
                        [2.672, -2.672],
                        [0, -9.602],
                        [-2.672, -2.672],
                        [-9.602, 0],
                        [-2.672, 2.672],
                        [0, 9.602],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [863.672, 688.26], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 2,
          op: 902,
          st: 2,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Group 3',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [137.683, 186.885, 0], ix: 2, l: 2 },
            a: { a: 0, k: [137.683, 186.885, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.05, 0.05, 0.05], y: [1, 1, 1] },
                  o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] },
                  t: 4,
                  s: [0, 0, 100],
                },
                { t: 64, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [2.671, 2.671],
                        [9.602, -0.001],
                        [2.671, -2.673],
                        [-0.001, -9.603],
                        [-2.673, -2.673],
                        [-9.603, -0.001],
                        [-2.673, 2.671],
                        [-0.001, 9.603],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [137.683, 186.885], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 3',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 4,
          op: 904,
          st: 4,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: 'Group 4',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [1213.201, 258.236, 0], ix: 2, l: 2 },
            a: { a: 0, k: [1373.201, 178.236, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.05, 0.05, 0.05], y: [1, 1, 1] },
                  o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] },
                  t: 6,
                  s: [0, 0, 100],
                },
                { t: 66, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [7.604, 4.023],
                        [23.568, 0],
                        [7.605, -4.023],
                        [12.921, -12.919],
                        [4.023, -7.604],
                        [0, -23.568],
                        [-4.024, -7.604],
                        [-12.921, -12.919],
                        [-7.605, -4.023],
                        [-23.568, 0],
                        [-7.605, 4.023],
                        [-12.921, 12.92],
                        [-4.024, 7.605],
                        [0, 23.568],
                        [4.023, 7.605],
                        [12.919, 12.92],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [1373.201, 178.236], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 4',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 6,
          op: 906,
          st: 6,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: 'Group 5',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [799.93, 223.394, 0], ix: 2, l: 2 },
            a: { a: 0, k: [799.93, 223.394, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.05, 0.05, 0.05], y: [1, 1, 1] },
                  o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] },
                  t: 8,
                  s: [0, 0, 100],
                },
                { t: 68, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [7.604, 4.023],
                        [23.568, 0],
                        [7.605, -4.023],
                        [12.92, -12.919],
                        [4.023, -7.604],
                        [0, -23.568],
                        [-4.023, -7.604],
                        [-12.921, -12.919],
                        [-7.606, -4.023],
                        [-23.568, 0],
                        [-7.606, 4.023],
                        [-12.921, 12.92],
                        [-4.023, 7.605],
                        [0, 23.568],
                        [4.023, 7.605],
                        [12.919, 12.92],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [799.93, 223.394], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 5',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 8,
          op: 908,
          st: 8,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: 'Group 6',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [619.709, 756.833, 0], ix: 2, l: 2 },
            a: { a: 0, k: [599.709, 796.833, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.05, 0.05, 0.05], y: [1, 1, 1] },
                  o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] },
                  t: 10,
                  s: [0, 0, 100],
                },
                { t: 70, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [7.604, 4.023],
                        [23.568, 0],
                        [7.605, -4.024],
                        [12.92, -12.92],
                        [4.023, -7.605],
                        [0, -23.569],
                        [-4.023, -7.605],
                        [-12.921, -12.92],
                        [-7.606, -4.024],
                        [-23.568, 0],
                        [-7.606, 4.023],
                        [-12.921, 12.92],
                        [-4.023, 7.605],
                        [0, 23.568],
                        [4.023, 7.605],
                        [12.919, 12.92],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [599.709, 796.833], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 6',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 10,
          op: 910,
          st: 10,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: 'Group 7',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [487.39, 585.386, 0], ix: 2, l: 2 },
            a: { a: 0, k: [487.39, 585.386, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.05, 0.05, 0.05], y: [1, 1, 1] },
                  o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] },
                  t: 12,
                  s: [0, 0, 100],
                },
                { t: 72, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [7.604, 4.023],
                        [23.568, 0],
                        [7.605, -4.024],
                        [12.921, -12.92],
                        [4.023, -7.604],
                        [-0.001, -23.569],
                        [-4.023, -7.604],
                        [-12.921, -12.92],
                        [-7.605, -4.024],
                        [-23.569, 0],
                        [-7.605, 4.023],
                        [-12.921, 12.92],
                        [-4.023, 7.605],
                        [-0.001, 23.568],
                        [4.023, 7.605],
                        [12.919, 12.92],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [487.39, 585.386], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 7',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 12,
          op: 912,
          st: 12,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: 'Group 8',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [253.143, 565.023, 0], ix: 2, l: 2 },
            a: { a: 0, k: [253.143, 565.023, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.05, 0.05, 0.05], y: [1, 1, 1] },
                  o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] },
                  t: 14,
                  s: [0, 0, 100],
                },
                { t: 74, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -4.462],
                        [4.463, 0],
                        [0, 4.462],
                        [-4.462, 0],
                      ],
                      o: [
                        [0, 4.462],
                        [-4.462, 0],
                        [0, -4.462],
                        [4.463, 0],
                      ],
                      v: [
                        [8.079, 0],
                        [-0.001, 8.08],
                        [-8.079, 0],
                        [-0.001, -8.08],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [253.143, 565.023], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 8',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 14,
          op: 914,
          st: 14,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 4,
          nm: 'Group 9',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [728.638, 108.909, 0], ix: 2, l: 2 },
            a: { a: 0, k: [728.638, 108.909, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.05, 0.05, 0.05], y: [1, 1, 1] },
                  o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] },
                  t: 16,
                  s: [0, 0, 100],
                },
                { t: 76, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -4.462],
                        [4.463, 0],
                        [0, 4.462],
                        [-4.462, 0],
                      ],
                      o: [
                        [0, 4.462],
                        [-4.462, 0],
                        [0, -4.462],
                        [4.463, 0],
                      ],
                      v: [
                        [8.08, 0],
                        [0, 8.08],
                        [-8.079, 0],
                        [0, -8.08],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.945098099054, 0.87450986376, 0.85882358925, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [728.638, 108.909], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 9',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 16,
          op: 916,
          st: 16,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 10,
          ty: 4,
          nm: 'Group 10',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [1312.749, 501.686, 0], ix: 2, l: 2 },
            a: { a: 0, k: [1312.749, 501.686, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.05, 0.05, 0.05], y: [1, 1, 1] },
                  o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] },
                  t: 0,
                  s: [0, 0, 100],
                },
                { t: 60, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-2.572, -2.572],
                        [-9.244, 0],
                        [-2.572, 2.572],
                        [0, 9.244],
                        [2.572, 2.572],
                        [9.244, 0],
                        [2.572, -2.572],
                        [0, -9.244],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [1312.749, 501.686], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 10',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 900,
          st: 0,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 11,
          ty: 4,
          nm: 'Group 11',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [858.297, 679.136, 0], ix: 2, l: 2 },
            a: { a: 0, k: [858.297, 679.136, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.05, 0.05, 0.05], y: [1, 1, 1] },
                  o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] },
                  t: 2,
                  s: [0, 0, 100],
                },
                { t: 62, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-2.572, -2.572],
                        [-9.244, 0],
                        [-2.572, 2.572],
                        [0, 9.244],
                        [2.572, 2.572],
                        [9.244, 0],
                        [2.572, -2.572],
                        [0, -9.244],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [858.297, 679.136], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 11',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 2,
          op: 902,
          st: 2,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 12,
          ty: 4,
          nm: 'Group 12',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [159.414, 196.481, 0], ix: 2, l: 2 },
            a: { a: 0, k: [159.414, 196.481, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.05, 0.05, 0.05], y: [1, 1, 1] },
                  o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] },
                  t: 4,
                  s: [0, 0, 100],
                },
                { t: 64, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [0, -9.244],
                        [-2.572, -2.572],
                        [-9.244, 0],
                        [-2.572, 2.572],
                        [0, 9.244],
                        [2.572, 2.572],
                        [9.244, 0],
                        [2.572, -2.572],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [159.414, 196.481], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 12',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 4,
          op: 904,
          st: 4,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 13,
          ty: 4,
          nm: 'Group 13',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [1188.802, 268.156, 0], ix: 2, l: 2 },
            a: { a: 0, k: [1348.802, 188.156, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.05, 0.05, 0.05], y: [1, 1, 1] },
                  o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] },
                  t: 6,
                  s: [0, 0, 100],
                },
                { t: 66, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-7.321, 3.873],
                        [-12.438, 12.438],
                        [-3.873, 7.321],
                        [0, 22.689],
                        [3.873, 7.321],
                        [12.438, 12.438],
                        [7.321, 3.873],
                        [22.689, 0],
                        [7.321, -3.873],
                        [12.438, -12.438],
                        [3.873, -7.321],
                        [0, -22.689],
                        [-3.873, -7.321],
                        [-12.438, -12.437],
                        [-7.321, -3.873],
                        [-22.689, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [1348.802, 188.156], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 13',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 6,
          op: 906,
          st: 6,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 14,
          ty: 4,
          nm: 'Group 14',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [796.936, 231.627, 0], ix: 2, l: 2 },
            a: { a: 0, k: [796.936, 231.627, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.05, 0.05, 0.05], y: [1, 1, 1] },
                  o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] },
                  t: 8,
                  s: [0, 0, 100],
                },
                { t: 68, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-3.873, 7.321],
                        [0, 22.689],
                        [3.872, 7.321],
                        [12.438, 12.438],
                        [7.321, 3.873],
                        [22.689, 0],
                        [7.322, -3.873],
                        [12.438, -12.438],
                        [3.872, -7.321],
                        [0, -22.689],
                        [-3.873, -7.321],
                        [-12.438, -12.437],
                        [-7.321, -3.873],
                        [-22.688, 0],
                        [-7.321, 3.873],
                        [-12.438, 12.437],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [796.936, 231.627], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 14',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 8,
          op: 908,
          st: 8,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 15,
          ty: 4,
          nm: 'Group 15',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [624.19, 743.655, 0], ix: 2, l: 2 },
            a: { a: 0, k: [604.19, 783.655, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.05, 0.05, 0.05], y: [1, 1, 1] },
                  o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] },
                  t: 10,
                  s: [0, 0, 100],
                },
                { t: 70, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [7.321, -3.873],
                        [12.438, -12.437],
                        [3.873, -7.321],
                        [-0.001, -22.689],
                        [-3.874, -7.321],
                        [-12.438, -12.437],
                        [-7.321, -3.873],
                        [-22.689, 0],
                        [-7.321, 3.873],
                        [-12.438, 12.438],
                        [-3.874, 7.321],
                        [-0.001, 22.689],
                        [3.873, 7.321],
                        [12.437, 12.438],
                        [7.32, 3.873],
                        [22.689, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [604.19, 783.655], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 15',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 10,
          op: 910,
          st: 10,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 16,
          ty: 4,
          nm: 'Group 16',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [496.064, 580.104, 0], ix: 2, l: 2 },
            a: { a: 0, k: [496.064, 580.104, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.05, 0.05, 0.05], y: [1, 1, 1] },
                  o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] },
                  t: 12,
                  s: [0, 0, 100],
                },
                { t: 72, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [7.321, -3.873],
                        [12.438, -12.437],
                        [3.873, -7.321],
                        [-0.001, -22.689],
                        [-3.874, -7.321],
                        [-12.438, -12.437],
                        [-7.321, -3.873],
                        [-22.689, 0],
                        [-7.321, 3.873],
                        [-12.438, 12.438],
                        [-3.874, 7.321],
                        [-0.001, 22.689],
                        [3.873, 7.321],
                        [12.437, 12.438],
                        [7.32, 3.873],
                        [22.689, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [496.064, 580.104], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 16',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 12,
          op: 912,
          st: 12,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 17,
          ty: 4,
          nm: 'Group 17',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [270.563, 560.501, 0], ix: 2, l: 2 },
            a: { a: 0, k: [270.563, 560.501, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.05, 0.05, 0.05], y: [1, 1, 1] },
                  o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] },
                  t: 14,
                  s: [0, 0, 100],
                },
                { t: 74, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -4.296],
                        [-4.296, 0],
                        [0, 4.296],
                        [4.296, 0],
                      ],
                      o: [
                        [0, 4.296],
                        [4.296, 0],
                        [0, -4.296],
                        [-4.296, 0],
                      ],
                      v: [
                        [-7.778, 0],
                        [0, 7.778],
                        [7.778, 0],
                        [0, -7.778],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [270.563, 560.501], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 17',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 14,
          op: 914,
          st: 14,
          ct: 1,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 18,
          ty: 4,
          nm: 'Group 18',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [728.305, 121.416, 0], ix: 2, l: 2 },
            a: { a: 0, k: [728.305, 121.416, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.05, 0.05, 0.05], y: [1, 1, 1] },
                  o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] },
                  t: 16,
                  s: [0, 0, 100],
                },
                { t: 76, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [4.296, 0],
                        [0, -4.296],
                        [-4.296, 0],
                        [0, 4.296],
                      ],
                      o: [
                        [-4.296, 0],
                        [0, 4.296],
                        [4.296, 0],
                        [0, -4.296],
                      ],
                      v: [
                        [0, -7.778],
                        [-7.778, 0],
                        [0, 7.778],
                        [7.778, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.050980395897, 0.094117654539, 0.082352941176, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [728.305, 121.416], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 18',
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 16,
          op: 916,
          st: 16,
          ct: 1,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: 'MS-Elements',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 90, ix: 10 },
        p: { a: 0, k: [195, 422, 0], ix: 2, l: 2 },
        a: { a: 0, k: [720, 440, 0], ix: 1, l: 2 },
        s: { a: 0, k: [60, 60, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 1440,
      h: 880,
      ip: 5,
      op: 905,
      st: 5,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 0,
      nm: 'MS-Stars',
      refId: 'comp_1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 90, ix: 10 },
        p: { a: 0, k: [195, 422, 0], ix: 2, l: 2 },
        a: { a: 0, k: [720, 440, 0], ix: 1, l: 2 },
        s: { a: 0, k: [60, 60, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 1440,
      h: 880,
      ip: 10,
      op: 910,
      st: 10,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: 'Pink',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 90, ix: 10 },
        p: { a: 0, k: [195, 422, 0], ix: 2, l: 2 },
        a: { a: 0, k: [720, 440, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.05, 0.05, 0.05], y: [1, 1, 1] },
              o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] },
              t: 10,
              s: [0, 0, 100],
            },
            { t: 71, s: [50, 50, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.1, -47.347],
                    [62.532, 57.833],
                    [-19.204, -79.132],
                    [84.238, 52.487],
                    [-138.689, -196.316],
                    [62.499, 1.1],
                    [-62.067, -63.29],
                    [33.59, -11],
                    [-127.654, 4.632],
                    [72.399, -89.678],
                    [-28.261, 15.4],
                    [4.4, -20.65],
                    [-52.553, 97.823],
                    [15.4, -58.299],
                    [-30.249, 65.149],
                    [-4.4, -74.799],
                    [-37.991, 101.589],
                    [-17.6, -91.438],
                    [-6.431, 90.269],
                    [-39.599, -62.699],
                    [50.599, 99.34],
                    [-17.871, -26.057],
                    [6.178, 82.757],
                    [-61.145, -22.85],
                    [91.859, 101.007],
                    [-65.545, 38.499],
                    [56.704, 4.61],
                    [-73.468, 20.9],
                    [165.733, -26.4],
                    [-45.143, 28.521],
                    [127.547, -76.008],
                    [-37.785, 77.353],
                    [66.339, -66.793],
                    [-24.199, 63.155],
                    [103.715, -107.798],
                    [7.365, 72.711],
                    [33.989, -162.901],
                    [26.399, 97.415],
                    [5.5, -87.882],
                    [15.4, 57.199],
                  ],
                  o: [
                    [0, 0],
                    [-62.531, -57.832],
                    [19.204, 79.132],
                    [-84.237, -52.488],
                    [138.689, 196.317],
                    [-62.5, -1.1],
                    [62.067, 63.29],
                    [-33.59, 11],
                    [127.654, -4.632],
                    [-72.4, 89.678],
                    [28.262, -15.4],
                    [-4.4, 20.65],
                    [52.553, -97.823],
                    [-15.399, 58.299],
                    [30.25, -65.149],
                    [4.4, 74.799],
                    [37.991, -101.589],
                    [17.599, 91.437],
                    [6.431, -90.269],
                    [39.6, 62.699],
                    [-50.599, -99.34],
                    [17.871, 26.058],
                    [-6.178, -82.757],
                    [61.144, 22.85],
                    [-91.859, -101.008],
                    [65.544, -38.499],
                    [-56.704, -4.609],
                    [73.467, -20.9],
                    [-165.732, 26.399],
                    [45.142, -28.521],
                    [-127.548, 76.008],
                    [37.785, -77.353],
                    [-66.339, 66.793],
                    [24.2, -63.154],
                    [-103.714, 107.798],
                    [-7.366, -72.711],
                    [-33.989, 162.901],
                    [-26.4, -97.414],
                    [-5.5, 87.883],
                    [-15.399, -57.199],
                  ],
                  v: [
                    [-212.188, -315.742],
                    [-265.155, -297.461],
                    [-374.39, -329.927],
                    [-461.245, -319.306],
                    [-553.274, -246.916],
                    [-584.181, -80.299],
                    [-627.349, -20.391],
                    [-723.089, 77],
                    [-684.136, 123.43],
                    [-584.181, 216.117],
                    [-487.946, 232.096],
                    [-456.384, 276.345],
                    [-391.239, 267.22],
                    [-294.687, 259.596],
                    [-225.938, 276.345],
                    [-116.49, 285.995],
                    [-47.782, 404.084],
                    [44.108, 365.055],
                    [113.575, 313.565],
                    [182.705, 250.797],
                    [266.304, 241.238],
                    [329.832, 241.238],
                    [475.979, 331.353],
                    [550.554, 276.345],
                    [660.658, 252.805],
                    [550.554, 123.198],
                    [569.404, 72.808],
                    [658.129, 9.9],
                    [678.432, -54.999],
                    [643.555, -115.577],
                    [606.148, -156.307],
                    [450.001, -156.307],
                    [393.142, -182.291],
                    [312.503, -229.44],
                    [218.221, -305.794],
                    [94.707, -253.927],
                    [-0.002, -379.596],
                    [-72.49, -293.078],
                    [-131.889, -357.378],
                    [-179.189, -366.294],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'mm',
              mm: 4,
              nm: 'Merge Paths 1',
              mn: 'ADBE Vector Filter - Merge',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.050980392843, 0.0941176489, 0.082352943718, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.960784313725, 0.788235294118, 0.850980392157, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [720, 440], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 10,
      op: 910,
      st: 10,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      nm: 'Fuscia',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 90, ix: 10 },
        p: { a: 0, k: [195, 422, 0], ix: 2, l: 2 },
        a: { a: 0, k: [720, 440, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.05, 0.05, 0.05], y: [1, 1, 1] },
              o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] },
              t: 5,
              s: [0, 0, 100],
            },
            { t: 66, s: [50, 50, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [11.573, 0],
                    [15.37, 31.144],
                    [4.707, 19.932],
                    [1.581, 26.86],
                    [4.805, 0],
                    [8.573, -18.464],
                    [15.197, 0],
                    [12.359, 16.048],
                    [-7.299, 29.608],
                    [9.117, -16.97],
                    [10.881, -11.307],
                    [16.733, 0],
                    [10.534, 13.015],
                    [-1.118, 18.77],
                    [27.582, 0],
                    [4.111, 8.603],
                    [-9.551, 21.758],
                    [-10.942, 13.554],
                    [-3.787, 6.336],
                    [20.864, 0],
                    [18.948, -0.687],
                    [6.42, 0],
                    [13.869, 3.902],
                    [1.874, 11.747],
                    [-29.636, 20.946],
                    [-13.424, 4.396],
                    [-6.942, 1.934],
                    [-14.922, 6.082],
                    [-3.533, 2.441],
                    [1.198, 1.222],
                    [6.531, 11.155],
                    [-4.706, 11.206],
                    [-27.356, 5.468],
                    [-17.256, 0],
                    [-7.833, -0.142],
                    [0, 0],
                    [-7.458, -0.445],
                    [-7.645, 0],
                    [-2.637, 0.263],
                    [5.12, 11.03],
                    [23.777, 33.655],
                    [-17.098, 33.012],
                    [-33.537, 0],
                    [-67.09, -41.803],
                    [0, 0],
                    [-5.526, -3.412],
                    [-12.411, 15.783],
                    [-15.521, 0],
                    [-59.236, -53.785],
                    [-2.845, 11.47],
                    [-3.749, 7.073],
                    [-8.177, 0],
                    [-12.157, -12.997],
                    [-6.951, 0],
                    [-14.132, -20.71],
                    [-5.174, -12.414],
                    [-5.009, -15.759],
                    [-3.339, 16.004],
                    [-6.942, 13.253],
                    [-11.864, 0],
                    [-14.908, -27.832],
                    [-7.264, -24.064],
                    [-3.938, -27.831],
                    [-16.179, 16.844],
                    [-4.716, 4.903],
                    [-29.407, 0],
                    [-13.426, -19.521],
                    [0.06, -20.323],
                    [8.103, -21.144],
                    [-0.072, -5.169],
                    [-9.402, 9.466],
                    [-15.663, 0],
                    [-9.138, -14.633],
                    [21.931, -45.706],
                    [-50.194, 29.911],
                    [-21.785, 0],
                    [-6.13, -21.179],
                    [17.285, -24.051],
                    [10.354, -8.83],
                    [-14.673, 0],
                    [-8.945, -3.052],
                    [-2.441, -17.506],
                    [27.699, -15.637],
                    [25.707, -7.313],
                    [9.619, -3.432],
                    [-0.996, -4.352],
                    [14.635, -16.256],
                    [7.361, -5.182],
                    [10.302, -6.188],
                    [-37.147, -40.847],
                    [-6.371, -11.709],
                    [7.01, -15.849],
                    [35.575, 0],
                    [50.507, 18.919],
                    [0, 0],
                    [8.113, 2.648],
                    [-0.776, -10.393],
                    [11.284, -12.159],
                    [14.537, 0],
                    [21.429, 15.225],
                    [16.669, 15.986],
                    [12.025, 13.612],
                    [5.25, -8.566],
                    [21.085, 0],
                    [27.771, 38.011],
                    [1.038, -14.576],
                    [9.936, -22.7],
                    [7.51, -8.08],
                    [10.86, 0],
                    [15.85, 26.12],
                    [3.968, 19.351],
                    [6.781, -18.131],
                    [6.8, -8.953],
                  ],
                  o: [
                    [-14.917, 0],
                    [-5.987, -12.131],
                    [-8.497, -35.986],
                    [-0.605, -10.286],
                    [-6.03, 0],
                    [-25.848, 55.669],
                    [-19.872, 0],
                    [-15.202, -19.738],
                    [-6.486, 6.364],
                    [-11.564, 21.525],
                    [-14.783, 15.362],
                    [-15.979, 0],
                    [-13.132, -16.225],
                    [-26.123, 11.243],
                    [-33.671, 0],
                    [-4.701, -9.837],
                    [4.616, -10.512],
                    [10.255, -12.702],
                    [-8.375, -1.15],
                    [-12.44, 0],
                    [-7.253, 0.264],
                    [-24.361, 0],
                    [-32.575, -9.166],
                    [-1.895, -11.876],
                    [10.98, -7.759],
                    [5.026, -1.645],
                    [13.659, -3.805],
                    [9.933, -4.05],
                    [-0.803, -0.951],
                    [-14.839, -15.131],
                    [-13.71, -23.415],
                    [4.913, -11.701],
                    [9.836, -1.966],
                    [7.133, 0],
                    [0, 0],
                    [7.421, 0.131],
                    [8.372, 0.498],
                    [4.628, -0.001],
                    [-1.134, -4.841],
                    [-10.047, -21.645],
                    [-58.811, -83.248],
                    [4.574, -8.832],
                    [32.471, 0],
                    [0, 0],
                    [5.461, 3.409],
                    [-2.713, -17.973],
                    [9.283, -11.805],
                    [26.799, 0],
                    [1.244, -13.476],
                    [2.646, -10.671],
                    [13.167, -24.837],
                    [6.327, 0],
                    [12.432, -24.449],
                    [11.354, 0],
                    [5.044, 7.394],
                    [4.708, 11.298],
                    [2.718, -12.272],
                    [5.079, -24.342],
                    [15.747, -30.069],
                    [22.31, 0],
                    [7.794, 14.553],
                    [11.206, 37.125],
                    [12.985, -12.911],
                    [4.347, -4.527],
                    [35.607, -37.01],
                    [22.92, 0],
                    [15.342, 22.31],
                    [-0.074, 24.313],
                    [-3.824, 9.981],
                    [4.773, -2.636],
                    [47.785, -48.111],
                    [17.745, 0],
                    [14.04, 22.484],
                    [18.3, -5.412],
                    [41.37, -24.653],
                    [23.682, 0],
                    [5.553, 19.183],
                    [-8.057, 11.212],
                    [22.784, -3.385],
                    [13.695, 0],
                    [18.283, 6.241],
                    [4.658, 33.398],
                    [-24.207, 13.668],
                    [-11.522, 3.278],
                    [4.277, 5.747],
                    [1.902, 8.312],
                    [-4.147, 4.606],
                    [-6.855, 4.826],
                    [25.93, 15.911],
                    [14.479, 15.921],
                    [10.497, 19.294],
                    [-3.995, 9.035],
                    [-32.409, 0],
                    [0, 0],
                    [-5.744, -2.146],
                    [-0.038, 5.719],
                    [1.616, 21.646],
                    [-9.54, 10.279],
                    [-16.41, 0],
                    [-13.393, -9.515],
                    [-15.322, -14.694],
                    [-0.503, 10.904],
                    [-10.247, 16.717],
                    [-34.837, 0],
                    [-1.465, 9.02],
                    [-2.236, 31.392],
                    [-5.643, 12.892],
                    [-15.286, 16.451],
                    [-11.326, 0],
                    [-7.059, -11.634],
                    [-5.039, 10.29],
                    [-5.887, 15.742],
                    [-14.783, 19.466],
                  ],
                  v: [
                    [-73.402, 477.982],
                    [-124.696, 440.302],
                    [-140.587, 392.654],
                    [-155.381, 288.284],
                    [-163.917, 267.586],
                    [-190.603, 292.751],
                    [-269.784, 354.43],
                    [-320.5, 329.177],
                    [-332.873, 251.687],
                    [-356.921, 285.659],
                    [-390.276, 334.451],
                    [-437.772, 357.602],
                    [-478.887, 337.419],
                    [-495.881, 278.857],
                    [-587.886, 303.911],
                    [-638.865, 275.298],
                    [-637.614, 227.409],
                    [-614.494, 191.646],
                    [-594.217, 163.489],
                    [-636.717, 161.355],
                    [-682.724, 162.362],
                    [-703.329, 162.76],
                    [-760.144, 156.961],
                    [-802.602, 114.972],
                    [-773.614, 59.102],
                    [-735.214, 39.976],
                    [-717.252, 34.727],
                    [-671.671, 20.104],
                    [-652.181, 10.147],
                    [-655.164, 6.886],
                    [-686.487, -31.643],
                    [-692.759, -84.539],
                    [-648.891, -116.802],
                    [-610.239, -119.607],
                    [-587.874, -119.33],
                    [-583.496, -119.25],
                    [-560.905, -118.215],
                    [-536.231, -117.2],
                    [-525.451, -117.659],
                    [-534.12, -141.096],
                    [-585.093, -224.437],
                    [-643.059, -385.994],
                    [-590.686, -415.37],
                    [-440.643, -352.371],
                    [-432.082, -347.031],
                    [-415.462, -336.695],
                    [-405.121, -390.112],
                    [-366.092, -408.688],
                    [-249.981, -336.403],
                    [-243.743, -374.642],
                    [-234.239, -401.01],
                    [-192.455, -428.53],
                    [-159.752, -413.909],
                    [-118.95, -440.477],
                    [-76.963, -415.419],
                    [-61.777, -385.982],
                    [-47.18, -345.333],
                    [-38.138, -387.555],
                    [-20.275, -443.424],
                    [29.589, -477.983],
                    [85.678, -436.039],
                    [108.372, -377.841],
                    [131.655, -272.523],
                    [176.555, -318.661],
                    [190.148, -332.805],
                    [286.767, -387.806],
                    [343.129, -357.533],
                    [361.559, -285.992],
                    [348.881, -215.5],
                    [344.175, -192.635],
                    [365.5, -209.744],
                    [455.297, -263.419],
                    [497.599, -240.393],
                    [486.209, -141.694],
                    [586.205, -189.773],
                    [677.523, -225.419],
                    [726.596, -190.556],
                    [708.915, -125.401],
                    [680.701, -94.766],
                    [736.457, -99.801],
                    [769.642, -95.327],
                    [802.74, -57.403],
                    [748.503, 13.865],
                    [668.788, 47.372],
                    [637.007, 57.531],
                    [644.345, 73.432],
                    [632.618, 114.37],
                    [615.75, 128.719],
                    [589.889, 145.18],
                    [689.48, 226.594],
                    [720.465, 267.656],
                    [725.72, 320.62],
                    [673.015, 350.669],
                    [542.079, 314.771],
                    [536.916, 312.839],
                    [513.853, 304.604],
                    [514.828, 328.453],
                    [500.258, 379.396],
                    [462.921, 395.336],
                    [407.459, 373.02],
                    [362.155, 334.588],
                    [319.727, 290.344],
                    [311.089, 319.612],
                    [261.789, 345.917],
                    [156.322, 281.235],
                    [152.434, 316.334],
                    [133.557, 400.216],
                    [113.736, 431.821],
                    [71.435, 451.726],
                    [22.892, 421.706],
                    [6.488, 375.622],
                    [-11.293, 417.73],
                    [-30.147, 454.43],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'mm',
              mm: 4,
              nm: 'Merge Paths 1',
              mn: 'ADBE Vector Filter - Merge',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.050980392843, 0.0941176489, 0.082352943718, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.929411764706, 0.098039215686, 0.309803921569, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [720, 440], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 5,
      op: 905,
      st: 5,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 4,
      nm: 'Purple',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 90, ix: 10 },
        p: { a: 0, k: [195, 422, 0], ix: 2, l: 2 },
        a: { a: 0, k: [720, 440, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.05, 0.05, 0.05], y: [1, 1, 1] },
              o: { x: [0.5, 0.5, 0.5], y: [0, 0, 0] },
              t: 0,
              s: [0, 0, 100],
            },
            { t: 61, s: [50, 50, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [33.783, 0],
                    [36.131, 73.211],
                    [5.489, 20.999],
                    [13.529, 0],
                    [23.9, 17.418],
                    [30.45, 0],
                    [25.245, 31.19],
                    [2.181, 3.206],
                    [12.619, 0],
                    [23.221, 17.592],
                    [5.985, 12.522],
                    [-2.431, 23.737],
                    [16.838, 4.737],
                    [17.615, 24.017],
                    [2.167, 13.584],
                    [-46.136, 32.605],
                    [-17.312, 6.656],
                    [0.136, 11.196],
                    [-6.655, 15.846],
                    [-46.807, 9.356],
                    [-1.758, 0.301],
                    [10.352, 28.851],
                    [-18.876, 36.448],
                    [-19.905, 14.91],
                    [-31.279, 0],
                    [-54.771, -28.892],
                    [-33.866, 0],
                    [-25.608, -14.9],
                    [-10.353, 6.618],
                    [-23.101, 0],
                    [-7.001, -1.087],
                    [-10.242, 0],
                    [-15.28, -6.689],
                    [-16.528, 8.118],
                    [-13.369, 0],
                    [-38.715, -72.28],
                    [-6.602, -18.827],
                    [-37.719, 0],
                    [-27.988, -40.696],
                    [-4.662, -20.583],
                    [-7.204, 0],
                    [-23.333, -37.365],
                    [-1.932, -3.89],
                    [-31.526, 0],
                    [-21.996, -15.627],
                    [-7.952, -27.474],
                    [1.608, -18.84],
                    [-12.265, -14.316],
                    [-3.252, -23.314],
                    [27.616, -30.225],
                    [15.978, -9.023],
                    [23.104, -8.538],
                    [4.012, -8.03],
                    [-11.598, -12.755],
                    [-9.389, -17.256],
                    [16.815, -38.018],
                    [20.865, -16.184],
                    [32.621, 0],
                    [35.085, 11.423],
                    [6.633, -7.147],
                    [36.457, 0],
                    [34.773, 24.705],
                    [12.492, 11.069],
                    [23.505, 0],
                    [16.99, 5.508],
                    [2.243, -5.125],
                    [13.479, -14.506],
                    [37.251, 0],
                    [16.75, 7.704],
                  ],
                  o: [
                    [-24.394, 0],
                    [-7.415, -15.026],
                    [-22.364, 9.161],
                    [-30.358, 0],
                    [-25.5, 19.796],
                    [-39.373, 0],
                    [-2.401, -2.966],
                    [-15.051, 3.093],
                    [-31.488, 0],
                    [-20.278, -15.364],
                    [-10.216, -21.381],
                    [-23.691, -0.887],
                    [-31.807, -8.95],
                    [-14.877, -20.288],
                    [-7.963, -49.908],
                    [14.439, -10.204],
                    [-2.928, -10.971],
                    [-0.214, -17.551],
                    [17.02, -40.531],
                    [1.724, -0.344],
                    [-28.36, -41.521],
                    [-16.466, -45.891],
                    [6.256, -12.08],
                    [23.517, -17.62],
                    [36.975, 0],
                    [23.56, -22.966],
                    [28.948, 0],
                    [8.193, -9.492],
                    [19.612, -12.537],
                    [5.001, 0],
                    [23.238, -11.754],
                    [16.993, 0],
                    [12.384, -14.714],
                    [22.175, -10.891],
                    [29.981, 0],
                    [7.317, 13.662],
                    [36.969, -28.027],
                    [48.628, 0],
                    [13.656, 19.858],
                    [7.161, -1.136],
                    [44.539, 0],
                    [2.335, 3.739],
                    [42.851, -23.058],
                    [27.883, 0],
                    [23.236, 16.506],
                    [3.637, 12.563],
                    [17.744, 7.869],
                    [15.237, 17.788],
                    [5.282, 37.882],
                    [-19.04, 20.838],
                    [-20.392, 11.512],
                    [-2.182, 8.578],
                    [12.943, 11.596],
                    [18.728, 20.592],
                    [22.019, 40.471],
                    [-5.824, 13.173],
                    [-23.607, 18.308],
                    [-29.958, 0],
                    [-4.831, 8.38],
                    [-24.448, 26.343],
                    [-32.914, 0],
                    [-11.09, -7.879],
                    [-19.417, 10.28],
                    [-16.494, 0],
                    [-1.984, 5.532],
                    [-9.162, 20.93],
                    [-26.833, 28.876],
                    [-18.904, 0],
                    [-24.964, 24.393],
                  ],
                  v: [
                    [-73.402, 555.898],
                    [-194.567, 474.783],
                    [-213.792, 421.128],
                    [-269.784, 432.346],
                    [-352.867, 405.38],
                    [-437.772, 435.518],
                    [-539.45, 386.439],
                    [-546.325, 377.176],
                    [-587.886, 381.826],
                    [-671.512, 354.936],
                    [-709.165, 308.897],
                    [-720.841, 240.359],
                    [-781.248, 231.964],
                    [-856.793, 181.571],
                    [-879.544, 127.249],
                    [-818.582, -4.526],
                    [-769.835, -30.376],
                    [-774.441, -63.659],
                    [-764.596, -114.708],
                    [-664.162, -193.206],
                    [-658.942, -194.173],
                    [-715.879, -297.739],
                    [-712.246, -421.827],
                    [-674.448, -466.351],
                    [-590.686, -493.285],
                    [-455.165, -450.74],
                    [-366.092, -486.603],
                    [-285.706, -462.96],
                    [-257.745, -487.281],
                    [-192.455, -506.445],
                    [-174.267, -505.018],
                    [-118.95, -518.392],
                    [-70.211, -508.175],
                    [-26.546, -542.721],
                    [29.589, -555.898],
                    [154.362, -472.826],
                    [175.289, -423.967],
                    [286.767, -465.721],
                    [407.326, -401.685],
                    [433.76, -339.634],
                    [455.297, -341.334],
                    [563.688, -281.661],
                    [570.089, -270.216],
                    [677.523, -303.335],
                    [753.767, -279.447],
                    [801.44, -212.219],
                    [806.028, -164.674],
                    [851.646, -130.991],
                    [879.909, -68.165],
                    [845.276, 37.449],
                    [786.812, 81.714],
                    [719.581, 112.624],
                    [710.267, 137.61],
                    [747.123, 174.173],
                    [788.907, 230.42],
                    [796.977, 352.136],
                    [758.962, 400.601],
                    [673.015, 428.585],
                    [574.583, 409.062],
                    [557.368, 432.399],
                    [462.921, 473.251],
                    [362.331, 436.536],
                    [326.881, 408.049],
                    [261.789, 423.832],
                    [211.278, 415.46],
                    [204.934, 431.459],
                    [170.813, 484.861],
                    [71.435, 529.641],
                    [17.531, 517.857],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'mm',
              mm: 4,
              nm: 'Merge Paths 1',
              mn: 'ADBE Vector Filter - Merge',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.050980392843, 0.0941176489, 0.082352943718, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.364705882353, 0.207843152214, 0.898039275525, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [720, 440], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 6',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 900,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 10,
      ty: 1,
      nm: 'Red Solid 2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [195, 422, 0], ix: 2, l: 2 },
        a: { a: 0, k: [720, 440, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      sw: 1440,
      sh: 880,
      sc: '#ed194f',
      ip: 0,
      op: 900,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
  props: {},
}
